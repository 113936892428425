<div class="row main-row">
  <div class="col-lg-12">
    <dashboard-widget-welcome-card></dashboard-widget-welcome-card>
  </div>

  <div class="col-lg-12">
    <div class="row">
      <div
        class="col-12 col-sm-6 col-md-4 mb-3"
        *ngFor="let manual of userManualList; let i = index"
      >
        <div class="manual-box" (click)="onClickUserManual(manual)">
          <img
            width="100%"
            [attr.src]="
              global.config.BACKEND_ADDRESS +
                '/dashboard/file/view/' +
                manual.thumbnail.uploadedFileName +
                '/' +
                global.appConstant.fileType.IMG_USER_MANUAL
                | resolveImgSrc
                | async
            "
            attr.alt="image"
            style="height: 200px; object-fit: cover; cursor: pointer"
            [disabled]="true"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <dashboard-widget
      [model]="widgetModel"
      [isLoading]="formLoading"
    ></dashboard-widget>
  </div>
</div>
