import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { PatientCondition } from 'src/app/core/bean/patient-condition';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { PatientConditionRequest } from './patient-condition.request';
import { PatientConditionResponse } from './patient-condition.response';
@Component({
  templateUrl: './patient-condition-edit-add.component.html'
})
export class PatientConditionEditAddComponent extends BaseModuleComponent {
  public patientConditionId: number;
  public patientConditionResponse: PatientConditionResponse;
  public patientCondition: PatientCondition;
  constructor(translateService: TranslateService) {
    super('patient-condition', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.patientConditionId = this.global.routerParams.get('patientConditionId');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required()])
      ],
      name: [
        null,
        Validators.compose([Validators.required()])
      ]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<PatientConditionResponse>(
        '/patient-condition/add-edit',
        new RouteRequestModel(this.todo, this.patientConditionId)
      )
      .subscribe(response => {
        this.patientConditionResponse = response;
        if (this.patientConditionResponse.patientCondition != null) {
          this.patientCondition = this.patientConditionResponse.patientCondition;
          this.formGroup.patchValue({
            id: this.patientCondition.id,
            code: this.patientCondition.code,
            name: this.patientCondition.name
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            const patientConditionRequest: PatientConditionRequest = this.formGroup.value;

            const url =
              this.todo === 'edit' ? '/patient-condition/update' : '/patient-condition/insert';
            this.httpClientService
              .post<Response<PatientCondition>>(url, patientConditionRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/patient-condition/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/patient-condition/']);
  }
}
