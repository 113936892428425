import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { MasterFaqResponse } from './master-faq.response';
import { MasterFaqRequest } from './master-faq.request';
import { FaqType } from 'src/app/core/bean/faq-type';
import { Faq } from 'src/app/core/bean/faq';
@Component({
  templateUrl: './master-faq-edit-add.component.html'
})
export class MasterFaqEditAddComponent extends BaseModuleComponent {
  public faqCode: string;
  public faqTypeOptionList: OptionListModel<FaqType> =
    new OptionListModel(false);
  public masterFaqResponse: MasterFaqResponse;
  public faqIndo: Faq;
  public faqEng: Faq;
  constructor(translateService: TranslateService) {
    super('master-faq', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.faqCode = this.global.routerParams.get('faqCode');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null],
      faqType: [
        null,
        Validators.compose([Validators.required()])
      ],
      groupText: [
        null,
        Validators.compose([Validators.required()])
      ],
      questionIndo: [
        null,
        Validators.compose([Validators.required()])
      ],
      answerIndo: [
        null,
        Validators.compose([Validators.required()])
      ],
      questionEng: [
        null,
        Validators.compose([Validators.required()])
      ],
      answerEng: [
        null,
        Validators.compose([Validators.required()])
      ],

    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<MasterFaqResponse>(
        '/master-faq/add-edit',
        {
          todo: this.todo,
          faqCode: this.faqCode
        }
      )
      .subscribe(response => {
        this.masterFaqResponse = response;
        this.faqTypeOptionList.setRequestValues(
          this.masterFaqResponse.faqTypeList
        );
        if (this.masterFaqResponse.faqList != null) {
          this.masterFaqResponse.faqList.forEach((faq) => {
            if (faq?.lang?.code === this.global.appConstant.core.LANG_CODE_INDONESIA) {
              this.faqIndo = faq;
            } else {
              this.faqEng = faq;
            }
          });
          this.formGroup.patchValue({
            id: this.faqIndo.id,
            code: this.faqCode,
            faqType: this.faqIndo.faqType,
            groupText: this.faqIndo.groupText,
            questionIndo: this.faqIndo.question,
            answerIndo: this.faqIndo.answer,
            questionEng: this.faqEng.question,
            answerEng: this.faqEng.answer
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            const faqList = [
              {
                id: this.faqIndo?.id,
                code: this.formGroup.value.code,
                faqType: this.formGroup.value.faqType,
                groupText: this.formGroup.value.groupText,
                question: this.formGroup.value.questionIndo,
                answer: this.formGroup.value.answerIndo,
                lang: { code: this.global.appConstant.core.LANG_CODE_INDONESIA }
              } as Faq,
              {
                id: this.faqEng?.id,
                code: this.formGroup.value.code,
                faqType: this.formGroup.value.faqType,
                groupText: this.formGroup.value.groupText,
                question: this.formGroup.value.questionEng,
                answer: this.formGroup.value.answerEng,
                lang: { code: this.global.appConstant.core.LANG_CODE_ENGLISH }
              } as Faq
            ];

            const masterFaqRequest = new MasterFaqRequest();
            masterFaqRequest.faqList = faqList;

            const url =
              this.todo === 'edit' ? '/master-faq/update' : '/master-faq/insert';
            this.httpClientService
              .post<Response<Faq>>(url, masterFaqRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/master-faq/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/master-faq/']);
  }
}
