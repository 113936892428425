import { Component, OnInit } from '@angular/core';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { BaseComponentComponent } from '../../../../core/base/angular/base-component.component';
import { UserManual } from '../../../../core/bean/user-manual';
import { ToastService } from '../../../../core/components/app-toast/app-toast.service';
import { DownloadService } from '../../../../core/services/download.service';
import { AppPopupUserManualComponent } from './app-popup-user-manual.component';

@Component({
  selector: 'theme-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent extends BaseComponentComponent implements OnInit {
  public userManualList: UserManual[] = [];
  constructor(
    public downloadService: DownloadService,public appPopupService: AppPopupService,
    public toastService: ToastService
  ) {
    super('help');
  }

  public onInit(): void {
    this.log.debug();
  }

  public doClick(): void {
    this.loadingBlockService.showInfo('app.msg.processing');
    const url = '/user-manual/user-manual-list';
    this.httpClientService
      .get<UserManual[]>(url)
      .subscribe(userManualList => {
        this.appPopupService.open(AppPopupUserManualComponent,{userManualList:userManualList})
      });
  }
 
}
