<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="user-manual.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'user-manual.form.role' | translate
        }}</label>
        <label class="col-sm-7 text-left">{{ role.name }}</label>
      </div>
      <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'user-manual.isShowOnDashboard' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <app-toggle-switch
              formControlName="isShowOnDashboard"
            ></app-toggle-switch>
          </div>
        </div>
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <div class="col-lg-12 mb-4">
            <app-tab  formArrayName="userManual" bordered="true" class="col-lg-12 mb-4">
              <app-tab-item  header="{{ userManual.value.name }}" *ngFor="
                let userManual of getFormArray(formGroup, 'userManual').controls;
                let i = index " [formGroup]="userManual">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-left" scope="col" style="width: 5%">{{
                          'app.table.column.no' | translate
                        }}</th>
                        <th class="text-center" scope="col" style="width: 15%">{{
                          'user-manual.form.type' | translate
                        }}</th>
                        <th class="text-center" scope="col" style="width: 30%">{{
                          'user-manual.form.title' | translate
                        }}</th>
                        <th class="text-center" scope="col" style="width: 25%">{{
                          'user-manual.form.content' | translate
                        }}</th>
                        <th class="text-center" scope="col" style="width: 25%">{{
                          'user-manual.form.thumbnail' | translate
                        }}</th>
                      </tr>
                    </thead>
                    <tbody formArrayName="userManualDetail">
                      <tr
                      *ngFor=" let userManualDetail of getFormArray(userManual, 'userManualDetail').controls;
                        let j = index" [formGroup]="userManualDetail">
                        <td class="text-left" >{{ j + 1 }}</td>
                        <td>
                          <app-dropdown-select
                            size="XL"
                            [optionList]="userManualTypeList"
                            formControlName="userManualType"
                            type="COMBOBOX"
                            (onChange)="onUserManualTypeChange(userManualDetail.value.userManualType.code, j)"
                          ></app-dropdown-select>

                        </td>
                        <td>
                          <app-text-field
                            formControlName="title"
                            autofocus="true"
                            size="XL"
                          ></app-text-field>
                        </td>

                        <td>
                          <ng-container *ngIf="selectedType[j] === 'DOCUMENT' || userManualDetail.value?.userManualType?.code === this.global.appConstant.pm.USER_MANUAL_TYPE_CODE_DOCUMENT; else linkOrEmptyField">
                            <app-upload
                              [fileUploader]="fileUploader"
                              formControlName="file"
                              size="L"
                            ></app-upload>
                          </ng-container>
                          <ng-template #linkOrEmptyField>
                            <ng-container *ngIf="selectedType[j] === 'LINK' || userManualDetail.value?.userManualType?.code === this.global.appConstant.pm.USER_MANUAL_TYPE_CODE_LINK; else emptyField">
                              <app-text-field
                                formControlName="link"
                                size="L"
                              ></app-text-field>
                            </ng-container>
                            <ng-template #emptyField>
                              <span></span>
                            </ng-template>
                          </ng-template>                          
                        </td>
                        <td>
                          <app-upload
                              [fileUploader]="thumbnailUploader"
                              formControlName="thumbnail"
                              size="L"
                            ></app-upload>                    
                        </td>
                      </tr>
                    </tbody>
                  </table>
              </app-tab-item> 
            </app-tab>            
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
