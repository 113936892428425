import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { BudgetAllocation } from 'src/app/core/bean/budget-allocation';
import { CatalogBulkPrice } from 'src/app/core/bean/catalog-bulk-price';
import { CatalogContract } from 'src/app/core/bean/catalog-contract';
import { CatalogSpecification } from 'src/app/core/bean/catalog-specification';
import { Currency } from 'src/app/core/bean/currency';
import { AppPopupChooseBudgetXService } from 'src/app/core/components/app-popup/app-popup-choose-budget-x/app-popup-choose-budget-x.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { FileObject } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Validators } from 'src/app/core/validators';
import { CatalogView } from 'src/app/core/view/entity/bean/catalog-view';
import { OrderAddEditResponse } from '../response/order-add-edit.response';
import { PrPopupItemResponse } from './pr-popup-item.response';
import { AppPopupPrAddBudgetComponent } from './app-popup-pr-add-budget.component';
import { AppPopupItemContractCatalogDetailInfoComponent } from 'src/app/core/components/app-catalog-detail-info/app-popup-item-contract-catalog-detail-info.component';

@Component({
  templateUrl: './app-popup-pr-item-edit.component.html',
  styleUrls: ['./app-popup-pr-item-edit.component.scss']
})
export class AppPopupPrItemEditComponent extends BaseComponentComponent {
  @Input() public prItemEdit: any;
  @Input() public orderResponse: OrderAddEditResponse;
  @Input() public prItemBudgetEditList: Map<number, number> = new Map<
    number,
    number
  >();
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  public fileList: File[] = [];
  public catalogSpecificationList: CatalogSpecification[] = [];
  public catalogContract: CatalogContract;
  public catalogView: CatalogView = new CatalogView();
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true,
    'code'
  );
  public tableResponse: TableResponseModel<BudgetAllocation>;
  public tableResponseNonESM: TableResponseModel<BudgetAllocation>;
  public usedBudgetList: Map<number, number> = new Map<number, number>(); // budgetAllocationId, prItemBugetAmount
  public prPopupItemResponse: PrPopupItemResponse = new PrPopupItemResponse();
  public isShowDiscount = false;
  public validateQuantity = false;
  // public isQuantityInPackage = false;
  public catalogBulkPriceList: CatalogBulkPrice[] =
    []; /** specific by catalog contract */

  constructor(
    public translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupChooseBudgetService: AppPopupChooseBudgetXService
  ) {
    super('pr');
  }

  onInit(): void {
    this.setOptionModel();
    this.setUsedBudgetList();
    this.buildTableResponse();
    this.buildFormGroup();
    this.setCatalogContract();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      pr: [null],
      item: [null],
      price: [null],
      totalPrice: [null],
      discount: [null],
      quantity: [
        null,
        Validators.compose([Validators.required(), Validators.min(1)])
      ],
      remainingQuantity: [null],
      specification: [null],
      itemImages: [null],
      totalBudget: [null],
      catalog: [null],
      catalogContract: [null],
      prItemBudgetList: this.formBuilder.array([]),
      budgetAllocationList: this.formBuilder.array([]),
      prItemImageList: this.formBuilder.array([]),
      recordList: this.tableResponse.formArray,
      workflowModelPrcs: [null],
      catalogRegionDetail: [null],
      merk: [null]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'organization.name',
        header: 'table.column.department'
      },
      {
        field: 'activityPeriod',
        header: 'table.column.activityPeriod'
      },
      {
        field: 'costCenter.code',
        header: 'table.column.costCenter'
      },
      {
        field: 'coa.code',
        header: 'table.column.coa'
      },
      {
        field: 'budgetType.name',
        header: 'table.column.budgetType'
      },
      {
        field: 'availableBudget',
        header: 'table.column.availableBudget',
        className: 'text-right',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): number => {
              if (tablePlugin.value) {
                const available = this.prItemBudgetEditList.get(
                  tablePlugin.row.record.id
                )
                  ? tablePlugin.value -
                  tablePlugin.row.record.tempBookedBudget -
                  this.usedBudgetList.get(tablePlugin.row.record.id) +
                  this.prItemBudgetEditList.get(tablePlugin.row.record.id)
                  : this.usedBudgetList.get(tablePlugin.row.record.id)
                    ? tablePlugin.value -
                    tablePlugin.row.record.tempBookedBudget -
                    this.usedBudgetList.get(tablePlugin.row.record.id)
                    : tablePlugin.value -
                    +tablePlugin.row.record.tempBookedBudget;

                return available;
              }
            }
          },
          {
            name: 'default',
            type: 'currency'
          }
        ]
      },
      {
        field: 'budgetValue',
        header: 'table.column.budgetValue',
        className: 'text-right',
        plugins: [
          {
            name: 'currency',
            optionList: this.currencyOptionList,
            validators: Validators.required(),
            onInput: this.onChangeBudgetValue.bind(this)
          }
        ]
      }
    ]);

    this.tableResponseNonESM = new TableResponseModel(this.moduleCode, [
      {
        field: 'organization.name',
        header: 'table.column.department'
      },
      {
        field: 'activityPeriod',
        header: 'table.column.activityPeriod'
      },
      {
        field: 'costCenter.code',
        header: 'table.column.costCenter'
      },
      {
        field: 'coa.code',
        header: 'table.column.coa'
      },
      {
        field: 'budgetType.name',
        header: 'table.column.budgetType'
      },
      {
        field: 'budgetValue',
        header: 'table.column.budgetValue',
        className: 'text-right',
        plugins: [
          {
            name: 'currency',
            optionList: this.currencyOptionList,
            validators: Validators.required(),
            onInput: this.onChangeBudgetValue.bind(this)
          }
        ]
      }
    ]);
  }

  public setCatalogContract(): void {
    if (
      this.prItemEdit.catalog?.catalogType.code ===
      this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
    ) {
      this.catalogContract = this.prItemEdit.catalogContract;
      // this.catalogContract.remainingQuantity =
      //   +this.prItemEdit.catalogRegionDetail.remainingQuantity;
      // this.catalogContract.quantity =
      //   +this.prItemEdit.catalogRegionDetail.quantity;
      const catalogContractId = this.catalogContract.id;
      this.httpClientService
        .get<PrPopupItemResponse>(
          '/pr/get-by-catalog-contract-id/' + catalogContractId
        )
        .subscribe((prPopupItemResponse: PrPopupItemResponse) => {
          this.prPopupItemResponse = prPopupItemResponse;
          this.catalogView = prPopupItemResponse.catalogView;
          if (this.catalogContract.isBulkPrice) {
            const quantity = +this.prItemEdit.quantity;
            const prices = +this.prItemEdit.price;
            let discountCatalog = 0;

            this.catalogBulkPriceList = prPopupItemResponse.catalogBulkPriceList
              ? prPopupItemResponse.catalogBulkPriceList.filter(
                cbulkPrice =>
                  cbulkPrice.catalogContract.id === this.catalogContract.id
              )
              : [];
            this.catalogBulkPriceList.forEach(
              (catalogBulkPrice: CatalogBulkPrice, i: number) => {
                if (
                  this.catalogBulkPriceList[i + 1]
                    ? quantity >= catalogBulkPrice.minimumOrderTotal &&
                    quantity <
                    this.catalogBulkPriceList[i + 1]?.minimumOrderTotal
                    : quantity >= catalogBulkPrice.minimumOrderTotal
                ) {
                  discountCatalog = catalogBulkPrice.discount;
                  this.isShowDiscount = true;
                }
              }
            );
            if (prices && +quantity) {
              const discount = +prices * +quantity * +(+discountCatalog / 100);
              this.formGroup.patchValue({
                discount
              });
            }
          } else {
            this.isShowDiscount = false;
          }
        });
      // if (this.catalogContract) {
      //   this.formGroup.patchValue({
      //     remainingQuantity: this.catalogContract.remainingQuantity
      //   });
      // }
    }
  }

  public setFormGroup(): void {
    this.formGroup.patchValue({
      id: this.prItemEdit.id,
      pr: this.prItemEdit.pr,
      item: this.prItemEdit.item,
      price: this.prItemEdit.price,
      totalPrice: this.prItemEdit.totalPrice,
      quantity: this.prItemEdit.quantity,
      specification: this.prItemEdit.specification,
      itemImages: this.prItemEdit.itemImages,
      totalBudget: this.prItemEdit.totalPrice,
      catalog: this.prItemEdit.catalog,
      catalogContract: this.prItemEdit.catalogContract,
      workflowModelPrcs: this.prItemEdit.workflowModelPrcs,
      catalogRegionDetail: this.prItemEdit.catalogRegionDetail,
      merk: this.prItemEdit.merk
    });
    this.setBudgetAllocationList();
    const prItemImageList = this.formGroup.get('prItemImageList') as FormArray;
    this.prItemEdit.prItemImageList.forEach(prItemImage => {
      const fileObjectList: FileObject[] = [];
      fileObjectList.push(prItemImage.fileObjectList);
      this.fileList.push(prItemImage.file);
      prItemImageList.push(
        this.formBuilder.group({
          fileObjectList,
          file: prItemImage.file
        })
      );
    });
    this.formGroup.get('specification').setIsView(true);

    if (this.orderResponse.isModuleGroupESMActive) {
      this.tableResponse.setRecordList(this.budgetAllocationList.value);
      this.tableResponse.reload();
    } else {
      this.tableResponseNonESM.setRecordList(this.budgetAllocationList.value);
      this.tableResponseNonESM.reload();
    }

    this.setStateReady();
  }

  public setOptionModel(): void {
    this.currencyOptionList.setRequestValues(this.orderResponse.currencyList);
  }

  public get budgetAllocationList(): FormArray {
    return this.formGroup.get('budgetAllocationList') as FormArray;
  }

  public setBudgetAllocationList(): void {
    const prItemBudgetList = this.prItemEdit.prItemBudgetList;
    const prItemBudgetLists = this.formGroup.get(
      'prItemBudgetList'
    ) as FormArray;
    prItemBudgetList.forEach(prItemBudget => {
      this.budgetAllocationList.push(
        this.formBuilder.group({
          id: prItemBudget.budgetAllocation.id,
          organization: prItemBudget.budgetAllocation.organization,
          costCenter: prItemBudget.budgetAllocation.costCenter,
          coa: prItemBudget.budgetAllocation.coa,
          activityPeriod: prItemBudget.budgetAllocation.activityPeriod,
          availableBudget: prItemBudget.budgetAllocation.availableBudget,
          currency: prItemBudget.budgetAllocation.currency,
          budgetType: prItemBudget.budgetAllocation.budgetType,
          tempBookedBudget: prItemBudget.budgetAllocation.tempBookedBudget,
          budgetValue: {
            price: prItemBudget.bookedAmount
          }
        })
      );
      prItemBudgetLists.push(
        this.formBuilder.group({
          id: prItemBudget.id,
          budgetAllocation: prItemBudget.budgetAllocation,
          bookedAmount: prItemBudget.bookedAmount
        })
      );
    });
  }

  public onChangeQuantity(): void {
    const prices = this.formGroup.value.price;
    let quantity = this.formGroup.value.quantity;
    quantity = !isNaN(quantity) ? Number(quantity) : quantity;
    this.formGroup.get('quantity').patchValue(quantity);
    let totalPrice = null;
    if (
      this.prItemEdit.catalog?.catalogType.code ===
      this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
    ) {
      // const quantityForm = this.formGroup.get('quantity');
      // if (+this.catalogContract.remainingQuantity < +quantity) {
      //   quantityForm.setValidators(
      //     Validators.compose([
      //       Validators.required(),
      //       Validators.max(+this.catalogContract.remainingQuantity)
      //     ])
      //   );

      //   quantityForm.setErrors({
      //     message: this.translateService.instant('app.validation.max')
      //   });
      //   quantityForm.markAsDirty();
      //   quantityForm.markAsTouched();
      // }
      // const remainingQuantity =
      //   (+this.formGroup.get('catalogContract').value.remainingQuantity + this.prItemEdit.quantity) -
      //   +quantity;
      // this.formGroup.patchValue({
      //   remainingQuantity
      // });
      if (this.catalogContract.isBulkPrice) {
        let discountCatalog = 0;
        this.catalogBulkPriceList = this.prPopupItemResponse
          .catalogBulkPriceList
          ? this.prPopupItemResponse.catalogBulkPriceList.filter(
            cbulkPrice =>
              cbulkPrice.catalogContract.id === this.catalogContract.id
          )
          : [];
        this.catalogBulkPriceList.forEach(
          (catalogBulkPrice: CatalogBulkPrice, i: number) => {
            if (
              this.catalogBulkPriceList[i + 1]
                ? quantity >= catalogBulkPrice.minimumOrderTotal &&
                quantity < this.catalogBulkPriceList[i + 1]?.minimumOrderTotal
                : quantity >= catalogBulkPrice.minimumOrderTotal
            ) {
              discountCatalog = catalogBulkPrice.discount;
              this.isShowDiscount = true;
            }
          }
        );
        if (prices && +quantity && +discountCatalog) {
          const discount = +prices * +quantity * +(+discountCatalog / 100);
          totalPrice = (+(+prices * +quantity) - +discount).toFixed(2);
          this.formGroup.patchValue({
            price: prices,
            discount
          });
        } else if (prices && +quantity) {
          const price =
            this.catalogContract?.price ||
            this.prItemEdit.catalogContract?.price ||
            (this.prItemEdit.catalog
              ? this.prItemEdit.catalog.price
              : this.prItemEdit.price);
          totalPrice = +(+price * +quantity);
          this.formGroup.patchValue({
            price: price,
            discount: +discountCatalog
          });
        }
        this.formGroup.patchValue({
          totalPrice
        });
      } else {
        if (prices && +quantity) {
          totalPrice = +(+prices * +quantity);
          this.formGroup.patchValue({
            price: prices
          });
        }
        this.formGroup.patchValue({
          totalPrice
        });
      }
    } else {
      if (prices && +quantity) {
        totalPrice = +(+prices * +quantity);
        this.formGroup.patchValue({
          price: prices
        });
      }
      this.formGroup.patchValue({
        totalPrice
      });
    }

    // if(this.isQuantityInPackage){
    //   const quantity = this.formGroup.value.quantity;
    //   const quantityInPackage = this.catalogView.quantityInPackage;
    //   if(quantity % quantityInPackage === 0){
    //     this.isQuantityInPackage = false;
    //   }
    // }
  }

  public doAddBudget(): void {
    const prItemBudgetList = this.formGroup.get(
      'prItemBudgetList'
    ) as FormArray;
    const orderResponse = this.orderResponse;
    this.appPopupService
      .open(AppPopupPrAddBudgetComponent, { orderResponse, todo: 'add' })
      .subscribe(budgetAllocation => {
        const budgetValue = {
          price: budgetAllocation.totalBudget,
          currency: this.orderResponse.currencyList[0]
        };

        const formGroup = this.formBuilder.group({
          id: budgetAllocation.id,
          organization: budgetAllocation.organization,
          costCenter: budgetAllocation.costCenter,
          coa: budgetAllocation.coa,
          activityPeriod: budgetAllocation.activityPeriod,
          availableBudget: budgetAllocation.totalBudget,
          currency: budgetAllocation.currency,
          budgetValue: budgetValue,
          totalBudget: budgetAllocation.totalBudget,
          budgetType: budgetAllocation.budgetType,
          tempBookedBudget: budgetAllocation.tempBookedBudget
        });
        this.budgetAllocationList.push(formGroup);
        const formGroupItem = this.formBuilder.group({
          prItem: null,
          budgetAllocation,
          bookedAmount: budgetAllocation.totalBudget
        });
        prItemBudgetList.push(formGroupItem);
        this.tableResponseNonESM.setRecordList(this.budgetAllocationList.value);
        this.tableResponseNonESM.reload();
      });
  }

  public doChooseBudget(): void {
    const budgetAllocationExistList = this.formGroup.value.budgetAllocationList;
    const budgetAllocationIdList: number[] = budgetAllocationExistList.map(
      budget => budget.id
    );
    const budgetAllocationList: BudgetAllocation[] = this.formGroup
      .get('prItemBudgetList')
      .value.map(prItemBudget => prItemBudget.budgetAllocation);
    const prItemBudgetExistList = this.formGroup.get('prItemBudgetList').value;
    this.appPopupChooseBudgetService
      .open(
        true,
        budgetAllocationList,
        this.usedBudgetList,
        this.prItemBudgetEditList
      )
      .subscribe(budgetAllocationListTemp => {
        const budgetAllocationLists = this.formGroup.get(
          'budgetAllocationList'
        ) as FormArray;
        budgetAllocationLists.clear();
        const prItemBudgetList = this.formGroup.get(
          'prItemBudgetList'
        ) as FormArray;
        prItemBudgetList.clear();
        budgetAllocationListTemp.forEach(budgetAllocation => {
          let budgetValue = null;
          let prItemBudgetId = null;

          if (budgetAllocationIdList.includes(budgetAllocation.id)) {
            budgetValue = budgetAllocationExistList.filter(
              budget => budget.id === budgetAllocation.id
            )[0].budgetValue;
            prItemBudgetId = prItemBudgetExistList.filter(
              prItemBudget =>
                (prItemBudget.budgetAllocation.id = budgetAllocation.id)
            )[0].id;
          }
          const formGroup = this.formBuilder.group({
            id: budgetAllocation.id,
            organization: budgetAllocation.organization,
            costCenter: budgetAllocation.costCenter,
            budgetType: budgetAllocation.budgetType,
            coa: budgetAllocation.coa,
            activityPeriod: budgetAllocation.activityPeriod,
            availableBudget: budgetAllocation.availableBudget,
            currency: budgetAllocation.currency,
            tempBookedBudget: budgetAllocation.tempBookedBudget,

            budgetValue
          });

          const formGroupItem = this.formBuilder.group({
            id: prItemBudgetId,
            prItem: null,
            budgetAllocation,
            bookedAmount: budgetValue ? +budgetValue.price : budgetValue
          });
          budgetAllocationLists.push(formGroup);
          prItemBudgetList.push(formGroupItem);
        });

        this.tableResponse.setRecordList(budgetAllocationLists.value);
        this.tableResponse.reload();
      });
  }

  public onChangeBudgetValue(event): void {
    if (this.orderResponse.isModuleGroupESMActive) {
      const tableRow = this.tableResponse.currentRowChange.row;
      const availableBudget = tableRow.columnList[5].value;

      const budgetValue = tableRow.formGroup.get('budgetValue');
      if (
        (budgetValue.value !== null && +budgetValue.value.price) >
        +availableBudget
      ) {
        budgetValue.setValidators([Validators.max(+availableBudget)]);
        budgetValue.setErrors({
          message: this.translateService.instant('app.validation.max')
        });
        budgetValue.markAsTouched({ onlySelf: true });
      } else {
        const prItemBudgetList = this.formGroup.get(
          'prItemBudgetList'
        ) as FormArray;
        prItemBudgetList.controls[tableRow.position - 1].patchValue({
          bookedAmount: +budgetValue.value?.price
        });
        (this.formGroup.get('budgetAllocationList') as FormArray).controls[
          tableRow.position - 1
        ]
          .get('budgetValue')
          .patchValue(event);
        this.formGroup
          .get('totalBudget')
          .patchValue(this.getTotalBudgetValue());
      }
    } else {
      const tableRow = this.tableResponseNonESM.currentRowChange.row;

      const budgetValue = tableRow.formGroup.get('budgetValue');
      const prItemBudgetList = this.formGroup.get(
        'prItemBudgetList'
      ) as FormArray;
      prItemBudgetList.controls[tableRow.position - 1].patchValue({
        bookedAmount: +budgetValue.value?.price
      });
      (this.formGroup.get('budgetAllocationList') as FormArray).controls[
        tableRow.position - 1
      ]
        .get('budgetValue')
        .patchValue(event);
      this.formGroup.get('totalBudget').patchValue(this.getTotalBudgetValue());
      const budgetAllocation: BudgetAllocation =
        prItemBudgetList.controls[tableRow.position - 1].get(
          'budgetAllocation'
        ).value;
      budgetAllocation.totalBudget = budgetValue.value
        ? +budgetValue.value.price
        : null;
      budgetAllocation.availableBudget = budgetValue.value
        ? +budgetValue.value.price
        : null;
      prItemBudgetList.controls[tableRow.position - 1].patchValue({
        budgetAllocation
      });
    }
  }

  public getTotalBudgetValue(): number {
    let totalBudgetValue = 0;
    const budgetAllocationList = this.formGroup.get(
      'budgetAllocationList'
    ) as FormArray;
    budgetAllocationList.controls.forEach((budget: FormGroup) => {
      if (budget.get('budgetValue').value) {
        totalBudgetValue =
          totalBudgetValue + +budget.get('budgetValue').value.price;
      }
    });
    return totalBudgetValue;
  }

  public doDeleteBudget(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.selectedRecordList.forEach(record => {
            const tableResponse = this.orderResponse.isModuleGroupESMActive
              ? this.tableResponse
              : this.tableResponseNonESM;

            const indexOfRecord = this.tableResponse
              .getRecordList()
              .findIndex(budget => budget.id === record.id);

            if (this.usedBudgetList.get(record.id)) {
              this.usedBudgetList.set(
                record.id,
                +this.usedBudgetList.get(record.id) - +record.budgetValue?.price
              );
            }

            tableResponse.getRecordList().splice(indexOfRecord, 1);
            tableResponse.reload();
            const budgetAllocationList = this.formGroup.get(
              'budgetAllocationList'
            ) as FormArray;
            const prItemBudgetExistList = this.formGroup.get(
              'prItemBudgetList'
            ) as FormArray;

            budgetAllocationList.removeAt(indexOfRecord);
            prItemBudgetExistList.removeAt(indexOfRecord);
          });
          this.formGroup
            .get('totalBudget')
            .patchValue(this.getTotalBudgetValue());
        }
      });
  }

  public doSave(): void {
    const quantity = this.formGroup.value.quantity;
    this.validate();
    if (quantity >= this.catalogView.catalogRegion.quantityInPackage && quantity % this.catalogView.catalogRegion.quantityInPackage == 0) {
      this.validateQuantity = false;
      if (this.formGroup.valid) {
        this.onChange.emit(this.formGroup.value);
        // const quantity = this.formGroup.value.quantity;
        // const quantityInPackage = this.catalogView.quantityInPackage;
        // if(quantity % quantityInPackage === 0){
        //   if (
        //     this.prItemEdit.catalog?.catalogType.code ===
        //     this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
        //   ) {
        //     this.catalogContract.remainingQuantity =
        //       this.formGroup.get('remainingQuantity').value;
        //     this.formGroup.get('catalogContract').patchValue(this.catalogContract);
        //   }
        //   this.onChange.emit(this.formGroup.value);
        // } else {
        //   this.isQuantityInPackage = true;
        // }
      }
    } else {
      this.validateQuantity = true;
    }

  }

  public setUsedBudgetList(): void {
    // usedBudgetList dari item2 yang sudah di tambahkan
    this.orderResponse.prItemList.forEach(prItem => {
      prItem.prItemBudgetList.forEach(prItemBudget => {
        if (this.usedBudgetList.get(prItemBudget.budgetAllocation.id)) {
          this.usedBudgetList.set(
            prItemBudget.budgetAllocation.id,
            +prItemBudget.bookedAmount +
            +this.usedBudgetList.get(prItemBudget.budgetAllocation.id)
          );
        } else {
          this.usedBudgetList.set(
            prItemBudget.budgetAllocation.id,
            +prItemBudget.bookedAmount
          );
        }
      });
    });
  }

  public doViewItemContract(): void {
    this.appPopupService
      .open(
        AppPopupItemContractCatalogDetailInfoComponent,
        {
          catalogContractList: this.prPopupItemResponse.catalogContractList,
          catalogBulkPriceList: this.prPopupItemResponse.catalogBulkPriceList,
          selectedCatalogContract: this.catalogContract
        },
        { size: 'xl' }
      )
      .subscribe((catalogContract: CatalogContract) => {
        this.catalogContract = catalogContract;
        this.catalogBulkPriceList = catalogContract
          ? catalogContract['catalogBulkPriceList']
          : [];

        if (this.catalogContract) {
          const quantityForm = this.formGroup.get('quantity');
          quantityForm.setValidators(
            Validators.compose([
              Validators.required(),
              Validators.max(+this.catalogContract.remainingQuantity)
            ])
          );
          quantityForm.updateValueAndValidity();

          this.onChangeQuantity();
        }
      });
  }
}
