/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */

export class CoreConstant {
  public NUMBER_PRECISSION_SCALE = 2;
  public CURRENCY_PRECISSION_SCALE = 2;
  public CURRENCY_MAX_INPUT_PRECISSION_SCALE = 3;

  public RESOURCE_LOGO_BIG = 'RESOURCE_LOGO_BIG';
  public RESOURCE_LOGO_SMALL = 'RESOURCE_LOGO_SMALL';
  public RESOURCE_BACKGROUND_IMAGE = 'RESOURCE_BACKGROUND_IMAGE';

  public TOKEN_KEY = 'AppTokenKey';
  public COOKIES_APP_ACTIVE_ROLE_CODE = 'AppActiveRoleCode';

  public ROLE_TYPE_OTHER = 1;
  public ROLE_TYPE_COMMITTEE = 2;
  public ROLE_TYPE_VENDOR = 3;
  public ROLE_TYPE_USER = 4;

  public LOGIN_SALT_KEY = 'bfcd13d88b8f05a3b91c7f77dc20c366';
  public LOGIN_IV_KEY = '4bf264874e8e0b0f46e55616f2c76187';
  public LOGIN_PASSPHRASE_KEY = 'uWo5rx!y';
  public LOGIN_SEPARATOR_KEY = '#PRM-MMI#';
  public LOGIN_SIZE_KEY = 128;
  public LOGIN_ITERATION_COUNT = 1000;

  public PRINT_TYPE_XLS = 'xls';
  public PRINT_TYPE_XLSX = 'xlsx';
  public PRINT_TYPE_DOC = 'doc';
  public PRINT_TYPE_DOCX = 'docx';
  public PRINT_TYPE_PDF = 'pdf';

  public URL_DEFAULT_PROFILE_IMAGE = '/assets/img/avatars/default.png';
  public URL_DEFAULT_IMAGE = 'assets/img/app/no-image.png';
  public URL_DEFAULT_ICON =
    '/assets/component/fileuploader/logo-icon/Default.png';

  public FORMAT_SHORT_DATE = 'dd/MM/yyyy';
  public FORMAT_SHORT_DATE_AND_TIME = 'dd/MM/y HH:mm:ss';
  public FORMAT_LONG_DATE = 'dd MMMM y';
  public FORMAT_LONG_DATE_AND_TIME = 'dd MMMM y HH:mm:ss';

  public WORKFLOW_CODE_STATUS_ON_GOING = 'ON_GOING';
  public WORKFLOW_CODE_STATUS_FINISH = 'FINISH';
  public WORKFLOW_CODE_STATUS_REJECT = 'REJECT';
  public WORKFLOW_CODE_STATUS_REVISION = 'REVISION';
  public WORKFLOW_CODE_STATUS_ON_APPROVAL = 'ON_APPROVAL';
  public WORKFLOW_CODE_STATUS_NEW = 'NEW';

  public MODULE_TYPE_CODE_MODULE = 'MODULE';
  public MODULE_TYPE_CODE_WIDGET = 'WIDGET';

  public ITEM_TYPE_CODE_MATERIAL = 'MATERIAL';
  public ITEM_TYPE_CODE_SERVICE = 'JASA';
  public ITEM_TYPE_CODE_OBAT = 'OBAT';
  public ITEM_TYPE_CODE_ALKES = 'ALKES';

  // components
  public UPLOAD_MAX_LENGTH_FILE_NAME = 128;

  public PHONE_REGEX =
    /^(?:\+62|\(0\d{2,3}\)|0)\s?(?:361|8[17]\s?\d?)?(?:[ -]?\d{3,4}){2,3}$/g;

  /* WEBSOCKET_STATUS */
  public WEBSOCKET_STATUS_ON_PROGRESS = 0;
  public WEBSOCKET_STATUS_CANCEL = 1;
  public WEBSOCKET_STATUS_FINISH = 2;
  public WEBSOCKET_STATUS_ERROR = 3;

  public SORT_ORDER_DESC = -1;
  public SORT_ORDER_ASC = 1;

  /* t1_value_type */
  public VALUE_TYPE_STRING = 'STRING';
  public VALUE_TYPE_NUMBER = 'NUMBER';
  public VALUE_TYPE_DATE_FORMAT_LONG = 'DATE_FORMAT_LONG';
  public VALUE_TYPE_DATE_FORMAT_SHORT = 'DATE_FORMAT_SHORT';

  /* t3_menu */
  public MENU_CODE_CART = 'MN_CART';
  public MENU_CODE_USER_NOTIFICATION = 'MN_USER_NOTIFICATION';
  public MENU_CODE_LKPP = 'MN_LKPP';

  public MAX_LINE_HTML_TEXT = 1;

  public MONTH_NAME_LIST =
    'january,february,march,april,may,june,july,august,september,october,november,december'.split(
      ','
    );

  public DAY_NAME_LIST =
    'sunday,monday,tuesday,wednesday,thursday,friday,saturday'.split(',');

  public CRUD_OPERATION_DELETE = 0;
  public CRUD_OPERATION_UPDATE = 1;
  public CRUD_OPERATION_INSERT = 2;

  /* t1_transaction_type */
  public TRANSACTION_TYPE_ONLINE = 'ONLINE';
  public TRANSACTION_TYPE_OFFLINE = 'OFFLINE';

  /* DELEGATION TYPE */
  public DELEGATION_NO_DELEGATION = 0;
  public DELEGATION_MANUAL_DELEGATION = 1;
  public DELEGATION_AUTO_DELEGATION = 2;

  /* Hierarchy */
  public NO_HIERARCHY = 0;
  public HIERARCHY_EXCLUSIVE = 1;
  public HIERARCHY_INCLUSIVE = 2;

  /* t3_workload_threshold_object */
  public WOKRLOAD_THRESHOLD_OBJECT_TYPE_CURRENCY = 1;
  public WOKRLOAD_THRESHOLD_OBJECT_TYPE_QUANTITY = 0;

  /* t2_module */
  public MCODE_PRICE_EVALUATION = 'PRICE-EVALUATION';
  public MCODE_AANWIJZING = 'AANWIJZING';
  public MCODE_WORKLIST_PM = 'WORKLIST-PM';
  public MCODE_SUBMIT_QUOTATION = 'SUBMIT-QUOTATION';
  public MCODE_OPEN_QUOTATION = 'OPEN-QUOTATION';
  public MCODE_REFUTATION = 'REFUTATION';
  public MCODE_PROCUREMENT_REG_BUYER = 'PROCUREMENT-REG-BUYER';
  public MCODE_EVALUATION_CRITERIA = 'EVALUATION-CRITERIA';
  public MCODE_CONTRACT_REQUEST = 'CONTRACT-REQUEST';
  public MCODE_ORDER = 'ORDER';

  /* t1_workload_level */
  public WORKLOAD_LEVEL_LOW = 'LOW';
  public WORKLOAD_LEVEL_MEDIUM = 'MEDIUM';
  public WORKLOAD_LEVEL_HIGH = 'HIGH';
  public WORKLOAD_LEVEL_NOT_ASSIGNED = 'NOT_ASSIGNED';

  public SOURCE_ORDER = 'ORDER';
  public SOURCE_ORDER_CONTRACT = 'ORDER_CONTRACT';
  public SOURCE_CONTRACT = 'CONTRACT';

  /* t1_form_object */
  public FORM_OBJECT_CODE_COMBO_BOX = 'COMBO-BOX';
  public FORM_OBJECT_CODE_CHOSEN = 'CHOSEN';
  public FORM_OBJECT_CODE_CHECK_BOX = 'CHECK-BOX';

  /* t1_item_status */
  public ITEM_STATUS_NEW = "NEW";
  public ITEM_STATUS_WAITING_APPROVAL = "WAITING_APPROVAL";
  public ITEM_STATUS_REVISION = "REVISION";
  public ITEM_STATUS_DONE = "DONE";
  public ITEM_STATUS_REJECTED = "REJECTED";

  /* t1_region */
  public REGION_CODE_INDONESIA = "ID";

  /* t1_verificator */
  public VERIFICATOR_CODE_INTERNAL_JR = 0;
  public VERIFICATOR_CODE_ADMEDIKA = 1;
  public VERIFICATOR_CODE_DOKTER_KONSULTAN = 2;
  public VERIFICATOR_CODE_OCR = 3;

  /* t1_lang */
  public LANG_CODE_INDONESIA = "ID";
  public LANG_CODE_ENGLISH = "EN";

}
