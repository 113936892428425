import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { ClaimStatus } from 'src/app/core/bean/claim-status';
import { AppModalConfirmDialogComponent } from 'src/app/core/components/app-modal/app-modal-confirm-dialog/app-modal-confirm-dialog.component';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table-x/model/field-format.enum';
import { TableRequestModel } from 'src/app/core/components/app-table-x/model/table-request-model';
import { TableResponseModel } from 'src/app/core/components/app-table-x/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { ClaimViewPaginationBean } from 'src/app/core/view/entity/bean/claim-view-pagination';
import { Counter } from '../../core/bean/counter';
import { Region } from '../../core/bean/region';
import { User } from '../../core/bean/user';
import { Verificator } from '../../core/bean/verificator';
import { ClaimOptionResponse } from './claim-option.response';

@Component({
  templateUrl: './claim.component.html'
})
export class ClaimComponent extends BaseModuleComponent implements OnInit {
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  public filter: string;
  public tableResponse: TableResponseModel<ClaimViewPaginationBean>;
  public claimStatusOptionList: OptionListModel<ClaimStatus> =
    new OptionListModel(true);
  public hospitalNameOptionList: OptionListModel<String> = new OptionListModel(
    true
  );
  public areaOptionList: OptionListModel<Region> = new OptionListModel(true);
  public executorOptionList: OptionListModel<User> = new OptionListModel(true);
  public counterOptionList: OptionListModel<Counter> = new OptionListModel(true);
  public verificatorOptionList: OptionListModel<Verificator> = new OptionListModel(true);
  public paymentStatusOptionList: OptionListModel<String> = new OptionListModel(true);
  public fromDate: String;
  public untilDate: String;
  private localStorageKey = 'claimFilter' + this.global.userSession.user.id;

  constructor(translateService: TranslateService, public appPopupService: AppPopupService, private datePipe: DatePipe) {
    super('claim', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.setOptionList();
    this.buildFormGroup();
    this.doBuildTableResponse();
    this.loadFilterFromLocalStorage();
    this.doSetCustomData();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    this.filter = this.global.routerParams.get('filter');
    this.global.routerParams.clear();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      claimStatusList: [null],
      hospitalNameList: [null],
      counterList: [null],
      verificatorList: [null],
      paymentStatusList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<ClaimOptionResponse>('/claim/get-option-list')
      .subscribe((claimOptionResponse: ClaimOptionResponse) => {
        this.claimStatusOptionList.setRequestValues(
          claimOptionResponse.claimStatusList
        );
        this.hospitalNameOptionList.setRequestValues(
          claimOptionResponse.hospitalNameList
        );
        this.areaOptionList.setRequestValues(claimOptionResponse.areaList);
        this.executorOptionList.setRequestValues(claimOptionResponse.executorList);
        this.counterOptionList.setRequestValues(claimOptionResponse.counterList);
        this.verificatorOptionList.setRequestValues(claimOptionResponse.verificatorList);
        this.paymentStatusOptionList.setRequestValues(claimOptionResponse.paymentStatusList);
      });
  }

  public doBuildTableResponse(): void {
    const isUser = [
      this.global.appConstant.ROLE_CODE_ADM_JRC,
      this.global.appConstant.ROLE_CODE_ADMIN_MONITORING,
      this.global.appConstant.ROLE_CODE_SUPPORT,
      this.global.appConstant.ROLE_CODE_SUPER_ADMIN
    ].includes(this.global.userSession.activeUserRole.role.code);

    if (isUser) {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'hospitalName',
          header: 'table.column.hospitalName'
        },
        {
          field: 'hospitalCode',
          header: 'table.column.hospitalCode'
        },
        {
          field: 'accidentVictim',
          header: 'table.column.accidentVictim'
        },
        {
          field: 'glNumber',
          header: 'table.column.insuranceId'
        },
        {
          field: 'idJaminan',
          header: 'table.column.glCode'
        },
        {
          field: 'glDate',
          header: 'table.column.glDate',
          plugins: 'date'
        },
        {
          field: 'verificatorName',
          header: 'table.column.verificator'
        },
        {
          field: 'paymentStatus',
          header: 'table.column.paymentStatus',
          plugins: {
            name: 'badge',
            field: 'paymentStatus',
            colorMap: {
              'Unpaid': 'SECONDARY',
              'Paid': 'SUCCESS'
            }
          }
        },
        {
          field: 'deviationStatus',
          header: 'table.column.deviationStatus',
          plugins: {
            name: 'badge',
            field: 'deviationStatus',
            colorMap: {
              'Ada Potongan': 'DANGER',
              'Tidak Ada Potongan': 'SUCCESS',
              '-': 'DEFAULT'
            }
          }
        },
        {
          field: 'glStatusName',
          header: 'table.column.glStatus',
          plugins: {
            name: 'badge',
            field: 'glStatusCode',
            colorMap: {
              0: 'DANGER',
              1: 'SUCCESS'
            }
          }
        },
        {
          field: 'claimStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            field: 'claimStatus.code',
            colorMap: {
              NEW: 'INFO',
              REVISION: 'FEEDBACK',
              WAITING_MANUAL_VERIFICATION: 'WARNING',
              WAITING_FIRST_VERIFICATION: 'WARNING',
              WAITING_SECOND_VERIFICATION: 'WARNING',
              DONE: 'SUCCESS',
              RESEND_FILE: 'DANGER',
              RESEND_INFORMATION: 'DANGER',
              REJECT: 'DARK',
              DRAFT: 'SECONDARY'
            }
          }
        },

        {
          field: 'statusAccidentVictimName',
          header: 'table.column.statusClaim',
          plugins: {
            name: 'badge',
            field: 'statusAccidentVictimCode',
            colorMap: {
              NEW: 'INFO',
              DRAFT: 'SECONDARY',
              DONE: 'SUCCESS'
            }
          }
        },
        {
          field: 'ocrVerifAmountD',
          header: 'table.column.ocrVerifAmount',
          customClass: 'text-right',
          format: FieldFormatEnum.Currency,
          sortable: false
        },
        {
          field: 'ocrVerifAmountApprovedD',
          header: 'table.column.ocrVerifAmountApproved',
          customClass: 'text-right',
          format: FieldFormatEnum.Currency,
          sortable: false
        },
        {
          field: 'counterName',
          header: 'table.column.counter'
        },
        {
          field: 'submitDate',
          header: 'table.column.submitDate',
          plugins: 'date'
        },
        {
          field: 'verifyDate',
          header: 'table.column.verifyDate',
          plugins: 'date'
        }
      ]);
    } else {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'hospitalName',
          header: 'table.column.hospitalName'
        },
        {
          field: 'accidentVictim',
          header: 'table.column.accidentVictim'
        },
        {
          field: 'glNumber',
          header: 'table.column.insuranceId'
        },
        {
          field: 'idJaminan',
          header: 'table.column.glCode'
        },
        {
          field: 'glDate',
          header: 'table.column.glDate',
          plugins: 'date'
        },
        {
          field: 'verificatorName',
          header: 'table.column.verificator'
        },
        {
          field: 'paymentStatus',
          header: 'table.column.paymentStatus',
          plugins: {
            name: 'badge',
            field: 'paymentStatus',
            colorMap: {
              'Unpaid': 'SECONDARY',
              'Paid': 'SUCCESS'
            }
          }
        },
        {
          field: 'deviationStatus',
          header: 'table.column.deviationStatus',
          plugins: {
            name: 'badge',
            field: 'deviationStatus',
            colorMap: {
              'Ada Potongan': 'DANGER',
              'Tidak Ada Potongan': 'SUCCESS',
              '-': 'DEFAULT'
            }
          }
        },
        {
          field: 'glStatusName',
          header: 'table.column.glStatus',
          plugins: {
            name: 'badge',
            field: 'glStatusCode',
            colorMap: {
              0: 'DANGER',
              1: 'SUCCESS'
            }
          }
        },
        {
          field: 'claimStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            field: 'claimStatus.code',
            colorMap: {
              NEW: 'INFO',
              REVISION: 'FEEDBACK',
              WAITING_MANUAL_VERIFICATION: 'WARNING',
              WAITING_FIRST_VERIFICATION: 'WARNING',
              WAITING_SECOND_VERIFICATION: 'WARNING',
              DONE: 'SUCCESS',
              RESEND_FILE: 'DANGER',
              RESEND_INFORMATION: 'DANGER',
              REJECT: 'DARK',
              DRAFT: 'SECONDARY'
            }
          }
        },

        {
          field: 'statusAccidentVictimName',
          header: 'table.column.statusClaim',
          plugins: {
            name: 'badge',
            field: 'statusAccidentVictimCode',
            colorMap: {
              NEW: 'INFO',
              DRAFT: 'SECONDARY',
              DONE: 'SUCCESS'
            }
          }
        },
        {
          field: 'ocrVerifAmountD',
          header: 'table.column.ocrVerifAmount',
          customClass: 'text-right',
          format: FieldFormatEnum.Currency,
          sortable: false
        },
        {
          field: 'ocrVerifAmountApprovedD',
          header: 'table.column.ocrVerifAmountApproved',
          customClass: 'text-right',
          format: FieldFormatEnum.Currency,
          sortable: false
        },
        {
          field: 'counterName',
          header: 'table.column.counter'
        },
        {
          field: 'submitDate',
          header: 'table.column.submitDate',
          plugins: 'date'
        },
        {
          field: 'verifyDate',
          header: 'table.column.verifyDate',
          plugins: 'date'
        }
      ]);
    }
  }

  private loadFilterFromLocalStorage(): void {
    const savedFilter = localStorage.getItem(this.localStorageKey);
    if (savedFilter) {
      const filterValues = JSON.parse(savedFilter);
      this.formGroup.patchValue(filterValues);
      for (const controlName in this.formGroup.controls) {
        if (this.formGroup.controls.hasOwnProperty(controlName)) {
          const control = this.formGroup.get(controlName);
          const value = control.value;
          if (Array.isArray(value) && value.length === 0) {
            control.setValue(null);
          }
        }
      }
      this.tableResponse.setCustomData(this.formGroup.value);
      this.tableResponse.reload();
      this.formGroup.get('fromDate').reset();
      this.formGroup.get('untilDate').reset();
    }
  }

  public doSetCustomData(): void {
    if (this.filter) {
      const customData = {
        statusCode: this.filter
      };
      this.global.routerParams.clear();
      this.tableResponse.setCustomData(customData);
      this.tableResponse.reload();
    }
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      const fromDate = this.datePipe.transform(this.formGroup.value.date.from, 'yyyy-MM-dd');
      const untilDate = this.datePipe.transform(this.formGroup.value.date.to, 'yyyy-MM-dd');

      this.formGroup.patchValue({
        fromDate: fromDate,
        untilDate: untilDate
      });
      this.fromDate = this.formGroup.get('fromDate').value;
      this.untilDate = this.formGroup.get('untilDate').value;
    } else if (!this.formGroup.value.date) {
      this.fromDate = null;
      this.untilDate = null;
    }
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.formGroup.value));
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
    this.formGroup.get('fromDate').reset();
    this.formGroup.get('untilDate').reset();
  }

  public doPrintReport(): void {
    if (this.table.state.pagination.totalRecords <= this.global.config.parameterModel.maxDataPrintReport) {
      this.loadingBlockService.showInfo(
        this.translateService.instant('app.info.preparingDownload')
      );
      this.httpClientService.post<TableRequestModel>('/claim/print-report', this.table.state.tableRequest, { responseType: 'arrayBuffer' as 'json' })
        .subscribe(response => {
          const file = new File([response], 'JRCARE - KLAIM REPORT.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = URL.createObjectURL(file);
          const a = document.createElement('a');
          a.href = url;
          a.download = file.name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.loadingBlockService.showCompleteAndClose(
            this.translateService.instant(
              'app.info.successDownloaded'
            )
          );
        });
    } else {
      this.appPopupService.open(AppModalConfirmDialogComponent, {
        prompt: this.translateService.instant('app.infromation.print.report.prompt.one') + this.global.config.parameterModel.maxDataPrintReport + this.translateService.instant('app.infromation.print.report.prompt.two'),
        title: 'app.infromation.print.report.title',
        icon: 'pvc pv-exclamation-triangle-alert',
        isSingleButton: true,
        buttonRight: 'app.button.ok'
      },
        { size: 'md' });
    }
  }

  public doClick(claimView: ClaimViewPaginationBean): void {
    const claim = claimView;
    this.global.routerParams.clear();
    this.global.routerParams.set('claimId', claim.id);
    this.global.routerParams.set('claimStatus', claim.claimStatus);
    this.global.routerParams.set('urlBackOutside', '/pages/claim/');

    const isUser =
      this.global.userSession.activeUserRole.role.code ===
        this.global.appConstant.ROLE_CODE_USER
        ? true
        : false;

    if (claimView.statusAccidentVictimCode === this.global.appConstant.pm.ACCIDENT_VICTIM_STATUS_NEW || claimView.statusAccidentVictimCode === this.global.appConstant.pm.ACCIDENT_VICTIM_STATUS_DRAFT) {
      this.global.routerParams.set('todo', 'view');
      this.router.navigate(['/pages/claim/detail']);
    } else {
      if (
        (claim.claimStatus.code === this.global.appConstant.pm.CLAIM_STATUS_NEW ||
          claim.claimStatus.code ===
          this.global.appConstant.pm.CLAIM_STATUS_REVISION || claim.claimStatus.code === this.global.appConstant.pm.CLAIM_STATUS_DRAFT) &&
        isUser && claim.glStatusCode === this.global.appConstant.pm.GL_STATUS_ACTIVE
      ) {
        this.global.routerParams.set('todo', 'detail');
        this.router.navigate(['/pages/claim/detail']);
      } else {
        this.global.routerParams.set('todo', 'view');
        this.router.navigate(['/pages/claim/detail']);
      }
    }
  }
}
