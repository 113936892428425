import { NgModule } from '@angular/core';
import { SharedModule } from "../../../../core/shared/shared.module";
import { VendorModule } from '../../../../core/vendor/vendor.module';
import { AppPopupUserManualComponent } from './app-popup-user-manual.component';
import { HelpComponent } from './help.component';
@NgModule({
  imports: [VendorModule, SharedModule],
  entryComponents: [AppPopupUserManualComponent],
  declarations: [HelpComponent,AppPopupUserManualComponent],
  exports: [HelpComponent]
})
export class HelpModule {}