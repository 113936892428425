import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hyperlink'
})
export class HyperlinkPipe implements PipeTransform {

  transform(value: string): string {
    // Regular expression untuk mencari http:// atau https://
    const regex = /(http:\/\/[^\s]+|https:\/\/[^\s]+)/g;

    // Mengganti URL dengan tag <a> yang membuka di tab baru
    return value.replace(regex, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  }
}
