import { Component } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Validators } from 'src/app/core/validators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Role } from '../../core/bean/role';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { UserManual } from './../../core/bean/user-manual';
import { UserManualRequest } from './user-manual-request';
import { UserManualResponse } from './user-manual-response';
@Component({
  templateUrl: './user-manual-edit-add.component.html'
})
export class UserManualEditAddComponent extends BaseModuleComponent {
  public role: Role = new Role();
  public userManualTypeList: OptionListModel<any> = new OptionListModel();
  public userManualList: UserManual[];
  public userManualRequest: UserManualRequest = new UserManualRequest(); 
  public userManualResponse: UserManualResponse;
  selectedType: { [key: number]: string } = {}; 

  constructor(translateService: TranslateService) {
    super('user-manual', translateService);
  }
  public fileUploader: FileUploader = new FileUploader(
    '/user-manual/',
    '',
    this.global.appConstant.fileType.DOC_USER_MANUAL
  );
  public thumbnailUploader: FileUploader = new FileUploader(
    '/user-manual/',
    '',
    this.global.appConstant.fileType.IMG_USER_MANUAL
  );
  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.role = this.global.routerParams.get('role');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      userManual: this.formBuilder.array([]),
      isShowOnDashboard: [true]
    });
  }
  
  public doSetFormGroup(): void {
    this.httpClientService
      .post<UserManualResponse>('/user-manual/edit', this.role)
      .subscribe((userManualResponse: UserManualResponse) => {
        this.userManualTypeList.setRequestValues(userManualResponse.userManualTypeList);
        this.userManualResponse = userManualResponse;

        if (this.role.isDashboardHelpShow) {
          this.formGroup.get('isShowOnDashboard').patchValue(true);
        } else {
          this.formGroup.get('isShowOnDashboard').patchValue(false);
        }
        this.formGroup.patchValue({
          isShowOnDashboard: this.role.isDashboardHelpShow
        });
        this.userManualResponse.langList.forEach(lang => {
          const userManualList: UserManual[] = [];
          this.userManualResponse.userManualList.forEach(
            userManual => {
              if (userManual.lang.id === lang.id) {
                userManualList.push(userManual);
              }
            }
          );
          this.formArrayUserManualList.push(
            this.formBuilder.group({
              name: lang.name,
              userManualDetail: this.buildUserManualDetail(userManualList)
            })
          );
        });
        this.setStateReady();
      });
  }


  public buildUserManualDetail(
    userManualList: UserManual[]
  ): FormArray {
    const controlLang = new FormArray([]);
    
    userManualList.forEach((userManual, index) => {
      const { id, lang, role, userManualType, title, link, file ,thumbnail} = userManual;
  
      this.selectedType[index] = userManualType?.code || '';
  
      let fileValue = null;
      if (userManualType?.code === this.global.appConstant.pm.USER_MANUAL_TYPE_CODE_DOCUMENT) {
        this.fileUploader.setFile(file); 
        fileValue = this.fileUploader.fileObjectList || null;
      }
      
      let fileThumbnail = null;
      if (thumbnail != null){
        this.thumbnailUploader.setFile(thumbnail); 
        fileThumbnail = this.thumbnailUploader.fileObjectList || null;
      }

      controlLang.push(
        this.formBuilder.group({
          id,
          lang,
          role,
          userManualType,
          title,
          link,
          file: fileValue,
          thumbnail: fileThumbnail
        })
      );
    });
  
    return controlLang;
  }
  

    public getFormArray(
      formGroup: FormGroup,
      formControlName: string
    ): FormArray {
      return formGroup.get(formControlName) as FormArray;
    }
    
  public get formArrayUserManualList(): FormArray {
    return this.formGroup.get('userManual') as FormArray;
  }

  onUserManualTypeChange(typeCode: string, index: number): void {
    this.selectedType[index] = typeCode || '';
  }
  
  public get formArrayUserManualTypeList(): FormArray {
    return this.formGroup.get('userManualTypeList') as FormArray;
  }
  

  public doSave(): void {
    const userManualFormArray = this.getFormArray(this.formGroup, 'userManual');

  userManualFormArray.controls.forEach((group: FormGroup) => {
    const userManualDetailArray = this.getFormArray(group, 'userManualDetail');

    userManualDetailArray.controls.forEach((detailGroup: FormGroup) => {
      const userManualTypeControl = detailGroup.get('userManualType');
      const titleControl = detailGroup.get('title');
      const linkControl = detailGroup.get('link');
      const fileControl = detailGroup.get('file');
      const thumbnailControl = detailGroup.get('thumbnail');

      titleControl?.clearValidators();
      linkControl?.clearValidators();
      fileControl?.clearValidators();
      thumbnailControl?.clearValidators();

      const initialValue = userManualTypeControl?.value;

      if (initialValue != null) {
        if (!thumbnailControl.value || thumbnailControl.value.length === 0) {
          thumbnailControl?.setValidators(Validators.required());
        } else {
          thumbnailControl?.clearValidators();
        }
        if (!titleControl.value) {
          titleControl?.setValidators(Validators.required());
        } else {
          titleControl?.clearValidators();
        }
      if (initialValue.code === this.global.appConstant.pm.USER_MANUAL_TYPE_CODE_DOCUMENT) {
        if (!fileControl.value || !fileControl.value.file || fileControl.value.length === 0) {
          fileControl?.setValidators(Validators.required());
        } else {
          fileControl?.clearValidators();
        }
      } else if (initialValue.code === this.global.appConstant.pm.USER_MANUAL_TYPE_CODE_LINK) {
        if (!linkControl.value) {
          linkControl?.setValidators(Validators.required());
        } else {
          linkControl?.clearValidators();
        }
      }
    }

      titleControl?.updateValueAndValidity();
      linkControl?.updateValueAndValidity();
      fileControl?.updateValueAndValidity();
      thumbnailControl?.updateValueAndValidity();
    });
  });

    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.userManualRequest.role = this.role;
            if(this.formGroup.get('isShowOnDashboard').value === false){
              this.userManualRequest.role.isDashboardHelpShow = false;
            } else {
              this.userManualRequest.role.isDashboardHelpShow = true;
            }
            const userManualList: UserManual[] = [];
            this.formGroup.value.userManual.forEach((userManualGroup: any) => {
                userManualGroup.userManualDetail.forEach((userManualDetail: any) => {

                if (userManualDetail.file) {
                  userManualDetail.fileObjectList = Array.isArray(userManualDetail.file)
                    ? [...userManualDetail.file] 
                    : [userManualDetail.file]; 
                } else {
                  userManualDetail.fileObjectList = [];
                }

                userManualDetail.file = null; 

                if (userManualDetail.thumbnail) {
                  userManualDetail.thumbnailObjectList = Array.isArray(userManualDetail.thumbnail)
                    ? [...userManualDetail.thumbnail]
                    : [userManualDetail.thumbnail];
                } else {
                  userManualDetail.thumbnailObjectList = [];
                }

                userManualDetail.thumbnail = null; 

                userManualDetail.link = userManualDetail.link || null;

                userManualList.push(userManualDetail);
              });
            });
            this.userManualRequest.userManual = userManualList;
            this.httpClientService
              .post<Response<UserManual>>('/user-manual/update', this.userManualRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/user-manual']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }
  
  public doCancel(): void {
    this.router.navigate(['/pages/user-manual']);
  }


}
