import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { Currency } from '../../bean/currency';
import { OptionListModel } from '../../model/option-list-model';
import { CatalogItemView } from '../../view/entity/bean/catalog-item-view';
import { TableCatalogResponseModel } from './model/table-catalog-response-model';
import { AppCatalogItemCustomDataRequest } from './request/app-catalog-item-custom-data.request';
@Component({
  selector: 'app-catalog-item-x',
  templateUrl: './app-catalog-item-x.component.html',
  styleUrls: ['./app-catalog-item-x.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppCatalogItemXComponent
  extends BaseComponentComponent
  implements OnChanges {
  @Input() customData: string;
  @Input() itemPath = '';
  @Input() customModel: any;

  public model: TableCatalogResponseModel<CatalogItemView> =
    new TableCatalogResponseModel('/app-catalog-item/index');

  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false
  );
  private customDataGlobal;
  private previousCustomDataGlobal;
  public first = 0;
  public isScrolled = false;
  public isLoadingData = false;
  constructor(public translateService: TranslateService) {
    super('app-catalog-item');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.customDataGlobal =
      changes.customData && changes.customData.currentValue
        ? JSON.parse(changes.customData.currentValue)
        : null;
    /* customData (include formGroup.value) */
    const customDataRequest: AppCatalogItemCustomDataRequest =
      new AppCatalogItemCustomDataRequest();
    if (this.customDataGlobal) {
      customDataRequest.itemCategory = this.customDataGlobal.itemCategory
        ? this.customDataGlobal.itemCategory[this.customDataGlobal.itemCategory.length - 1]
        : null;
      customDataRequest.regionCodeList = this.customDataGlobal.regionCodeList;

      customDataRequest.catalogType = this.customDataGlobal.catalogType;
      customDataRequest.minimumPrice = this.customDataGlobal.minimumPrice
        ? this.customDataGlobal.minimumPrice.price
        : null;
      customDataRequest.maximumPrice = this.customDataGlobal.maximumPrice
        ? this.customDataGlobal.maximumPrice.price
        : null;
      customDataRequest.vendorId = this.customDataGlobal.vendorId;
      customDataRequest.attributeList = this.customDataGlobal.attributeList;
      customDataRequest.valueList = this.customDataGlobal.valueList;
      customDataRequest.itemTypeCode = this.customDataGlobal.itemTypeCode;

      this.model.setSearchString(this.customDataGlobal.keyword || '');
      this.model.setSortField(
        (this.customDataGlobal.sortBy && this.customDataGlobal.sortBy.value) || null
      );

      this.model.setCustomData(customDataRequest);
    }

    this.log.debug('changes');
    this.log.debug(changes);

    this.setStateLoading();
    this.model.reload();
  }

  public onInit(): void {
    if (this.customModel) {
      this.model = this.customModel;
    } else {
      this.model = this.model;
    }
    this.handleReloadChanges();
    this.getAndSetCatalogs();
  }

  public getAndSetCatalogs(): void {
    if (this.isLoadingData) {
    return;
    }
     this.isLoadingData = true;
    this.model.setStateLoading();

    this.model.tableRequest.cols = [
      { field: 'merk', header: '' },
      { field: 'distributorName', header: '' },
      { field: 'produsenName', header: '' },
      { field: 'rating', header: '' },
      { field: 'currencyCode', header: '' },
      { field: 'price', header: '' }
    ];
    if(this.previousCustomDataGlobal !== this.customDataGlobal){
      this.isScrolled = false
    }

    this.model.tableRequest.rows = 10;
    if((this.isScrolled) || (this.isScrolled && this.previousCustomDataGlobal !== this.customDataGlobal)){
      this.model.tableRequest.first = this.model.getPageRecords().length;
    } else {
      this.model.tableRequest.first = 0 ;
    }

    this.httpClientService
      .post<any>(this.model.stringUrl, this.model.tableRequest)
      .subscribe(page => {
        if(this.previousCustomDataGlobal !== this.customDataGlobal){
          this.previousCustomDataGlobal = this.customDataGlobal;
          this.model.setPageRecords(page.records);
        } else {
          this.model.getPageRecords().push(...page.records);
        }
        this.model.setTotalRecords(page.totalRecords);
        this.model.setStateReady();
        this.setStateReady();
      }, _ => {
        this.isLoadingData = false; 
      },
      () => {
        this.isLoadingData = false; 
      }
    );
  }

  public handleReloadChanges(): void {
    this.model.reloadChanges.subscribe(() => {
      this.getAndSetCatalogs();
    });
  }

  public onClickCatalog(catalogId: number): void {
    this.global.routerParams.set('catalogId', catalogId);
    this.router.navigate(['/pages/' + this.itemPath]);
  }

  public onScrollEnd(event): void {
    this.log.debug(event);
    this.log.debug('onScrollEnd');
    this.isScrolled = true;
    const page = this.model.pagination.page + 1;
    const recordPerPage = this.model.pagination.perPage;
    if (!this.model.isLoading) {
      this.model.setPerPage(page * recordPerPage);
      this.model.reload();
    }
  }
}
 