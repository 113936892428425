import { BaseEntity } from "../base/base-entity";
import { Authority } from "../bean/authority";
import { Claim } from "../bean/claim";
import { Gender } from "../bean/gender";

export class AuthorityLetterEntity extends BaseEntity {
  claim: Claim;
  firstPartyGender: Gender;
  firstPartyAuthority: Authority;
  accidentLocation: String;
  treatmentFromDate: Date;
  treatmentToDate: Date;
  totalRequestAmount: number;
  firstPartyName: String;
  firstPartyDobDate: Date;
  firstPartyPlaceOfBirth: String;
  firstPartyAddress: String;
  firstPartyNik: String;
  firstPartyPhone: String;
  secondPartyName: String;
  secondPartyNik: String;
  secondPartyPosition: String;
  secondPartyRepresent: String;
}