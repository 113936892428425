import { OrganizationEntity } from '../entity/organization.entity';
import { Region } from './region';

export class Organization extends OrganizationEntity {
    isDistributor: boolean;
	isBranch: boolean;
    distributorLocationCode: string;
    countryRegion: Region;
	provinceRegion: Region;
	cityRegion: Region;
	phone: string;
	fax: string;
	email: string;
	website: string;
	address: string;
	hospitalCode: string;
}
