<app-layout [isLoading]="formLoading" [backTo]="urlBackOutside">
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge
      class="cml-2"
      *ngIf="
        (claimStatus?.code === global.appConstant.pm.CLAIM_STATUS_NEW) === 'new'
      "
      color="INFO"
    >
      {{ 'claim.label.new' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        claimStatus?.code ===
        global.appConstant.pm.CLAIM_STATUS_WAITING_VERIFICATION
      "
      color="WARNING"
    >
      {{
        'dynamic-master-attribute.' + claimStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="claimStatus?.code === global.appConstant.pm.CLAIM_STATUS_REVISION"
      color="FEEDBACK"
    >
      {{
        'dynamic-master-attribute.' + claimStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        claimStatus?.code === global.appConstant.pm.CLAIM_STATUS_RESEND_FILE
      "
      color="DANGER"
    >
      {{
        'dynamic-master-attribute.' + claimStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        claimStatus?.code ===
        global.appConstant.pm.CLAIM_STATUS_RESEND_INFORMATION
      "
      color="DANGER"
    >
      {{
        'dynamic-master-attribute.' + claimStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="claimStatus?.code === global.appConstant.pm.CLAIM_STATUS_DONE"
      color="SUCCESS"
    >
      {{
        'dynamic-master-attribute.' + claimStatus.translationKey.key | translate
      }}
    </app-badge>
  </ng-template>

  <div class="row main-row" *ngIf="!formLoading">
    <div class="col-lg-12 mb-4">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div>
          <app-alert-x color="WARNING" *ngIf="!isUserAvailable">{{
            'claim.alert.isUserAvailable' | translate
          }}</app-alert-x>
        </div>
        <div>
          <app-alert-x color="WARNING" *ngIf="isFillAccidentHealthForm"
            >{{ 'claim.alert.fillAccidentHealthForm' | translate }}
            <a
              (click)="clickFormKesehatan(claimResponse)"
              [ngStyle]="{ color: '#87b4e7' }"
            >
              {{ 'claim.alert.clickHere' | translate }}
              <!-- click here -->
            </a>
          </app-alert-x>
        </div>

        <app-card
          header="claim.detail.general.title"
          tips="claim.detail.general.tips"
        >
          <app-fullfilled *ngIf="!formGroup.isView"></app-fullfilled>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.hospitalCode' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.hospitalCode }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.hospitalName' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.hospitalName }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.glCode' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.glNumber }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.insuranceId' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.idJaminan || '-' }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.glStatus' | translate
            }}</label>
            <div
              class="col-sm-9 text-left"
              *ngIf="glStatus === global.appConstant.pm.GL_STATUS_NAME_ACTIVE"
            >
              <span class="badge badge-success badge-pill">{{ glStatus }}</span>
            </div>
            <div
              class="col-sm-9 text-left"
              *ngIf="glStatus === global.appConstant.pm.GL_STATUS_NAME_CANCEL"
            >
              <span class="badge badge-danger badge-pill">{{ glStatus }}</span>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.accidentVictim' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.accidentVictim }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.accidentDate' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{
                formGroup.value.accidentDate
                  ? (formGroup.value.accidentDate
                    | date : global.appConstant.core.FORMAT_LONG_DATE)
                  : '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.verificator' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.verificator }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.counter' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.counter }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.glLink' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <a href="{{ formGroup.value.link }}" target="_blank">{{
                formGroup.value.link || '-'
              }}</a>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.historyLink' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <a href="{{ formGroup.value.linkHistory }}" target="_blank">{{
                formGroup.value.linkHistory || '-'
              }}</a>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.description' | translate
            }}</label>
            <div
              [ngClass]="
                !formGroup.isView ? 'col-sm-9 text-left' : 'col-sm-6 text-left'
              "
            >
              <app-text-area formControlName="description" maxLength="512">
              </app-text-area>
            </div>
          </div>
        </app-card>

        <app-card
          header="claim.detail.note.title"
          tips="claim.detail.note.tips"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{ 'claim.form.note' | translate }}</label>
            <div class="col-sm-9 text-left">
              <app-text-area
                formControlName="note"
                maxLength="1024"
              ></app-text-area>
            </div>
          </div>
        </app-card>

        <app-card
          header="claim.detail.requestAmount.title"
          tips="claim.detail.requestAmount.tips"
          *ngIf="formGroup.isView"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.totalRequest' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-left':
                  totalRequest === null || totalRequest === undefined,
                'text-right': totalRequest !== null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              {{
                totalRequest || totalRequest === 0
                  ? global.converterService.convertMoney(totalRequest || 0) +
                    ' IDR'
                  : '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim.form.doctor' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.doctor !== null,
                'text-left': formGroup.value.doctor === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              <app-currency
                formControlName="doctor"
                [optionList]="currencyOptionList"
                maxLength="16"
                (onChange)="countTotalRequest()"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim.form.medicine' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.medicine !== null,
                'text-left': formGroup.value.medicine === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              <app-currency
                formControlName="medicine"
                [optionList]="currencyOptionList"
                maxLength="16"
                (onChange)="countTotalRequest()"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim.form.medicalTools' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.medicalTools !== null,
                'text-left': formGroup.value.medicalTools === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              <app-currency
                formControlName="medicalTools"
                [optionList]="currencyOptionList"
                maxLength="16"
                (onChange)="countTotalRequest()"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim.form.administration' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.administration !== null,
                'text-left': formGroup.value.administration === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              <app-currency
                formControlName="administration"
                [optionList]="currencyOptionList"
                maxLength="16"
                (onChange)="countTotalRequest()"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim.form.room' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.room !== null,
                'text-left': formGroup.value.room === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              <app-currency
                formControlName="room"
                [optionList]="currencyOptionList"
                maxLength="16"
                (onChange)="countTotalRequest()"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim.form.roomType' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.roomType !== null,
                'text-left': formGroup.value.roomType === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              <app-dropdown-select
                size="LG"
                [optionList]="roomTypeList"
                formControlName="roomType"
                type="COMBOBOX"
              >
              </app-dropdown-select>
            </div>
          </div>
          <br />
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.totalP3k' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.totalP3k !== null,
                'text-left': formGroup.value.totalP3k === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              <app-currency
                formControlName="totalP3k"
                [optionList]="currencyOptionList"
                maxLength="16"
                (onChange)="validateP3k()"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.totalAmbulance' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.totalAmbulance !== null,
                'text-left': formGroup.value.totalAmbulance === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              <app-currency
                formControlName="totalAmbulance"
                [optionList]="currencyOptionList"
                maxLength="16"
                (onChange)="validateAmbl()"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.totalCompensationRequest' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-left':
                  totalCompensationRequest === null ||
                  totalCompensationRequest === undefined,
                'text-right': totalCompensationRequest !== null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              <ng-container
                *ngIf="
                  totalCompensationRequest || totalCompensationRequest === 0;
                  else defaultText
                "
              >
                <span style="font-weight: bold">
                  {{
                    global.converterService.convertMoney(
                      totalCompensationRequest || 0
                    ) + ' IDR'
                  }}
                </span>
              </ng-container>
              <ng-template #defaultText>
                <span>-</span>
              </ng-template>
            </div>
          </div>
        </app-card>

        <app-card
          header="claim.detail.requestAmount.title"
          tips="claim.detail.requestAmount.tips"
          *ngIf="!formGroup.isView"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.totalRequest' | translate
            }}</label>
            <div class="col-sm-3 text-left" style="padding-right: 100px">
              {{
                totalRequest || totalRequest === 0
                  ? global.converterService.convertMoney(totalRequest || 0) +
                    ' IDR'
                  : '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim.form.doctor' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-currency
                formControlName="doctor"
                [optionList]="currencyOptionList"
                maxLength="16"
                (onChange)="countTotalRequest()"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim.form.medicine' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-currency
                formControlName="medicine"
                [optionList]="currencyOptionList"
                maxLength="16"
                (onChange)="countTotalRequest()"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim.form.medicalTools' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-currency
                formControlName="medicalTools"
                [optionList]="currencyOptionList"
                maxLength="16"
                (onChange)="countTotalRequest()"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim.form.administration' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-currency
                formControlName="administration"
                [optionList]="currencyOptionList"
                maxLength="16"
                (onChange)="validateAdministration()"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim.form.room' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-currency
                formControlName="room"
                [optionList]="currencyOptionList"
                maxLength="16"
                (onChange)="countTotalRequest()"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim.form.roomType' | translate
            }}</label>
            <div class="col-sm-5 text-left">
              <app-dropdown-select
                size="LG"
                [optionList]="roomTypeList"
                formControlName="roomType"
                type="COMBOBOX"
              >
              </app-dropdown-select>
            </div>
          </div>
          <br />
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.totalP3k' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-currency
                formControlName="totalP3k"
                [optionList]="currencyOptionList"
                maxLength="16"
                (onChange)="validateP3k()"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.totalAmbulance' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-currency
                formControlName="totalAmbulance"
                [optionList]="currencyOptionList"
                maxLength="16"
                (onChange)="validateAmbl()"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.totalCompensationRequest' | translate
            }}</label>
            <div class="col-sm-3 text-left" style="padding-right: 100px">
              <ng-container
                *ngIf="
                  totalCompensationRequest || totalCompensationRequest === 0;
                  else defaultText
                "
              >
                <span style="font-weight: bold">
                  {{
                    global.converterService.convertMoney(
                      totalCompensationRequest || 0
                    ) + ' IDR'
                  }}
                </span>
              </ng-container>
              <ng-template #defaultText>
                <span>-</span>
              </ng-template>
            </div>
          </div>
          <app-alert-x color="WARNING" *ngIf="!formGroup.isView"
            >{{ 'claim.alert.notes' | translate }}
          </app-alert-x>
        </app-card>

        <app-card
          header="claim.detail.ocr.detail.title"
          tips="claim.detail.ocr.detail.tips"
          *ngIf="isShowOCRResult"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.ocrVerifAmount' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-currency
                formControlName="ocrVerifAmount"
                [optionList]="currencyOptionList"
                maxLength="16"
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.ocrVerifAmountApproved' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-currency
                formControlName="ocrVerifAmountApproved"
                [optionList]="currencyOptionList"
                maxLength="16"
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'claim.form.fileOcrResult' | translate }}
            </label>
            <div class="col-sm-6 text-left">
              <app-upload-x
                formControlName="fileOcrResult"
                [fileUploader]="fileUploaderOCR"
              ></app-upload-x>
            </div>
          </div>
          <div class="button-group button-group-center">
            <app-button (onClick)="doUpdate()" *ngIf="this.isUserSuperAdmin"
              >{{ 'app.button.update' | translate }}
            </app-button>
          </div>
        </app-card>

        <app-card
          header="claim.detail.additional.benefits.title"
          tips="claim.detail.additional.benefits.tips"
          *ngIf="isShowOCRResult"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.p3kRequest' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-currency
                formControlName="totalP3k"
                [optionList]="currencyOptionList"
                maxLength="16"
                (onChange)="validateP3k()"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim.form.ambulanceRequest' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-currency
                formControlName="totalAmbulance"
                [optionList]="currencyOptionList"
                maxLength="16"
                (onChange)="validateAmbl()"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
        </app-card>

        <app-card
          header="claim.detail.payment.info.title"
          tips="claim.detail.payment.info.tips"
        >
          <div
            class="form-group row text-sm-center d-flex justify-content-center align-items-center"
            *ngIf="paymentTotal == null && !isPaymentSpecialCode"
          >
            {{
              isLoading
                ? ('claim.payment.loading.msg' | translate)
                : ('claim.info.paymentInfoNotAvailable' | translate)
            }}
          </div>
          <div
            class="form-group row text-sm-center d-flex justify-content-center align-items-center"
            *ngIf="
              paymentTotal == null &&
              isPaymentSpecialCode &&
              claim.sysPaymentCheckCode == '300'
            "
          >
            {{ 'claim.payment.pleaseCallAdministrator.msg' | translate }}
          </div>
          <div
            class="form-group row text-sm-center d-flex justify-content-center align-items-center"
            *ngIf="
              paymentTotal == null &&
              isPaymentSpecialCode &&
              claim.sysPaymentCheckCode == '400'
            "
          >
            {{ 'claim.payment.claimNotFound.msg.start' | translate }}
            {{ formGroup.value.glNumber }}
            {{ 'claim.payment.claimNotFound.msg.end' | translate }}</div
          >
          <div *ngIf="paymentTotal != null">
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'claim.form.paymentTotal' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                {{
                  paymentTotal
                    ? global.converterService.convertMoney(paymentTotal) +
                      ' IDR'
                    : '-'
                }}
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'claim.form.paymentDate' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                {{
                  paymentDate
                    ? (paymentDate
                      | date : global.appConstant.core.FORMAT_LONG_DATE)
                    : '-'
                }}
              </div>
            </div>
          </div>
          <div
            class="button-group button-group-center"
            *ngIf="paymentTotal == null"
          >
            <app-button
              (onClick)="doGetPaymentInfo()"
              mode="BYPASS"
              [disabled]="isLoading"
              ><em
                class="fas"
                [ngClass]="!isLoading ? '' : 'fa-circle-notch fa-spin'"
              ></em>
              {{ 'app.button.update' | translate }}
            </app-button>
          </div>
        </app-card>

        <app-card
          header="claim.detail.document.title"
          tips="claim.detail.document.tips"
        >
          <div class="table-responsive custom-table">
            <table
              class="table table-striped"
              [formGroup]="formGroup"
              aria-describedby="Table"
            >
              <thead> </thead>
              <tbody formArrayName="docClaimList">
                <tr
                  *ngFor="
                    let docClaim of formArrayDocClaimList.controls;
                    let i = index
                  "
                  [formGroup]="docClaim"
                >
                  <td>
                    {{
                      'claim.' + docClaim.value.translationKey.key | translate
                    }}
                    <span *ngIf="docClaim.value.isMandatory" style="color: red"
                      >*</span
                    >
                  </td>
                  <td>
                    <app-upload
                      formControlName="file"
                      [fileUploader]="fileUploaderList[i]"
                      size="XL"
                      isView="true"
                    >
                    </app-upload>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div class="col-sm-6 control-label text-left">
            <label>
              {{ 'claim.docOther' | translate }}
            </label>
          </div>
          <app-upload-x
            formControlName="fileObjectList"
            [fileUploader]="fileUploader"
          ></app-upload-x>
        </app-card>

        <app-card
          header="claim.detail.uploadReceipt"
          tips="claim.detail.uploadReceipt.tips" *ngIf="isUploadReceipt"
        >
          <app-upload-x
            formControlName="receiptDoc"
            [fileUploader]="fileUploaderReceipt"
          ></app-upload-x>
        </app-card>

        <app-card
          header="claim.detail.history.activity.title"
          tips="claim.detail.history.activity.tips"
        >
          <app-table [model]="tableResponse" [isServerSide]="false"></app-table>
        </app-card>

        <div class="floating-button mt-5">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div class="button-group button-group-center">
                <app-button
                  color="SECONDARY"
                  (onClick)="doBack()"
                  mode="BYPASS"
                  [disabled]="formSaving"
                  >{{ 'app.button.back' | translate }}
                </app-button>
                <app-button
                  *ngIf="isAllowSend"
                  color="PRIMARY"
                  (onClick)="doSubmit(isDraft)"
                  [disabled]="formSaving"
                  class="mr-1"
                  >{{ 'app.button.saveDraft' | translate }}</app-button
                >
                <app-button (onClick)="doSubmit(!isDraft)" *ngIf="isAllowSend"
                  >{{ 'app.button.submit' | translate }}
                </app-button>
                <app-button (onClick)="doSubmit(!isDraft)" *ngIf="isAllowResend"
                  >{{ 'app.button.resend' | translate }}
                </app-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight *ngIf="!claimResponse?.isUserApproval">
    <app-tips></app-tips>
  </ng-template>
</app-layout>
