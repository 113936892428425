import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { PatientCondition } from 'src/app/core/bean/patient-condition';
import { VictimDiagnose } from 'src/app/core/bean/victim-diagnose';
import { RecapPrintService } from 'src/app/core/services/recap-print.service';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './patient-condition.component.html'
})
export class PatientConditionComponent extends BaseModuleComponent implements OnInit {
  public tableResponse: TableResponseModel<PatientCondition>;
  public isLoading: boolean;
  constructor(translateService: TranslateService, public recapPrintService: RecapPrintService,) {
    super('patient-condition', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'code', header: 'table.column.code' },
      { field: 'name', header: 'table.column.name' },
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/patient-condition/add']);
  }

  public doEdit(patientCondition: PatientCondition): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('patientConditionId', patientCondition.id);
    this.router.navigate(['/pages/patient-condition/edit']);
  }

  public doDelete(patientCondition: PatientCondition): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<VictimDiagnose>>('/patient-condition/delete', patientCondition)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }
}
