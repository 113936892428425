<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="master-faq.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <div class="form-group row text-sm-left required">
          <label class="col-sm-3 control-label" for="faqType">{{
            'master-faq.form.type' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select
              size="MD"
              [optionList]="faqTypeOptionList"
              formControlName="faqType"
              type="AUTOCOMPLETE"
            >
            </app-dropdown-select>
          </div>
        </div>
        <div class="form-group row text-sm-left required">
          <label class="col-sm-3 control-label">{{
            'master-faq.form.groupText' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              formControlName="groupText"
              maxLength="64"
            >
            </app-text-field>
          </div>
        </div>

        <app-card header="master-faq.detail.indo">
          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label">{{
              'master-faq.form.questionIndo' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="questionIndo"
                maxLength="255"
              ></app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label">{{
              'master-faq.form.answerIndo' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-wysiwyg
                size="LG"
                formControlName="answerIndo"
                maxLength="1024"
              ></app-wysiwyg>
            </div>
          </div>
        </app-card>

        <app-card header="master-faq.detail.eng">
          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label">{{
              'master-faq.form.questionEng' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="questionEng"
                maxLength="255"
              ></app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label">{{
              'master-faq.form.answerEng' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-wysiwyg
                size="LG"
                formControlName="answerEng"
                maxLength="1024"
              ></app-wysiwyg>
            </div>
          </div>
        </app-card>

        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
            >{{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">{{
            'app.button.save' | translate
          }}</app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
