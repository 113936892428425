<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="user-faq.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <ng-template #customLoading>
        <div class="promise-loading">
          <div class="loading"></div>
        </div>
      </ng-template>

      <div class="faq-content">
        <div class="custom-search mb-4 d-flex justify-content-between">
          <div class="col-sm-8 p-0">
            <em class="pir pi-search"></em>
            <input
              type="text"
              class="form-control"
              placeholder="Search Question"
              [formControl]="keyword"
              (input)="doInputKeyword()"
            />
            <em class="pir pi-times" (click)="doResetSearch()"></em>
          </div>

          <div class="app-table-filter-item col-sm-3 p-0">
            <app-combo-box
              size="XL"
              [formControl]="groupText"
              [optionList]="groupTextList"
              (onChange)="setFaqState()"
            ></app-combo-box>
          </div>
        </div>

        <app-accordion [isShowMultiple]="true">
          <app-accordion-item
            *ngFor="let qa of faqList"
            [header]="qa.question"
            customClassHeader="bg-white"
            customClassBody="bg-light"
            [groupText]="qa.groupText"
          >
            <div
              [innerHTML]="qa.answer"
              [ngStyle]="{
                'white-space': 'normal',
                'max-width': '600px',
                'word-break': 'break-word'
              }"
            ></div>
          </app-accordion-item>
        </app-accordion>

        <div
          class="d-flex py-5 align-items-center flex-column"
          *ngIf="!formLoading && faqList.length === 0"
        >
          <div class="pvc pv-search pv-1x mb-3"></div>
          <p>{{ 'app.text.noResultsFor' | translate }} "{{ keyword.value }}"</p>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12" *ngIf="pagination.totalRecords > 0">
          <app-pagination
            [model]="pagination"
            (onChange)="goToPage($event)"
          ></app-pagination>
        </div>
      </div>
    </app-card>
  </div>
</div>
