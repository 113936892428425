<app-layout [isLoading]="formLoading" [backTo]="urlBackOutside">
  <ng-template #additionalTitle *ngIf="!formLoading"> </ng-template>
  <div class="row main-row" *ngIf="!formLoading">
    <div class="col-lg-12 mb-4">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-card
          *ngIf="claim.acceptGLDate === null && !formGroup.isView"
          header="authority-letter.form.detail.acceptGl"
          tips="authority-letter.form.detail.acceptGl"
        >
          <label
            class="d-flex justify-content-center align-items-center text-center mb-5"
            >{{ 'authority-letter.form.messageAcceptGl' | translate }}</label
          >
          <div class="d-flex justify-content-center align-items-center gap-2">
            <input
              type="checkbox"
              (click)="doCheck($event)"
              [checked]="acceptGlStatus"
              (keyup)="onKeyUp($event)"
              (keydown)="onKeyDown($event)"
              aria-label="Accept GL"
            />
            <span class="mx-1">Accept GL</span>
          </div>
          <small
            class="text-danger d-flex justify-content-center align-items-center"
            *ngIf="!isHideValid"
          >
            {{ 'authority-letter.form.errorMessageAcceptGl' | translate }}
          </small>
        </app-card>
        <app-card
          *ngIf="
            claim.acceptGLDate !== null ||
            this.global.userSession.activeUserRole.role.code !==
              this.global.appConstant.ROLE_CODE_USER ||
            (claim.acceptGLDate === null && formGroup.isView)
          "
          header="authority-letter.form.detail.claim"
          tips="authority-letter.form.detail.claim"
        >
          <app-fullfilled *ngIf="!formGroup.isView"></app-fullfilled>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.hospitalName' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ claim.hospitalName }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.guaranteeId' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ claim.glNumber }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.guaranteeLetter' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ claim.idJaminan }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.verificator' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ verificator.name }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.counter' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ claim.kantorJR }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.accidentVictim' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ claim.accidentVictim }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.accidentDate' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{
                claim.accidentDate
                  ? (claim.accidentDate
                    | date : global.appConstant.core.FORMAT_LONG_DATE)
                  : '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.accidentLocation' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                autofocus="true"
                formControlName="accidentLocation"
                size="MD"
                maxLength="64"
              >
              </app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.treatmentDate' | translate
            }}</label>
            <app-date-picker-x
              *ngIf="!formGroup.isView"
              class="col-sm-3"
              formControlName="treatmentFromDate"
              maxDate="currentDate"
              size="M"
            ></app-date-picker-x>
            <div class="col-sm-3 text-left" *ngIf="formGroup.isView">
              {{
                formGroup.value.treatmentFromDate
                  ? (formGroup.value.treatmentFromDate
                    | date : global.appConstant.core.FORMAT_LONG_DATE)
                  : '-'
              }}
            </div>
            <span
              *ngIf="!formGroup.isView"
              class="d-flex justify-content-center align-items-center text-center"
              >s/d</span
            >
            <span *ngIf="formGroup.isView" class="align-items-center">s/d</span>
            <app-date-picker-x
              *ngIf="!formGroup.isView"
              class="col-sm-3"
              formControlName="treatmentToDate"
              minDate="treatmentFromDate"
              maxDate="currentDate"
              size="M"
            ></app-date-picker-x>
            <div class="col-sm-3 text-left" *ngIf="formGroup.isView">
              {{
                formGroup.value.treatmentToDate
                  ? (formGroup.value.treatmentToDate
                    | date : global.appConstant.core.FORMAT_LONG_DATE)
                  : '-'
              }}
            </div>
            <span class="col-sm-2"></span>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.totalRequestAmount' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-currency
                formControlName="totalRequestAmount"
                [optionList]="currencyOptionList"
                maxLength="16"
                placeholder=" "
              ></app-currency>
            </div>
          </div>
        </app-card>

        <app-card
          *ngIf="
            claim.acceptGLDate !== null ||
            this.global.userSession.activeUserRole.role.code !==
              this.global.appConstant.ROLE_CODE_USER ||
            (claim.acceptGLDate === null && formGroup.isView)
          "
          header="authority-letter.form.detail.firstParty"
          tips="authority-letter.form.detail.firstParty"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.firstPartyName' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="firstPartyName"
                size="MD"
                maxLength="128"
              >
              </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.firstPartyGender' | translate
            }}</label>
            <div class="col-sm-5 text-left" *ngIf="!formGroup.isView">
              <app-dropdown-select
                size="LG"
                [optionList]="genderList"
                formControlName="firstPartyGender"
                type="COMBOBOX"
              >
                <ng-template #value let-value>
                  <div *ngIf="value">{{
                    getTranslateKey(value) | translate
                  }}</div>
                </ng-template>
                <ng-template #option let-data>
                  {{ getTranslateKey(data) | translate }}
                </ng-template>
              </app-dropdown-select>
            </div>
            <div class="col-sm-9 text-left" *ngIf="formGroup.isView">
              {{ formGroup.value.firstPartyGender?.name || '-' }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.firstPartyDob' | translate
            }}</label>
            <app-text-field
              class="col-sm-3"
              formControlName="firstPartyPlaceOfBirth"
              size="M"
              maxLength="128"
            >
            </app-text-field>
            <app-date-picker-x
              *ngIf="!formGroup.isView"
              class="col-sm-3"
              formControlName="firstPartyDobDate"
              maxDate="currentDate"
              size="M"
            ></app-date-picker-x>
            <div class="col-sm-3 text-left" *ngIf="formGroup.isView">
              {{
                formGroup.value.firstPartyDobDate
                  ? (formGroup.value.firstPartyDobDate
                    | date : global.appConstant.core.FORMAT_LONG_DATE)
                  : '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.firstPartyAddress' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-area
                formControlName="firstPartyAddress"
                maxLength="1064"
                size="LG"
              >
              </app-text-area>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.firstPartyNik' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                type="integer"
                formControlName="firstPartyNik"
                size="MD"
                maxLength="64"
              >
              </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.firstPartyPhone' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                type="integer"
                formControlName="firstPartyPhone"
                size="MD"
                maxLength="64"
              >
              </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.firstPartyAuthority' | translate
            }}</label>
            <div class="col-sm-5 text-left" *ngIf="!formGroup.isView">
              <app-dropdown-select
                size="LG"
                [optionList]="authorityList"
                formControlName="firstPartyAuthority"
                type="COMBOBOX"
              >
                <ng-template #value let-value>
                  <div *ngIf="value">{{
                    getTranslateKey(value) | translate
                  }}</div>
                </ng-template>
                <ng-template #option let-data>
                  {{ getTranslateKey(data) | translate }}
                </ng-template>
              </app-dropdown-select>
            </div>
            <div class="col-sm-9 text-left" *ngIf="formGroup.isView">
              {{ formGroup.value.firstPartyAuthority?.name || '-' }}
            </div>
          </div>
        </app-card>

        <app-card
          *ngIf="
            claim.acceptGLDate !== null ||
            this.global.userSession.activeUserRole.role.code !==
              this.global.appConstant.ROLE_CODE_USER ||
            (claim.acceptGLDate === null && formGroup.isView)
          "
          header="authority-letter.form.detail.secondParty"
          tips="authority-letter.form.detail.secondParty"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.secondPartyName' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="secondPartyName"
                size="MD"
                maxLength="128"
              >
              </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.secondPartyNik' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                type="integer"
                formControlName="secondPartyNik"
                size="MD"
                maxLength="64"
              >
              </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.secondPartyPosition' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="secondPartyPosition"
                size="MD"
                maxLength="64"
              >
              </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'authority-letter.form.secondPartyRepresent' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="secondPartyRepresent"
                size="MD"
                maxLength="128"
              >
              </app-text-field>
            </div>
          </div>
        </app-card>

        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div class="button-group button-group-center">
              <app-button
                color="SECONDARY"
                (onClick)="doBack()"
                mode="BYPASS"
                [disabled]="formSaving"
                >{{ 'app.button.back' | translate }}
              </app-button>
              <app-button
                *ngIf="todo !== 'view' && claim.acceptGLDate !== null"
                color="PRIMARY"
                (onClick)="
                  doSubmit(
                    this.global.appConstant.pm.CLAIM_STATUS_AUTHORITY_LETTER
                  )
                "
                [disabled]="formSaving"
                class="mr-1"
                >{{ 'app.button.saveDraft' | translate }}</app-button
              >
              <app-button
                (onClick)="
                  doSubmit(this.global.appConstant.pm.CLAIM_STATUS_SUBMIT_CLAIM)
                "
                *ngIf="todo !== 'view' && claim.acceptGLDate !== null"
                >{{ 'app.button.submit' | translate }}
              </app-button>
              <app-button
                (onClick)="doSubmit(null)"
                *ngIf="todo !== 'view' && claim.acceptGLDate === null"
                >{{ 'app.button.submit' | translate }}
              </app-button>
              <app-button
                (onClick)="doGenerate($event)"
                mode="BYPASS"
                *ngIf="
                  todo === 'view' &&
                  this.authorityLetterResponse.authorityLetter != null &&
                  this.authorityLetterResponse.authorityLetter?.isDraft == false
                "
                >{{ 'app.button.printReport' | translate }}
              </app-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
