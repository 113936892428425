import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { TablePluginData } from 'src/app/core/components/app-table-x/interfaces/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/app-table-x/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { DeliveryStatus } from '../../core/bean/delivery-status';
import { Dr } from '../../core/bean/dr';
import { DrType } from '../../core/bean/dr-type';
import { Organization } from '../../core/bean/organization';
import { DrOptionListResponse } from './response/dr-option-list.response';

@Component({
  templateUrl: './dr.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DrComponent extends BaseModuleComponent implements OnInit {
  public filter: string;
  public tableResponse: TableResponseModel<Dr>;
  public statusOptionList: OptionListModel<DeliveryStatus> =
    new OptionListModel(true);
  public drTypeOptionList: OptionListModel<DrType> = new OptionListModel(true);
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel(true);

  constructor(translateService: TranslateService, private datePipe: DatePipe) {
    super('dr', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.doBuildTableResponse();
    this.buildSetFormGroup();
    this.doSetCustomData();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    this.filter = this.global.routerParams.get('filter');
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      this.global.userSession?.activeUserRole.role.code !==
        this.global.appConstant.ROLE_CODE_VENDOR
        ? [
          {
            field: 'code',
            header: 'table.column.orderNumber',
            plugins: {
              name: 'hyperlink',
              onClick: this.doClick.bind(this),
              className: 'white-space-normal'
            }
          },
          {
            field: 'drType.name',
            header: 'table.column.orderType'
          },
          {
            field: 'requiredDate',
            header: 'table.column.requiredDate',
            plugins: {
              name: 'date',
              format: 'short-date'
            }
          },
          {
            field: 'title',
            header: 'table.column.title'
          },
          {
            field: 'organization.name',
            header: 'table.column.department'
          },
          { field: 'organization.name', header: 'table.column.requestorDepartment' },
          {
            field: 'organizationDistributor.distributorLocationCode',
            header: 'table.column.distributorLocationCode',
            sortable: false

          },
          {
            field: 'percentage',
            header: 'table.column.percentageOfWork',
            plugins: {
              name: 'custom-plugin',
              before: (tablePlugin: TablePluginData): string => {
                const value = tablePlugin.value || 0;
                return `${this.global.converterService.convertDecimal(value)} %`;
              }
            }
          },
          {
            field: 'deliveryStatus.name',
            header: 'table.column.status',
            plugins: {
              name: 'badge',
              pill: true,
              colorMap: {
                ON_PROGRESS: 'PROGRESS',
                DONE: 'SUCCESS',
                CLOSED: 'DANGER',
                CANCELED: 'DANGER'
              },
              field: 'deliveryStatus.code'
            }
          }
        ]
        : [
          {
            field: 'code',
            header: 'table.column.orderNumber',
            plugins: {
              name: 'hyperlink',
              onClick: this.doClick.bind(this),
              className: 'white-space-normal'
            }
          },
          {
            field: 'drType.name',
            header: 'table.column.orderType'
          },
          {
            field: 'requiredDate',
            header: 'table.column.requiredDate',
            plugins: {
              name: 'date',
              format: 'short-date'
            }
          },
          {
            field: 'title',
            header: 'table.column.title'
          },
          {
            field: 'percentage',
            header: 'table.column.percentageOfWork',
            plugins: {
              name: 'custom-plugin',
              before: (tablePlugin: TablePluginData): string => {
                const value = tablePlugin.value || 0;
                return `${this.global.converterService.convertDecimal(value)} %`;
              }
            }
          },
          {
            field: 'deliveryStatus.name',
            header: 'table.column.status',
            plugins: {
              name: 'badge',
              pill: true,
              colorMap: {
                ON_PROGRESS: 'PROGRESS',
                DONE: 'SUCCESS',
                CLOSED: 'DANGER',
                CANCELED: 'DANGER'
              },
              field: 'deliveryStatus.code'
            }
          }
        ]
    );
  }

  public buildSetFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      requiredDateFrom: [null],
      requiredDateTo: [null],
      organizationList: [null],
      statusList: [null],
      drTypeList: [null]
    });

    this.httpClientService
      .get<DrOptionListResponse>('/dr/get-option-list', {})
      .subscribe((response: DrOptionListResponse) => {
        this.statusOptionList.setRequestValues(response.statusList);
        this.organizationOptionList.setRequestValues(response.organizationList);
        this.drTypeOptionList.setRequestValues(response.drTypeList);
      });
  }

  public doSetCustomData(): void {
    if (this.filter) {
      const customData = {
        statusCode: this.filter
      };
      this.global.routerParams.clear();
      this.tableResponse.setCustomData(customData);
      this.tableResponse.reload();
    }
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      const fromDate = this.datePipe.transform(this.formGroup.value.date.from, 'yyyy-MM-dd');
      const untilDate = this.datePipe.transform(this.formGroup.value.date.to, 'yyyy-MM-dd');
  
      this.formGroup.patchValue({
        requiredDateFrom: fromDate,
        requiredDateTo: untilDate
      });
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
    this.formGroup.get('requiredDateFrom').reset();
    this.formGroup.get('requiredDateTo').reset();
  }

  public doClick(dr: Dr): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('dr', dr);
    this.global.routerParams.set('drId', dr.id);
    this.global.routerParams.set('urlBackOutside', '/pages/dr');
    if (this.global.getIsMenuWrite()) {
      if (
        this.global.userSession?.activeUserRole.role.code ===
        this.global.appConstant.ROLE_CODE_VENDOR ||
        dr.deliveryStatus.code ===
        this.global.appConstant.pm.DELIVERY_STATUS_DONE ||
        dr.deliveryStatus.code ===
        this.global.appConstant.pm.DELIVERY_STATUS_CLOSED ||
        dr.deliveryStatus.code ===
        this.global.appConstant.pm.DELIVERY_STATUS_CANCELED
      ) {
        this.global.routerParams.set('todo', 'view');
        this.router.navigate(['/pages/dr/detail']);
      } else {
        this.global.routerParams.set('todo', 'edit');
        this.router.navigate(['/pages/dr/edit']);
      }
    } else {
      this.global.routerParams.set('todo', 'view');
      this.router.navigate(['/pages/dr/detail']);
    }
  }
}
