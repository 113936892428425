
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CatalogAttributeService {

    private catalogAtributeParamSubject = new BehaviorSubject<{ catalogAtributId?: number | null }>({ catalogAtributId: null });
    catalogAtributeParam$ = this.catalogAtributeParamSubject.asObservable();

    setCatalogAtributeParam(param: { catalogAtributId?: number | null }) {
        this.catalogAtributeParamSubject.next(param);
    }

    getCatalogAtributeParam(): { catalogAtributId?: number | null } {
        return this.catalogAtributeParamSubject.value;
    }
}