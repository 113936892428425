<app-popup header="user-manual.popup.title" [isLoading]="formLoading">
  <div class="table-responsive custom-table">
    <table 
      class="table table-striped"
      [formGroup]="formGroup"
      aria-describedby="Table"
    >
      <thead>
      </thead>
      <tbody formArrayName="userManualList">
        <tr
        class="tr-no-data"
        *ngIf="!formLoading && userManualList?.length === 0"
      >
        <td colspan="9999999" rowspan="7" class="bg-white text-center">
          <div class="no-data-wrapper" >
            <div class="text-no-data">
              <span class="text-no-data">
                {{ 'app-table.noData' | translate }}
              </span>
            </div>
          </div>
        </td>
      </tr>
        <tr
          *ngFor="
            let userManual of formArrayUserManualList.controls;
            let i = index
          "
          [formGroup]="userManual"
        > <td>{{ userManual.value.title }}</td>
          <td *ngIf="!formLoading && userManual.value.type.code === this.global.appConstant.pm.USER_MANUAL_TYPE_CODE_DOCUMENT" >
          <app-upload
            formControlName="fileObjectList"
            [fileUploader]="fileUploader"
            size="XL"
            [isView]="userManual.value.isView">
          </app-upload>

          </td>
          <td *ngIf="userManual.value.type.code === this.global.appConstant.pm.USER_MANUAL_TYPE_CODE_LINK">
            <a
              *ngIf="userManual.value.link"
              [href]="userManual.value.link"
              target="_blank"
              rel="noopener noreferrer"
              style="color: blue; text-decoration: underline;"
            >
            {{ 'Link' || ('user-manual.link.open' | translate) }}
            </a>
              <em
                class="pir pi-external-link"
                [href]="userManual.value.link"
                target="_blank"
                style="color: blue; text-decoration: underline;"
                placement="top"
              ></em>
            </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-popup>
