import { AccidentVictimCondition } from "src/app/core/bean/accident-victim-condition";
import { AccidentVictimDiagnose } from "src/app/core/bean/accident-victim-diagnose";
import { PatientCondition } from "src/app/core/bean/patient-condition";
import { VictimDiagnoseInjuriesCategory } from "src/app/core/bean/victim-diagnose-injuries-category";
import { VictimInformantCategory } from "src/app/core/bean/victim-informant-category";
import { AccidentVictim } from "../../core/bean/accident-victim";
import { AccidentVictimTreatment } from "../../core/bean/accident-victim-treatment";
import { Claim } from "../../core/bean/claim";
import { Gender } from "../../core/bean/gender";
import { VictimAction } from "../../core/bean/victim-action";
import { VictimCondition } from "../../core/bean/victim-condition";
import { VictimDiagnose } from "../../core/bean/victim-diagnose";
import { VictimInjuries } from "../../core/bean/victim-injuries";
import { VictimSurgery } from "../../core/bean/victim-surgery";

export class AccidentVictimResponse {
    accidentVictim: AccidentVictim;
    claim: Claim;
    victimDiagnoseList: VictimDiagnose[];
    patientConditionList: PatientCondition[];
    victimConditionList: VictimCondition[];
    accidentVictimTreatmenntList: AccidentVictimTreatment[];
    genderList: Gender[];
    victimInjuriesCategoryList: VictimInjuries[];
    victimSurgeryList: VictimSurgery[];
    accidentVictimTreatmenntOperationList: AccidentVictimTreatment[];
    victimActionList: VictimAction[];
    accidentVictimDiagnoseSekunderList: AccidentVictimDiagnose[];
    accidentVictimDiagnoseOtherList: AccidentVictimDiagnose[];
    accidentVictimConditionList: AccidentVictimCondition[];
    victimInformantCategoryList: VictimInformantCategory[];
    victimDiagnoseInjuriesCategorySekunderList: VictimDiagnoseInjuriesCategory[];
    victimDiagnoseSekunderList: VictimDiagnose[];
}