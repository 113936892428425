import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guard/auth.guard';
import { PagesComponent } from './pages.component';
export const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'aanwijzing',
        loadChildren: () =>
          import('./aanwijzing/aanwijzing.module').then(
            m => m.AanwijzingModule
          ),
        data: { breadcrumb: 'aanwijzing.breadcrumb.index' }
      },
      {
        path: 'account-setting',
        loadChildren: () =>
          import('./account-setting/account-setting.module').then(
            m => m.AccountSettingModule
          ),
        data: { breadcrumb: 'account-setting.breadcrumb.index' }
      },
      {
        path: 'address-book',
        loadChildren: () =>
          import('./address-book/address-book.module').then(
            m => m.AddressBookModule
          ),
        data: { breadcrumb: 'address-book.breadcrumb.index' }
      },
      {
        path: 'admin-panel',
        loadChildren: () =>
          import('./admin-panel/admin-panel.module').then(
            m => m.AdminPanelModule
          ),
        data: { breadcrumb: 'admin-panel.breadcrumb.index' }
      },
      {
        path: 'administration-criteria',
        loadChildren: () =>
          import(
            './administration-criteria/administration-criteria.module'
          ).then(m => m.AdministrationCriteriaModule),
        data: { breadcrumb: 'administration-criteria.breadcrumb.index' }
      },
      {
        path: 'administration-evaluation',
        loadChildren: () =>
          import(
            './administration-evaluation/administration-evaluation.module'
          ).then(m => m.AdministrationEvaluationModule),
        data: { breadcrumb: 'administration-evaluation.breadcrumb.index' }
      },
      {
        path: 'all-performance',
        loadChildren: () =>
          import('./performance-for-all/all-performance.module').then(
            m => m.AllPerformanceModule
          ),
        data: { breadcrumb: 'all-performance.breadcrumb.index' }
      },
      {
        path: 'approval',
        loadChildren: () =>
          import('./approval/approval.module').then(m => m.ApprovalModule),
        data: { breadcrumb: 'approval.breadcrumb.index' }
      },
      {
        path: 'approval-prcs',
        loadChildren: () =>
          import('./approval-prcs/approval-prcs.module').then(
            m => m.ApprovalPrcsModule
          ),
        data: { breadcrumb: 'worklist-approval.breadcrumb.index' }
      },
      {
        path: 'approval-workflow',
        loadChildren: () =>
          import('./approval-workflow/approval-workflow.module').then(
            m => m.ApprovalWorkflowModule
          ),
        data: { breadcrumb: 'approval-workflow.breadcrumb.index' }
      },
      {
        path: 'assignment-delegation',
        loadChildren: () =>
          import('./assignment-delegation/assignment-delegation.module').then(
            m => m.AssignmentDelegationModule
          ),
        data: { breadcrumb: 'assignment-delegation.breadcrumb.index' }
      },
      {
        path: 'association',
        loadChildren: () =>
          import('./association/association.module').then(
            m => m.AssociationModule
          ),
        data: { breadcrumb: 'association.breadcrumb.index' }
      },
      {
        path: 'attribute',
        loadChildren: () =>
          import('./attribute/attribute.module').then(m => m.AttributeModule),
        data: { breadcrumb: 'attribute.breadcrumb.index' }
      },
      {
        path: 'authority-letter',
        loadChildren: () =>
          import('./authority-letter/authority-letter.module').then(
            m => m.AuthorityLetterModule
          ),
        data: { breadcrumb: 'authority-letter.breadcrumb.index' }
      },
      {
        path: 'bank',
        loadChildren: () =>
          import('./bank/bank.module').then(m => m.BankModule),
        data: { breadcrumb: 'bank.breadcrumb.index' }
      },
      {
        path: 'bank-account',
        loadChildren: () =>
          import('./bank-account/bank-account.module').then(
            m => m.BankAccountModule
          ),
        data: { breadcrumb: 'bank-account.breadcrumb.index' }
      },
      {
        path: 'billing-memo',
        loadChildren: () =>
          import('./billing-memo/billing-memo.module').then(
            m => m.BillingMemoModule
          ),
        data: { breadcrumb: 'billing-memo.breadcrumb.index' }
      },
      {
        path: 'billing-term',
        loadChildren: () =>
          import('./billing-term/billing-term.module').then(
            m => m.BillingTermModule
          ),
        data: { breadcrumb: 'billing-term.breadcrumb.index' }
      },
      {
        path: 'blacklist-criteria',
        loadChildren: () =>
          import('./blacklist-criteria/blacklist-criteria.module').then(
            m => m.BlacklistCriteriaModule
          ),
        data: { breadcrumb: 'blacklist-criteria.breadcrumb.index' }
      },
      {
        path: 'budget-source',
        loadChildren: () =>
          import('./budget-source/budget-source.module').then(
            m => m.BugdetSourceModule
          ),
        data: { breadcrumb: 'budget-source.breadcrumb.index' }
      },
      {
        path: 'budget-allocation',
        loadChildren: () =>
          import('./budget-allocation/budget-allocation.module').then(
            m => m.BudgetAllocationModule
          ),
        data: { breadcrumb: 'budget-allocation.breadcrumb.index' }
      },
      {
        path: 'budget-allocation-bjb',
        loadChildren: () =>
          import('./budget-allocation-bjb/budget-allocation-bjb.module').then(
            m => m.BudgetAllocationBjbModule
          ),
        data: { breadcrumb: 'budget-allocation-bjb.breadcrumb.index' }
      },
      {
        path: 'cart',
        loadChildren: () =>
          import('./cart/cart.module').then(m => m.CartModule),
        data: { breadcrumb: 'cart.breadcrumb.index' }
      },
      {
        path: 'catalog-attribute',
        loadChildren: () =>
          import('./catalog-attribute/catalog-attribute.module').then(
            m => m.CatalogAttributeModule
          ),
        data: { breadcrumb: 'catalog-attribute.breadcrumb.index' }
      },
      {
        path: 'catalog-attribute-group',
        loadChildren: () =>
          import(
            './catalog-attribute-group/catalog-attribute-group.module'
          ).then(m => m.CatalogAttributeGroupModule),
        data: { breadcrumb: 'catalog-attribute-group.breadcrumb.index' }
      },
      {
        path: 'catalog-verification',
        loadChildren: () =>
          import('./catalog-verification/catalog-verification.module').then(
            m => m.CatalogVerificationModule
          ),
        data: { breadcrumb: 'catalog-verification.breadcrumb.index' }
      },
      {
        path: 'worklist-cm',
        loadChildren: () =>
          import('./worklist-cm/worklist-cm.module').then(
            m => m.WorklistCMModule
          ),
        data: { breadcrumb: 'worklist-cm.breadcrumb.index' }
      },
      {
        path: 'item-category',
        loadChildren: () =>
          import('./item-category/item-category.module').then(
            m => m.ItemCategoryModule
          ),
        data: { breadcrumb: 'item-category.breadcrumb.index' }
      },
      {
        path: 'coa',
        loadChildren: () => import('./coa/coa.module').then(m => m.CoaModule),
        data: { breadcrumb: 'coa.breadcrumb.index' }
      },
      {
        path: 'coa-bjb',
        loadChildren: () =>
          import('./coa-bjb/coa-bjb.module').then(m => m.CoaBJBModule),
        data: { breadcrumb: 'coa-bjb.breadcrumb.index' }
      },
      {
        path: 'committee',
        loadChildren: () =>
          import('./committee/committee.module').then(m => m.CommitteeModule),
        data: { breadcrumb: 'committee.breadcrumb.index' }
      },
      {
        path: 'company-profile',
        loadChildren: () =>
          import('./company-profile/company-profile.module').then(
            m => m.CompanyProfileModule
          ),
        data: { breadcrumb: 'company-profile.breadcrumb.index' }
      },
      {
        path: 'compliance-test',
        loadChildren: () =>
          import('./compliance-test/compliance-test.module').then(
            m => m.ComplianceTestModule
          ),
        data: { breadcrumb: 'compliance-test.breadcrumb.index' }
      },
      {
        path: 'compliance-test-master',
        loadChildren: () =>
          import('./compliance-test-master/compliance-test-master.module').then(
            m => m.ComplianceTestMasterModule
          ),
        data: { breadcrumb: 'compliance-test-master.breadcrumb.index' }
      },
      {
        path: 'compliance-test-group',
        loadChildren: () =>
          import('./compliance-test-group/compliance-test-group.module').then(
            m => m.ComplianceTestGroupModule
          ),
        data: { breadcrumb: 'compliance-test-group.breadcrumb.index' }
      },
      {
        path: 'condition',
        loadChildren: () =>
          import('./condition/condition.module').then(m => m.ConditionModule),
        data: { breadcrumb: 'condition.breadcrumb.index' }
      },
      {
        path: 'content',
        loadChildren: () =>
          import('./content/content.module').then(m => m.ContentModule),
        data: { breadcrumb: 'content.breadcrumb.index' }
      },
      {
        path: 'contract-draft',
        loadChildren: () =>
          import('./contract-draft/contract-draft.module').then(
            m => m.ContractDraftModule
          ),
        data: { breadcrumb: 'contract-draft.breadcrumb.index' }
      },
      {
        path: 'contract-finalization',
        loadChildren: () =>
          import('./contract-finalization/contract-finalization.module').then(
            m => m.ContractFinalizationModule
          ),
        data: { breadcrumb: 'contract-finalization.breadcrumb.index' }
      },
      {
        path: 'contract-history',
        loadChildren: () =>
          import('./contract-history/contract-history.module').then(
            m => m.ContractHistoryModule
          ),
        data: { breadcrumb: 'contract-history.breadcrumb.index' }
      },
      {
        path: 'contract-history-vendor',
        loadChildren: () =>
          import(
            './contract-history-vendor/contract-history-vendor.module'
          ).then(m => m.ContractHistoryVendorModule),
        data: { breadcrumb: 'contract-history-vendor.breadcrumb.index' }
      },
      {
        path: 'contract-monitoring',
        loadChildren: () =>
          import('./contract-monitoring/contract-monitoring.module').then(
            m => m.ContractMonitoringModule
          ),
        data: { breadcrumb: 'contract-monitoring.breadcrumb.index' }
      },
      {
        path: 'contract-negotiation',
        loadChildren: () =>
          import('./contract-negotiation/contract-negotiation.module').then(
            m => m.ContractNegotiationModule
          ),
        data: { breadcrumb: 'contract-negotiation.breadcrumb.index' }
      },
      {
        path: 'contract-parameter',
        loadChildren: () =>
          import('./contract-parameter/contract-parameter.module').then(
            m => m.ContractParameterModule
          ),
        data: { breadcrumb: 'contract-parameter.breadcrumb.index' }
      },
      {
        path: 'contract-request',
        loadChildren: () =>
          import('./contract-request/contract-request.module').then(
            m => m.ContractRequestModule
          ),
        data: { breadcrumb: 'contract-request.breadcrumb.index' }
      },
      {
        path: 'contract-template',
        loadChildren: () =>
          import('./contract-template/contract-template.module').then(
            m => m.ContractTemplateModule
          ),
        data: { breadcrumb: 'contract-template.breadcrumb.index' }
      },
      {
        path: 'contract-termination',
        loadChildren: () =>
          import('./contract-termination/contract-termination.module').then(
            m => m.ContractTerminationModule
          ),
        data: { breadcrumb: 'contract-termination.breadcrumb.index' }
      },
      {
        path: 'contract-type',
        loadChildren: () =>
          import('./contract-type/contract-type.module').then(
            m => m.ContractTypeModule
          ),
        data: { breadcrumb: 'contract-type.breadcrumb.index' }
      },
      {
        path: 'cost-center',
        loadChildren: () =>
          import('./cost-center/cost-center.module').then(
            m => m.CostCenterModule
          ),
        data: { breadcrumb: 'cost-center.breadcrumb.index' }
      },
      {
        path: 'country-flag',
        loadChildren: () =>
          import('./country-flag/country-flag.module').then(
            m => m.CountryFlagModule
          ),
        data: { breadcrumb: 'country-flag.breadcrumb.index' }
      },
      {
        path: 'currency',
        loadChildren: () =>
          import('./currency/currency.module').then(m => m.CurrencyModule),
        data: { breadcrumb: 'currency.breadcrumb.index' }
      },
      {
        path: 'claim-unclaim',
        loadChildren: () =>
          import('./claim-unclaim/claim-unclaim.module').then(
            m => m.ClaimUnclaimModule
          ),
        data: { breadcrumb: 'claim-unclaim.breadcrumb.index' }
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { breadcrumb: 'dashboard.breadcrumb.index' }
      },
      {
        path: 'dealing-agreement-type',
        loadChildren: () =>
          import('./dealing-agreement-type/dealing-agreement-type.module').then(
            m => m.DealingAgreementTypeModule
          ),
        data: { breadcrumb: 'dealing-agreement-type.breadcrumb.index' }
      },
      {
        path: 'delivery-monitoring',
        loadChildren: () =>
          import('./delivery-monitoring/delivery-monitoring.module').then(
            m => m.DeliveryMonitoringModule
          ),
        data: { breadcrumb: 'delivery-monitoring.breadcrumb.index' }
      },
      {
        path: 'dependency',
        loadChildren: () =>
          import('./dependency/dependency.module').then(
            m => m.DependencyModule
          ),
        data: { breadcrumb: 'dependency.breadcrumb.index' }
      },
      {
        path: 'directors-decree-submission',
        loadChildren: () =>
          import(
            './directors-decree-submission/directors-decree-submission.module'
          ).then(m => m.DirectorsDecreeSubmissionModule),
        data: { breadcrumb: 'directors-decree-submission.breadcrumb.index' }
      },
      {
        path: 'doc-group',
        loadChildren: () =>
          import('./doc-group/doc-group.module').then(m => m.DocGroupModule),
        data: { breadcrumb: 'document-group.breadcrumb.index' }
      },
      {
        path: 'doc-retrieval',
        loadChildren: () =>
          import('./doc-retrieval/doc-retrieval.module').then(
            m => m.DocRetrievalModule
          ),
        data: {
          breadcrumb: 'doc-retrieval.breadcrumb.index'
        }
      },
      {
        path: 'doc-retrieval-response',
        loadChildren: () =>
          import('./doc-retrieval-response/doc-retrieval-response.module').then(
            m => m.DocRetrievalResponseModule
          ),
        data: {
          breadcrumb: 'doc-retrieval-response.breadcrumb.index'
        }
      },
      {
        path: 'document-type',
        loadChildren: () =>
          import('./file-type/file-type.module').then(m => m.FileTypeModule),
        data: { breadcrumb: 'document-type.breadcrumb.index' }
      },
      // {
      //   path: 'drtu-criteria',
      //   loadChildren: () =>
      //       import('./drtu-criteria/drtu-criteria.module').then(
      //           m => m.DrtuCriteriaModule
      //       ),
      //   data: { breadcrumb: 'drtu-criteria.breadcrumb.index' }
      // },
      {
        path: 'due-diligence',
        loadChildren:
          'src/app/pages/due-diligence/due-diligence.module#DueDiligenceModule',
        data: { breadcrumb: 'Due Diligence' }
      },
      {
        path: 'pq',
        loadChildren: () => import('./pq/pq.module').then(m => m.PqModule),
        data: { breadcrumb: 'pq.breadcrumb.index' }
      },
      {
        path: 'dr',
        loadChildren: () => import('./dr/dr.module').then(m => m.DrModule),
        data: { breadcrumb: 'dr.breadcrumb.index' }
      },
      {
        path: 'due-diligence-template',
        loadChildren: () =>
          import('./due-diligence-template/due-diligence-template.module').then(
            m => m.DueDiligenceTemplateModule
          ),
        data: { breadcrumb: 'due-diligence-template.breadcrumb.index' }
      },
      {
        path: 'education',
        loadChildren: () =>
          import('./education/education.module').then(m => m.EducationModule),
        data: { breadcrumb: 'education.breadcrumb.index' }
      },
      {
        path: 'employee',
        loadChildren: () =>
          import('./example/employee/employee.module').then(
            m => m.EmployeeModule
          ),
        data: { breadcrumb: 'employee.breadcrumb.index' }
      },
      {
        path: 'sample',
        loadChildren: () =>
          import('./example/sample/sample.module').then(m => m.SampleModule),
        data: { breadcrumb: 'sample.breadcrumb.index' }
      },
      {
        path: 'employee-detail',
        loadChildren: () =>
          import('./example/employee-detail/employee-detail.module').then(
            m => m.EmployeeDetailModule
          ),
        data: { breadcrumb: 'employee-detail.breadcrumb.index' }
      },
      {
        path: 'employee-data',
        loadChildren: () =>
          import('./employee-data/employee-data.module').then(
            m => m.EmployeeDataModule
          ),
        data: { breadcrumb: 'employee-data.breadcrumb.index' }
      },
      {
        path: 'equipment-type',
        loadChildren: () =>
          import('./equipment-type/equipment-type.module').then(
            m => m.EquipmentTypeModule
          ),
        data: { breadcrumb: 'equipment-type.breadcrumb.index' }
      },
      {
        path: 'evaluation-criteria',
        loadChildren: () =>
          import('./evaluation-criteria/evaluation-criteria.module').then(
            m => m.EvaluationCriteriaModule
          ),
        data: { breadcrumb: 'evaluation-criteria.breadcrumb.index' }
      },
      {
        path: 'example-one',
        loadChildren: () =>
          import('./example/example-one/example-one.module').then(
            m => m.ExampleOneModule
          ),
        data: { breadcrumb: 'employee-one.breadcrumb.index' }
      },
      {
        path: 'executor',
        loadChildren: () =>
          import('./executor/executor.module').then(m => m.ExecutorModule),
        data: { breadcrumb: 'executor.breadcrumb.index' }
      },
      {
        path: 'fines',
        loadChildren: () =>
          import('./fines/fines.module').then(m => m.FinesModule),
        data: { breadcrumb: 'fines.breadcrumb.index' }
      },
      {
        path: 'fines-monitoring',
        loadChildren: () =>
          import('./fines-monitoring/fines-monitoring.module').then(
            m => m.FinesMonitoringModule
          ),
        data: { breadcrumb: 'fines-monitoring.breadcrumb.index' }
      },
      {
        path: 'guarantee-bond-type',
        loadChildren: () =>
          import('./guarantee-bond-type/guarantee-bond-type.module').then(
            m => m.GuaranteeBondTypeModule
          ),
        data: { breadcrumb: 'guarantee-bond-type.breadcrumb.index' }
      },
      {
        path: 'holiday',
        loadChildren: () =>
          import('./holiday/holiday.module').then(m => m.HolidayModule),
        data: { breadcrumb: 'holiday.breadcrumb.index' }
      },
      {
        path: 'industry',
        loadChildren: () =>
          import('./industry/industry.module').then(m => m.IndustryModule),
        data: { breadcrumb: 'industry.breadcrumb.index' }
      },
      {
        path: 'initiation',
        loadChildren: () =>
          import('./initiation/initiation.module').then(
            m => m.InitiationModule
          ),
        data: { breadcrumb: 'initiation.breadcrumb.index' }
      },
      {
        path: 'institution',
        loadChildren: () =>
          import('./institution/institution.module').then(
            m => m.InstitutionModule
          ),
        data: { breadcrumb: 'institution.breadcrumb.index' }
      },
      {
        path: 'internal-payment-request',
        loadChildren: () =>
          import(
            './internal-payment-request/internal-payment-request.module'
          ).then(m => m.InternalPaymentRequestModule),
        data: { breadcrumb: 'internal-payment-request.breadcrumb.index' }
      },
      {
        path: 'item',
        loadChildren: () =>
          import('./item/item.module').then(m => m.ItemModule),
        data: { breadcrumb: 'item.breadcrumb.index' }
      },
      {
        path: 'lang',
        loadChildren: () =>
          import('./lang/lang.module').then(m => m.LangModule),
        data: { breadcrumb: 'lang.breadcrumb.index' }
      },
      {
        path: 'login-log',
        loadChildren: () =>
          import('./login-log/login-log.module').then(m => m.LoginLogModule),
        data: { breadcrumb: 'login-log.breadcrumb.index' }
      },
      {
        path: 'manage-catalog',
        loadChildren: () =>
          import('./manage-catalog/manage-catalog.module').then(
            m => m.ManageCatalogModule
          ),
        data: { breadcrumb: 'manage-catalog.breadcrumb.index' }
      },
      {
        path: 'master-faq',
        loadChildren: () =>
          import('./master-faq/master-faq.module').then(
            m => m.MasterFaqModule
          ),
        data: { breadcrumb: 'master-faq.breadcrumb.index' }
      },
      {
        path: 'module',
        loadChildren: () =>
          import('./module/module.module').then(m => m.ModuleModule),
        data: { breadcrumb: 'module.breadcrumb.index' }
      },
      {
        path: 'module-dependency',
        loadChildren: () =>
          import('./module-dependency/module-dependency.module').then(
            m => m.ModuleDependencyModule
          ),
        data: { breadcrumb: 'module-dependency.breadcrumb.index' }
      },
      {
        path: 'module-group',
        loadChildren: () =>
          import('.//module-group/module-group.module').then(
            m => m.ModuleGroupModule
          ),
        data: { breadcrumb: 'module-group.breadcrumb.index' }
      },
      {
        path: 'mvc-generator',
        loadChildren: () =>
          import('./mvc-generator/mvc-generator.module').then(
            m => m.MVCGeneratorModule
          ),
        data: { breadcrumb: 'mvc-generator.breadcrumb.index' }
      },
      {
        path: 'news',
        loadChildren: () =>
          import('./news/news.module').then(m => m.NewsModule),
        data: { breadcrumb: 'news.breadcrumb.index' }
      },
      {
        path: 'notification-blast',
        loadChildren: () =>
          import('./notification-blast/notification-blast.module').then(
            m => m.NotificationBlastModule
          ),
        data: { breadcrumb: 'notification-blast.breadcrumb.index' }
      },
      {
        path: 'notification-job',
        loadChildren: () =>
          import('./notification-job/notification-job.module').then(
            m => m.NotificationJobModule
          ),
        data: { breadcrumb: 'notification-log.breadcrumb.index' }
      },
      {
        path: 'notification-loader',
        loadChildren: () =>
          import('./notification-loader/notification-loader.module').then(
            m => m.NotificationLoaderModule
          ),
        data: { breadcrumb: 'notification-loader.breadcrumb.index' }
      },
      {
        path: 'notification-template',
        loadChildren: () =>
          import('./notification-template/notification-template.module').then(
            m => m.NotificationTemplateModule
          ),
        data: { breadcrumb: 'notification-template.breadcrumb.index' }
      },
      {
        path: 'number-generator',
        loadChildren: () =>
          import('./number-generator/number-generator.module').then(
            m => m.NumberGeneratorModule
          ),
        data: { breadcrumb: 'number-generator.breadcrumb.index' }
      },
      {
        path: 'oep',
        loadChildren: () => import('./oep/oep.module').then(m => m.OepModule),
        data: { breadcrumb: 'oep.breadcrumb.index' }
      },
      {
        path: 'officer',
        loadChildren: () =>
          import('./officer/officer.module').then(m => m.OfficerModule),
        data: { breadcrumb: 'officer.breadcrumb.index' }
      },
      {
        path: 'official-memo-submission',
        loadChildren: () =>
          import(
            './official-memo-submission/official-memo-submission.module'
          ).then(m => m.OfficialMemoSubmissionModule),
        data: { breadcrumb: 'official-memo-submission.breadcrumb.index' }
      },
      {
        path: 'on-boarding',
        loadChildren: () =>
          import('./on-boarding/on-boarding.module').then(
            m => m.OnBoardingModule
          ),
        data: { breadcrumb: 'on-boarding.breadcrumb.index' }
      },
      {
        path: 'open-quotation',
        loadChildren: () =>
          import('./open-quotation/open-quotation.module').then(
            m => m.OpenQuotationModule
          ),
        data: { breadcrumb: 'open-quotation.breadcrumb.index' }
      },
      {
        path: 'open-quotation-2nd',
        loadChildren: () =>
          import('./open-quotation/open-quotation.module').then(
            m => m.OpenQuotationModule
          ),
        data: { breadcrumb: 'open-quotation.breadcrumb.index' }
      },
      {
        path: 'order',
        loadChildren: () =>
          import('./order/order.module').then(m => m.OrderModule),
        data: { breadcrumb: 'order.breadcrumb.index' }
      },
      {
        path: 'order-response',
        loadChildren: () =>
          import('./order-response/order-response.module').then(
            m => m.OrderResponseModule
          ),
        data: { breadcrumb: 'order-response.breadcrumb.index' }
      },
      {
        path: 'organization',
        loadChildren: () =>
          import('./organization/organization.module').then(
            m => m.OrganizationModule
          ),
        data: { breadcrumb: 'organization.breadcrumb.index' }
      },
      {
        path: 'parameter',
        loadChildren: () =>
          import('./parameter/parameter.module').then(m => m.ParameterModule),
        data: { breadcrumb: 'parameter.breadcrumb.index' }
      },
      {
        path: 'payment-history',
        loadChildren: () =>
          import('./payment-history/payment-history.module').then(
            m => m.PaymentHistoryModule
          ),
        data: { breadcrumb: 'payment-history.breadcrumb.index' }
      },
      {
        path: 'payment-monitoring',
        loadChildren: () =>
          import('./payment-monitoring/payment-monitoring.module').then(
            m => m.PaymentMonitoringModule
          ),
        data: { breadcrumb: 'payment-monitoring.breadcrumb.index' }
      },
      {
        path: 'payment-request-review',
        loadChildren: () =>
          import('./payment-request-review/payment-request-review.module').then(
            m => m.PaymentRequestReviewModule
          ),
        data: { breadcrumb: 'payment-request-review.breadcrumb.index' }
      },
      {
        path: 'payment-term',
        loadChildren: () =>
          import('./payment-term/payment-term.module').then(
            m => m.PaymentTermModule
          ),
        data: { breadcrumb: 'payment-term.breadcrumb.index' }
      },
      {
        path: 'payment-voucher',
        loadChildren: () =>
          import('./payment-voucher/payment-voucher.module').then(
            m => m.PaymentVoucherModule
          ),
        data: { breadcrumb: 'payment-voucher.breadcrumb.index' }
      },
      {
        path: 'performance-fulfillment',
        loadChildren: () =>
          import(
            './performance-fulfillment/performance-fulfillment.module'
          ).then(m => m.PerformanceFulfillmentModule),
        data: { breadcrumb: 'performance-fulfillment.breadcrumb.index' }
      },
      {
        path: 'performance-registration',
        loadChildren: () =>
          import(
            './performance-on-registration/performance-on-registration.module'
          ).then(m => m.PerformanceOnRegistrationModule),
        data: { breadcrumb: 'performance-on-registration.breadcrumb.index' }
      },
      {
        path: 'performance-procurement',
        loadChildren: () =>
          import(
            './performance-procurement/performance-procurement.module'
          ).then(m => m.PerformanceProcurementModule),
        data: { breadcrumb: 'performance-procurement.breadcrumb.index' }
      },
      {
        path: 'period',
        loadChildren: () =>
          import('./period/period.module').then(m => m.PeriodModule),
        data: { breadcrumb: 'period.breadcrumb.index' }
      },
      {
        path: 'pr',
        loadChildren: () => import('./pr/pr.module').then(m => m.PrModule),
        data: { breadcrumb: 'pr.breadcrumb.index' }
      },
      {
        path: 'price-evaluation',
        loadChildren: () =>
          import('./price-evaluation/price-evaluation.module').then(
            m => m.PriceEvaluationModule
          ),
        data: { breadcrumb: 'price-evaluation.breadcrumb.index' }
      },
      {
        path: 'price-evaluation-vendor',
        loadChildren: () =>
          import(
            './price-evaluation-vendor/price-evaluation-vendor.module'
          ).then(m => m.PriceEvaluationVendorModule),
        data: { breadcrumb: 'price-evaluation-vendor.breadcrumb.index' }
      },
      {
        path: 'procurement-content-management',
        loadChildren: () =>
          import(
            './procurement-content-management/procurement-content-management.module'
          ).then(m => m.ProcurementContentManagementModule),
        data: { breadcrumb: 'procurement-content-management.breadcrumb.index' }
      },
      {
        path: 'procurement-doc-type',
        loadChildren: () =>
          import('./procurement-doc-type/procurement-doc-type.module').then(
            m => m.ProcurementDocTypeModule
          ),
        data: { breadcrumb: 'procurement-doc-type.breadcrumb.index' }
      },
      {
        path: 'procurement-history',
        loadChildren: () =>
          import('./procurement-history/procurement-history.module').then(
            m => m.ProcurementHistoryModule
          ),
        data: { breadcrumb: 'procurement-history.breadcrumb.index' }
      },
      {
        path: 'procurement-history-vendor',
        loadChildren: () =>
          import(
            './procurement-history-vendor/procurement-history-vendor.module'
          ).then(m => m.ProcurementHistoryVendorModule),
        data: { breadcrumb: 'procurement-history-vendor.breadcrumb.index' }
      },
      {
        path: 'procurement-matrix',
        loadChildren: () =>
          import('./procurement-matrix/procurement-matrix.module').then(
            m => m.ProcurementMatrixModule
          ),
        data: { breadcrumb: 'procurement-matrix.breadcrumb.index' }
      },
      {
        path: 'procurement-method',
        loadChildren: () =>
          import('./procurement-method/procurement-method.module').then(
            m => m.ProcurementMethodModule
          ),
        data: { breadcrumb: 'procurement-method.breadcrumb.index' }
      },
      {
        path: 'procurement-offline-submission',
        loadChildren: () =>
          import(
            './procurement-offline-submission/procurement-offline-submission.module'
          ).then(m => m.ProcurementOfflineSubmissionModule),
        data: { breadcrumb: 'procurement-offline-submission.breadcrumb.index' }
      },
      {
        path: 'procurement-participant',
        loadChildren: () =>
          import(
            './procurement-participant/procurement-participant.module'
          ).then(m => m.ProcurementParticipantModule),
        data: { breadcrumb: 'procurement-participant.breadcrumb.index' }
      },
      {
        path: 'procurement-reg-vendor',
        loadChildren: () =>
          import('./procurement-reg-vendor/procurement-reg-vendor.module').then(
            m => m.ProcurementRegVendorModule
          ),
        data: { breadcrumb: 'procurement-reg-vendor.breadcrumb.index' }
      },
      {
        path: 'procurement-reg-buyer',
        loadChildren: () =>
          import('./procurement-reg-buyer/procurement-reg-buyer.module').then(
            m => m.ProcurementRegBuyerModule
          ),
        data: { breadcrumb: 'procurement-reg-buyer.breadcrumb.index' }
      },
      {
        path: 'procurement-type',
        loadChildren: () =>
          import('./procurement-type/procurement-type.module').then(
            m => m.ProcurementTypeModule
          ),
        data: { breadcrumb: 'procurement-type.breadcrumb.index' }
      },
      {
        path: 'procurement-schedule',
        loadChildren: () =>
          import('./procurement-schedule/procurement-schedule.module').then(
            m => m.ProcurementScheduleModule
          ),
        data: { breadcrumb: 'procurement-schedule.breadcrumb.index' }
      },
      {
        path: 'procurement-scope-work',
        loadChildren: () =>
          import('./procurement-scope-work/procurement-scope-work.module').then(
            m => m.ProcurementScopeWorkModule
          ),
        data: { breadcrumb: 'procurement-scope-work.breadcrumb.index' }
      },
      {
        path: 'project',
        loadChildren: () =>
          import('./project/project.module').then(m => m.ProjectModule),
        data: { breadcrumb: 'project.breadcrumb.index' }
      },
      {
        path: 'project-team-submission',
        loadChildren: () =>
          import(
            './project-team-submission/project-team-submission.module'
          ).then(m => m.ProjectTeamSubmissionModule),
        data: { breadcrumb: 'team-project.breadcrumb.index' }
      },
      {
        path: 'profession',
        loadChildren: () =>
          import('./profession/profession.module').then(
            m => m.ProfessionModule
          ),
        data: { breadcrumb: 'profession.breadcrumb.index' }
      },
      {
        path: 'position',
        loadChildren: () =>
          import('./position/position.module').then(m => m.PositionModule),
        data: { breadcrumb: 'position.breadcrumb.index' }
      },
      {
        path: 'principle-permit-submission',
        loadChildren: () =>
          import(
            './principle-permit-submission/principle-permit-submission.module'
          ).then(m => m.PrinciplePermitSubmissionModule),
        data: { breadcrumb: 'principle-permit-submission.breadcrumb.index' }
      },
      {
        path: 'proposal-criteria',
        loadChildren: () =>
          import('./proposal-criteria/proposal-criteria.module').then(
            m => m.ProposalCriteriaModule
          ),
        data: { breadcrumb: 'proposal-criteria.breadcrumb.index' }
      },
      {
        path: 'pr-verification',
        loadChildren: () =>
          import('./pr-verification/pr-verification.module').then(
            m => m.PrVerificationModule
          ),
        data: { breadcrumb: 'pr-verification.breadcrumb.index' }
      },
      {
        path: 'pr-po-verification',
        loadChildren: () =>
          import('./pr-po-verification/pr-po-verification.module').then(
            m => m.PrPoVerificationModule
          ),
        data: { breadcrumb: 'pr-po-verification.breadcrumb.index' }
      },
      {
        path: 'hospital-stock',
        loadChildren: () =>
          import(
            './hospital-stock-monitoring/hospital-stock-monitoring.module'
          ).then(m => m.HospitalStockMonitoringModule),
        data: { breadcrumb: 'hospital-stock.breadcrumb.index' }
      },
      {
        path: 'qualification',
        loadChildren: () =>
          import('./qualification/qualification.module').then(
            m => m.QualificationModule
          ),
        data: { breadcrumb: 'qualification.breadcrumb.index' }
      },
      {
        path: 'question',
        loadChildren: () =>
          import('./question/question.module').then(m => m.QuestionModule),
        data: { breadcrumb: 'question.breadcrumb.index' }
      },
      {
        path: 'rabbit-mq',
        loadChildren: () =>
          import('./example/rabbit-mq/rabbit-mq.module').then(
            m => m.RabbitMQModule
          ),
        data: { breadcrumb: 'rabbit-mq.breadcrumb.index' }
      },
      {
        path: 'refutation',
        loadChildren: () =>
          import('./refutation/refutation.module').then(
            m => m.RefutationModule
          ),
        data: { breadcrumb: 'refutation.breadcrumb.index' }
      },
      {
        path: 'refutation-vendor',
        loadChildren: () =>
          import('./refutation-vendor/refutation-vendor.module').then(
            m => m.RefutationVendorModule
          ),
        data: { breadcrumb: 'refutation-vendor.breadcrumb.index' }
      },
      {
        path: 'region',
        loadChildren: () =>
          import('./region/region.module').then(m => m.RegionModule),
        data: { breadcrumb: 'region.breadcrumb.index' }
      },
      {
        path: 'reminder',
        loadChildren: () =>
          import('./reminder/reminder.module').then(m => m.ReminderModule),
        data: { breadcrumb: 'reminder.breadcrumb.index' }
      },
      {
        path: 'request-for-information',
        loadChildren: () => import('./rfi/rfi.module').then(m => m.RfiModule),
        data: { breadcrumb: 'rfi.breadcrumb.index' }
      },
      {
        path: 'resource',
        loadChildren: () =>
          import('./resource/resource.module').then(m => m.ResourceModule),
        data: { breadcrumb: 'resource.breadcrumb.index' }
      },
      {
        path: 'resource-ownership',
        loadChildren: () =>
          import('./resource-ownership/resource-ownership.module').then(
            m => m.ResourceOwnershipModule
          ),
        data: { breadcrumb: 'resource-ownership.breadcrumb.index' }
      },
      {
        path: 'response-rfi',
        loadChildren: () =>
          import('./response-rfi/response-rfi.module').then(
            m => m.ResponseRfiModule
          ),
        data: { breadcrumb: 'response-rfi.breadcrumb.index' }
      },
      {
        path: 'report-recap',
        loadChildren: () =>
          import('./report-recap/report-recap.module').then(
            m => m.ReportRecapModule
          ),
        data: { breadcrumb: 'report-recap.breadcrumb.index' }
      },
      {
        path: 'report-recap-pr',
        loadChildren: () =>
          import('../core/report-recap/pr/report-recap-pr.module').then(
            m => m.ReportRecapPrModule
          ),
        data: { breadcrumb: 'report-recap-pr.breadcrumb.index' }
      },
      {
        path: 'report-recap-order-process',
        loadChildren: () =>
          import(
            '../core/report-recap/order-process/report-recap-order-process.module'
          ).then(m => m.ReportRecapOrderProcessModule),
        data: { breadcrumb: 'report-recap-order-process.breadcrumb.index' }
      },
      {
        path: 'report-recap-procurement',
        loadChildren: () =>
          import(
            '../core/report-recap/procurement/report-recap-procurement.module'
          ).then(m => m.ReportRecapProcurementModule),
        data: { breadcrumb: 'report-recap-procurement.breadcrumb.index' }
      },
      {
        path: 'report-recap-rfp',
        loadChildren: () =>
          import('../core/report-recap/rfp/report-recap-rfp.module').then(
            m => m.ReportRecapRfpModule
          ),
        data: { breadcrumb: 'report-recap-rfp.breadcrumb.index' }
      },
      {
        path: 'report-recap-rfq',
        loadChildren: () =>
          import('../core/report-recap/rfq/report-recap-rfq.module').then(
            m => m.ReportRecapRfqModule
          ),
        data: { breadcrumb: 'report-recap-rfq.breadcrumb.index' }
      },
      {
        path: 'report-recap-budget-used',
        loadChildren: () =>
          import(
            '../core/report-recap/budget-used/report-recap-budget-used.module'
          ).then(m => m.ReportRecapBudgetUsedModule),
        data: { breadcrumb: 'report-recap-budget-used.breadcrumb.index' }
      },
      {
        path: 'reporting-sla-work-plan',
        loadChildren: () =>
          import(
            './reporting-sla-work-plan/reporting-sla-work-plan.module'
          ).then(m => m.ReportingSlaWorkPlanModule),
        data: { breadcrumb: 'reporting-sla-work-plan.breadcrumb.index' }
      },
      {
        path: 'review-contract',
        loadChildren: () =>
          import('./review-contract/review-contract.module').then(
            m => m.ReviewContractModule
          ),
        data: { breadcrumb: 'review-contract.breadcrumb.index' }
      },
      {
        path: 'rfp',
        loadChildren: () => import('./rfp/rfp.module').then(m => m.RfpModule),
        data: { breadcrumb: 'rfp.breadcrumb.index' }
      },
      {
        path: 'rfp-response',
        loadChildren: () =>
          import('./rfp-response/rfp-response.module').then(
            m => m.RfpResponseModule
          ),
        data: { breadcrumb: 'rfp-response.breadcrumb.index' }
      },
      {
        path: 'rfq',
        loadChildren: () => import('./rfq/rfq.module').then(m => m.RfqModule),
        data: { breadcrumb: 'rfq.breadcrumb.index' }
      },
      {
        path: 'rfq-response',
        loadChildren: () =>
          import('./rfq-response/rfq-response.module').then(
            m => m.RfqResponseModule
          ),
        data: { breadcrumb: 'rfq-response.breadcrumb.index' }
      },
      {
        path: 'role',
        loadChildren: () =>
          import('./role/role.module').then(m => m.RoleModule),
        data: { breadcrumb: 'role.breadcrumb.index' }
      },
      {
        path: 'role-menu',
        loadChildren: () =>
          import('./role-menu/role-menu.module').then(m => m.RoleMenuModule),
        data: { breadcrumb: 'role-menu.breadcrumb.index' }
      },
      {
        path: 'schedule-stages',
        loadChildren: () =>
          import('./schedule-stages/schedule-stages.module').then(
            m => m.ScheduleStagesModule
          ),
        data: { breadcrumb: 'schedule-stages.breadcrumb.index' }
      },
      {
        path: 'scheduler',
        loadChildren: () =>
          import('./scheduler/scheduler.module').then(m => m.SchedulerModule),
        data: { breadcrumb: 'scheduler.breadcrumb.index' }
      },
      {
        path: 'scheduler-log',
        loadChildren: () =>
          import('./scheduler-log/scheduler-log.module').then(
            m => m.SchedulerLogModule
          ),
        data: { breadcrumb: 'scheduler-log.breadcrumb.index' }
      },
      {
        path: 'scoring-criteria',
        loadChildren: () =>
          import('./scoring-criteria/scoring-criteria.module').then(
            m => m.ScoringCriteriaModule
          ),
        data: { breadcrumb: 'scoring-criteria.breadcrumb.index' }
      },
      {
        path: 'scoring-criteria-group',
        loadChildren: () =>
          import('./scoring-criteria-group/scoring-criteria-group.module').then(
            m => m.ScoringCriteriaGroupModule
          ),
        data: { breadcrumb: 'scoring-criteria-group.breadcrumb.index' }
      },
      {
        path: 'scoring-template',
        loadChildren: () =>
          import('./scoring-template/scoring-template.module').then(
            m => m.ScoringTemplateModule
          ),
        data: { breadcrumb: 'scoring-template.breadcrumb.index' }
      },
      {
        path: 'segmentation',
        loadChildren: () =>
          import('./segmentation/segmentation.module').then(
            m => m.SegmentationModule
          ),
        data: { breadcrumb: 'segmentation.breadcrumb.index' }
      },
      {
        path: 'sla',
        loadChildren: () => import('./sla/sla.module').then(m => m.SlaModule),
        data: { breadcrumb: 'sla.breadcrumb.index' }
      },
      {
        path: 'sla-contract',
        loadChildren: () =>
          import('./sla-contract/sla-contract.module').then(
            m => m.SlaContractModule
          ),
        data: { breadcrumb: 'sla-contract.breadcrumb.index' }
      },
      {
        path: 'sla-vendor',
        loadChildren: () =>
          import('./sla-vendor/sla-vendor.module').then(m => m.SlaVendorModule),
        data: { breadcrumb: 'sla-vendor.breadcrumb.index' }
      },
      {
        path: 'sla-pm',
        loadChildren: () =>
          import('./sla-pm/sla-pm.module').then(m => m.SlaPmModule),
        data: { breadcrumb: 'sla-pm.breadcrumb.index' }
      },
      {
        path: 'sla-core',
        loadChildren: () =>
          import('./sla-core/sla-core.module').then(m => m.SlaCoreModule),
        data: { breadcrumb: 'sla-core.breadcrumb.index' }
      },
      {
        path: 'sla-spending',
        loadChildren: () =>
          import('./sla-spending/sla-spending.module').then(
            m => m.SlaSpendingModule
          ),
        data: { breadcrumb: 'sla-spending.breadcrumb.index' }
      },
      {
        path: 'stage-of-work',
        loadChildren: () =>
          import('./stage-of-work/stage-of-work.module').then(
            m => m.StageOfWorkModule
          ),
        data: { breadcrumb: 'stage-of-work.breadcrumb.index' }
      },
      {
        path: 'submit-quotation',
        loadChildren: () =>
          import('./submit-quotation/submit-quotation.module').then(
            m => m.SubmitQuotationModule
          ),
        data: { breadcrumb: 'submit-quotation.breadcrumb.index' }
      },
      {
        path: 'submit-quotation-2',
        loadChildren: () =>
          import('./submit-quotation/submit-quotation.module').then(
            m => m.SubmitQuotationModule
          ),
        data: { breadcrumb: 'submit-quotation.breadcrumb.index' }
      },
      {
        path: 'submit-quotation-vendor',
        loadChildren: () =>
          import(
            './submit-quotation-vendor/submit-quotation-vendor.module'
          ).then(m => m.SubmitQuotationVendorModule),
        data: { breadcrumb: 'submit-quotation-vendor.breadcrumb.index' }
      },
      {
        path: 'tax',
        loadChildren: () => import('./tax/tax.module').then(m => m.TaxModule),
        data: { breadcrumb: 'tax.breadcrumb.index' }
      },
      {
        path: 'tc',
        loadChildren: () => import('./tc/tc.module').then(m => m.TcModule),
        data: { breadcrumb: 'tc.breadcrumb.index' }
      },
      {
        path: 'technical-criteria',
        loadChildren: () =>
          import('./technical-criteria/technical-criteria.module').then(
            m => m.TechnicalCriteriaModule
          ),
        data: { breadcrumb: 'technical-criteria.breadcrumb.index' }
      },
      {
        path: 'technical-evaluation',
        loadChildren: () =>
          import('./technical-evaluation/technical-evaluation.module').then(
            m => m.TechnicalEvaluationModule
          ),
        data: { breadcrumb: 'technical-evaluation.breadcrumb.index' }
      },
      {
        path: 'template-group',
        loadChildren: () =>
          import('./template-group/template-group.module').then(
            m => m.TemplateGroupModule
          ),
        data: { breadcrumb: 'template-group.breadcrumb.index' }
      },
      {
        path: 'translation',
        loadChildren: () =>
          import('./translation/translation.module').then(
            m => m.TranslationModule
          ),
        data: { breadcrumb: 'translation.breadcrumb.index' }
      },
      {
        path: 'threshold',
        loadChildren: () =>
          import('./threshold/threshold.module').then(m => m.ThresholdModule),
        data: { breadcrumb: 'threshold.breadcrumb.index' }
      },
      {
        path: 'tor-submission',
        loadChildren: () =>
          import('./tor-submission/tor-submission.module').then(
            m => m.TorSubmissionModule
          ),
        data: { breadcrumb: 'tor-submission.breadcrumb.index' }
      },
      {
        path: 'upload-document',
        loadChildren: () =>
          import('./upload-document/upload-document.module').then(
            m => m.UploadDocumentModule
          ),
        data: { breadcrumb: 'upload-document.breadcrumb.index' }
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./user/user.module').then(m => m.UserModule),
        data: { breadcrumb: 'user.breadcrumb.index' }
      },
      {
        path: 'user-action',
        loadChildren: () =>
          import('./user-action/user-action.module').then(
            m => m.UserActionModule
          ),
        data: { breadcrumb: 'user-action.breadcrumb.index' }
      },
      {
        path: 'user-delegation',
        loadChildren: () =>
          import('./user-delegation/user-delegation.module').then(
            m => m.UserDelegationModule
          ),
        data: { breadcrumb: 'delegation.breadcrumb.index' }
      },
      {
        path: 'user-faq',
        loadChildren: () =>
          import('./user-faq/user-faq.module').then(
            m => m.UserFaqModule
          ),
        data: { breadcrumb: 'user-faq.breadcrumb.index' }
      },
      {
        path: 'user-monitoring',
        loadChildren: () =>
          import('./user-monitoring/user-monitoring.module').then(
            m => m.UserMonitoringModule
          ),
        data: { breadcrumb: 'user-monitoring.breadcrumb.index' }
      },
      {
        path: 'user-notification',
        loadChildren: () =>
          import('./user-notification/user-notification.module').then(
            m => m.UserNotificationModule
          ),
        data: { breadcrumb: 'user-notification.breadcrumb.index' }
      },
      {
        path: 'user-manual',
        loadChildren: () =>
          import('./user-manual/user-manual.module').then(
            m => m.UserManualModule
          ),
        data: { breadcrumb: 'user-manual.breadcrumb.index' }
      },
      {
        path: 'uom',
        loadChildren: () => import('./uom/uom.module').then(m => m.UomModule),
        data: { breadcrumb: 'uom.breadcrumb.index' }
      },
      {
        path: 'vendor-attribute',
        loadChildren: () =>
          import('./vendor-attribute/vendor-attribute.module').then(
            m => m.VendorAttributeModule
          ),
        data: { breadcrumb: 'vendor-attribute.breadcrumb.index' }
      },
      {
        path: 'vendor-blacklist',
        loadChildren: () =>
          import('./vendor-blacklist/vendor-blacklist.module').then(
            m => m.VendorBlacklistModule
          ),
        data: { breadcrumb: 'vendor-blacklist.breadcrumb.index' }
      },
      {
        path: 'vendor-category',
        loadChildren: () =>
          import('./vendor-category/vendor-category.module').then(
            m => m.VendorCategoryModule
          ),
        data: { breadcrumb: 'vendor-category.breadcrumb.index' }
      },
      {
        path: 'vendor-certificate',
        loadChildren: () =>
          import('./vendor-certificate/vendor-certificate.module').then(
            m => m.VendorCertificateModule
          ),
        data: { breadcrumb: 'vendor-certificate.breadcrumb.index' }
      },
      {
        path: 'vendor-content-management',
        loadChildren: () =>
          import(
            './vendor-content-management/vendor-content-management.module'
          ).then(m => m.VendorContentManagementModule),
        data: { breadcrumb: 'vendor-content-management.breadcrumb.index' }
      },
      {
        path: 'vendor-designation',
        loadChildren: () =>
          import('./vendor-designation/vendor-designation.module').then(
            m => m.VendorDesignationModule
          ),
        data: { breadcrumb: 'vendor-designation.breadcrumb.index' }
      },
      {
        path: 'vendor-doc-master',
        loadChildren: () =>
          import('./vendor-document/vendor-document.module').then(
            m => m.VendorDocumentModule
          ),
        data: { breadcrumb: 'vendor-document.breadcrumb.index' }
      },
      {
        path: 'vendor-entity',
        loadChildren: () =>
          import('./vendor-entity/vendor-entity.module').then(
            m => m.VendorEntityModule
          ),
        data: { breadcrumb: 'vendor-entity.breadcrumb.index' }
      },
      {
        path: 'vendor-expired-document',
        loadChildren: () =>
          import(
            './vendor-expired-document/vendor-expired-document.module'
          ).then(m => m.VendorExpiredDocumentModule),
        data: { breadcrumb: 'vendor-expired-document.breadcrumb.index' }
      },
      {
        path: 'vendor-history',
        loadChildren: () =>
          import('./vendor-history/vendor-history.module').then(
            m => m.VendorHistoryModule
          ),
        data: { breadcrumb: 'vendor-history.breadcrumb.index' }
      },
      {
        path: 'vendor-invitation',
        loadChildren: () =>
          import('./vendor-invitation/vendor-invitation.module').then(
            m => m.VendorInvitationModule
          ),
        data: { breadcrumb: 'vendor-invitation.breadcrumb.index' }
      },
      {
        path: 'vendor-list',
        loadChildren: () =>
          import('./vendor-list/vendor-list.module').then(
            m => m.VendorListModule
          ),
        data: { breadcrumb: 'vendor-list.breadcrumb.index' }
      },
      {
        path: 'vendor-location',
        loadChildren: () =>
          import('./vendor-location/vendor-location.module').then(
            m => m.VendorLocationModule
          ),
        data: { breadcrumb: 'vendor-location.breadcrumb.index' }
      },
      {
        path: 'vendor-ownership',
        loadChildren: () =>
          import('./vendor-ownership/vendor-ownership.module').then(
            m => m.VendorOwnershipModule
          ),
        data: { breadcrumb: 'vendor-ownership.breadcrumb.index' }
      },
      {
        path: 'vendor-payment-request',
        loadChildren: () =>
          import('./vendor-payment-request/vendor-payment-request.module').then(
            m => m.VendorPaymentRequestModule
          ),
        data: { breadcrumb: 'vendor-payment-request.breadcrumb.index' }
      },
      {
        path: 'vendor-position',
        loadChildren: () =>
          import('./vendor-position/vendor-position.module').then(
            m => m.VendorPositionModule
          ),
        data: { breadcrumb: 'vendor-position.breadcrumb.index' }
      },
      {
        path: 'vendor-qualification',
        loadChildren: () =>
          import('./vendor-qualification/vendor-qualification.module').then(
            m => m.VendorQualificationModule
          ),
        data: { breadcrumb: 'vendor-qualification.breadcrumb.index' }
      },
      {
        path: 'vendor-reactivation',
        loadChildren: () =>
          import('./vendor-reactivation/vendor-reactivation.module').then(
            m => m.VendorReactivationModule
          ),
        data: { breadcrumb: 'vendor-reactivation.breadcrumb.index' }
      },
      {
        path: 'vendor-reference',
        loadChildren: () =>
          import('./vendor-reference/vendor-reference.module').then(
            m => m.VendorReferenceModule
          ),
        data: { breadcrumb: 'vendor-reference.breadcrumb.index' }
      },
      {
        path: 'vendor-type',
        loadChildren: () =>
          import('./vendor-type/vendor-type.module').then(
            m => m.VendorTypeModule
          ),
        data: { breadcrumb: 'vendor-type.breadcrumb.index' }
      },
      {
        path: 'vendor-whitelist',
        loadChildren: () =>
          import('./vendor-whitelist/vendor-whitelist.module').then(
            m => m.VendorWhitelistModule
          ),
        data: { breadcrumb: 'vendor-whitelist.breadcrumb.index' }
      },
      {
        path: 'victim-diagnose',
        loadChildren: () =>
          import('./victim-diagnose/victim-diagnose.module').then(
            m => m.VictimDiagnoseModule
          ),
        data: { breadcrumb: 'victim-diagnose.breadcrumb.index' }
      },
      {
        path: 'view-catalog',
        loadChildren: () =>
          import('./catalog/view-catalog/view-catalog.module').then(
            m => m.ViewCatalogModule
          ),
        data: { breadcrumb: 'view-catalog.breadcrumb.index' }
      },
      {
        path: 'view-vendor',
        loadChildren: () =>
          import('./catalog/view-vendor/view-vendor.module').then(
            m => m.ViewVendorModule
          ),
        data: { breadcrumb: 'view-vendor.breadcrumb.index' }
      },
      {
        path: 'wanprestasi',
        loadChildren: () =>
          import('./wanprestasi/wanprestasi.module').then(
            m => m.WanprestasiModule
          ),
        data: { breadcrumb: 'wanprestasi.breadcrumb.index' }
      },
      // {
      //   path: 'wanprestasi-reason',
      //   loadChildren: () =>
      //     import('./wanprestasi-reason/wanprestasi-reason.module').then(
      //       m => m.WanprestasiReasonModule
      //     ),
      //   data: { breadcrumb: 'wanprestasi-reason.breadcrumb.index' }
      // },
      {
        path: 'workflow',
        loadChildren: () =>
          import('./workflow/workflow.module').then(m => m.WorkflowModule),
        data: { breadcrumb: 'workflow.breadcrumb.index' }
      },
      {
        path: 'workflow-condition',
        loadChildren: () =>
          import('./workflow-condition/workflow-condition.module').then(
            m => m.WorkflowConditionModule
          ),
        data: { breadcrumb: 'workflow-condition.breadcrumb.index' }
      },
      {
        path: 'worklist-pra-procurement',
        loadChildren: () =>
          import(
            './worklist-pra-procurement/worklist-pra-procurement.module'
          ).then(m => m.WorklistPraProcurementModule),
        data: { breadcrumb: 'worklist-pra-procurement.breadcrumb.index' }
      },
      {
        path: 'worklist-pm',
        loadChildren: () =>
          import('./worklist-pm/worklist-pm.module').then(
            m => m.WorklistPMModule
          ),
        data: { breadcrumb: 'worklist-pm.breadcrumb.index' }
      },
      {
        path: 'workload',
        loadChildren: () =>
          import('./workload/workload.module').then(m => m.WorkloadModule),
        data: { breadcrumb: 'workload.breadcrumb.index' }
      },
      {
        path: 'workload-monitoring',
        loadChildren: () =>
          import('./workload-monitoring/workload-monitoring.module').then(
            m => m.WorkloadMonitoringModule
          ),
        data: { breadcrumb: 'workload-monitoring.breadcrumb.index' }
      },
      {
        path: 'work-plan',
        loadChildren: () =>
          import('./work-plan/work-plan.module').then(m => m.WorkPlanModule),
        data: { breadcrumb: 'work-plan.breadcrumb.index' }
      },
      {
        path: 'work-plan-realize',
        loadChildren: () =>
          import('./work-plan-realize/work-plan-realize.module').then(
            m => m.WorkPlanRealizeModule
          ),
        data: { breadcrumb: 'work-plan-realize.breadcrumb.index' }
      },
      {
        path: 'work-plan',
        loadChildren: () =>
          import('./work-plan/work-plan.module').then(m => m.WorkPlanModule),
        data: { breadcrumb: 'work-plan.breadcrumb.index' }
      },
      {
        path: 'work-program',
        loadChildren: () =>
          import('./work-program/work-program.module').then(
            m => m.WorkProgramModule
          ),
        data: { breadcrumb: 'work-program.breadcrumb.index' }
      },
      {
        path: 'official-report',
        loadChildren: () =>
          import('./official-report/official-report.module').then(
            m => m.OfficialReportModule
          ),
        data: { breadcrumb: 'official-report.breadcrumb.index' }
      },
      {
        path: 'push-notification',
        loadChildren: () =>
          import('./push-notification/push-notification.module').then(
            m => m.PushNotificationModule
          ),
        data: { breadcrumb: 'push-notification.breadcrumb.index' }
      },
      {
        path: 'lost-connection',
        loadChildren: () =>
          import(
            './errors/lost-connection-page/lost-connection-page.module'
          ).then(m => m.LostConnectionPageModule),
        data: { breadcrumb: 'pages.lostConnection' }
      },
      {
        path: 'distribution-region',
        loadChildren: () =>
          import('./distribution-region/distribution-region.module').then(
            m => m.DistributionRegionModule
          ),
        data: { breadcrumb: 'distribution-region.breadcrumb.index' }
      },
      {
        path: 'claim',
        loadChildren: () =>
          import('./claim/claim.module').then(m => m.ClaimModule),
        data: { breadcrumb: 'claim.breadcrumb.index' }
      },
      {
        path: 'counter',
        loadChildren: () =>
          import('./counter/counter.module').then(m => m.CounterModule),
        data: { breadcrumb: 'counter.breadcrumb.index' }
      },
      {
        path: 'verificator',
        loadChildren: () =>
          import('./verificator/verificator.module').then(
            m => m.VerificatorModule
          ),
        data: { breadcrumb: 'verificator.breadcrumb.index' }
      },
      {
        path: 'claim-verification',
        loadChildren: () =>
          import('./claim-verification/claim-verification.module').then(
            m => m.ClaimVerificationModule
          ),
        data: { breadcrumb: 'claim-verification.breadcrumb.index' }
      },
      {
        path: 'accident-victim',
        loadChildren: () =>
          import('./accident-victim/accident-victim.module').then(
            m => m.AccidentVictimModule
          ),
        data: { breadcrumb: 'accident-victim.breadcrumb.index' }
      },
      {
        path: 'patient-condition',
        loadChildren: () =>
          import('./patient-condition/patient-condition.module').then(
            m => m.PatientConditionModule
          ),
        data: { breadcrumb: 'patient-condition.breadcrumb.index' }
      },
      {
        path: 'error',
        // component: ErrorPageComponent,
        loadChildren: () =>
          import('./errors/error-page/error-page.module').then(
            m => m.ErrorPageModule
          ),
        data: { breadcrumb: 'pages.error' }
      },
      { path: '**', redirectTo: 'error' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
