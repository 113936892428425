<app-layout [isLoading]="formLoading" [backTo]="urlBackOutside">
  <ng-template #additionalTitle *ngIf="!formLoading"> </ng-template>
  <div class="row main-row" *ngIf="!formLoading">
    <div class="col-lg-12 mb-4">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-card
          header="accident-victim.form.detail.signature"
          tips="accident-victim.form.detail.signature"
        >
          <app-fullfilled *ngIf="!formGroup.isView"></app-fullfilled>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'accident-victim.form.hospitalName' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.hospitalName }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'accident-victim.form.addresHospital' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.addressHospital }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'accident-victim.form.doctorsName' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                autofocus="true"
                formControlName="doctorsName"
                size="MD"
                maxLength="255"
              >
              </app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'accident-victim.form.accidentVictimName' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.accidentVictimName }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'accident-victim.form.age' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              <app-text-field
                type="integer"
                formControlName="age"
                maxLength="3"
              ></app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'accident-victim.form.gender' | translate
            }}</label>
            <div class="col-sm-5 text-left">
              <app-dropdown-select
                size="LG"
                [optionList]="genderList"
                formControlName="gender"
                type="COMBOBOX"
              >
                <ng-template #value let-value>
                  <div *ngIf="value">{{
                    getTranslateKey(value) | translate
                  }}</div>
                </ng-template>
                <ng-template #option let-data>
                  {{ getTranslateKey(data) | translate }}
                </ng-template>
              </app-dropdown-select>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'accident-victim.form.addressVictim' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-area formControlName="addresVictim" maxLength="512">
              </app-text-area>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'accident-victim.form.patientCondition' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              <app-dropdown-select
                size="LG"
                [optionList]="patientConditionList"
                formControlName="patientCondition"
                type="COMBOBOX"
              >
              </app-dropdown-select>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'accident-victim.form.description' | translate
            }}</label>

            <div class="col-sm-9 text-left">
              {{ formGroup.value.description }}
            </div>
          </div>
        </app-card>

        <app-card
          header="accident-victim.form.detail.victimCondition"
          tips="accident-victim.form.detail.victimCondition"
        >
          <app-alert-x
            *ngIf="
              !formGroup.isView &&
              !isVictimLocationExist &&
              isVictimLocationExist != null
            "
            color="DANGER"
          >
            {{ 'accident-victim.form.fillAtLeastOneData' | translate }}
          </app-alert-x>
          <div class="form-group row text-sm-left">
            <label class="col-sm-12"
              ><strong>{{
                'accident-victim.form.victimConditionLabel' | translate
              }}</strong></label
            >
          </div>
          <div class="table-responsive custom-table">
            <table class="table table-striped" aria-describedby="Table">
              <thead>
                <tr>
                  <th class="text-left" scope="col" style="width: 5%">{{
                    'app.table.column.no' | translate
                  }}</th>
                  <th class="text-center" scope="col" style="width: 8%">{{
                    'accident-victim.form.location' | translate
                  }}</th>
                  <th class="text-center" scope="col" style="width: 17%">{{
                    'accident-victim.form.victimCondition' | translate
                  }}</th>
                  <th class="text-left" scope="col" style="width: 8%">{{
                    'accident-victim.form.informantName' | translate
                  }}</th>
                  <th class="text-left" scope="col" style="width: 25%">{{
                    'accident-victim.form.victimInformantCategory' | translate
                  }}</th>
                  <th class="text-left" scope="col" style="width: 8%">{{
                    'accident-victim.form.informantContact' | translate
                  }}</th>
                  <th class="text-left" scope="col" style="width: 29%">{{
                    'accident-victim.form.picture' | translate
                  }}</th>
                </tr>
              </thead>
              <tbody formArrayName="accidentVictimConditionList">
                <tr
                  *ngFor="
                    let accidentVictimCondition of formArrayAccidentVictimConditionList.controls;
                    let i = index
                  "
                  [formGroup]="accidentVictimCondition"
                >
                  <td> {{ i + 1 }}</td>
                  <td>
                    {{ accidentVictimCondition.value.victimLocationName }}
                  </td>
                  <td>
                    <div *ngIf="accidentVictimCondition.value.isDisable">
                      {{ accidentVictimCondition.value.victimCondition?.name }}
                    </div>
                    <app-dropdown-select
                      size="XL"
                      [optionList]="victimConditionList"
                      formControlName="victimCondition"
                      type="COMBOBOX"
                      *ngIf="!accidentVictimCondition.value.isDisable"
                    >
                      <ng-template #value let-value>
                        <div *ngIf="value">{{
                          getTranslateKey(value) | translate
                        }}</div>
                      </ng-template>
                      <ng-template #option let-data>
                        {{ getTranslateKey(data) | translate }}
                      </ng-template>
                    </app-dropdown-select>
                  </td>
                  <td>
                    <div *ngIf="accidentVictimCondition.value.isDisable">
                      {{ accidentVictimCondition.value.informantName }}
                    </div>
                    <app-text-field
                      size="XL"
                      formControlName="informantName"
                      maxLength="255"
                      *ngIf="!accidentVictimCondition.value.isDisable"
                    >
                    </app-text-field>
                  </td>
                  <td>
                    <div *ngIf="accidentVictimCondition.value.isDisable">
                      {{
                        accidentVictimCondition.value.victimInformantCategory
                          ?.name
                      }}
                    </div>
                    <app-dropdown-select
                      size="XL"
                      [optionList]="victimInformantCategoryList"
                      formControlName="victimInformantCategory"
                      type="COMBOBOX"
                      *ngIf="!accidentVictimCondition.value.isDisable"
                    >
                      <ng-template #value let-value>
                        <div *ngIf="value">{{
                          getTranslateKey(value) | translate
                        }}</div>
                      </ng-template>
                      <ng-template #option let-data>
                        {{ getTranslateKey(data) | translate }}
                      </ng-template>
                    </app-dropdown-select>
                  </td>
                  <td>
                    <div *ngIf="accidentVictimCondition.value.isDisable">
                      {{ accidentVictimCondition.value.informantContact }}
                    </div>
                    <app-text-field
                      size="XL"
                      formControlName="informantContact"
                      maxLength="255"
                      *ngIf="!accidentVictimCondition.value.isDisable"
                    >
                    </app-text-field>
                  </td>
                  <td>
                    <app-upload
                      size="XL"
                      formControlName="file"
                      [fileUploader]="
                        accidentVictimCondition.value.fileUploader
                      "
                    ></app-upload>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <br />
          <div class="form-group row text-sm-left">
            <label class="col-sm-12"
              ><strong>{{
                'accident-victim.form.victimDiagnoseLabel' | translate
              }}</strong></label
            >
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'accident-victim.form.victimDiagnose' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              <app-dropdown-select
                size="LG"
                [optionList]="victimDiagnoseList"
                formControlName="victimDiagnose"
                type="AUTOCOMPLETE"
                (onChange)="diagnoseOther($event)"
              >
              </app-dropdown-select>
            </div>
          </div>

          <div class="form-group row text-sm-left" *ngIf="isDiagnoseOther">
            <label class="col-sm-3"></label>
            <div class="col-sm-9 text-left">
              <app-text-field formControlName="diagnoseOther" maxLength="255">
              </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left" *ngIf="!isDiagnoseOther">
            <label class="col-sm-3">{{
              'accident-victim.form.victimInjuries' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ victimInjuriesCategoryPrimer?.name || '-' }}
            </div>
          </div>
          <div class="form-group row text-sm-left" *ngIf="isDiagnoseOther">
            <label class="col-sm-3">{{
              'accident-victim.form.victimInjuries' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              <app-dropdown-select
                size="LG"
                [optionList]="victimInjuriesList"
                formControlName="victimInjuries"
                type="COMBOBOX"
                (onChange)="victimInjuriesPrimer($event)"
              >
                <ng-template #value let-value>
                  <div *ngIf="value">{{
                    getTranslateKey(value) | translate
                  }}</div>
                </ng-template>
                <ng-template #option let-data>
                  {{ getTranslateKey(data) | translate }}
                </ng-template>
              </app-dropdown-select>
            </div>
          </div>

          <br />
          <div class="form-group row text-sm-left">
            <label class="col-sm-3"
              ><strong>{{
                'accident-victim.form.victimDiagnoseSekunderLabel' | translate
              }}</strong></label
            >
          </div>
          <div class="text-right">
            <ng-container>
              <app-button
                (onClick)="doAddDiagnoseSekunder()"
                [disabled]="!isDiagnosePrimerExist"
                *ngIf="todo !== 'view'"
                ><em class="fas fa-plus"></em>
                {{ 'app.button.add' | translate }}</app-button
              >
            </ng-container>
          </div>
          <br />
          <div class="table-responsive custom-table">
            <table class="table table-striped" aria-describedby="Table">
              <thead>
                <tr>
                  <th class="text-left" scope="col">{{
                    'app.table.column.no' | translate
                  }}</th>
                  <th class="text-center" scope="col">{{
                    'accident-victim.form.victimDiagnoseSekunder' | translate
                  }}</th>
                  <th class="text-center" scope="col">{{
                    'accident-victim.form.victimInjuriesCategory' | translate
                  }}</th>
                  <th class="text-center" scope="col">{{
                    'accident-victim.form.victimDiagnoseOther' | translate
                  }}</th>
                  <th class="text-center" scope="col" *ngIf="todo !== 'view'"
                    >{{ 'app.table.column.action' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody
                *ngIf="
                  formArrayAccidentVictimDiagnoseSekunderList.controls.length >
                  0
                "
                formArrayName="accidentVictimDiagnoseSekunderList"
              >
                <tr
                  *ngFor="
                    let accidentVictimDiagnoseSekunder of formArrayAccidentVictimDiagnoseSekunderList.controls;
                    let i = index
                  "
                  [formGroup]="accidentVictimDiagnoseSekunder"
                >
                  <td> {{ i + 1 }}</td>
                  <td class="text-center">
                    <div *ngIf="accidentVictimDiagnoseSekunder.value.isDisable">
                      {{
                        accidentVictimDiagnoseSekunder.value.victimDiagnose
                          ?.name
                      }}
                    </div>
                    <app-dropdown-select
                      size="XL"
                      [optionList]="victimDiagnoseSekunderOtherList"
                      formControlName="victimDiagnose"
                      type="AUTOCOMPLETE"
                      (onChange)="diagnoseSekunder(i)"
                      *ngIf="!accidentVictimDiagnoseSekunder.value.isDisable"
                    >
                    </app-dropdown-select>
                  </td>
                  <td class="text-center">
                    <div *ngIf="accidentVictimDiagnoseSekunder.value.isDisable">
                      {{
                        accidentVictimDiagnoseSekunder.value
                          .victimInjuriesCategory?.name
                      }}
                    </div>
                    <app-dropdown-select
                      size="XL"
                      [optionList]="
                        accidentVictimDiagnoseSekunder.value
                          .victimInjuriesCategoryList
                      "
                      formControlName="victimInjuriesCategory"
                      type="COMBOBOX"
                      *ngIf="!accidentVictimDiagnoseSekunder.value.isDisable"
                    >
                      <ng-template #value let-value>
                        <div *ngIf="value">{{
                          getTranslateKey(value) | translate
                        }}</div>
                      </ng-template>
                      <ng-template #option let-data>
                        {{ getTranslateKey(data) | translate }}
                      </ng-template>
                    </app-dropdown-select>
                  </td>
                  <td class="text-center">
                    <div
                      *ngIf="
                        accidentVictimDiagnoseSekunder.value.isDisable &&
                        !accidentVictimDiagnoseSekunder.value.isUnableOther
                      "
                    >
                      {{ '' }}
                    </div>
                    <div
                      *ngIf="
                        accidentVictimDiagnoseSekunder.value.isDisable &&
                        accidentVictimDiagnoseSekunder.value.isUnableOther
                      "
                    >
                      {{
                        accidentVictimDiagnoseSekunder.value.victimDiagnoseText
                      }}
                    </div>
                    <div
                      *ngIf="
                        !accidentVictimDiagnoseSekunder.value.isDisable &&
                        !accidentVictimDiagnoseSekunder.value.isUnableOther
                      "
                    >
                      {{ '' }}
                    </div>
                    <app-text-field
                      size="XL"
                      formControlName="victimDiagnoseText"
                      maxLength="512"
                      *ngIf="
                        !accidentVictimDiagnoseSekunder.value.isDisable &&
                        accidentVictimDiagnoseSekunder.value.isUnableOther
                      "
                    >
                    </app-text-field>
                  </td>
                  <td class="text-center" *ngIf="todo !== 'view'">
                    <app-button
                      size="TN"
                      (onClick)="doSaveAccidentDiagnoseSekunder(i)"
                      title="{{ 'app.tooltip.save' | translate }}"
                      *ngIf="!accidentVictimDiagnoseSekunder.value.isDisable"
                    >
                      <em class="fas fa-check"></em>
                    </app-button>
                    <app-button
                      size="TN"
                      (onClick)="doEditDiagnoseSekunder(i)"
                      title="{{ 'app.tooltip.edit' | translate }}"
                      *ngIf="accidentVictimDiagnoseSekunder.value.isDisable"
                    >
                      <em class="fas fa-pencil-alt"></em>
                    </app-button>
                    <app-button
                      size="TN"
                      color="DANGER"
                      (onClick)="doDeleteDiagnoseSekunder(i)"
                      title="{{ 'app.tooltip.delete' | translate }}"
                    >
                      <em class="fa fas fa-trash"></em>
                    </app-button>
                  </td>
                </tr>
              </tbody>
              <tbody
                *ngIf="
                  formArrayAccidentVictimDiagnoseSekunderList.controls
                    .length === 0
                "
              >
                <tr class="tr-no-data">
                  <td
                    colspan="9999999"
                    rowspan="7"
                    class="bg-white text-center"
                  >
                    <div class="no-data-wrapper">
                      <div class="book-wrapper pvc pv-file-list"></div>
                      <div class="text-no-data">
                        <span class="text-no-data">
                          {{ 'app-table.noDataAvailable' | translate }}
                        </span>
                        <span class="text-try-to-add">
                          {{ 'app-table.letsTryToAddList' | translate }}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <br />
          <div class="form-group row text-sm-left">
            <label class="col-sm-12"
              ><strong>{{
                'accident-victim.form.victimDiagnoseOtherLabel' | translate
              }}</strong></label
            >
          </div>
          <div class="text-right">
            <ng-container>
              <app-button
                (onClick)="doAddDiagnoseOther()"
                *ngIf="todo !== 'view'"
                [disabled]="!isDiagnosePrimerExist"
                ><em class="fas fa-plus"></em>
                {{ 'app.button.add' | translate }}</app-button
              >
            </ng-container>
          </div>
          <br />
          <br />
          <div class="table-responsive custom-table">
            <table class="table table-striped" aria-describedby="Table">
              <thead>
                <tr>
                  <th class="text-left" scope="col">{{
                    'app.table.column.no' | translate
                  }}</th>
                  <th class="text-center" scope="col">{{
                    'accident-victim.form.victimDiagnoseOther' | translate
                  }}</th>
                  <th class="text-center" scope="col">{{
                    'accident-victim.form.note' | translate
                  }}</th>
                  <th class="text-center" scope="col" *ngIf="todo !== 'view'"
                    >{{ 'app.table.column.action' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody
                *ngIf="
                  formArrayAccidentVictimDiagnoseOtherList.controls.length > 0
                "
                formArrayName="accidentVictimDiagnoseOtherList"
              >
                <tr
                  *ngFor="
                    let accidentVictimDiagnoseOther of formArrayAccidentVictimDiagnoseOtherList.controls;
                    let i = index
                  "
                  [formGroup]="accidentVictimDiagnoseOther"
                >
                  <td> {{ i + 1 }}</td>
                  <td class="text-center">
                    <div *ngIf="accidentVictimDiagnoseOther.value.isDisable">
                      {{ accidentVictimDiagnoseOther.value.victimDiagnoseText }}
                    </div>
                    <div *ngIf="!accidentVictimDiagnoseOther.value.isDisable">
                      {{ '' }}
                    </div>
                    <app-text-field
                      size="XL"
                      formControlName="victimDiagnoseText"
                      maxLength="512"
                      *ngIf="!accidentVictimDiagnoseOther.value.isDisable"
                    >
                    </app-text-field>
                  </td>
                  <td class="text-center">
                    <div *ngIf="accidentVictimDiagnoseOther.value.isDisable">
                      {{ accidentVictimDiagnoseOther.value.description }}
                    </div>
                    <app-text-field
                      size="XL"
                      formControlName="description"
                      maxLength="512"
                      *ngIf="!accidentVictimDiagnoseOther.value.isDisable"
                    >
                    </app-text-field>
                  </td>
                  <td class="text-center" *ngIf="todo !== 'view'">
                    <app-button
                      size="TN"
                      (onClick)="doSaveAccidentDiagnoseOther(i)"
                      title="{{ 'app.tooltip.save' | translate }}"
                      *ngIf="!accidentVictimDiagnoseOther.value.isDisable"
                    >
                      <em class="fas fa-check"></em>
                    </app-button>
                    <app-button
                      size="TN"
                      (onClick)="doEditDiagnoseOther(i)"
                      title="{{ 'app.tooltip.edit' | translate }}"
                      *ngIf="accidentVictimDiagnoseOther.value.isDisable"
                    >
                      <em class="fas fa-pencil-alt"></em>
                    </app-button>
                    <app-button
                      size="TN"
                      color="DANGER"
                      (onClick)="doDeleteDiagnoseOther(i)"
                      title="{{ 'app.tooltip.delete' | translate }}"
                    >
                      <em class="fa fas fa-trash"></em>
                    </app-button>
                  </td>
                </tr>
              </tbody>
              <tbody
                *ngIf="
                  formArrayAccidentVictimDiagnoseOtherList.controls.length === 0
                "
              >
                <tr class="tr-no-data">
                  <td
                    colspan="9999999"
                    rowspan="7"
                    class="bg-white text-center"
                  >
                    <div class="no-data-wrapper">
                      <div class="book-wrapper pvc pv-file-list"></div>
                      <div class="text-no-data">
                        <span class="text-no-data">
                          {{ 'app-table.noDataAvailable' | translate }}
                        </span>
                        <span class="text-try-to-add">
                          {{ 'app-table.letsTryToAddList' | translate }}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-card>

        <app-card
          header="accident-victim.form.detail.victimAction"
          tips="accident-victim.form.detail.victimAction"
        >
          <app-alert-x
            *ngIf="
              !formGroup.isView &&
              !isVictimActionExist &&
              isVictimActionExist != null
            "
            color="DANGER"
          >
            {{ 'accident-victim.form.fillAtLeastOneData' | translate }}
          </app-alert-x>
          <div class="table-responsive custom-table">
            <table class="table table-striped" aria-describedby="Table">
              <thead>
                <tr>
                  <th class="text-left" scope="col">{{
                    'app.table.column.no' | translate
                  }}</th>
                  <th class="text-center" scope="col">{{
                    'accident-victim.form.action' | translate
                  }}</th>
                  <th class="text-center" scope="col">{{
                    'accident-victim.form.entryTime' | translate
                  }}</th>
                  <th class="text-left" scope="col">{{
                    'accident-victim.form.exitTime' | translate
                  }}</th>
                  <th class="text-left" scope="col">{{
                    'accident-victim.form.refer' | translate
                  }}</th>
                </tr>
              </thead>
              <tbody formArrayName="accidentVictimTreatmenntOperationList">
                <tr
                  *ngFor="
                    let accidentVictimTreatmenntOperation of formArrayAccidentVictimTreatmentOptionList.controls;
                    let i = index
                  "
                  [formGroup]="accidentVictimTreatmenntOperation"
                >
                  <td> {{ i + 1 }}</td>
                  <td>
                    {{ accidentVictimTreatmenntOperation.value.action }}
                  </td>
                  <td>
                    <div
                      *ngIf="accidentVictimTreatmenntOperation.value.isDisable"
                    >
                      {{
                        accidentVictimTreatmenntOperation.value.startDate
                          | date : global.config.parameterModel.dateFormatShort
                      }}
                    </div>
                    <app-date-picker
                      size="XL"
                      formControlName="startDate"
                      maxDate="currentDate"
                      (onChange)="
                        setMinMaxDate(
                          accidentVictimTreatmenntOperation.value,
                          'minDate',
                          $event
                        )
                      "
                      *ngIf="!accidentVictimTreatmenntOperation.value.isDisable"
                    ></app-date-picker>
                  </td>
                  <td>
                    <div
                      *ngIf="accidentVictimTreatmenntOperation.value.isDisable"
                    >
                      {{
                        accidentVictimTreatmenntOperation.value.endDate
                          | date : global.config.parameterModel.dateFormatShort
                      }}
                    </div>
                    <app-date-picker
                      size="XL"
                      formControlName="endDate"
                      minDate="startDate"
                      (onChange)="
                        setMinMaxDate(
                          accidentVictimTreatmenntOperation.value,
                          'maxDate',
                          $event
                        )
                      "
                      *ngIf="!accidentVictimTreatmenntOperation.value.isDisable"
                    ></app-date-picker>
                  </td>
                  <td>
                    <div
                      *ngIf="accidentVictimTreatmenntOperation.value.isDisable"
                    >
                      {{ accidentVictimTreatmenntOperation.value.description }}
                    </div>
                    <app-text-field
                      size="XL"
                      formControlName="description"
                      maxLength="512"
                      *ngIf="!accidentVictimTreatmenntOperation.value.isDisable"
                    >
                    </app-text-field>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-card>

        <app-card
          header="accident-victim.form.detail.acctionSugery"
          tips="accident-victim.form.detail.acctionSugery"
        >
          <app-alert-x
            *ngIf="
              !formGroup.isView &&
              !isVictimSurgeryExist &&
              isVictimSurgeryExist != null
            "
            color="DANGER"
          >
            {{ 'accident-victim.form.fillAtLeastOneDataSurgery' | translate }}
          </app-alert-x>
          <div class="text-right">
            <ng-container>
              <app-button (onClick)="doAdd()" *ngIf="todo !== 'view'"
                ><em class="fas fa-plus"></em>
                {{ 'app.button.add' | translate }}</app-button
              >
            </ng-container>
          </div>
          <br />
          <div class="table-responsive custom-table">
            <table class="table table-striped" aria-describedby="Table">
              <thead>
                <tr>
                  <th class="text-left" scope="col">{{
                    'app.table.column.no' | translate
                  }}</th>
                  <th class="text-center" scope="col">{{
                    'accident-victim.form.action' | translate
                  }}</th>
                  <th class="text-center" scope="col">{{
                    'accident-victim.form.dateOperation' | translate
                  }}</th>
                  <th class="text-left" scope="col">{{
                    'accident-victim.form.timeOperation' | translate
                  }}</th>
                  <th class="text-left" scope="col">{{
                    'accident-victim.form.refer' | translate
                  }}</th>
                  <th class="text-left" scope="col">{{
                    'accident-victim.form.purpose' | translate
                  }}</th>
                  <th class="text-left" scope="col">{{
                    'accident-victim.form.result' | translate
                  }}</th>

                  <th class="text-center" scope="col" *ngIf="todo !== 'view'"
                    >{{ 'app.table.column.action' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody
                *ngIf="formArrayAccidentVictimTreatmentList.controls.length > 0"
                formArrayName="accidentVictimTreatmenntList"
              >
                <tr
                  *ngFor="
                    let accidentVictimTreatmennt of formArrayAccidentVictimTreatmentList.controls;
                    let i = index
                  "
                  [formGroup]="accidentVictimTreatmennt"
                >
                  <td> {{ i + 1 }}</td>
                  <td class="text-center">
                    <div *ngIf="accidentVictimTreatmennt.value.isDisable">
                      {{ accidentVictimTreatmennt.value.victimSurgery?.name }}
                    </div>
                    <app-dropdown-select
                      size="XL"
                      [optionList]="victimSurgeryList"
                      formControlName="victimSurgery"
                      type="AUTOCOMPLETE"
                      (onChange)="onChangeVictimSurgery(i)"
                      *ngIf="!accidentVictimTreatmennt.value.isDisable"
                    >
                    </app-dropdown-select>
                  </td>
                  <td class="text-center">
                    <div
                      *ngIf="
                        (accidentVictimTreatmennt.value.isDisable &&
                          !accidentVictimTreatmennt.value.isUnableOther) ||
                        (!accidentVictimTreatmennt.value.isDisable &&
                          !accidentVictimTreatmennt.value.isUnableOther)
                      "
                    >
                      {{ '' }}
                    </div>
                    <div
                      *ngIf="
                        accidentVictimTreatmennt.value.isDisable &&
                        accidentVictimTreatmennt.value.isUnableOther
                      "
                    >
                      {{
                        accidentVictimTreatmennt.value.startDate
                          | date : global.config.parameterModel.dateFormatShort
                      }}
                    </div>
                    <app-date-picker
                      size="XL"
                      formControlName="startDate"
                      [minDate]="minTreatmentDate"
                      [maxDate]="maxTreatmentDate"
                      *ngIf="
                        !accidentVictimTreatmennt.value.isDisable &&
                        accidentVictimTreatmennt.value.isUnableOther
                      "
                    ></app-date-picker>
                  </td>
                  <td>
                    <div
                      *ngIf="
                        (accidentVictimTreatmennt.value.isDisable &&
                          !accidentVictimTreatmennt.value.isUnableOther) ||
                        (!accidentVictimTreatmennt.value.isDisable &&
                          !accidentVictimTreatmennt.value.isUnableOther)
                      "
                    >
                      {{ '' }}
                    </div>
                    <div
                      *ngIf="
                        accidentVictimTreatmennt.value.isDisable &&
                        accidentVictimTreatmennt.value.isUnableOther
                      "
                    >
                      {{
                        accidentVictimTreatmennt.value.endDate | date : 'HH:mm'
                      }}
                    </div>
                    <app-time
                      [isShowArrow]="false"
                      formControlName="endDate"
                      *ngIf="
                        !accidentVictimTreatmennt.value.isDisable &&
                        accidentVictimTreatmennt.value.isUnableOther
                      "
                    ></app-time>
                  </td>
                  <td>
                    <div
                      *ngIf="
                        (accidentVictimTreatmennt.value.isDisable &&
                          !accidentVictimTreatmennt.value.isUnableOther) ||
                        (!accidentVictimTreatmennt.value.isDisable &&
                          !accidentVictimTreatmennt.value.isUnableOther)
                      "
                    >
                      {{ '' }}
                    </div>
                    <div
                      *ngIf="
                        accidentVictimTreatmennt.value.isDisable &&
                        accidentVictimTreatmennt.value.isUnableOther
                      "
                    >
                      {{ accidentVictimTreatmennt.value.description }}
                    </div>
                    <app-text-field
                      size="XL"
                      formControlName="description"
                      maxLength="512"
                      *ngIf="
                        !accidentVictimTreatmennt.value.isDisable &&
                        accidentVictimTreatmennt.value.isUnableOther
                      "
                    >
                    </app-text-field>
                  </td>
                  <td>
                    <div
                      *ngIf="
                        (accidentVictimTreatmennt.value.isDisable &&
                          !accidentVictimTreatmennt.value.isUnableOther) ||
                        (!accidentVictimTreatmennt.value.isDisable &&
                          !accidentVictimTreatmennt.value.isUnableOther)
                      "
                    >
                      {{ '' }}
                    </div>
                    <div
                      *ngIf="
                        accidentVictimTreatmennt.value.isDisable &&
                        accidentVictimTreatmennt.value.isUnableOther
                      "
                    >
                      {{ accidentVictimTreatmennt.value.purpose }}
                    </div>
                    <app-text-field
                      size="XL"
                      formControlName="purpose"
                      maxLength="512"
                      *ngIf="
                        !accidentVictimTreatmennt.value.isDisable &&
                        accidentVictimTreatmennt.value.isUnableOther
                      "
                    >
                    </app-text-field>
                  </td>
                  <td>
                    <div
                      *ngIf="
                        (accidentVictimTreatmennt.value.isDisable &&
                          !accidentVictimTreatmennt.value.isUnableOther) ||
                        (!accidentVictimTreatmennt.value.isDisable &&
                          !accidentVictimTreatmennt.value.isUnableOther)
                      "
                    >
                      {{ '' }}
                    </div>
                    <div
                      *ngIf="
                        accidentVictimTreatmennt.value.isDisable &&
                        accidentVictimTreatmennt.value.isUnableOther
                      "
                    >
                      {{ accidentVictimTreatmennt.value.result }}
                    </div>
                    <app-text-field
                      size="XL"
                      formControlName="result"
                      maxLength="512"
                      *ngIf="
                        !accidentVictimTreatmennt.value.isDisable &&
                        accidentVictimTreatmennt.value.isUnableOther
                      "
                    >
                    </app-text-field>
                  </td>
                  <td class="text-center" *ngIf="todo !== 'view'">
                    <app-button
                      size="TN"
                      (onClick)="doSaveAccidentVictimTreatmennt(i)"
                      title="{{ 'app.tooltip.save' | translate }}"
                      *ngIf="!accidentVictimTreatmennt.value.isDisable"
                    >
                      <em class="fas fa-check"></em>
                    </app-button>
                    <app-button
                      size="TN"
                      (onClick)="doEdit(i)"
                      title="{{ 'app.tooltip.edit' | translate }}"
                      *ngIf="accidentVictimTreatmennt.value.isDisable"
                    >
                      <em class="fas fa-pencil-alt"></em>
                    </app-button>
                    <app-button
                      size="TN"
                      color="DANGER"
                      (onClick)="doDelete(i)"
                      title="{{ 'app.tooltip.delete' | translate }}"
                    >
                      <em class="fa fas fa-trash"></em>
                    </app-button>
                  </td>
                </tr>
              </tbody>
              <tbody
                *ngIf="
                  formArrayAccidentVictimTreatmentList.controls.length === 0
                "
              >
                <tr class="tr-no-data">
                  <td
                    colspan="9999999"
                    rowspan="7"
                    class="bg-white text-center"
                  >
                    <div class="no-data-wrapper">
                      <div class="book-wrapper pvc pv-file-list"></div>
                      <div class="text-no-data">
                        <span class="text-no-data">
                          {{ 'app-table.noDataAvailable' | translate }}
                        </span>
                        <span class="text-try-to-add">
                          {{ 'app-table.letsTryToAddList' | translate }}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-card>

        <div class="floating-button mt-5">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div class="button-group button-group-center">
                <app-button
                  color="SECONDARY"
                  (onClick)="doBack()"
                  mode="BYPASS"
                  [disabled]="formSaving"
                  >{{ 'app.button.back' | translate }}
                </app-button>
                <app-button
                  *ngIf="todo !== 'view'"
                  color="PRIMARY"
                  (onClick)="
                    doSubmit(
                      this.global.appConstant.pm.ACCIDENT_VICTIM_STATUS_DRAFT
                    )
                  "
                  [disabled]="formSaving"
                  class="mr-1"
                  >{{ 'app.button.saveDraft' | translate }}</app-button
                >
                <app-button
                  (onClick)="
                    doSubmit(
                      this.global.appConstant.pm.ACCIDENT_VICTIM_STATUS_DONE
                    )
                  "
                  *ngIf="todo !== 'view'"
                  >{{ 'app.button.submit' | translate }}
                </app-button>
                <app-button
                  (onClick)="doGenerate($event)"
                  mode="BYPASS"
                  *ngIf="
                    todo === 'view' &&
                    this.accidentVictimResponse.accidentVictim != null
                  "
                  >{{ 'app.button.printReport' | translate }}
                </app-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
