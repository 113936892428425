import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Catalog } from 'src/app/core/bean/catalog';
// import { CatalogBulkPrice } from 'src/app/core/bean/catalog-bulk-price';
import { CatalogContract } from 'src/app/core/bean/catalog-contract';
import { CatalogRegionBulkPrice } from 'src/app/core/bean/catalog-region-bulk-price';
import { Currency } from 'src/app/core/bean/currency';
import { File } from 'src/app/core/bean/file';
import { Item } from 'src/app/core/bean/item';
import { ItemCategory } from 'src/app/core/bean/item-category';
import { PrItem } from 'src/app/core/bean/pr-item';
import { TableCatalogResponseModel } from 'src/app/core/components/app-catalog-item-x/model/table-catalog-response-model';
import { AppPopupChooseBudgetXService } from 'src/app/core/components/app-popup/app-popup-choose-budget-x/app-popup-choose-budget-x.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Validators } from 'src/app/core/validators';
import { CatalogView } from 'src/app/core/view/entity/bean/catalog-view';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';
import { BudgetAllocation } from '../../core/bean/budget-allocation';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { AppPopupPrAddBudgetComponent } from './app-popup-pr-add-budget.component';
import { PrResponse } from './pr.response';

@Component({
  templateUrl: './pr-add-item-detail.component.html',
  styleUrls: ['./app-popup-pr-item-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrAddItemDetailComponent
  extends BaseModuleComponent
  implements OnInit
{
  public tableResponse: TableResponseModel<BudgetAllocation>;
  public tableResponseNonESM: TableResponseModel<BudgetAllocation>;
  public catalogId: number;
  public prItemId: number;
  public vendorId: number;
  public prResponse: PrResponse = new PrResponse();
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false,
    'code'
  );
  public prItem: PrItem = new PrItem();
  public vendorView: PopupVendorView = new PopupVendorView();
  public fileUploader: FileUploader = new FileUploader(
    '/manage-catalog/',
    'manage-catalog.form.header',
    this.global.appConstant.fileType.IMG_ITEM_CATALOG,
    false,
    5
  );
  public itemCategoryList: ItemCategory[] = [];
  public catalog: Catalog = new Catalog();
  public item: Item = new Item();
  public catalogContract: CatalogContract = new CatalogContract();
  public catalogView: CatalogView = new CatalogView();
  public model: TableCatalogResponseModel<CatalogView> =
    new TableCatalogResponseModel('/pr/last-purchase');
  public backToUrl: string;
  public isShowDiscount = false;
  public isCombineCatalogExist = false;
  // public isQuantityInPackage = false;
  public budgetAllocationExistList: BudgetAllocation[] = [];
  // public catalogBulkPriceList: CatalogBulkPrice[] = [];
  public catalogRegionBulkPriceList: CatalogRegionBulkPrice[] = [];
  public usedBudgetList: Map<number, number> = new Map<number, number>(); // budgetAllocationId, prItemBugetAmount
  public prItemBudgetEditList: Map<number, number> = new Map<number, number>(); // budgetAllocationId, prItemBugetAmount

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupChooseBudgetService: AppPopupChooseBudgetXService
  ) {
    super('pr', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.handleReloadChanges();
    this.getAndSetCatalogs();
    this.setPagination();
    this.setOptionModel();
    this.setUsedBudgetList();
    this.buildTableResponse();
    this.buildFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.prItemId = this.global.routerParams.get('prItemId');
    this.prResponse = this.global.routerParams.get('prResponse');
    this.catalogId = this.global.routerParams.get('catalogId');
    this.backToUrl = this.global.routerParams.get('backToUrl');
    this.prItemBudgetEditList = this.global.routerParams.get(
      'prItemBudgetEditList'
    );
  }

  public setOptionModel(): void {
    this.currencyOptionList.setRequestValues(this.prResponse.currencyList);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      pr: [null],
      item: [null],
      price: [null],
      totalPrice: [null],
      discount: [null],
      quantity: [
        null,
        Validators.compose([Validators.required(), Validators.min(1)])
      ],
      remainingQuantity: [null],
      specification: [null],
      itemImages: [null],
      totalBudget: [null],
      catalog: [null],
      crudOperation: [this.global.appConstant.core.CRUD_OPERATION_INSERT],
      isMaxPage: [false],
      catalogContract: [null],
      prItemBudgetList: this.formBuilder.array([]),
      budgetAllocationList: this.formBuilder.array([]),
      prItemImageList: this.formBuilder.array([]),
      vendorId: [null],
      catalogRegionDetail: [null],
      merk: [null]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'organization.name',
        header: 'table.column.department'
      },
      {
        field: 'activityPeriod',
        header: 'table.column.activityPeriod'
      },
      {
        field: 'costCenter.code',
        header: 'table.column.costCenter'
      },
      {
        field: 'coa.code',
        header: 'table.column.coa'
      },
      {
        field: 'budgetType.name',
        header: 'table.column.budgetType'
      },
      {
        field: 'availableBudget',
        header: 'table.column.availableBudget',
        className: 'text-right',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): number => {
              if (tablePlugin.value) {
                const available = this.prItemBudgetEditList.get(
                  tablePlugin.row.record.id
                )
                  ? tablePlugin.value -
                    tablePlugin.row.record.tempBookedBudget -
                    +(this.usedBudgetList.get(tablePlugin.row.record.id) || 0) +
                    this.prItemBudgetEditList.get(tablePlugin.row.record.id)
                  : this.usedBudgetList.get(tablePlugin.row.record.id)
                  ? tablePlugin.value -
                    tablePlugin.row.record.tempBookedBudget -
                    this.usedBudgetList.get(tablePlugin.row.record.id)
                  : tablePlugin.value -
                    +tablePlugin.row.record.tempBookedBudget;

                return available;
              }
            }
          },
          {
            name: 'default',
            type: 'currency'
          }
        ]
      },
      {
        field: 'budgetValue',
        header: 'table.column.budgetValue',
        className: 'text-right',
        plugins: [
          {
            name: 'currency',
            optionList: this.currencyOptionList,
            isView: !!(this.todo === 'view'),
            validators: Validators.required(),
            onInput: this.onChangeBudgetValue.bind(this)
          }
        ]
      }
    ]);

    this.tableResponseNonESM = new TableResponseModel(this.moduleCode, [
      {
        field: 'organization.name',
        header: 'table.column.department'
      },
      {
        field: 'activityPeriod',
        header: 'table.column.activityPeriod'
      },
      {
        field: 'costCenter.code',
        header: 'table.column.costCenter'
      },
      {
        field: 'coa.code',
        header: 'table.column.coa'
      },
      {
        field: 'budgetType.name',
        header: 'table.column.budgetType'
      },
      {
        field: 'budgetValue',
        header: 'table.column.budgetValue',
        className: 'text-right',
        plugins: [
          {
            name: 'currency',
            optionList: this.currencyOptionList,
            isView: !!(this.todo === 'view'),
            validators: Validators.required(),
            onInput: this.onChangeBudgetValue.bind(this)
          }
        ]
      }
    ]);
  }

  public get budgetAllocationList(): FormArray {
    return this.formGroup.get('budgetAllocationList') as FormArray;
  }

  public doChangeCatalog(event): void {
    this.log.debug(event, 'dari component');
    this.itemCategoryList = event.itemCategoryList;
    this.catalog = event.catalog;
    this.vendorId = event.catalogView.vendorId;
    this.catalogContract = event.catalogView.catalogRegion.catalogContract;
    this.catalogView = event.catalogView;
    /*this.catalogBulkPriceList = event.catalogBulkPriceList
      ? (event.catalogBulkPriceList as Array<CatalogBulkPrice>).filter(
          bulkPrice =>
            bulkPrice?.catalogContract?.id === this.catalogContract?.id
        )
      : event.catalogBulkPriceList;*/

    this.catalogRegionBulkPriceList = event.catalogRegionBulkPriceList
      ? (
          event.catalogRegionBulkPriceList as Array<CatalogRegionBulkPrice>
        ).filter(
          bulkPrice =>
            bulkPrice?.catalogRegion?.catalogContract?.id ===
            this.catalogView?.catalogRegion?.catalogContract?.id
        )
      : event.catalogRegionBulkPriceList;
    console.log(this.catalogRegionBulkPriceList);
    if (this.catalog) {
      this.formGroup.patchValue({
        id: this.prItem.id,
        pr: this.prItem.pr,
        item: this.catalog.item,
        price: this.catalogView ? this.catalogView.price : this.catalog.price,
        catalog: this.catalog,
        catalogContract: this.catalogContract,
        vendorId: this.vendorId,
        catalogRegionDetail: this.catalogView.catalogRegionDetail
      });
      const prItemImageList = this.formGroup.get(
        'prItemImageList'
      ) as FormArray;
      event.imageList.forEach((file: File) => {
        this.fileUploader.setFile(file);
        prItemImageList.push(
          this.formBuilder.group({
            file
          })
        );
      });
      this.setStateReady();
    }
    setTimeout(() => {
      // if (
      //   this.catalogView?.catalogRegion?.catalogContract !== undefined &&
      //   this.catalogView?.catalogRegion?.catalogContract !== null
      // ) {
      //   const quantityForm = this.formGroup.get('quantity');
      //   quantityForm.setValidators(
      //     Validators.compose([
      //       Validators.required(),
      //       Validators.max(+this.catalogView.remainingQuantity)
      //     ])
      //   );
      //   quantityForm.updateValueAndValidity();

      //   this.onChangeQuantity();
      // }
    }, 500);
  }

  public onChangeQuantity(): void {
    let prices = this.catalogView.price;
    const quantity = this.formGroup.value.quantity;
    let totalPrice = null;
    if (
      this.catalog.catalogType.code ===
      this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
    ) {
      prices = this.catalogView.price;

      // const remainingQuantity = +this.catalogView.remainingQuantity - +quantity;
      // const quantityForm = this.formGroup.get('quantity');
      // this.formGroup.patchValue({
      //   remainingQuantity
      // });
      // if (+this.catalogView.remainingQuantity < +quantity) {
      //   quantityForm.setValidators(
      //     Validators.compose([
      //       Validators.required(),
      //       Validators.max(+this.catalogView.remainingQuantity)
      //     ])
      //   );

      //   quantityForm.setErrors({
      //     message: this.translateService.instant('app.validation.max')
      //   });
      //   quantityForm.markAsDirty();
      //   quantityForm.markAsTouched();
      // }
      if (this.catalogView.catalogRegion.isBulkPrice) {
        let discountCatalog = 0;
        /*this.catalogBulkPriceList.forEach(
          (catalogBulkPrice: CatalogBulkPrice, i: number) => {
            if (
              this.catalogBulkPriceList[i + 1]
                ? quantity >= catalogBulkPrice.minimumOrderTotal &&
                  quantity < this.catalogBulkPriceList[i + 1]?.minimumOrderTotal
                : quantity >= catalogBulkPrice.minimumOrderTotal
            ) {
              discountCatalog = catalogBulkPrice.discount;
              this.isShowDiscount = true;
            }
          }
        );*/
        this.catalogRegionBulkPriceList.forEach(
          (catalogRegionBulkPrice: CatalogRegionBulkPrice, i: number) => {
            if (
              this.catalogRegionBulkPriceList[i + 1]
                ? quantity >= catalogRegionBulkPrice.minimumOrderTotal &&
                  quantity <
                    this.catalogRegionBulkPriceList[i + 1]?.minimumOrderTotal
                : quantity >= catalogRegionBulkPrice.minimumOrderTotal
            ) {
              discountCatalog = catalogRegionBulkPrice.discount;
              this.isShowDiscount = true;
            }
          }
        );
        if (prices && +quantity) {
          const discount = +prices * +quantity * +(+discountCatalog / 100);
          totalPrice = (+(+prices * +quantity) - +discount).toFixed(2);
          const priceAfterDiscount =
            +prices - +prices * +(+discountCatalog / 100);
          this.formGroup.patchValue({
            price: priceAfterDiscount,
            discount
          });
        }
        this.formGroup.patchValue({
          totalPrice
        });
      } else {
        this.isShowDiscount = false;
        if (prices && +quantity) {
          totalPrice = +(+prices * +quantity);
          this.formGroup.patchValue({
            price: prices
          });
        }
        this.formGroup.patchValue({
          totalPrice
        });
      }
    } else {
      if (prices && +quantity) {
        totalPrice = +(+prices * +quantity);
        this.formGroup.patchValue({
          price: prices
        });
      }
      this.formGroup.patchValue({
        totalPrice
      });
    }

    // if (this.isQuantityInPackage) {
    //   const quantity = this.formGroup.value.quantity;
    //   const quantityInPackage = this.catalogView.quantityInPackage;
    //   if (quantity % quantityInPackage === 0) {
    //     this.isQuantityInPackage = false;
    //   }
    // }
  }

  public doChooseBudget(): void {
    const budgetAllocationList = this.formGroup.value.budgetAllocationList;
    const budgetAllocationIdList: number[] = budgetAllocationList.map(
      budget => budget.id
    );

    if (budgetAllocationList.length !== 0) {
      this.appPopupChooseBudgetService
        .open(
          true,
          this.budgetAllocationExistList,
          this.usedBudgetList,
          this.prItemBudgetEditList
        )
        .subscribe(budgetAllocationListTemp => {
          const budgetAllocationArrayList = this.formGroup.get(
            'budgetAllocationList'
          ) as FormArray;
          budgetAllocationArrayList.clear();
          const prItemArrayList = this.formGroup.get(
            'prItemBudgetList'
          ) as FormArray;
          prItemArrayList.clear();
          this.budgetAllocationExistList = budgetAllocationListTemp;
          this.budgetAllocationExistList.forEach(budgetAllocation => {
            let budgetValue = null;
            if (budgetAllocationIdList.includes(budgetAllocation.id)) {
              budgetValue = budgetAllocationList.filter(
                budget => budget.id === budgetAllocation.id
              )[0].budgetValue;
            }

            if (budgetAllocationListTemp.length === 1) {
              const available = this.prItemBudgetEditList.get(
                budgetAllocation.id
              )
                ? budgetAllocation.availableBudget -
                  budgetAllocation.tempBookedBudget -
                  +(this.usedBudgetList.get(budgetAllocation.id) || 0) +
                  this.prItemBudgetEditList.get(budgetAllocation.id)
                : this.usedBudgetList.get(budgetAllocation.id)
                ? budgetAllocation.availableBudget -
                  budgetAllocation.tempBookedBudget -
                  this.usedBudgetList.get(budgetAllocation.id)
                : budgetAllocation.availableBudget -
                  +budgetAllocation.tempBookedBudget;

              const priceBudgetValue =
                +this.formGroup.get('totalPrice').value > +available
                  ? +available
                  : +this.formGroup.get('totalPrice').value;

              budgetValue = {
                currency: {},
                price: priceBudgetValue
              };
            }
            const formGroup = this.formBuilder.group({
              id: budgetAllocation.id,
              organization: budgetAllocation.organization,
              costCenter: budgetAllocation.costCenter,
              budgetType: budgetAllocation.budgetType,
              coa: budgetAllocation.coa,
              activityPeriod: budgetAllocation.activityPeriod,
              availableBudget: budgetAllocation.availableBudget,
              currency: budgetAllocation.currency,
              tempBookedBudget: budgetAllocation.tempBookedBudget,
              budgetValue
            });
            const formGroupItem = this.formBuilder.group({
              prItem: null,
              budgetAllocation,
              bookedAmount: budgetValue ? +budgetValue.price : budgetValue
            });
            budgetAllocationArrayList.push(formGroup);
            prItemArrayList.push(formGroupItem);
          });
          this.formGroup
            .get('totalBudget')
            .patchValue(this.getTotalBudgetValue());

          this.tableResponse.setRecordList(budgetAllocationArrayList.value);
          this.tableResponse.reload();
        });
    } else {
      this.appPopupChooseBudgetService
        .open(true, null, this.usedBudgetList, this.prItemBudgetEditList)
        .subscribe(budgetAllocationListTemp => {
          this.budgetAllocationExistList = budgetAllocationListTemp;
          this.prResponse.prItemBudgetList = [];
          const budgetAllocationLists = this.formGroup.get(
            'budgetAllocationList'
          ) as FormArray;
          const prItemBudgetList = this.formGroup.get(
            'prItemBudgetList'
          ) as FormArray;
          this.budgetAllocationExistList.forEach(budgetAllocation => {
            let budgetValue = null;
            if (budgetAllocationListTemp.length === 1) {
              const available = this.prItemBudgetEditList.get(
                budgetAllocation.id
              )
                ? budgetAllocation.availableBudget -
                  budgetAllocation.tempBookedBudget -
                  +(this.usedBudgetList.get(budgetAllocation.id) || 0) +
                  this.prItemBudgetEditList.get(budgetAllocation.id)
                : this.usedBudgetList.get(budgetAllocation.id)
                ? budgetAllocation.availableBudget -
                  budgetAllocation.tempBookedBudget -
                  this.usedBudgetList.get(budgetAllocation.id)
                : budgetAllocation.availableBudget -
                  +budgetAllocation.tempBookedBudget;

              const priceBudgetValue =
                +this.formGroup.get('totalPrice').value > +available
                  ? +available
                  : +this.formGroup.get('totalPrice').value;

              budgetValue = {
                currency: {},
                price: priceBudgetValue
              };
            }

            const formGroup = this.formBuilder.group({
              id: budgetAllocation.id,
              organization: budgetAllocation.organization,
              costCenter: budgetAllocation.costCenter,
              budgetType: budgetAllocation.budgetType,
              coa: budgetAllocation.coa,
              activityPeriod: budgetAllocation.activityPeriod,
              availableBudget: budgetAllocation.availableBudget,
              currency: budgetAllocation.currency,
              tempBookedBudget: budgetAllocation.tempBookedBudget,
              budgetValue
            });
            budgetAllocationLists.push(formGroup);
            const formGroupItem = this.formBuilder.group({
              prItem: null,
              budgetAllocation,
              bookedAmount: budgetValue ? +budgetValue.price : budgetValue
            });
            prItemBudgetList.push(formGroupItem);
          });
          this.formGroup
            .get('totalBudget')
            .patchValue(this.getTotalBudgetValue());
          this.tableResponse.setRecordList(budgetAllocationLists.value);
          this.tableResponse.reload();
        });
    }
  }
  public onChangeBudgetValue(event): void {
    if (this.prResponse.isModuleGroupESMActive) {
      const tableRow = this.tableResponse.currentRowChange.row;
      const availableBudget = tableRow.columnList[5].value;

      const budgetValue = tableRow.formGroup.get('budgetValue');
      if (
        (budgetValue.value !== null && +budgetValue.value.price) >
        +availableBudget
      ) {
        budgetValue.setValidators([Validators.max(+availableBudget)]);
        budgetValue.setErrors({
          message: this.translateService.instant('app.validation.max')
        });
        budgetValue.markAsTouched({ onlySelf: true });
      } else {
        const prItemBudgetList = this.formGroup.get(
          'prItemBudgetList'
        ) as FormArray;
        prItemBudgetList.controls[tableRow.position - 1].patchValue({
          bookedAmount: +budgetValue.value?.price
        });
        (this.formGroup.get('budgetAllocationList') as FormArray).controls[
          tableRow.position - 1
        ]
          .get('budgetValue')
          .patchValue(event);
        this.formGroup
          .get('totalBudget')
          .patchValue(this.getTotalBudgetValue());
      }
    } else {
      const tableRow = this.tableResponseNonESM.currentRowChange.row;

      const budgetValue = tableRow.formGroup.get('budgetValue');
      const prItemBudgetList = this.formGroup.get(
        'prItemBudgetList'
      ) as FormArray;
      prItemBudgetList.controls[tableRow.position - 1].patchValue({
        bookedAmount: +budgetValue.value?.price
      });
      (this.formGroup.get('budgetAllocationList') as FormArray).controls[
        tableRow.position - 1
      ]
        .get('budgetValue')
        .patchValue(event);
      this.formGroup.get('totalBudget').patchValue(this.getTotalBudgetValue());
      const budgetAllocation: BudgetAllocation =
        prItemBudgetList.controls[tableRow.position - 1].get(
          'budgetAllocation'
        ).value;
      budgetAllocation.totalBudget = budgetValue.value
        ? +budgetValue.value.price
        : null;
      budgetAllocation.availableBudget = budgetValue.value
        ? +budgetValue.value.price
        : null;
      prItemBudgetList.controls[tableRow.position - 1].patchValue({
        budgetAllocation
      });
    }
  }

  public getTotalBudgetValue(): number {
    let totalBudgetValue = 0;
    const budgetAllocationList = this.formGroup.get(
      'budgetAllocationList'
    ) as FormArray;
    budgetAllocationList.controls.forEach((budget: FormGroup) => {
      if (budget.get('budgetValue').value) {
        totalBudgetValue =
          totalBudgetValue + +budget.get('budgetValue').value.price;
      }
    });
    return totalBudgetValue;
  }

  public getVendorView(): void {
    const vendorIdList = this.prResponse.vendorViewList.map(
      vendor => +vendor.id
    );
    // jika vendor nya belum ada, maka add
    if (!vendorIdList.includes(+this.vendorId)) {
      this.httpClientService
        .get<PopupVendorView>('/pr/get-vendor-view/' + +this.vendorId)
        .subscribe((vendorViews: PopupVendorView) => {
          this.vendorView = vendorViews;
          if (!vendorIdList.includes(+vendorViews.id)) {
            this.prResponse.vendorViewList.push(this.vendorView);
          }
        });
    }
  }

  public doDeleteBudget(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const tableResponse = this.prResponse.isModuleGroupESMActive
            ? this.tableResponse
            : this.tableResponseNonESM;
          event.selectedRecordList.forEach((record: PrItem) => {
            const indexOfRecord = tableResponse
              .getRecordList()
              .findIndex(budget => budget.id === record.id);
            tableResponse.getRecordList().splice(indexOfRecord, 1);
            tableResponse.reload();
            const budgetAllocationList = this.formGroup.get(
              'budgetAllocationList'
            ) as FormArray;
            const prItemBudgetExistList = this.formGroup.get(
              'prItemBudgetList'
            ) as FormArray;

            this.budgetAllocationExistList.splice(indexOfRecord, 1);
            budgetAllocationList.removeAt(indexOfRecord);
            prItemBudgetExistList.removeAt(indexOfRecord);
          });
          this.formGroup
            .get('totalBudget')
            .patchValue(this.getTotalBudgetValue());
        }
      });
  }

  public isCatalogContractExist(): boolean {
    const prItemContractList = this.prResponse.prItemList.filter(
      prItem =>
        prItem.catalog &&
        prItem.catalog.catalogType.code ===
          this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
    );
    if (prItemContractList && prItemContractList.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  public isCatalogVendorExist(): boolean {
    const prItemNotContractList = this.prResponse.prItemList.filter(
      prItem =>
        !prItem.catalog ||
        (prItem.catalog &&
          prItem.catalog.catalogType.code ===
            this.global.appConstant.vm.CATALOG_TYPE_VENDOR)
    );
    if (prItemNotContractList && prItemNotContractList.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  public doPurchaseRequest(): void {
    // const quantity = this.formGroup.value.quantity;
    // const quantityInPackage = this.catalogView.quantityInPackage;
    // if (quantity % quantityInPackage === 0) {
    this.formGroup.markAllAsTouched();
    if (
      (this.isCatalogContractExist() &&
        this.catalog &&
        this.catalog.catalogType.code !==
        this.global.appConstant.vm.CATALOG_TYPE_CONTRACT) ||
      (this.isCatalogVendorExist() &&
        this.catalog &&
        this.catalog.catalogType.code ===
        this.global.appConstant.vm.CATALOG_TYPE_CONTRACT)
    ) {
      this.isCombineCatalogExist = true;
    }
    this.validate();
    if (this.formGroup.valid && !this.isCombineCatalogExist) {
      this.setStateProcessing();
      this.getVendorView();
      setTimeout(() => {
        // if (
        //   this.catalog.catalogType.code ===
        //   this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
        // ) {
        //   this.catalogView.remainingQuantity =
        //     this.formGroup.get('remainingQuantity').value;
        //   this.catalogView.catalogRegion.catalogContract.remainingQuantity =
        //     this.formGroup.get('remainingQuantity').value;
        //   this.formGroup
        //     .get('catalogContract')
        //     .patchValue(this.catalogContract);
        //   const catalogRegionDetail =
        //     this.catalogView.catalogRegion.catalogRegionDetailList.find(
        //       catalogRegionDetail =>
        //         catalogRegionDetail.distributionRegionDetail.region.id ===
        //         this.catalogView.region.id
        //     );
        //   this.formGroup
        //     .get('catalogRegionDetail')
        //     .patchValue(catalogRegionDetail);
        // }
        this.formGroup.value.merk = this.catalogView.merk;
        this.prResponse.prItemList.push(this.formGroup.value);
        this.prResponse.prItemList.forEach((prItem, index) => {
          prItem.code = '00' + (+index + +1);
        });
        this.global.routerParams.set('prResponse', this.prResponse);

        this.router.navigate(['/pages/pr/add']);
      }, 1000);
    }
    // } else {
    //   this.isQuantityInPackage = true;
    // }
  }

  public doCart(): void {
    // const quantity = this.formGroup.value.quantity;
    // const quantityInPackage = this.catalogView.quantityInPackage;
    // if (quantity % quantityInPackage === 0) {
      this.validate();
      if (this.formGroup.valid) {
        const prItemList: PrItem[] = [];
        prItemList.push(this.formGroup.value);
        prItemList.forEach((prItem, index) => {
          prItem.code = '00' + index + 1;
        });
        this.global.modalService
          .confirmation(
            'pr.confirmation.addCart.prompt',
            'pr.confirmation.addCart.title',
            'pr.confirmation.addCart.button.cancel',
            'pr.confirmation.addCart.button.yes',
            'pvc pv-shopping-cart',
            false
          )
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              this.httpClientService
                .post<Response<PrItem[]>>('/pr/insert-cart', prItemList)
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.global.alertService.showSuccessSavingOnNextRoute();
                    this.global.routerParams.set('todo', this.todo);
                    this.global.routerParams.set('prResponse', this.prResponse);
                    this.global.routerParams.set('catalogView', this.catalogView);
                    this.global.routerParams.set('backToUrl', this.backToUrl);
                    this.router.navigate(['/pages/cart/']);
                  } else {
                    this.global.alertService.showError(response.statusText);
                  }
                  this.setStateReady();
                });
            }
          });
      }
    // } else {
    //   this.isQuantityInPackage = true;
    // }
  }

  public getAndSetCatalogs(): void {
    this.model.setStateLoading();
    this.model.tableRequest.cols = [
      { field: 'merk', header: '' },
      { field: 'produsenName', header: '' },
      { field: 'rating', header: '' },
      { field: 'catalogRegion.currency.code', header: '' },
      { field: 'price', header: '' }
    ];

    this.httpClientService
      .post<any>(this.model.stringUrl, this.model.tableRequest)
      .subscribe(page => {
        this.model.setPageRecords(page.records);
        this.model.setTotalRecords(page.totalRecords);
        this.model.setStateReady();
        this.setStateReady();
      });
  }

  public handleReloadChanges(): void {
    this.model.reloadChanges.subscribe(() => {
      this.getAndSetCatalogs();
    });
  }

  public setPagination(): void {
    this.model.setCurrentPage(1);
    this.model.setPerPage(5);
  }

  public onPageChangeRight(): void {
    const page = +this.model.pagination.page + 1;
    if (
      this.model.pagination.page !==
      Math.ceil(
        this.model.pagination.totalRecords / this.model.pagination.perPage
      )
    ) {
      this.model.setCurrentPage(+this.model.pagination.page + 1);
      this.model.pagination.page = page;
      this.model.reload();
    }
    this.formGroup
      .get('isMaxPage')
      .patchValue(
        page ===
          Math.ceil(
            this.model.pagination.totalRecords / this.model.pagination.perPage
          )
          ? true
          : false
      );
  }

  public onPageChangeLeft(): void {
    const page = +this.model.pagination.page - 1;
    this.model.setCurrentPage(+this.model.pagination.page - 1);
    this.model.pagination.page = page;
    this.model.reload();
    this.formGroup
      .get('isMaxPage')
      .patchValue(
        page ===
          Math.ceil(
            this.model.pagination.totalRecords / this.model.pagination.perPage
          )
          ? true
          : false
      );
  }

  public setUsedBudgetList(): void {
    // usedBudgetList dari item2 yang sudah di tambahkan
    this.prResponse.prItemList.forEach(prItem => {
      prItem.prItemBudgetList.forEach(prItemBudget => {
        if (this.usedBudgetList.get(prItemBudget.budgetAllocation.id)) {
          this.usedBudgetList.set(
            prItemBudget.budgetAllocation.id,
            +prItemBudget.bookedAmount +
              +this.usedBudgetList.get(prItemBudget.budgetAllocation.id)
          );
        } else {
          this.usedBudgetList.set(
            prItemBudget.budgetAllocation.id,
            +prItemBudget.bookedAmount
          );
        }
      });
    });
  }

  public doAddBudget(): void {
    const prItemBudgetList = this.formGroup.get(
      'prItemBudgetList'
    ) as FormArray;
    const prResponse = this.prResponse;
    this.appPopupService
      .open(AppPopupPrAddBudgetComponent, { prResponse, todo: 'add' })
      .subscribe(budgetAllocation => {
        const budgetValue = {
          price: budgetAllocation.totalBudget,
          currency: this.prResponse.currencyList[0]
        };
        const formGroup = this.formBuilder.group({
          id: budgetAllocation.id,
          organization: budgetAllocation.organization,
          costCenter: budgetAllocation.costCenter,
          coa: budgetAllocation.coa,
          activityPeriod: budgetAllocation.activityPeriod,
          availableBudget: budgetAllocation.totalBudget,
          currency: budgetAllocation.currency,
          budgetValue,
          totalBudget: budgetAllocation.totalBudget,
          budgetType: budgetAllocation.budgetType,
          tempBookedBudget: budgetAllocation.tempBookedBudget
        });
        this.budgetAllocationList.push(formGroup);
        const formGroupItem = this.formBuilder.group({
          prItem: null,
          budgetAllocation,
          bookedAmount: budgetAllocation.totalBudget
        });
        prItemBudgetList.push(formGroupItem);
        this.tableResponseNonESM.setRecordList(this.budgetAllocationList.value);
        this.tableResponseNonESM.reload();

        this.formGroup
          .get('totalBudget')
          .patchValue(this.getTotalBudgetValue());
      });
  }
}
