import { Claim } from "src/app/core/bean/claim";
import { ClaimRequestAmount } from "src/app/core/bean/claim-request-amount";
import { FileObject } from "src/app/core/components/upload";

export class ClaimRequest {
    claim: Claim;
    fileObjectList: FileObject[] = [];
    isResend: boolean;
    fileOcrResult: FileObject[] = [];
    receiptDoc: FileObject[] = [];
    claimRequestAmount: ClaimRequestAmount;
    note: String;
    isDraft: boolean;
}