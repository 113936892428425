import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { Catalog } from '../../../core/bean/catalog';
import { CatalogVerification } from '../../../core/bean/catalog-verification';
import { AppTableComponent } from '../../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../../core/model/option-list-model';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { RouteRequestModel } from '../../../core/model/route-request-model';
import { TabModel } from '../../../core/model/tab/tab-model';
import { TabResponseModel } from '../../../core/model/tab/tab-response-model';
import { Validators } from '../../../core/validators';
import { CatalogVerificationDTO } from '../../catalog-verification/dto/catalog-verification.dto';
import { WorkflowModelPrcs } from 'src/app/core/bean/workflow-model-prcs';
@Component({
  templateUrl: './verification-edit.component.html'
})
export class VerificationEditComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public catalog: Catalog;
  public catalogVerificationDTO: CatalogVerificationDTO =
    new CatalogVerificationDTO();
  public statusOptionList: OptionListModel<any> = new OptionListModel();
  public tableResponse: TableResponseModel<CatalogVerification>;
  public catalogVerificationIdCustomData: any;
  public previousTab: TabModel;
  public tabResponse: TabResponseModel;
  public generalTab: TabModel;
  public isApproval: false;
  public workflowModelPrcs: WorkflowModelPrcs;
  public approvalModelPrcsId: number;

  constructor(translateService: TranslateService) {
    super('catalog-verification', translateService);
  }

  onInit(): void {
    this.getFromRouterParams();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public getFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.catalog = this.global.routerParams.get('catalog');
    this.tabResponse = this.global.routerParams.get('tabResponse');
    this.previousTab = this.global.routerParams.get(
      'tabModelBeforeVerification'
    );
    this.isApproval = this.global.routerParams.get('isApproval');
    this.approvalModelPrcsId = this.global.routerParams.get('approvalModelPrcsId');
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'user', header: 'table.column.admin' },
      {
        field: 'note',
        header: 'table.column.note',
        customClass: 'white-space-normal'
      },
      {
        field: 'date',
        header: 'table.column.date',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'catalogStatus.name',
        header: 'table.column.status',
        customClass: 'text-center'
      }
    ]);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorName: [null],
      name: [null],
      // item: [null, Validators.required()],
      status: [null, Validators.required()],
      note: [null]
    });
  }

  public setFormGroup(): void {
    if (this.catalog !== undefined) {
      this.catalogVerificationIdCustomData = JSON.stringify(this.catalog.id);
      this.formGroup.patchValue({
        vendorName: this.catalog.vendor.name,
        name: this.catalog.item.name
        // item: this.catalog.item
      });

      // if (
      //   this.catalog.catalogType?.code ===
      //   this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
      // ) {
      //   this.formGroup.get('item').setIsView(true);
      //   this.formGroup.get('item').clearValidators();
      //   this.formGroup.get('item').updateValueAndValidity();
      // }
    }

    this.httpClientService
      .post<CatalogVerificationDTO>(
        '/catalog-verification/edit',
        new RouteRequestModel(this.todo, this.catalog.id)
      )
      .subscribe((catalogVerificationDTO: CatalogVerificationDTO) => {
        this.catalogVerificationDTO = catalogVerificationDTO;

        if (
          this.catalog.catalogType.code ===
          this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
        ) {
          this.catalogVerificationDTO.catalogStatusList =
            this.catalogVerificationDTO.catalogStatusList.filter(
              status =>
                status.code !==
                this.global.appConstant.vm.CATALOG_STATUS_REJECTED
            );
        }

        this.statusOptionList.setRequestValues(
          this.catalogVerificationDTO.catalogStatusList
        );

        if (this.catalogVerificationDTO.catalogVerification != null) {
          this.formGroup.patchValue({
            status:
              this.catalogVerificationDTO.catalogVerification?.catalogStatus,
            note: this.catalogVerificationDTO.catalogVerification?.note
          });
          this.formGroup.get('status').setIsView(true);
          this.formGroup.get('note').setIsView(true);
        }

        this.tableResponse.setRecords(
          this.catalogVerificationDTO.catalogVerificationList
        );
        this.tableResponse.setTotalRecords(
          this.catalogVerificationDTO.catalogVerificationList
            ? this.catalogVerificationDTO.catalogVerificationList.length
            : 0
        );
        this.tableResponse.reloadClient();
        this.setStateReady();

        // if (
        //   this.catalog.catalogType.code ===
        //   this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
        // ) {
        //   this.formGroup.get('item').setIsView(true);
        //   this.formGroup.get('item').clearValidators();
        //   this.formGroup.get('item').updateValueAndValidity();
        // }
      });
  }

  public doPrevious(): void {
    this.tabResponse.setCurrentTab(this.previousTab);
    this.router.navigate([this.previousTab.routeUrl]);
  }

  public doSave(): void {
    this.generalTab = this.tabResponse.tabs.get('GENERAL');
    this.catalog.code = this.generalTab.value.code;
    var valid = false;
    if (this.formGroup.value.status.code === 'ACTIVE') {
      valid = this.generalTab.formGroup.valid && this.formGroup.valid ? true : false;
    } else {
      valid = this.formGroup.valid ? true : false;
    }

    this.validate();
    if (valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const catalogVerification: CatalogVerification =
              this.formGroup.value;
            catalogVerification.catalogStatus = this.formGroup.value.status;
            this.catalog.catalogStatus = this.formGroup.value.status;
            this.catalogVerificationDTO.catalog = this.catalog;
            this.catalogVerificationDTO.catalogVerificationList.push(
              catalogVerification
            );
            this.httpClientService
              .post<Response<CatalogVerificationDTO>>(
                '/catalog-verification/insert',
                this.catalogVerificationDTO
              )
              .subscribe((response: Response<CatalogVerificationDTO>) => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/catalog-verification']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    } else {
      if (!this.generalTab.formGroup.valid) {
        this.tabResponse.setCurrentTab(this.generalTab);
        this.router.navigate([this.generalTab.routeUrl]);
      }
    }
  }
}
