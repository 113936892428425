<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <form
      class="form-horizontal"
      [formGroup]="generalTab.formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <h5 class="separator">{{
        'manage-catalog.form-group.catalogInformation' | translate
      }}</h5>

      <div>
        <app-alert-x color="WARNING" *ngIf="!isActive && isActiveItem && !isOther">{{
          'manage-catalog.alert.isActive' | translate
        }}</app-alert-x>
        <app-alert-x color="WARNING" *ngIf="!isActive && !isActiveItem">{{
          'manage-catalog.alert.isActiveItem' | translate
        }}</app-alert-x>
      </div>

      <div
        class="form-group row text-sm-right"
        *ngIf="(this.isGuest && !manageCatalogDTO.catalog?.code) || 
        manageCatalogDTO.catalog?.code"
      >
        <label class="col-sm-3 control-label">{{
          'manage-catalog.form.code' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            autofocus="true"
            formControlName="code"
            type="code"
            tooltip="{{ 'manage-catalog.tooltip.code' | translate }}"
          ></app-text-field>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!generalTab.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'manage-catalog.form.name' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-dropdown-select
            size="MD"
            [optionList]="itemOptionList"
            formControlName="item"
            type="AUTOCOMPLETE"
            (onChange)="onChangeItemName($event)"
          >
          </app-dropdown-select>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'manage-catalog.form.description' | translate
        }}</label>
        <div
          class="text-left text-break"
          [ngClass]="!generalTab.formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
        >
          <app-wysiwyg
            size="XL"
            formControlName="description"
            maxLength="512"
            placeholder="manage-catalog.wysiwyg.placeholder"
          ></app-wysiwyg>
        </div>
      </div>

      <div class="form-group row text-sm-right"
      *ngIf="item !== undefined"
      >
        <label class="col-sm-3 control-label">{{
          'manage-catalog.form.groupProduct' | translate
        }}</label>
        <div
          class="text-left text-break"
          [ngClass]="'col-sm-9'"
        >
        {{item?.itemType?.name }}
        </div>
      </div>

      <div class="form-group row text-sm-right"
      *ngIf="item !== undefined"
      >
        <label class="col-sm-3 control-label">{{
          'manage-catalog.form.preparationForm' | translate
        }}</label>
        <div
          class="text-left text-break"
          [ngClass]="'col-sm-9'"
        >
        {{item?.uom?.name }}
        </div>
      </div>

      <div class="form-group row text-sm-right"
      *ngIf="item !== undefined"
      >
        <label class="col-sm-3 control-label">{{
          'manage-catalog.form.therapyClass' | translate
        }}</label>
        <div
          class="text-left text-break"
          [ngClass]="'col-sm-9'"
        >
        {{item?.itemCategory?.name }}
        </div>
      </div>

      <div class="form-group row text-sm-right"
      *ngIf="item !== undefined"
      >
        <label class="col-sm-3 control-label">{{
          'manage-catalog.form.dose' | translate
        }}</label>
        <div
          class="text-left text-break"
          [ngClass]="'col-sm-9'"
        >
        {{item?.dose || '-' }}
        </div>
      </div>

      <div class="form-group row text-sm-right"
      [ngClass]="'required'"  
      *ngIf="isOther && !this.isGuest && isVerified && isActiveItem">
        <label class="col-sm-3 control-label">{{
          'manage-catalog.form.catalogStatus' | translate
          }}</label>
        <div class="col-sm-4 text-left">
          <app-toggle-switch formControlName="isActive" 
          (onChange)="onClickisActive($event)" ></app-toggle-switch>
        </div>
      </div>

      <ng-container
        *ngIf="
          (!formLoading &&
            !generalTab.formGroup.isView &&
            generalTab.formGroup.get('id').value !== null &&
            !isGuest) ||
          (generalTab.formGroup.isView &&
            manageCatalogDTO.catalogVerificationList.length > 1 &&
            !isGuest)
        "
      >
        <h5 class="separator">{{
          'manage-catalog.form-group.historyActivity' | translate
        }}</h5>
        <app-table [model]="tableResponse" [isServerSide]="false"></app-table>
        <!--*ngIf="!isLoadingTable"-->
      </ng-container>
    </form>
  </div>
</div>
