import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppPopupChangePasswordService } from 'src/app/core/components/app-popup/app-popup-change-password/app-popup-change-password.service';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AppPopUpTOCService } from '../../core/components/app-popup/app-popup-toc/app-popup-toc.service';
import { AppPopUpUserTOCService } from '../../core/components/app-popup/app-popup-user-toc/app-popup-user-toc.service';
import { DashboardResponse } from './dashboard-response';
import { WidgetResponseModel } from './widget/model/widget-response-model';
import { UserManual } from 'src/app/core/bean/user-manual';
@Component({
  templateUrl: './dashboard.component.html'
})
export class DashboardXtComponent extends BaseModuleComponent {
  public widgetModel: WidgetResponseModel = new WidgetResponseModel();
  public userManualList: UserManual[];
  constructor(
    translate: TranslateService,
    private appPopUpUserTOCService: AppPopUpUserTOCService,
    private appPopUpTOCService: AppPopUpTOCService,
    private appPopupChangePasswordService: AppPopupChangePasswordService
  ) {
    super('dashboard', translate);
  }

  onInit(): void {
    this.setWidgetModelState();
  }

  private setWidgetModelState(): void {
    this.httpClientService
      .get('/dashboard/load-my-dashboard')
      .subscribe((response: DashboardResponse) => {
        if (response.contentUser != null) {
          this.showPopupUserTremAndCondition(response);
        }
        if (response.contentVendor != null && response.contentUser == null) {
          this.showPopupTremAndCondition(response);
        }
        if (response.contentUser == null && response.contentVendor == null
          && response.contentChangePassword != null) {
          this.showPopupUserChangePassword(response);
        }
        this.widgetModel.setWidgetList(response.userDashboardWidgetList);
        this.userManualList = response.userManualList;
        this.setStateReady();
      });
  }

  private showPopupTremAndCondition(
    dashboardResponse: DashboardResponse
  ): void {
    this.appPopUpTOCService.open(dashboardResponse);
  }

  private showPopupUserTremAndCondition(
    dashboardResponse: DashboardResponse
  ): void {
    this.appPopUpUserTOCService.open(dashboardResponse);
  }

  private showPopupUserChangePassword(
    dashboardResponse: DashboardResponse
  ): void {
    this.appPopupChangePasswordService.open(dashboardResponse);
  }

  public onClickUserManual(userManual: UserManual): void {
    if (userManual.userManualType.code == this.global.appConstant.pm.USER_MANUAL_TYPE_CODE_LINK) {
      window.open(userManual.link, '_blank');
    } else if (userManual.userManualType.code == this.global.appConstant.pm.USER_MANUAL_TYPE_CODE_DOCUMENT) {
      const urlFile =
        '/api/dashboard/file/view/' +
        userManual.file.uploadedFileName +
        '/' +
        userManual.file.fileType.code;
      if (urlFile.toLowerCase().includes('.pdf/')) {
        this.openPdf(urlFile);
      }
      else {
        this.downloadService.download(urlFile, userManual.file.fileName);
      }
    }
  }

  openPdf(url: string): void {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = URL.createObjectURL(blob);
        const newWindow = window.open();
        if (newWindow) {
          newWindow.document.write(
            `<iframe src="${blobUrl}" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>`
          );
        }
      })
      .catch(error => console.error('Error fetching PDF:', error));
  }
}
