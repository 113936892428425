export class FileTypeConstant {
  public IMG_NEWS = 'IMG_NEWS';
  public IMG_BIG_COMPANY_LOGO = 'IMG_BIG_COMPANY_LOGO';
  public IMG_HOME_SLIDE = 'IMG_HOME_SLIDE';
  public IMG_SMALL_COMPANY_LOGO = 'IMG_SMALL_COMPANY_LOGO';
  public IMG_ITEM_CATALOG = 'IMG_ITEM_CATALOG';
  public IMG_BANNER_VENDOR = 'IMG_BANNER_VENDOR';
  public IMG_LOGO_VENDOR = 'IMG_LOGO_VENDOR';
  public IMG_PROFILE_PICTURE = 'IMG_PROFILE_PICTURE';
  public IMG_WATERMARK_REPORT = 'IMG_WATERMARK_REPORT';
  public IMG_OFC_PHOTO = 'IMG_OFC_PHOTO';
  public IMG_ICON = 'IMG_ICON';
  public IMG_PORTAL = 'IMG_PORTAL';
  public IMG_ITEM_PR = 'IMG_ITEM_PR';
  public IMG_SIGNATURE = 'IMG_SIGNATURE';

  public IMG_MAIN_LOGO = 'IMG_MAIN_LOGO';
  public IMG_SMALL_LOGO = 'IMG_SMALL_LOGO';
  public IMG_REPORT_LOGO = 'IMG_REPORT_LOGO';
  public IMG_ACCIDENT_VICTIM_CONDITION = 'IMG_ACCIDENT_VICTIM_CONDITION';
  public IMG_USER_MANUAL = 'IMG_USER_MANUAL';

  public DOC_USER_MANUAL = 'DOC_USER_MANUAL';
  public DOC_DELEGATION = 'DOC_DELEGATION';
  public DOC_APPROVAL = 'DOC_APPROVAL';
  public DOC_TOR = 'DOC_TOR';
  public DOC_PRINCIPLE_PERMIT = 'DOC_PRINCIPLE_PERMIT';
  public DOC_DIRECTORS_DECREE = 'DOC_DIRECTORS_DECREE';
  public DOC_OFFICIAL_MEMO = 'DOC_OFFICIAL_MEMO';
  public DOC_PURCHASE_REQUEST = 'DOC_PURCHASE_REQUEST';
  public DOC_PURCHASE_ORDER = 'DOC_PURCHASE_ORDER';
  public DOC_CONTRACT = 'DOC_CONTRACT';
  public DOC_PROCUREMENT = 'DOC_PROCUREMENT';
  public DOC_WORKPLAN_DELIVERY_RECEIVED = 'DOC_WORKPLAN_DELIVERY_RECEIVED';
  public DOC_EMPLOYEE_ALL = 'DOC_EMPLOYEE_ALL';
  public DOC_MEMO_WANPRESTASI = 'DOC_MEMO_WANPRESTASI';
  public DOC_CERTIFICATE_VENDOR = 'DOC_CERTIFICATE_VENDOR';
  public DOC_VIOLATION_BLACKLIST = 'DOC_VIOLATION_BLACKLIST';
  public DOC_PROOF_CONTRACT = 'DOC_PROOF_CONTRACT';
  public DOC_CV = 'DOC_CV';
  public DOC_OWNERSHIP = 'DOC_OWNERSHIP';
  public DOC_FINANCIAL_STATEMENT = 'DOC_FINANCIAL_STATEMENT';
  public DOC_COMPANY_PROFILE = 'DOC_COMPANY_PROFILE';
  public DOC_OTS = 'DOC_OTS';
  public DOC_VENDOR_WHITELIST = 'DOC_VENDOR_WHITELIST';
  public DOC_VENDOR_REACTIVATION = 'DOC_VENDOR_REACTIVATION';
  public DOC_PERFORMANCE = 'DOC_PERFORMANCE';
  public DOC_NPWP = 'DOC_NPWP';
  public DOC_KTP = 'DOC_KTP';
  public DOC_STKRK = 'DOC_STKRK';
  public DOC_IDENTITY = 'DOC_IDENTITY';
  public DOC_APP_LETTER = 'DOC_APP_LETTER';
  public DOC_STATEMENT_LETTER = 'DOC_STATEMENT_LETTER';
  public DOC_TDR = 'DOC_TDR';
  public DOC_APP = 'DOC_APP';
  public DOC_SK_KEMENKUMHAM = 'DOC_SK_KEMENKUMHAM';
  public DOC_RI_APP = 'DOC_RI_APP';
  public DOC_APPT = 'DOC_APPT';
  public DOC_SKAPT = 'DOC_SKAPT';
  public DOC_SIUP = 'DOC_SIUP';
  public DOC_BERITA_RI_APP = 'DOC_BERITA_RI_APP';
  public DOC_SIUJK = 'DOC_SIUJK';
  public DOC_IUPJTK = 'DOC_IUPJTK';
  public DOC_TDP = 'DOC_TDP';
  public DOC_SKDP = 'DOC_SKDP';
  public DOC_SBU = 'DOC_SBU';
  public DOC_FCAMC = 'DOC_FCAMC';
  public DOC_SKTWP = 'DOC_SKTWP';
  public DOC_SPPKP = 'DOC_SPPKP';
  public DOC_SKRBBJB = 'DOC_SKRBBJB';
  public DOC_SPPPH = 'DOC_SPPPH';
  public DOC_SPD_UPDATED = 'DOC_SPD_UPDATED';
  public DOC_DPTTT = 'DOC_DPTTT';
  public DOC_DPP = 'DOC_DPP';
  public DOC_RKB = 'DOC_RKB';
  public DOC_DPPR = 'DOC_DPPR';
  public DOC_DGKWPG = 'DOC_DGKWPG';
  public DOC_DMPU = 'DOC_DMPU';
  public DOC_CAPPRCOM = 'DOC_CAPPRCOM';
  public DOC_DPMSU = 'DOC_DPMSU';
  public DOC_CONTRACT_PO = 'DOC_CONTRACT_PO';
  public DOC_IUK = 'DOC_IUK';
  public DOC_ISO = 'DOC_ISO';
  public DOC_SPKE_BJB = 'DOC_SPKE_BJB';
  public DOC_NIB = 'DOC_NIB';
  public DOC_BBP_SPT = 'DOC_BBP_SPT';
  public DOC_OHSAS = 'DOC_OHSAS';
  public DOC_MEMBERCARD = 'DOC_MEMBERCARD';
  public DOC_EMAIL_ATTACHMENT = 'DOC_EMAIL_ATTACHMENT';
  public DOC_STATEMENT_NEW_DATA = 'DOC_STATEMENT_NEW_DATA';
  public DOC_SPTSBH = 'DOC_SPTSBH';
  public DOC_EDU_CERTIFICATE = 'DOC_EDU_CERTIFICATE';
  public DOC_EXPERIENCE = 'DOC_EXPERIENCE';
  public DOC_EXPERIENCE_CONTRACT = 'DOC_EXPERIENCE_CONTRACT';
  public DOC_NON_BLACKLIST = 'DOC_NON_BLACKLIST';
  public DOC_NPASJKA = 'DOC_NPASJKA';
  public DOC_INTEGRITY_PACT = 'DOC_INTEGRITY_PACT';
  public DOC_TEMPLATE_VENDOR = 'DOC_TEMPLATE_VENDOR';
  public DOC_ADDITIONAL_DOCUMENT = 'DOC_ADDITIONAL_DOCUMENT';
  public DOC_WORK_PLAN = 'DOC_WORK_PLAN';
  public DOC_PROCUREMENT_OFFLINE = 'DOC_PROCUREMENT_OFFLINE';
  public DOC_APPROVAL_MEMO = 'DOC_APPROVAL_MEMO';
  public DOC_RFI_TEMPLATE = 'DOC_RFI_TEMPLATE';
  public DOC_RFI = 'DOC_RFI';
  public DOC_CONTRACT_TEMPLATE = 'DOC_CONTRACT_TEMPLATE';
  public DOC_CONTRACT_CANCELED = 'DOC_CONTRACT_CANCELED';
  public DOC_CLOSED_CONTRACT = 'DOC_CLOSED_CONTRACT';
  public DOC_INVOICE = 'DOC_INVOICE';
  public DOC_BILLING_TERMS = 'DOC_BILLING_TERMS';
  public DOC_DELIVERY_ITEM = 'DOC_DELIVERY_ITEM';
  public DOC_PAYMENT_PROOF = 'DOC_PAYMENT_PROOF';
  public DOC_CONTRACT_TERMINATION = 'DOC_CONTRACT_TERMINATION';
  public DOC_FINES_ADJUSTMENT = 'DOC_FINES_ADJUSTMENT';
  public DOC_REGIST_VENDOR = 'DOC_REGIST_VENDOR';
  public DOC_USER_TOC = 'DOC_USER_TOC';
  public DOC_PR = 'DOC_PR';
  public DOC_PRA_QUALIFICATION: 'DOC_PRA_QUALIFICATION';
  public DOC_RFP_TEMPLATE = 'DOC_RFP_TEMPLATE';
  public DOC_RFP_RESPONSE = 'DOC_RFP_RESPONSE';
  public DOC_OEP = 'DOC_OEP';
  public DOC_ORDER = 'DOC_ORDER';
  public DOC_RFQ_TEMPLATE = 'DOC_RFQ_TEMPLATE';
  public DOC_RFQ_RESPONSE = 'DOC_RFQ_RESPONSE';
  public DOC_OFFICIAL_REPORT_TEMPLATE = 'DOC_OFFICIAL_REPORT_TEMPLATE';
  public DOC_SUMMARY = 'DOC_SUMMARY';
  public DOC_CHAT = 'DOC_CHAT';
  public DOC_OFFICIAL_REPORT = 'DOC_OFFICIAL_REPORT';
  public DOC_CANCEL_PROCUREMENT = 'DOC_CANCEL_PROCUREMENT';
  public DOC_FAILED_PROCUREMENT = 'DOC_FAILED_PROCUREMENT';
  public DOC_COMPLIANCE_VENDOR = 'DOC_COMPLIANCE_VENDOR';
  public DOC_REFUTATION = 'DOC_REFUTATION';
  public DOC_COMMITTEE_DECREE = 'DOC_COMMITTEE_DECREE';
  public DOC_CLAIM = 'DOC_CLAIM';
  public DOC_KLAIM_OTHER = 'DOC_KLAIM_OTHER';
  public DOC_OCR_RESULT = 'DOC_OCR_RESULT';
  public DOC_KLAIM_RECEIPT = 'DOC_KLAIM_RECEIPT';
}
