import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Item } from 'src/app/core/bean/item';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { CatalogAttributeGroup } from '../../../core/bean/catalog-attribute-group';
import { CatalogCategory } from '../../../core/bean/catalog-category';
import { CatalogVerification } from '../../../core/bean/catalog-verification';
import { Currency } from '../../../core/bean/currency';
import { EquipmentCondition } from '../../../core/bean/equipment-condition';
import { ItemCategory } from '../../../core/bean/item-category';
import { Uom } from '../../../core/bean/uom';
import { AppPopupService } from '../../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../../core/model/option-list-model';
import { TabModel } from '../../../core/model/tab/tab-model';
import { Validators } from '../../../core/validators';
import { ManageCatalogDTO } from '../dto/manage-catalog.dto';
import { CatalogAttributeService } from '../catalog-attribute-service';
import { Subscription } from 'rxjs';
@Component({
  templateUrl: './general.component.html'
})
export class GeneralComponent extends BaseModuleComponent implements OnInit {
  @ViewChild(AppTableComponent) table: AppTableComponent;
  public readonly urlIndex = '/manage-catalog/index';

  public manageCatalogDTO: ManageCatalogDTO = new ManageCatalogDTO();
  public sessionId = null;
  public generalTab: TabModel;
  public isTodoView;
  public customData: any;
  public catalogCategoryList: CatalogCategory[] = [];
  public catalogCategory: CatalogCategory = new CatalogCategory();
  public itemCategoryList: ItemCategory[] = [];
  public catalogId: number;
  public isGuest = false;
  public isActive = true;
  public isViewCode = true;
  public isActiveItem = true;
  public isLoadingTable = true;
  public item: Item;

  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false,
    'code'
  );
  public catalogUomOptionList: OptionListModel<Uom> = new OptionListModel();
  public equipmentConditionOptionList: OptionListModel<EquipmentCondition> =
    new OptionListModel();
  public weightOptionList: OptionListModel<Uom> = new OptionListModel();
  public lengthOptionList: OptionListModel<Uom> = new OptionListModel();
  public widthOptionList: OptionListModel<Uom> = new OptionListModel();
  public heightOptionList: OptionListModel<Uom> = new OptionListModel();
  public catalogAttributeGroupOptionList: OptionListModel<CatalogAttributeGroup> =
    new OptionListModel();
  public itemOptionList: OptionListModel<any> = new OptionListModel();
  public isVendor: boolean;
  public isOther: boolean;
  public isVerified: boolean;
  public catalogAttribute: number;
  catalogAtributeSubscription: Subscription;

  public tableResponse: TableResponseModel<CatalogVerification>;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    private catalogAttributeService: CatalogAttributeService
  ) {
    super('manage-catalog', translateService);
  }

  ngOnInit() {
    this.onInit();

    this.catalogAtributeSubscription = this.catalogAttributeService.catalogAtributeParam$.subscribe(param => {
      const catalogAtributId = param.catalogAtributId;

      if ((this.todo == 'edit' || this.todo == 'add') && this.isVendor) {
        if (catalogAtributId) {
          this.generalTab.setIsView(false);
          this.itemOptionList.setRequestValues(this.manageCatalogDTO.itemList);
          this.generalTab.formGroup.patchValue({
            item: this.manageCatalogDTO.catalog.item ? this.itemOptionList.getRequestValues().filter(
              valueOption => valueOption.id === this.manageCatalogDTO.catalog.item.id)[0] : null
          })
        } else {
          this.itemOptionList.setRequestValues([]);
          this.generalTab.setIsView(true);
        }
      }
    });
  }

  onInit(): void {
    this.isVendor = this.global.userSession.vendor ? true : false;
    this.isOther = this.isVendor ? false : this.global.userSession.activeUserRole.role.type
      === this.global.appConstant.core.ROLE_TYPE_OTHER ? true : false;
    this.buildTableResponse();
    this.setCatalog();
    this.buildFormGroup();
    this.getAndSetCatalogToFormGroup();
    this.setOptionListModel();
    if(this.isGuest && (this.manageCatalogDTO.catalog?.code === null || this.manageCatalogDTO.catalog?.code === '')){
      this.generalTab.setIsView(this.isTodoView);
      this.generalTab.formGroup.get('code').setIsView(true);
      this.isViewCode = false;
    }else if(this.isOther){
      this.generalTab.setIsView(true);
      this.generalTab.formGroup.get('isActive').setIsView(false);
    } else{
      this.generalTab.setIsView(this.isTodoView);
      this.generalTab.formGroup.get('code').setIsView(true);
    }
    this.setTableRecords();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'user', header: 'table.column.createdBy' },
      {
        field: 'note',
        header: 'table.column.note',
        customClass: 'white-space-normal'
      },
      {
        field: 'date',
        header: 'table.column.createAt',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      },
      {
        field: 'catalogStatus.name',
        header: 'table.column.catalogStatusName',
        customClass: 'text-center'
      }
    ]);
  }

  public setTableRecords(): void {
    this.manageCatalogDTO.viewIsOther = this.isOther;
    this.tableResponse.setRecords(
      this.manageCatalogDTO.catalogVerificationList
    );
    this.tableResponse.setTotalRecords(
      this.manageCatalogDTO.catalogVerificationList
        ? this.manageCatalogDTO.catalogVerificationList.length
        : 0
    );
    // this.tableResponse.reloadClient();
    this.tableResponse.setStateReady();
    this.isVerified = this.manageCatalogDTO.catalogContractList[0].isVerified;
    this.setStateReady();
  }

  public setOptionListModel(): void {
    this.currencyOptionList.setRequestValues(
      this.manageCatalogDTO.currencyList
    );
    this.equipmentConditionOptionList.setRequestValues(
      this.manageCatalogDTO.equipmentConditionList
    );
    this.weightOptionList.setRequestValues(this.manageCatalogDTO.weightList);
    this.lengthOptionList.setRequestValues(this.manageCatalogDTO.lengthList);
    this.widthOptionList.setRequestValues(this.manageCatalogDTO.widthList);
    this.heightOptionList.setRequestValues(this.manageCatalogDTO.heightList);
    this.catalogAttributeGroupOptionList.setRequestValues(
      this.manageCatalogDTO.catalogAttributeGroupList
    );
  }

  public onChangeCategory(): void {
    const category: ItemCategory =
      this.generalTab.formGroup.value.categoryList[
        this.generalTab.formGroup.value.categoryList.length - 1
      ];
    this.httpClientService
      .get<Uom[]>('/manage-catalog/get-uom-list/' + category.id)
      .subscribe(response => {
        this.catalogUomOptionList.setRequestValues(response);
        this.generalTab.formGroup.get('catalogUom').patchValue(null);
      });
  }

  public setCatalog(): void {
    this.generalTab = this.global.routerParams
      .get('tabResponse')
      .tabs.get('GENERAL');
    this.todo = this.global.routerParams.get('todo');
    this.isTodoView = this.global.routerParams.get('tabResponse').isView;
    this.manageCatalogDTO = this.global.routerParams.get('manageCatalogDTO');
    if (this.global.routerParams.get('isGuest')) {
      this.isGuest = this.global.routerParams.get('isGuest');
    }
    if(this.todo === 'edit' && 
    (this.manageCatalogDTO.catalog.catalogStatus.code ===  this.global.appConstant.vm.CATALOG_CONTRACT_STATUS_NON_ACTIVE ||
    this.manageCatalogDTO.catalog.catalogStatus.code ===  this.global.appConstant.vm.CATALOG_CONTRACT_STATUS_ACTIVE)){
      this.isActive = 
      this.manageCatalogDTO.catalog.catalogStatus.code === 
      this.global.appConstant.vm.CATALOG_CONTRACT_STATUS_NON_ACTIVE? false: true;
      if(!this.isActive){
        this.isActiveItem = this.manageCatalogDTO.catalog.item.isActive;
      }
    }
    if (this.todo !== 'add') {
      /*this.manageCatalogDTO.catalogCategoryList.forEach(catalogCategory => {
        this.item = catalogCategory.catalog.item;
      });*/
      this.item = this.manageCatalogDTO.catalog.item;
    } else {
      this.item = this.manageCatalogDTO?.catalog?.item;
    }
    
  }

  public buildFormGroup(): void {
    if (!this.generalTab.isFormGroupReady) {
      this.catalogId =
        this.manageCatalogDTO.catalog !== null
          ? this.manageCatalogDTO.catalog.id
          : null;
      this.generalTab.formGroup = this.formBuilder.group({
        id: [null],
        code: [
          null,
          {
            // validators: [Validators.required()],
            asyncValidators: [
              Validators.existsAsync(
                '/manage-catalog/check-code-exist',
                null,
                null,
                { id: this.catalogId }
              )
            ]
          }
        ],
        item: [
          null,
          Validators.compose([Validators.required(), Validators.maxLength(128)])
        ],
        description: [
          null,
          Validators.maxLength(512, 'manage-catalog.validation.maxLength')
        ],
        vendor: [null],
        catalogAttributeGroup: [null],
        submissionDate: [null],
        catalogStatus: [null],
        isActive: [null]
      });
    }
  }

  public getAndSetCatalogToFormGroup(): void {
    if (
      Object.keys(this.generalTab.formGroup.controls).length === 0 ||
      this.generalTab.formGroup.controls.id.value === null
    ) {
      const categoryList = [];
      this.manageCatalogDTO.catalogCategoryList.forEach(category => {
        categoryList.push(category.itemCategory);
      });

      this.generalTab.formGroup.patchValue({
        categoryList
      });

      this.generalTab.formGroup.patchValue(this.manageCatalogDTO.catalog);
      this.itemOptionList.setRequestValues(this.manageCatalogDTO.itemList);
      this.generalTab.formGroup.patchValue({
        item: this.manageCatalogDTO.catalog.item ? this.itemOptionList.getRequestValues().filter(
          valueOption => valueOption.id === this.manageCatalogDTO.catalog.item.id)[0] : null
      })
      if (this.manageCatalogDTO.catalog.currency !== null && this.isTodoView) {
        this.generalTab.formGroup.patchValue({
          priceCatalogs: {
            currency: this.manageCatalogDTO.catalog.currency,
            price: this.manageCatalogDTO.catalog.price
          }
        });
      } else if (
        this.manageCatalogDTO.catalog.id !== null &&
        this.manageCatalogDTO.catalog.currency !== null
      ) {
        this.generalTab.formGroup.patchValue({
          priceCatalogs: {
            currency: this.manageCatalogDTO.catalog.currency,
            price: this.manageCatalogDTO.catalog.price
          }
        });
      }

      this.generalTab.formGroup.patchValue({
        isActive: this.isActive
      });
    }
  }

  public onClickisActive(data): void {
    if(data){
      this.manageCatalogDTO.catalog.catalogStatus.code = 
      this.global.appConstant.vm.CATALOG_CONTRACT_STATUS_ACTIVE;
    }else{
      this.manageCatalogDTO.catalog.catalogStatus.code = 
      this.global.appConstant.vm.CATALOG_CONTRACT_STATUS_NON_ACTIVE;
    }
  }

  public onChangeItemName(item : Item): void {
    this.item = item;
    this.manageCatalogDTO.catalog.item = this.item;
    if(!this.isActiveItem){
      this.isActiveItem = this.manageCatalogDTO.catalog.item.isActive;
      this.isActive = true
    }
  }
}
