import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Fines } from 'src/app/core/bean/fines';
import { Guarantee } from 'src/app/core/bean/guarantee';
import { PrItem } from 'src/app/core/bean/pr-item';
import { Vendor } from 'src/app/core/bean/vendor';
import { AppPopupPrItemInfoService } from 'src/app/core/components/app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AddressBook } from '../../core/bean/address-book';
import { AddressPic } from '../../core/bean/address-pic';
import { ItemType } from '../../core/bean/item-type';
import { Order } from '../../core/bean/order';
import { OrderActivityHistory } from '../../core/bean/order-activity-history';
import { OrderActivityStatus } from '../../core/bean/order-activity-status';
import { OrderCancellation } from '../../core/bean/order-cancellation';
import { OrderShippingItem } from '../../core/bean/order-shipping-item';
import { OrderStatus } from '../../core/bean/order-status';
import { OrderTc } from '../../core/bean/order-tc';
import { PrItemReleased } from '../../core/bean/pr-item-released';
import { Sow } from '../../core/bean/sow';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { OrderResponseRequest } from './order-response-request';
import { OrderResponseResponse } from './order-response-response';

@Component({
  templateUrl: './order-response-edit.component.html'
})
export class OrderResponseEditComponent
  extends BaseModuleComponent
  implements OnInit
{
  public token: string;
  public orderId: number;
  public orderStatus: OrderStatus;
  public orderCancellation: OrderCancellation;
  public urlBackOutside: string;
  public isShowBuyerNote: boolean;
  public order: Order;
  public itemTypeList: ItemType[] = [];
  public sowList: Sow[] = [];
  public prItemReleasedList: PrItemReleased[] = [];
  public prItemList: PrItem[] = [];
  public orderShippingItemList: OrderShippingItem[];
  public finesList: Fines[] = [];
  public guaranteeList: Guarantee[] = [];
  public vendorListEdit: Vendor[] =[];
  public orderActivityHistory: OrderActivityHistory;
  public orderResponseRequest: OrderResponseRequest =
    new OrderResponseRequest();
  public orderResponseResponse: OrderResponseResponse =
    new OrderResponseResponse();
  public orderActStatusOptionList: OptionListModel<OrderActivityStatus> =
    new OptionListModel(true);
  public addressOptionList: OptionListModel<AddressBook> = new OptionListModel(
    true,
    'addressDetail'
  );
  public tableResponseVendor: TableResponseModel<Vendor>;

  public tcTableResponse: TableResponseModel<OrderTc>;
  public orderActHistoryTableResponse: TableResponseModel<OrderActivityHistory>;
  public fileUploader: FileUploader = new FileUploader(
    '/order-response/',
    '',
    this.global.appConstant.fileType.DOC_ORDER
  );

  public tableResponseItem: TableResponseModel<PrItem>;

  public badgeColor = {
    WAITING_APPROVAL: 'WARNING',
    WAITING_CONFIRMATION: 'WARNING',
    VENDOR_REVISION: 'FEEDBACK',
    ON_PROGRESS: 'PROGRESS',
    DONE: 'SUCCESS',
    CANCELED: 'DANGER',
    REJECTED: 'DANGER',
    CLOSED: 'DANGER'
  };

  public getColorAndIconConfirm = {
    CONFIRMED: { color: 'text-success', icon: 'pic pi-thumbs-up-circle' },
    REVISED: { color: 'text-primary', icon: 'pic pi-pencil-circle' },
    REJECTED: { color: 'text-danger', icon: 'pic pi-times-circle' }
  };

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public activatedRoute: ActivatedRoute,
    public appPopupPrItemInfoService: AppPopupPrItemInfoService
  ) {
    super('order-response', translateService);
  }
  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Order>('/order-response/notification/' + this.token)
      .subscribe((order: Order) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('orderId', order.id);
        this.global.routerParams.set('orderStatus', order.orderStatus);
        this.global.routerParams.set('urlBackOutside', '/pages/order-response');
        if (
          order.orderStatus.code !==
          this.global.appConstant.pm.ORDER_STATUS_WAITING_CONFIRMATION
        ) {
          this.global.routerParams.set('todo', 'view');
          this.router.navigate(['/pages/order-response/detail']);
        } else {
          this.global.routerParams.set('todo', 'edit');
          this.router.navigate(['/pages/order-response/detail']);
        }
      });
  }

  public setInitializationState(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.buildTableResponseVendor();
    this.doBuildTcTableresponse();
    this.doBuildOrderActHistoryTableresponse();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.orderId = this.global.routerParams.get('orderId');
    this.orderStatus = this.global.routerParams.get('orderStatus');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      number: [null],
      title: [null],
      orderType: [null],
      requiredDate: [null],
      department: [null],
      description: [null],
      document: [null],
      orderActivityStatus: [null, Validators.required()],
      note: [null, Validators.required()],
      buyerNote: [null],
      addressList: [null]
    });
  }

  public buildTableResponseVendor(): void {
    this.tableResponseVendor = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.vendorName'
      },
      {
        field: 'email',
        header: 'table.column.email'
      },
      {
        field: 'address',
        header: 'table.column.address'
      },
      {
        field: 'phone',
        header: 'table.column.phone'
      }
    ]);
  }

  public doBuildTcTableresponse(): void {
    this.tcTableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'tcDetail.termType.name',
        header: 'tc.table.column.term',
        isSortable: false
      },
      {
        field: 'condition',
        header: 'tc.table.column.condition',
        plugins: 'html',
        isSortable: false
      }
    ]);
  }

  public doBuildOrderActHistoryTableresponse(): void {
    this.orderActHistoryTableResponse = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'processedByUser.name',
          header: 'orderActHistory.table.column.userName'
        },
        {
          field: 'processedDate',
          header: 'orderActHistory.table.column.date',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'note',
          header: 'orderActHistory.table.column.note'
        },
        {
          field: 'orderActivityStatus.name',
          header: 'orderActHistory.table.column.status',
          plugins: {
            name: 'badge',
            colorField: 'orderActivityStatus.code',
            colorMap: {
              SUBMITTED: 'SUCCESS',
              CONFIRMED: 'INFO',
              REVISED: 'FEEDBACK',
              RESEND: 'DANGER',
              REJECTED: 'DANGER',
              CANCELED: 'DANGER',
              CLOSED: 'DANGER'
            }
          }
        }
      ]
    );
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<OrderResponseResponse>(
        '/order-response/edit',
        new RouteRequestModel(this.todo, this.orderId)
      )
      .subscribe((response: OrderResponseResponse) => {
        this.orderResponseResponse = response;
        this.order = response.order;
        this.orderCancellation = response.orderCancellation;
        this.orderStatus = this.order.orderStatus;
        this.orderActStatusOptionList.setRequestValues(
          response.orderActivityStatusList
        );
        this.prItemList = response.prItemList;
        this.itemTypeList = response.itemTypeList;
        this.orderShippingItemList = response.orderShippingItemList;
        this.sowList = response.sowList;
        this.finesList = response.finesList || [];
        this.guaranteeList = response.guaranteeList || [];
        this.prItemReleasedList = response.prItemReleasedList;
        const fileList = [];
        response.orderDocList.map(orderDoc => fileList.push(orderDoc.file));
        this.fileUploader.setFileList(fileList);
        const addressList = [];
        response.orderShippingList.map(orderShipping =>
          addressList.push(orderShipping.address)
        );
        this.addressOptionList.setRequestValues(addressList);
        const buyerResendAct = response.orderActivityHistoryList.find(
          e =>
            e.orderActivityStatus.code ===
            this.global.appConstant.pm.ORDER_ACTIVITY_STATUS_RESEND
        );
        this.orderActivityHistory = response.orderActivityHistoryList.find(
          data =>
            data.orderActivityStatus.code ===
              this.global.appConstant.pm.ORDER_ACTIVITY_STATUS_CONFIRMED ||
            data.orderActivityStatus.code ===
              this.global.appConstant.pm.ORDER_ACTIVITY_STATUS_REVISED ||
            data.orderActivityStatus.code ===
              this.global.appConstant.pm.ORDER_ACTIVITY_STATUS_REJECTED
        );
        this.formGroup.patchValue({
          number: this.order.code,
          title: this.order.title,
          orderType: this.order.orderType,
          requiredDate: this.order.requiredDate,
          department: this.order.organization,
          description: this.order.description,
          document: this.fileUploader.fileObjectList,
          addressList: addressList,
          buyerNote: buyerResendAct ? buyerResendAct.note : null
        });

        if (this.todo === 'view' && this.orderActivityHistory) {
          this.formGroup.patchValue({
            note: this.orderActivityHistory.note,
            orderActivityStatus: this.orderActivityHistory.orderActivityStatus
          });
        }

        response.vendorAddressList.forEach(vendorAddress => {
          vendorAddress.vendor['address'] = vendorAddress.address;
          this.vendorListEdit.push(vendorAddress.vendor);
        });
        this.tableResponseVendor.setRecordList(this.vendorListEdit);
        this.tableResponseVendor.reload();
        this.buildTableResponse();
        response.orderTcList.forEach(orderTc => {
          if (!orderTc.condition) {
            orderTc.condition = orderTc.tcDetail.condition;
          }
        });
        this.isShowBuyerNote =
          buyerResendAct &&
          this.orderActivityHistory &&
          this.orderActivityHistory.orderActivityStatus.code !==
            this.global.appConstant.pm.ORDER_ACTIVITY_STATUS_CONFIRMED
            ? true
            : false;
        this.tcTableResponse.setRecordList(response.orderTcList);
        this.orderActHistoryTableResponse.setRecordList(
          response.orderActivityHistoryList
        );
        this.setStateReady();
        setTimeout(() => {
          this.setIsViewOnly();
        }, 1000);
      });
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    } else {
      this.formGroup.get('description').setIsView(true);
      this.formGroup.get('document').setIsView(true);
      this.formGroup.get('buyerNote').setIsView(true);
      this.formGroup.get('addressList').setIsView(true);
    }
  }

  public doViewItem(prItem: PrItem): void {
    this.appPopupPrItemInfoService.open(prItem.id, true);
  }

  public doSubmit(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const orderActivityHistory: OrderActivityHistory =
              new OrderActivityHistory();
            orderActivityHistory.note = this.formGroup.get('note').value;
            orderActivityHistory.orderActivityStatus = this.formGroup.get(
              'orderActivityStatus'
            ).value;
            const orderResponseRequest: OrderResponseRequest =
              new OrderResponseRequest();
            orderResponseRequest.order = this.order;
            orderResponseRequest.orderActivityHistory = orderActivityHistory;
            this.setStateProcessing();
            this.httpClientService
              .post<Response<Order>>(
                '/order-response/update',
                orderResponseRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService.submitSuccess().subscribe(result => {
                    if (result) {
                      this.router.navigate(['/pages/order-response']);
                    } else {
                      this.router
                        .navigateByUrl('/', { skipLocationChange: true })
                        .then(() => {
                          this.global.routerParams.clear();
                          this.global.routerParams.set('orderId', this.orderId);
                          this.global.routerParams.set(
                            'urlBackOutside',
                            '/pages/order-response'
                          );
                          this.global.routerParams.set('todo', 'view');
                          this.router.navigate([
                            '/pages/order-response/detail'
                          ]);
                        });
                    }
                  });
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public getTranslateKey(data: any): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public getUserNameList(addressPicList: AddressPic[]): string {
    return addressPicList.map(a => a.user.name).join(', ');
  }

  public buildTableResponse(): void {
    this.tableResponseItem = new TableResponseModel('pr', [
      {
        field: 'prItemImageList',
        header: 'table.column.thumbnail',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePluginData: TablePluginData): File => {
              if (tablePluginData.value && tablePluginData.value.length > 0) {
                return tablePluginData.value[0].file;
              }
              return null;
            }
          },
          {
            name: 'img',
            classNameMap: {
              MATERIAL: 'pvr pv-box',
              JASA: 'pvr pv-tools'
            },
            fieldClassName: 'item.itemType.code',
            onClick: this.doViewItemPrItem.bind(this)
          }
        ],
        isSortable: false,
        isSearchTable: false
      },
      {
        field: 'merk',
        header: 'table.column.itemName'
      },
      {
        field: 'code',
        header: 'table.column.prLine'
      },
      {
        field: 'catalog.catalogType.name',
        header: 'table.column.catalogType',
        plugins: {
          name: 'badge',
          className: 'badge-catalog',
          pill: false,
          colorMap: {
            ITEM: 'WARNING',
            VENDOR: 'INFO',
            CONTRACT: 'SUCCESS'
          },
          callbacks: {
            text: (tablePluginData: TablePluginData): string => {
              if (tablePluginData.row.record.catalog) {
                return this.translateService.instant(
                  'dynamic-master-attribute.' +
                    tablePluginData.row.record.catalog.catalogType
                      .translationKey.key
                );
              } else {
                return 'Item';
              }
            },
            color: (tablePluginData: TablePluginData): string => {
              if (tablePluginData.row.record.catalog) {
                if (
                  tablePluginData.row.record.catalog.catalogType.code ===
                  this.global.appConstant.vm.CATALOG_TYPE_VENDOR
                ) {
                  return 'VENDOR';
                } else if (
                  tablePluginData.row.record.catalog.catalogType.code ===
                  this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
                ) {
                  return 'CONTRACT';
                }
              } else {
                return 'ITEM';
              }
            }
          }
        }
      },
      {
        field: 'item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'item.itemType.code',
          callbacks: {
            text: (tablePluginData: TablePluginData): string => {
              if (
                tablePluginData.row.record.item.itemType.code ===
                this.global.appConstant.core.ITEM_TYPE_CODE_OBAT
              ) {
                return this.translateService.instant(
                  'dynamic-master-attribute.itemType.good'
                );
              } else if(tablePluginData.row.record.item.itemType.code ===
                this.global.appConstant.core.ITEM_TYPE_CODE_ALKES) {
                return this.translateService.instant(
                  'dynamic-master-attribute.itemType.service'
                );
              } else if (
                tablePluginData.row.record.item.itemType.parentCode ===
                this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
              ) {
                return this.translateService.instant(
                  'dynamic-master-attribute.itemType.good'
                );
              } else {
                return this.translateService.instant(
                  'dynamic-master-attribute.itemType.service'
                );
              }
            }
          }
        }
      },
      {
        field: 'workflowModelPrcs.name',
        header: 'table.column.stage'
      },
      {
        field: 'quantity',
        header: 'table.column.quantity',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        },
        className: 'text-right'
      },
      {
        field: 'price',
        header: 'table.column.price',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      },
      {
        field: 'totalPrice',
        header: 'table.column.totalPrice',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      }
    ]);


    this.tableResponseItem.setRecordList(
      this.orderResponseResponse.prItemList
    );
  }

  public doViewItemPrItem(prItem: PrItem): void {
    this.appPopupPrItemInfoService.open(prItem.id, true);
  }
}
