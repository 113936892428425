<app-layout
  [isLoading]="formLoading"
  header="dr.breadcrumb.index"
  [backTo]="urlBackOutside"
>
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge
      *ngIf="todo !== 'add'"
      class="cml-2"
      [color]="badgeColor[dr?.deliveryStatus.code]"
    >
      {{ getTranslateKey(dr?.deliveryStatus) | translate }}
    </app-badge>
  </ng-template>

  <app-alert-x
    *ngIf="response.orderCancellation"
    color="DANGER"
    [isBold]="false"
  >
    {{ 'dr.alert.msg.thisProcessWasCanceledBy' | translate }}
    <strong>{{ response.orderCancellation?.cancelByUser?.name }}</strong>
    {{ 'dr.alert.msg.at' | translate }}
    <strong>
      {{
        response.orderCancellation?.cancelDate
          | date: global.config.parameterModel.datetimeFormatShort
      }}
    </strong>
    {{ 'dr.alert.msg.theCancelationReasonIs' | translate }}
    <strong>&#34;{{ response.orderCancellation?.cancelNote }}&#34;</strong>
  </app-alert-x>

  <ng-container
    *ngIf="
      !formLoading &&
      dr.deliveryStatus?.code ===
        global.appConstant.pm.DELIVERY_STATUS_ON_PROGRESS
    "
  >
    <app-alert-x
      [isShowIcon]="false"
      color="DANGER"
      *ngIf="
        !formLoading &&
        response.daysContractExpiredSoon &&
        !response.orderCancellation
      "
    >
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="pvc pv-clipboard-x-red mr-3"></div>
          <label class="mb-0 text-danger"
            >{{
              'dr.detail.alert.contractExpireSoon.prefix' | translate
            }}&nbsp;{{ response.daysContractExpiredSoon }}&nbsp;{{
              'dr.detail.alert.contractExpireSoon.suffix' | translate
            }}</label
          >
        </div>
      </div>
    </app-alert-x>

    <app-alert-x
      [isShowIcon]="false"
      color="DANGER"
      *ngIf="
        !formLoading &&
        response.daysOrderExpiredSoon &&
        !response.orderCancellation
      "
    >
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="pvc pv-clipboard-x-red mr-3"></div>
          <label class="mb-0 text-danger"
            >{{ 'dr.detail.alert.orderExpireSoon.prefix' | translate }}&nbsp;{{
              response.daysOrderExpiredSoon
            }}&nbsp;{{
              'dr.detail.alert.orderExpireSoon.suffix' | translate
            }}</label
          >
        </div>
      </div>
    </app-alert-x>
  </ng-container>

  <div class="row main-row">
    <div class="col-lg-12">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        *ngIf="!formLoading"
        novalidate
      >
        <app-stepper [isLoading]="formLoading" position="HORIZONTAL">
          <app-stepper-item
            *ngFor="let sow of sowList; let i = index"
            lineStyle="dashed"
            width="max-content"
          >
            <ng-template #circle>
              <em
                class="pi-3x"
                [ngClass]="
                  sow.deliveryStatus
                    ? iconList[sow.deliveryStatus.code]
                    : iconList['NOT_STARTED']
                "
                style="background: white"
                [title]="sow.stageOfWork.name"
              ></em>
            </ng-template>
            <ng-template #contentTop>
              <label>{{ sow.stageOfWork.name }}</label>
            </ng-template>
            <ng-template #contentBottom>
              <app-badge [color]="badgeColor[sow.deliveryStatus.code]">
                {{
                  getTranslateKey(sow.deliveryStatus) | translate
                }} </app-badge
              ><br />
              <p style="margin-top: 3px">
                {{ getNumberOfDoneTerm(sow.sowPaymentTermList) }} of
                {{ sow.sowPaymentTermList.length }} Termin
              </p>
            </ng-template>
          </app-stepper-item>
        </app-stepper>
        <app-card header="dr.details.title" tips="dr.details.tips">
          <div class="form-group row text-sm-left" *ngIf="todo !== 'add'">
            <label class="col-sm-3">
              {{ 'dr.form.number' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.code }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'dr.form.registeredOnDistributor' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <label>
                {{ response?.organization?.name || '-' }}
              </label>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'dr.form.distributionLocationCode' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <label>
                {{ response?.organization?.name || '-' }}
              </label>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'dr.form.requestorDepartment' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <label>
                {{ response?.order?.organization?.name || '-' }}
              </label>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'dr.form.requestor' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <label>
                {{ response?.order?.userRequester?.name || '-' }}
              </label>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'dr.form.title' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.title }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'dr.form.orderType' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              {{
                formGroup.value.orderType
                  ? formGroup.value.orderType?.name
                  : formGroup.value.drType?.name
              }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'dr.form.requiredDate' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-date-picker-x
                formControlName="requiredDate"
                [minDate]="today"
              ></app-date-picker-x>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'dr.form.description' | translate }}
            </label>
            <div class="col-sm-6 text-left">
              {{ formGroup.value.description || '-' }}
            </div>
          </div>
        </app-card>

        <app-card
          header="pr-verification.detail.officialReport.title"
          tips="pr-verification.detail.officialReport.tips"
          *ngIf="response?.order?.id"
        >
        <app-official-report
          [objectId]="response?.order?.id"
          [templateCode]="global.appConstant.pm.ORT_ORDER_PURCHASE_REQUEST_LETTER"
          [appOfficialReportModel]="appOfficialReportModel"
          mode="view"
          numberGeneratorCode="NUMBER"
          [isShowButtonGenerate]="false"
          [include]="['subject', 'priority', 'location']"
        ></app-official-report>
        </app-card>

        <ng-container *ngIf="!formLoading">
          <app-card
            *ngFor="let sow of formGroup.value.sowList; let i = index"
            [header]="sow.stageOfWork.name"
            tips="dr.stageOfWork.tips{{ i }}"
          >
            <div
              class="form-group row"
              *ngIf="!formLoading"
              style="
                height: 40px;
                margin-left: 5px;
                margin-right: 5px;
                box-sizing: border-box;
                border-radius: 5px;
                background-color: #f3f5f4;
              "
            >
              <div style="margin-top: 1%; margin-bottom: 1%"></div>

              <div class="col-sm-4 text-center pt-2">
                {{ 'dr.stageOfWork.billingMethod' | translate }}
                <app-badge class="cml-2" color="DARK" [pill]="false">
                  {{ sow.sowPaymentTermList[0].billingMethod.name }}
                </app-badge>
              </div>

              <div
                style="
                  border-right: 1px solid var(--gray-400);
                  margin-top: 1%;
                  margin-bottom: 1%;
                "
              ></div>
              <div class="col-sm-4 text-center pt-2">
                {{ 'dr.stageOfWork.deliveryDueDate' | translate }}
                <app-badge class="cml-2" color="DANGER" [pill]="false">
                  {{
                    sow.endDate
                      | date: global.config.parameterModel.dateFormatShort
                  }}
                </app-badge>
              </div>
              <ng-container
                *ngIf="
                  !formLoading &&
                  sow.deliveryStatus.code ===
                    global.appConstant.pm.DELIVERY_STATUS_DONE
                "
              >
                <div
                  style="
                    border-right: 1px solid var(--gray-400);
                    margin-top: 1%;
                    margin-bottom: 1%;
                  "
                ></div>
                <div
                  class="col-sm-3 text-center pt-2"
                  style="min-width: fit-content"
                >
                  {{ 'dr.stageOfWork.realization' | translate }}
                  <app-badge class="cml-2" color="WARNING" [pill]="false">
                    {{ doGetRealizationDate(sow) }}
                  </app-badge>
                </div>
              </ng-container>
            </div>
            <app-stepper [isLoading]="formLoading" position="VERTICAL">
              <app-stepper-item
                *ngFor="
                  let sowPaymentTerm of sow.sowPaymentTermList;
                  let j = index
                "
                lineStyle="dashed"
              >
                <ng-template #circle>
                  <em
                    class="pi-3x"
                    [ngClass]="
                      sowPaymentTerm.deliveryStatus
                        ? iconList[sowPaymentTerm.deliveryStatus.code]
                        : iconList['NOT_STARTED']
                    "
                    style="background: white"
                    [title]="sowPaymentTerm.paymentTerm.name"
                  ></em>
                </ng-template>
                <ng-template #contentRight>
                  <div style="max-width: 80%">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        <h4> {{ sowPaymentTerm.paymentTerm.name }}</h4>
                        <app-badge
                          class="cml-2"
                          [color]="
                            sowPaymentTerm.deliveryStatus
                              ? badgeColor[sowPaymentTerm.deliveryStatus.code]
                              : badgeColor['NOT_STARTED']
                          "
                        >
                          {{
                            sowPaymentTerm.deliveryStatus
                              ? (getTranslateKey(sowPaymentTerm.deliveryStatus)
                                | translate)
                              : ('dynamic-master-attribute.deliveryStatus.notStarted'
                                | translate)
                          }}
                        </app-badge>
                      </div>
                      <app-button
                        *ngIf="
                          global.userSession?.activeUserRole.role.code ===
                          global.appConstant.ROLE_CODE_VENDOR
                        "
                        (onClick)="doRequestPayment(sowPaymentTerm)"
                        [disabled]="checkDisableReqPaymentBtn(sowPaymentTerm)"
                        class="float-right"
                        [ngStyle]="{ 'margin-bottom': '2%' }"
                      >
                        {{ 'dr.button.requestPayment' | translate }}
                      </app-button> </div
                    ><br />
                    <div class="row">
                      <div class="col-lg-12">
                        <app-tab [bordered]="true">
                          <app-table-xx
                            *ngIf="tableItemList[i][j]?.isGoodsItemEmpty"
                            [model]="tableItemList[i][j].tableResponseGoods"
                            stringUrl="/dr/table-goods"
                            header="{{ 'dr.table.goods' | translate }}"
                          >
                            <div style="margin-bottom: 15px">
                              <ng-template #alertWrapper>
                                <div
                                  class="table-item-{{
                                    global.appConstant.core
                                      .ITEM_TYPE_CODE_MATERIAL
                                  }}-{{ sowPaymentTerm.id }}"
                                  style="margin-bottom: 15px"
                                >
                                </div>
                              </ng-template>
                            </div>
                          </app-table-xx>
                          <app-table-xx
                            *ngIf="tableItemList[i][j]?.isServiceItemEmpty"
                            [model]="tableItemList[i][j].tableResponseService"
                            stringUrl="/dr/table-service"
                            header="{{ 'dr.table.service' | translate }}"
                          >
                            <div style="margin-bottom: 15px">
                              <ng-template #alertWrapper>
                                <div
                                  class="table-item-{{
                                    global.appConstant.core
                                      .ITEM_TYPE_CODE_SERVICE
                                  }}-{{ sowPaymentTerm.id }}"
                                  style="margin-bottom: 15px"
                                >
                                </div>
                              </ng-template>
                            </div>
                          </app-table-xx>
                          <ng-container
                            *ngIf="
                              !tableItemList[i][j]?.isServiceItemEmpty &&
                              !tableItemList[i][j]?.isGoodsItemEmpty
                            "
                          >
                            <div>
                              <div
                                class="pvc pv-truck-delivery"
                                style="
                                  height: 88.28px;
                                  width: 76.17px;
                                  margin: 0 auto;
                                "
                              ></div>
                              <div
                                style="
                                  text-align: center;
                                  padding: 10px 0;
                                  color: var(--gray-500);
                                  font-weight: 500;
                                  font-size: 12px;
                                "
                              >
                                <span>
                                  {{
                                    'dr.detail.noItemWilBeDelivered' | translate
                                  }}
                                </span>
                              </div>
                            </div>
                          </ng-container>
                        </app-tab>
                      </div>
                    </div>
                  </div>
                  <br />
                </ng-template>
              </app-stepper-item>
            </app-stepper>
          </app-card>
        </ng-container>

        <app-card
          header="dr.shippingAddress.title"
          tips="dr.shippingAddress.tips"
        >
          <br *ngIf="!formGroup.isView" />
          <br *ngIf="!formGroup.isView" />
          <app-select
            *ngIf="
              drShippingOptionList.getRequestValues() &&
              drShippingOptionList.getRequestValues().length > 0
            "
            formControlName="drShipping"
            [optionList]="drShippingOptionList"
            [isMultiple]="true"
            maxHeight="max-content"
            [isShowHover]="false"
          >
            <ng-template #content let-data>
              <div
                style="
                  border: 1px solid var(--gray-400);
                  border-radius: 5px;
                  box-sizing: border-box;
                  padding: 1rem;
                "
              >
                <p
                  >{{ data.address.organization.name }}
                  <app-badge-catalog
                    *ngIf="!data.id || data.isFromDr"
                    class="cml-2"
                    [color]="'INFO'"
                  >
                    {{ 'dr.status.new' | translate }}
                  </app-badge-catalog>
                </p>
                <h5>{{ getUserNameList(data.address.addressPicList) }}</h5>
                <p>{{ data.address.addressDetail }}</p>
                <p>{{ data.address.phone }}</p>

                <div
                  class="py-1"
                  style="border-top: 1px dashed var(--gray-400)"
                >
                </div>
                <div class="button-group button-group-right">
                  <app-button
                    *ngIf="
                      (!data.id || data.isFromDr) &&
                      !formGroup.isView &&
                      global.userSession?.activeUserRole.role.code !==
                        global.appConstant.ROLE_CODE_DELIVERY
                    "
                    (onClick)="doDeleteShipping(data)"
                    color="DANGER"
                    [outline]="true"
                  >
                    {{ 'dr.button.deleteAddress' | translate }}
                  </app-button>
                  <app-button (onClick)="doViewItem(data)">
                    {{ 'dr.button.viewItem' | translate }}
                  </app-button>
                </div>
              </div>
            </ng-template>
          </app-select>
        </app-card>
      </form>
    </div>
  </div>

  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
