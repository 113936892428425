import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Faq } from '../../core/bean/faq';
import { UserFaqResponse } from './user-faq.response';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { HttpClient } from '@angular/common/http';
import { TablePaginationModel } from 'src/app/core/components/app-table/model/table-pagination-model';
@Component({
  templateUrl: './user-faq.component.html'
})
export class UserFaqComponent extends BaseModuleComponent {
  public model: TableResponseModel<any>;
  public faq: string;
  public faqList: Faq[] = [];
  public keyword: FormControl = new FormControl(null);
  public groupText: FormControl = new FormControl(null);
  public groupTextList: OptionListModel<any> = new OptionListModel(
    true
  );
  public pagination: TablePaginationModel = new TablePaginationModel();
  private debounceTimer: any;
  constructor(translate: TranslateService, private http: HttpClient) {
    super('user-faq', translate);
  }

  public onInit(): void {
    this.doSetOption();
    this.setFaqState();
  }

  public doSetOption(): void {
    this.httpClientService
      .get<UserFaqResponse>('/user-faq/get-option-list-filter', {})
      .subscribe(optionListFilter => {
        this.groupTextList.setRequestValues(optionListFilter.groupTextList);
      });
  }

  public doResetSearch(): void {
    this.keyword.reset();
    this.doInputKeyword();
  }

  public doInputKeyword(): void {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.setFaqState();
    }, 1000);
  }

  public setFaqState(page: number = 1): void {
    this.pagination.setCurrentPage(page);

    const params = {
      page: this.pagination.page,
      first: (page - 1) * 10,
      rows: 10,
      customData: JSON.stringify({
        keyword: this.keyword.value,
        groupText: this.groupText.value
      }),
      globalFilter: "",
    };

    this.http.post<{ records: Faq[], totalRecords: number }>('/user-faq/index', params)
      .subscribe(response => {
        this.faqList = response.records;
        this.pagination.setTotalRecords(response.totalRecords);
        this.setStateReady();
      });
  }

  public goToPage(page: number): void {
    this.setFaqState(page);
  }
}
