import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { ClaimStatus } from 'src/app/core/bean/claim-status';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableResponseModel } from 'src/app/core/components/app-table-x/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { ClaimViewPaginationBean } from 'src/app/core/view/entity/bean/claim-view-pagination';
import { ClaimVerificationOptionResponse } from './claim-verification-option.response';

@Component({
  templateUrl: './claim-verification.component.html'
})
export class ClaimVerificationComponent extends BaseModuleComponent implements OnInit {
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  public filter: string;
  public tableResponse: TableResponseModel<ClaimViewPaginationBean>;
  public claimStatusOptionList: OptionListModel<ClaimStatus> = new OptionListModel(
    true
  );
  public hospitalNameOptionList: OptionListModel<string> =
    new OptionListModel(true);
  private localStorageKey = 'claimVerificationFilter' + this.global.userSession.user.id;

  constructor(translateService: TranslateService,private datePipe: DatePipe) {
    super('claim-verification', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.setOptionList();
    this.buildFormGroup();
    this.doBuildTableResponse();
    this.loadFilterFromLocalStorage();
    this.doSetCustomData();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    this.filter = this.global.routerParams.get('filter');
    this.global.routerParams.clear();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      claimStatusList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<ClaimVerificationOptionResponse>('/claim-verification/get-option-list')
      .subscribe((claimVerificationOptionResponse: ClaimVerificationOptionResponse) => {
        this.claimStatusOptionList.setRequestValues(claimVerificationOptionResponse.claimStatusList);
        this.hospitalNameOptionList.setRequestValues(
          claimVerificationOptionResponse.hospitalNameList
        );
      });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'hospitalCode',
        header: 'table.column.hospitalCode'
      },
      {
        field: 'hospitalName',
        header: 'table.column.hospitalName'
      },
      {
        field: 'accidentVictim',
        header: 'table.column.accidentVictim'
      },
      {
        field: 'glNumber',
        header: 'table.column.glCode',
        plugins: {
          name: 'href',
          field: 'uuid',
          url: '/pages/claim-verification/detail/tab/',
          onClick: this.doClickTab.bind(this)
        }
      },
      {
        field: 'glDate',
        header: 'table.column.glDate',
        plugins: 'date'
      },
      {
        field: 'verificatorName',
        header: 'table.column.verificator'
      },
      {
        field: 'claimStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          field: 'claimStatus.code',
          colorMap: {
            REVISION: 'FEEDBACK',
            WAITING_MANUAL_VERIFICATION: 'WARNING',
            WAITING_FIRST_VERIFICATION: 'WARNING',
            WAITING_SECOND_VERIFICATION: 'WARNING',
            DONE: 'SUCCESS',
            RESEND_FILE: 'DANGER',
            RESEND_INFORMATION: 'DANGER',
            REJECT: 'DARK'
          }
        }
      },
      {
        field: 'glStatusName',
        header: 'table.column.glStatus',
        plugins: {
          name: 'badge',
          field: 'glStatusCode',
          colorMap: {
            0: 'DANGER',
            1: 'SUCCESS'
          }
        }
      }
    ]);
  }

  private loadFilterFromLocalStorage(): void {
    const savedFilter = localStorage.getItem(this.localStorageKey);
    if (savedFilter) {
      const filterValues = JSON.parse(savedFilter);
      this.formGroup.patchValue(filterValues);
      for (const controlName in this.formGroup.controls) {
        if (this.formGroup.controls.hasOwnProperty(controlName)) {
          const control = this.formGroup.get(controlName);
          const value = control.value;
          if (Array.isArray(value) && value.length === 0) {
            control.setValue(null);
          }
        }
      }
      this.tableResponse.setCustomData(this.formGroup.value);
      this.tableResponse.reload();
      this.formGroup.get('fromDate').reset();
      this.formGroup.get('untilDate').reset();
    }
  }

  public doSetCustomData(): void {
    if (this.filter) {
      const customData = {
        statusCode: this.filter
      };
      this.global.routerParams.clear();
      this.tableResponse.setCustomData(customData);
      this.tableResponse.reload();
    }
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      const fromDate = this.datePipe.transform(this.formGroup.value.date.from, 'yyyy-MM-dd');
      const untilDate = this.datePipe.transform(this.formGroup.value.date.to, 'yyyy-MM-dd');

      this.formGroup.patchValue({
        fromDate: fromDate,
        untilDate: untilDate
      });
    }
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.formGroup.value));
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
    this.formGroup.get('fromDate').reset();
    this.formGroup.get('untilDate').reset();
  }

  public doClickTab(claimView: ClaimViewPaginationBean): void {
    const claim = claimView;
    window.open('/pages/claim-verification/detail/tab/' + claim.uuid);
  }

  public doClick(claimView: ClaimViewPaginationBean): void {
    var claim = claimView;
    this.global.routerParams.clear();
    this.global.routerParams.set('claimId', claim.id);
    this.global.routerParams.set('claimStatus', claim.claimStatus);
    this.global.routerParams.set('urlBackOutside', '/pages/claim-verification/');
    if (claim.claimStatus.code != this.global.appConstant.pm.CLAIM_STATUS_WAITING_MANUAL_VERIFICATION) {
      this.global.routerParams.set('todo', 'view');
    } else {
      this.global.routerParams.set('todo', 'detail');
    }
    this.router.navigate(['/pages/claim-verification/detail']);
  }
}
