import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { File } from 'src/app/core/bean/file';
import { UserManual } from 'src/app/core/bean/user-manual';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { FileUploader } from 'src/app/core/components/upload';

@Component({
  templateUrl: './app-popup-user-manual.component.html'
})
export class AppPopupUserManualComponent extends BaseModuleComponent {
  @Input() userManualList: UserManual[];
  @Output() onChange: EventEmitter<UserManual[]> = new EventEmitter();
  public tableResponse: TableResponseModel<UserManual>;
    public fileUploader: FileUploader = new FileUploader(
        '/user-manual/',
        '',
        this.global.appConstant.fileType.DOC_USER_MANUAL
      );
      constructor(
        public translate: TranslateService,
        public appPopupService: AppPopupService,
        public activeModal: NgbActiveModal,
        public formBuilder: FormBuilder,
        public toastService: ToastService
      ) {
        super('user-monitoring', translate);
      }
    
      public onInit(): void {
        this.buildFormGroup(); 
        this.setFormGroup(); 
        this.setStateReady(); 
      }
    
      public buildFormGroup(): void {
        this.formGroup = this.formBuilder.group({
          userManualList: this.formBuilder.array([])
        });
      }
    
      public setFormGroup(): void {
        const userManualArray = this.formGroup.get('userManualList') as FormArray;
    
        this.userManualList.forEach((userManual) => {
          this.fileUploader.setFile(userManual.file);
          userManualArray.push(
            this.formBuilder.group({
              title: [userManual.title],
              type: [userManual.userManualType],
              link: [userManual.link || null],
              fileObjectList: [this.fileUploader.fileObjectList || null],
              isView: [true]
            })
          );
        });
        this.formArrayUserManualList.controls.forEach( control => {
          control.setIsView(true);
        })
      }
    
      public get formArrayUserManualList(): FormArray {
        return this.formGroup.get('userManualList') as FormArray;
      }

      public doDownloadUserManual(file:File): void {
              if (file != null) {
                const stringUrl =
                  '/user-manual' +
                  '/file/view/' +
                  file.uploadedFileName +
                  '/' +
                  this.global.appConstant.fileType.DOC_USER_MANUAL;
                this.downloadService.download(
                  stringUrl,
                  file.fileName
                );
              } else {
                this.toastService.showError(
                  this.global.translateService.instant('user-manual.fileNotExist')
                );
              }
      }
    }