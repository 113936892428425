import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Claim } from 'src/app/core/bean/claim';
import { File } from 'src/app/core/bean/file';
import { AppDatePickerComponent } from 'src/app/core/components/app-date-picker/app-date-picker.component';
import { Response } from 'src/app/core/model/response-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { Validators } from 'src/app/core/validators';
import { AppOfficialReportRequest } from '../../core/components/app-official-report/app-official-report.request';
import { OptionListModel } from '../../core/model/option-list-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { EncryptService } from '../../core/services/encrypt.service';
import { AuthorityLetterRequest } from './authority-letter.request';
import { AuthorityLetterResponse } from './authority-letter.response';
import { AppModalConfirmDialogComponent } from 'src/app/core/components/app-modal/app-modal-confirm-dialog/app-modal-confirm-dialog.component';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { Verificator } from 'src/app/core/bean/verificator';
import { Currency } from 'src/app/core/bean/currency';
import { AuthorityLetter } from 'src/app/core/bean/authority-letter';

@Component({
  styleUrls: ['./authority-letter-edit-add.component.scss'],
  templateUrl: './authority-letter-edit-add.component.html'
})
export class AuthorityLetterEditAddComponent extends BaseModuleComponent implements OnInit {

  @ViewChildren(AppDatePickerComponent) datePickers!: QueryList<AppDatePickerComponent>;

  public claimId: number;
  public authorityLetterId: number;
  public addressHospital: string;
  public description: string;
  public authorityLetterResponse: AuthorityLetterResponse = new AuthorityLetterResponse();
  public urlBackOutside: string;
  public genderList: OptionListModel<any> = new OptionListModel();
  public authorityList: OptionListModel<any> = new OptionListModel();
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(true);
  public isDisable = false;
  public authorityLetterRequest: AuthorityLetterRequest = new AuthorityLetterRequest();
  public authorityLetter: AuthorityLetter = new AuthorityLetter();
  public claim: Claim = new Claim();
  public verificator: Verificator = new Verificator();
  public isTemplateDownloaded: boolean;
  public isFormValid = true;
  public statusCode: string;
  public startDate: Date;
  public endDate: Date;
  public isHideValid = true;

  constructor(translateService: TranslateService,
    public encryptService: EncryptService,
    public appPopupService: AppPopupService) {
    super('authority-letter', translateService);
  }
  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.setFormGroup();
    this.setIsViewOnlyFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.claimId = this.global.routerParams.get('claimId');
    this.addressHospital = this.global.routerParams.get('addressHospital');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
    this.statusCode = this.global.routerParams.get('statusCode');
    if (this.statusCode) {
      if (this.statusCode !== this.global.appConstant.pm.CLAIM_STATUS_AUTHORITY_LETTER) {
        this.todo = 'view';
      } else {
        this.todo = 'detail';
      }
    }
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      acceptGlStatus: [null],
      accidentLocation: [null, Validators.required()],
      treatmentFromDate: [null, Validators.required()],
      treatmentToDate: [null, Validators.required()],
      totalRequestAmount: [null, Validators.required()],
      firstPartyName: [null, Validators.required()],
      firstPartyGender: [null, Validators.required()],
      firstPartyDobDate: [null, Validators.required()],
      firstPartyPlaceOfBirth: [null, Validators.required()],
      firstPartyAddress: [null, Validators.required()],
      firstPartyNik: [null, Validators.required()],
      firstPartyPhone: [null, Validators.required()],
      firstPartyAuthority: [null, Validators.required()],
      secondPartyName: [null, Validators.required()],
      secondPartyNik: [null, Validators.required()],
      secondPartyPosition: [null, Validators.required()],
      secondPartyRepresent: [null, Validators.required()],
      currentDate: new Date(),
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<AuthorityLetterResponse>(
        '/authority-letter/edit',
        new RouteRequestModel(this.todo, this.claimId)
      )
      .subscribe((authorityLetterResponse: AuthorityLetterResponse) => {
        this.authorityLetterResponse = authorityLetterResponse;
        this.claim = authorityLetterResponse.claim;
        this.genderList.setRequestValues(authorityLetterResponse.genderList);
        this.authorityList.setRequestValues(authorityLetterResponse.authorityList);
        this.verificator = authorityLetterResponse.verificator;
        this.currencyOptionList.setRequestValues(authorityLetterResponse.currencyList);
        if (authorityLetterResponse.authorityLetter) {
          this.authorityLetter = authorityLetterResponse.authorityLetter;
          this.formGroup.patchValue({
            id: this.authorityLetter.id,
            acceptGlStatus: true,
            accidentLocation: this.authorityLetter.accidentLocation,
            treatmentFromDate: this.authorityLetter.treatmentFromDate,
            treatmentToDate: this.authorityLetter.treatmentToDate,
            totalRequestAmount: { price: this.authorityLetter.totalRequestAmount },
            firstPartyName: this.authorityLetter.firstPartyName,
            firstPartyGender: this.authorityLetter.firstPartyGender ? this.genderList.getRequestValues().filter(valueOption => valueOption.id === this.authorityLetter.firstPartyGender.id)[0] : null,
            firstPartyDobDate: this.authorityLetter.firstPartyDobDate,
            firstPartyPlaceOfBirth: this.authorityLetter.firstPartyPlaceOfBirth,
            firstPartyAddress: this.authorityLetter.firstPartyAddress,
            firstPartyNik: this.authorityLetter.firstPartyNik,
            firstPartyPhone: this.authorityLetter.firstPartyPhone,
            firstPartyAuthority: this.authorityLetter.firstPartyAuthority ? this.authorityList.getRequestValues().filter(
              valueOption => valueOption.id === this.authorityLetter.firstPartyAuthority.id)[0] : null,
            secondPartyName: this.authorityLetter.secondPartyName,
            secondPartyNik: this.authorityLetter.secondPartyNik,
            secondPartyPosition: this.authorityLetter.secondPartyPosition,
            secondPartyRepresent: this.authorityLetter.secondPartyRepresent,
          });
        }
        this.setStateReady();
      });
  }


  public doGenerate(event: any): void {
    const appOfficialReportRequest = new AppOfficialReportRequest();
    appOfficialReportRequest.letterDate = new Date();
    appOfficialReportRequest.auth = this.encryptService.encrypt([
      this.authorityLetterResponse.authorityLetter.id.toString(),
      'ORT_AUTHORITY_LETTER',
      'NUMBER'
    ]);

    this.loadingBlockService.showInfo(`app.msg.processing`);
    this.httpClientService
      .post<Response<File>>('/authority-letter/generate', appOfficialReportRequest)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          const file: File = response.body;
          this.doDownload(
            event,
            '/authority-letter/file/view/' +
            file.uploadedFileName +
            '/' +
            file.fileType.code,
            file.fileName
          );
          this.isTemplateDownloaded = true;
          this.loadingBlockService.close();
        }
      });
  }
  public doBack(): void {
    this.router.navigate(['/pages/authority-letter']);
  }

  public doSubmit(code: string): void {
    this.doUpdateValidator(code);
    this.validate();
    if (code === null) {
      var acceptGlStatus = this.formGroup.get('acceptGlStatus').value;
      if (acceptGlStatus) {
        this.isHideValid = true;
        this.global.modalService
          .submitConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {

              this.setStateProcessing();
              this.httpClientService
                .post<Response<Claim>>('/authority-letter/accept-gl', this.claimId)
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.appPopupService.open(AppModalConfirmDialogComponent, {
                      prompt: 'app.confirmation.saveSuccess.acceptGlDescription',
                      title: 'app.confirmation.saveSuccess.acceptGlTitle',
                      icon: 'pvc pv-check-circle',
                      isSingleButton: true,
                      buttonRight: 'app.button.ok'
                    },
                      { size: 'md' })
                      .subscribe(result => {
                        if (result) {
                          this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(() => {
                              this.global.routerParams.clear();
                              this.global.routerParams.set('claimId', this.claimId);
                              this.global.routerParams.set('todo', 'detail');
                              this.global.routerParams.set('addressHospital', this.addressHospital);
                              this.router.navigate(['/pages/authority-letter/detail']);
                            });
                        }
                      });
                  } else {
                    this.global.alertService.showError(
                      response.statusText
                    );
                    this.setStateReady();
                  }
                });
            }
          });
      } else {
        this.isHideValid = false;
      }
    } else if (code === this.global.appConstant.pm.CLAIM_STATUS_AUTHORITY_LETTER && this.formGroup.valid) {
      const value = this.formGroup.value;
      this.authorityLetterRequest.statusCode = code;
      this.authorityLetterRequest.claim = this.authorityLetterResponse.claim;
      this.authorityLetter.accidentLocation = value.accidentLocation;
      this.authorityLetter.treatmentFromDate = value.treatmentFromDate;
      this.authorityLetter.treatmentToDate = value.treatmentToDate;
      this.authorityLetter.totalRequestAmount = +value.totalRequestAmount?.price;
      this.authorityLetter.firstPartyName = value.firstPartyName;
      this.authorityLetter.firstPartyGender = value.firstPartyGender;
      this.authorityLetter.firstPartyDobDate = value.firstPartyDobDate;
      this.authorityLetter.firstPartyPlaceOfBirth = value.firstPartyPlaceOfBirth;
      this.authorityLetter.firstPartyAddress = value.firstPartyAddress;
      this.authorityLetter.firstPartyNik = value.firstPartyNik;
      this.authorityLetter.firstPartyPhone = value.firstPartyPhone;
      this.authorityLetter.firstPartyAuthority = value.firstPartyAuthority;
      this.authorityLetter.secondPartyName = value.secondPartyName;
      this.authorityLetter.secondPartyNik = value.secondPartyNik;
      this.authorityLetter.secondPartyPosition = value.secondPartyPosition;
      this.authorityLetter.secondPartyRepresent = value.secondPartyRepresent;
      this.authorityLetterRequest.authorityLetter = this.authorityLetter;

      this.setStateProcessing();
      this.httpClientService
        .post<Response<AuthorityLetter>>('/authority-letter/insert', this.authorityLetterRequest)
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.global.modalService
              .submitSuccess()
              .pipe(take(1))
              .subscribe(result => {
                if (result) {
                  this.router.navigate(['/pages/authority-letter/']);
                } else {
                  this.router
                    .navigateByUrl('/', { skipLocationChange: true })
                    .then(() => {
                      this.global.routerParams.clear();
                      this.global.routerParams.set('claimId', this.claimId);
                      this.global.routerParams.set('statusCode', code);
                      this.global.routerParams.set('addressHospital', this.addressHospital);
                      this.router.navigate(['/pages/authority-letter/detail']);
                    });
                }
              });
          } else {
            this.global.alertService.showError(
              response.statusText
            );
            this.setStateReady();
          }
        });
    } else if (code === this.global.appConstant.pm.CLAIM_STATUS_SUBMIT_CLAIM && this.formGroup.valid) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const value = this.formGroup.value;
            this.authorityLetterRequest.statusCode = code;
            this.authorityLetterRequest.claim = this.authorityLetterResponse.claim;
            this.authorityLetter.accidentLocation = value.accidentLocation;
            this.authorityLetter.treatmentFromDate = value.treatmentFromDate;
            this.authorityLetter.treatmentToDate = value.treatmentToDate;
            this.authorityLetter.totalRequestAmount = +value.totalRequestAmount?.price;
            this.authorityLetter.firstPartyName = value.firstPartyName;
            this.authorityLetter.firstPartyGender = value.firstPartyGender;
            this.authorityLetter.firstPartyDobDate = value.firstPartyDobDate;
            this.authorityLetter.firstPartyPlaceOfBirth = value.firstPartyPlaceOfBirth;
            this.authorityLetter.firstPartyAddress = value.firstPartyAddress;
            this.authorityLetter.firstPartyNik = value.firstPartyNik;
            this.authorityLetter.firstPartyPhone = value.firstPartyPhone;
            this.authorityLetter.firstPartyAuthority = value.firstPartyAuthority;
            this.authorityLetter.secondPartyName = value.secondPartyName;
            this.authorityLetter.secondPartyNik = value.secondPartyNik;
            this.authorityLetter.secondPartyPosition = value.secondPartyPosition;
            this.authorityLetter.secondPartyRepresent = value.secondPartyRepresent;
            this.authorityLetterRequest.authorityLetter = this.authorityLetter;

            this.setStateProcessing();
            this.httpClientService
              .post<Response<AuthorityLetter>>('/authority-letter/insert', this.authorityLetterRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccess()
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router.navigate(['/pages/authority-letter/']);
                      } else {
                        this.router
                          .navigateByUrl('/', { skipLocationChange: true })
                          .then(() => {
                            this.global.routerParams.clear();
                            this.global.routerParams.set('claimId', this.claimId);
                            this.global.routerParams.set('statusCode', code);
                            this.global.routerParams.set('addressHospital', this.addressHospital);
                            this.router.navigate(['/pages/authority-letter/detail']);
                          });
                      }
                    });
                } else {
                  this.global.alertService.showError(
                    response.statusText
                  );
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doUpdateValidator(code: string) {
    if (code === this.global.appConstant.pm.CLAIM_STATUS_AUTHORITY_LETTER) {
      this.formGroup.get('accidentLocation').clearValidators();
      this.formGroup.get('treatmentFromDate').clearValidators();
      this.formGroup.get('treatmentToDate').clearValidators();
      this.formGroup.get('totalRequestAmount').clearValidators();
      this.formGroup.get('firstPartyName').clearValidators();
      this.formGroup.get('firstPartyGender').clearValidators();
      this.formGroup.get('firstPartyDobDate').clearValidators();
      this.formGroup.get('firstPartyPlaceOfBirth').clearValidators();
      this.formGroup.get('firstPartyAddress').clearValidators();
      this.formGroup.get('firstPartyNik').clearValidators();
      this.formGroup.get('firstPartyPhone').clearValidators();
      this.formGroup.get('firstPartyAuthority').clearValidators();
      this.formGroup.get('secondPartyName').clearValidators();
      this.formGroup.get('secondPartyNik').clearValidators();
      this.formGroup.get('secondPartyPosition').clearValidators();
      this.formGroup.get('secondPartyRepresent').clearValidators();

    } else if (code === this.global.appConstant.pm.CLAIM_STATUS_SUBMIT_CLAIM) {
      this.formGroup.get('accidentLocation').setValidators(Validators.required());
      this.formGroup.get('treatmentFromDate').setValidators(Validators.required());
      this.formGroup.get('treatmentToDate').setValidators(Validators.required());
      this.formGroup.get('totalRequestAmount').setValidators(Validators.required());
      this.formGroup.get('firstPartyName').setValidators(Validators.required());
      this.formGroup.get('firstPartyGender').setValidators(Validators.required());
      this.formGroup.get('firstPartyDobDate').setValidators(Validators.required());
      this.formGroup.get('firstPartyPlaceOfBirth').setValidators(Validators.required());
      this.formGroup.get('firstPartyAddress').setValidators(Validators.required());
      this.formGroup.get('firstPartyNik').setValidators(Validators.required());
      this.formGroup.get('firstPartyPhone').setValidators(Validators.required());
      this.formGroup.get('firstPartyAuthority').setValidators(Validators.required());
      this.formGroup.get('secondPartyName').setValidators(Validators.required());
      this.formGroup.get('secondPartyNik').setValidators(Validators.required());
      this.formGroup.get('secondPartyPosition').setValidators(Validators.required());
      this.formGroup.get('secondPartyRepresent').setValidators(Validators.required());

    }
    this.formGroup.get('accidentLocation').updateValueAndValidity();
    this.formGroup.get('treatmentFromDate').updateValueAndValidity();
    this.formGroup.get('treatmentToDate').updateValueAndValidity();
    this.formGroup.get('totalRequestAmount').updateValueAndValidity();
    this.formGroup.get('firstPartyName').updateValueAndValidity();
    this.formGroup.get('firstPartyGender').updateValueAndValidity();
    this.formGroup.get('firstPartyDobDate').updateValueAndValidity();
    this.formGroup.get('firstPartyPlaceOfBirth').updateValueAndValidity();
    this.formGroup.get('firstPartyAddress').updateValueAndValidity();
    this.formGroup.get('firstPartyNik').updateValueAndValidity();
    this.formGroup.get('firstPartyPhone').updateValueAndValidity();
    this.formGroup.get('firstPartyAuthority').updateValueAndValidity();
    this.formGroup.get('secondPartyName').updateValueAndValidity();
    this.formGroup.get('secondPartyNik').updateValueAndValidity();
    this.formGroup.get('secondPartyPosition').updateValueAndValidity();
    this.formGroup.get('secondPartyRepresent').updateValueAndValidity();
  }

  public getTranslateKey(data: any): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public doCheck(event: any): void {
    this.formGroup.get('acceptGlStatus').setValue((event.target as HTMLInputElement).checked);
    this.formGroup;
  }

}
