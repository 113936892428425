import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RecapPrintService } from 'src/app/core/services/recap-print.service';
import { Faq } from 'src/app/core/bean/faq';
import { FaqType } from 'src/app/core/bean/faq-type';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { MasterFaqResponse } from './master-faq.response';
@Component({
  templateUrl: './master-faq.component.html'
})
export class MasterFaqComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Faq>;
  public isLoading: boolean;
  public faqTypeList: OptionListModel<FaqType> = new OptionListModel(
    true
  );
  public groupTextList: OptionListModel<any> = new OptionListModel(
    true
  );
  constructor(translateService: TranslateService, public recapPrintService: RecapPrintService,) {
    super('master-faq', translateService);
  }

  public onInit(): void {
    this.doBuildFormGroup();
    this.doBuildTableResponse();
    this.doSetOption();
    this.setStateReady();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      faqType: [null],
      groupText: [null]
    });
  }

  public doSetOption(): void {
    this.httpClientService
      .get<MasterFaqResponse>('/master-faq/get-option-list-filter', {})
      .subscribe(optionListFilter => {
        this.faqTypeList.setRequestValues(optionListFilter.faqTypeList);
        this.groupTextList.setRequestValues(optionListFilter.groupTextList);
      });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'faqType.name', header: 'table.column.type' },
      {
        field: 'groupText',
        header: 'table.column.groupText'
      },
      { field: 'question', header: 'table.column.question' },
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/master-faq/add']);
  }

  public doEdit(faq: Faq): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('faqCode', faq.code);
    this.router.navigate(['/pages/master-faq/edit']);
  }

  public doDelete(faq: Faq): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<Faq>>('/master-faq/delete', faq)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doOnChangeFilter(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

}
