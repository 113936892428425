import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { ClaimStatus } from 'src/app/core/bean/claim-status';
import { AppModalConfirmDialogComponent } from 'src/app/core/components/app-modal/app-modal-confirm-dialog/app-modal-confirm-dialog.component';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableRequestModel } from 'src/app/core/components/app-table-x/model/table-request-model';
import { TableResponseModel } from 'src/app/core/components/app-table-x/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { ClaimUserViewPaginationBean } from 'src/app/core/view/entity/bean/claim-user-view-pagination';
import { Counter } from '../../core/bean/counter';
import { Region } from '../../core/bean/region';
import { Verificator } from '../../core/bean/verificator';
import { ClaimViewPaginationBean } from '../../core/view/entity/bean/claim-view-pagination';
import { AccidentVictimOptionResponse } from './accident-victim-option.response';

@Component({
  templateUrl: './accident-victim.component.html'
})
export class AccidentVictimComponent
  extends BaseModuleComponent
  implements OnInit {
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  public filter: string;
  public tableResponse: TableResponseModel<ClaimViewPaginationBean>;
  public tableResponseUser: TableResponseModel<ClaimUserViewPaginationBean>;
  public claimStatusOptionList: OptionListModel<ClaimStatus> =
    new OptionListModel(true);
  public hospitalNameOptionList: OptionListModel<String> = new OptionListModel(
    true
  );
  public areaOptionList: OptionListModel<Region> = new OptionListModel(true);
  public counterOptionList: OptionListModel<Counter> = new OptionListModel(
    true
  );
  public verificatorOptionList: OptionListModel<Verificator> =
    new OptionListModel(true);
  public fromDate: String;
  public untilDate: String;
  private localStorageKey = 'accidentVictimFilter' + this.global.userSession.user.id;

  constructor(translateService: TranslateService, public appPopupService: AppPopupService, private datePipe: DatePipe) {
    super('accident-victim', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.setOptionList();
    this.buildFormGroup();
    this.doBuildTableResponse();
    this.loadFilterFromLocalStorage();
    this.doSetCustomData();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    this.filter = this.global.routerParams.get('filter');
    this.global.routerParams.clear();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      claimStatusList: [null],
      hospitalNameList: [null],
      counterList: [null],
      verificatorList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<AccidentVictimOptionResponse>('/claim/get-option-list')
      .subscribe(
        (accidentVictimOptionResponse: AccidentVictimOptionResponse) => {
          this.claimStatusOptionList.setRequestValues(
            accidentVictimOptionResponse.claimStatusList
          );
          this.hospitalNameOptionList.setRequestValues(
            accidentVictimOptionResponse.hospitalNameList
          );
          this.areaOptionList.setRequestValues(
            accidentVictimOptionResponse.areaList
          );
          this.counterOptionList.setRequestValues(
            accidentVictimOptionResponse.counterList
          );
          this.verificatorOptionList.setRequestValues(
            accidentVictimOptionResponse.verificatorList
          );
        }
      );
  }

  public doBuildTableResponse(): void {
    if (this.global.userSession.activeUserRole.role.code == this.global.appConstant.ROLE_CODE_EXECUTOR) {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'hospitalName',
          header: 'table.column.hospitalName'
        },
        {
          field: 'kantorJR',
          header: 'table.column.office'
        },
        {
          field: 'accidentVictim',
          header: 'table.column.accidentVictim'
        },
        {
          field: 'glNumber',
          header: 'table.column.insuranceId'
        },
        {
          field: 'idJaminan',
          header: 'table.column.glCode'
        },
        {
          field: 'glDate',
          header: 'table.column.glDate',
          plugins: 'date'
        },
        {
          field: 'verificatorName',
          header: 'table.column.verificator'
        },
        {
          field: 'glStatusName',
          header: 'table.column.glStatus',
          plugins: {
            name: 'badge',
            field: 'glStatusCode',
            colorMap: {
              0: 'DANGER',
              1: 'SUCCESS'
            }
          }
        },
        {
          field: 'claimStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            field: 'claimStatus.code',
            colorMap: {
              NEW: 'INFO',
              REVISION: 'FEEDBACK',
              WAITING_MANUAL_VERIFICATION: 'WARNING',
              WAITING_FIRST_VERIFICATION: 'WARNING',
              WAITING_SECOND_VERIFICATION: 'WARNING',
              DONE: 'SUCCESS',
              RESEND_FILE: 'DANGER',
              RESEND_INFORMATION: 'DANGER',
              REJECT: 'DARK',
              DRAFT: 'SECONDARY',
              SUBMIT_CLAIM: 'INFO',
              UPLOAD_RECEIPT: 'SERVICE',
              AUTHORITY_LETTER: 'GOOD'
            }
          }
        },

        {
          field: 'statusAccidentVictimName',
          header: 'table.column.statusClaim',
          plugins: {
            name: 'badge',
            field: 'statusAccidentVictimCode',
            colorMap: {
              NEW: 'INFO',
              DRAFT: 'SECONDARY',
              DONE: 'SUCCESS'
            }
          }
        },
        {
          field: 'counterName',
          header: 'table.column.counter'
        }
      ]);
    } else {
      this.tableResponseUser = new TableResponseModel(this.moduleCode, [
        {
          field: 'hospitalName',
          header: 'table.column.hospitalName'
        },
        {
          field: 'kantorJR',
          header: 'table.column.office'
        },
        {
          field: 'accidentVictim',
          header: 'table.column.accidentVictim'
        },
        {
          field: 'glNumber',
          header: 'table.column.insuranceId'
        },
        {
          field: 'idJaminan',
          header: 'table.column.glCode'
        },
        {
          field: 'glDate',
          header: 'table.column.glDate',
          plugins: 'date'
        },
        {
          field: 'verificatorName',
          header: 'table.column.verificator'
        },
        {
          field: 'glStatusName',
          header: 'table.column.glStatus',
          plugins: {
            name: 'badge',
            field: 'glStatusCode',
            colorMap: {
              0: 'DANGER',
              1: 'SUCCESS'
            }
          }
        },
        {
          field: 'claimStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            field: 'claimStatus.code',
            colorMap: {
              NEW: 'INFO',
              REVISION: 'FEEDBACK',
              WAITING_MANUAL_VERIFICATION: 'WARNING',
              WAITING_FIRST_VERIFICATION: 'WARNING',
              WAITING_SECOND_VERIFICATION: 'WARNING',
              DONE: 'SUCCESS',
              RESEND_FILE: 'DANGER',
              RESEND_INFORMATION: 'DANGER',
              REJECT: 'DARK',
              DRAFT: 'SECONDARY',
              SUBMIT_CLAIM: 'INFO',
              UPLOAD_RECEIPT: 'SERVICE',
              AUTHORITY_LETTER: 'GOOD'
            }
          }
        },

        {
          field: 'statusAccidentVictimName',
          header: 'table.column.statusClaim',
          plugins: {
            name: 'badge',
            field: 'statusAccidentVictimCode',
            colorMap: {
              NEW: 'INFO',
              DRAFT: 'SECONDARY',
              DONE: 'SUCCESS'
            }
          }
        },
        {
          field: 'counterName',
          header: 'table.column.counter'
        }
      ]);
    }
  }

  private loadFilterFromLocalStorage(): void {
    const savedFilter = localStorage.getItem(this.localStorageKey);
    if (savedFilter) {
      const filterValues = JSON.parse(savedFilter);
      this.formGroup.patchValue(filterValues);
      for (const controlName in this.formGroup.controls) {
        if (this.formGroup.controls.hasOwnProperty(controlName)) {
          const control = this.formGroup.get(controlName);
          const value = control.value;
          if (Array.isArray(value) && value.length === 0) {
            control.setValue(null);
          }
        }
      }
      if (this.global.userSession.activeUserRole.role.code == this.global.appConstant.ROLE_CODE_EXECUTOR) {
        this.tableResponse.setCustomData(this.formGroup.value);
        this.tableResponse.reload();
      } else {
        this.tableResponseUser.setCustomData(this.formGroup.value);
        this.tableResponseUser.reload();
      }
      this.formGroup.get('fromDate').reset();
      this.formGroup.get('untilDate').reset();
    }
  }

  public doSetCustomData(): void {
    if (this.filter) {
      const customData = {
        statusCode: this.filter
      };
      this.global.routerParams.clear();
      if (this.global.userSession.activeUserRole.role.code == this.global.appConstant.ROLE_CODE_EXECUTOR) {
        this.tableResponse.setCustomData(customData);
        this.tableResponse.reload();
      } else {
        this.tableResponseUser.setCustomData(customData);
        this.tableResponseUser.reload();
      }
    }
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      const fromDate = this.datePipe.transform(this.formGroup.value.date.from, 'yyyy-MM-dd');
      const untilDate = this.datePipe.transform(this.formGroup.value.date.to, 'yyyy-MM-dd');

      this.formGroup.patchValue({
        fromDate: fromDate,
        untilDate: untilDate
      });
      this.fromDate = this.formGroup.get('fromDate').value;
      this.untilDate = this.formGroup.get('untilDate').value;
    } else if (!this.formGroup.value.date) {
      this.fromDate = null;
      this.untilDate = null;
    }
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.formGroup.value));
    if (this.global.userSession.activeUserRole.role.code == this.global.appConstant.ROLE_CODE_EXECUTOR) {
      this.tableResponse.setCustomData(this.formGroup.value);
      this.tableResponse.reload();
    } else {
      this.tableResponseUser.setCustomData(this.formGroup.value);
      this.tableResponseUser.reload();
    }
    this.formGroup.get('fromDate').reset();
    this.formGroup.get('untilDate').reset();
  }

  public doPrintReport(): void {
    if (this.table.state.pagination.totalRecords <= this.global.config.parameterModel.maxDataPrintReport) {
      this.loadingBlockService.showInfo(
        this.translateService.instant('app.info.preparingDownload')
      );
      if (this.global.userSession.activeUserRole.role.code == this.global.appConstant.ROLE_CODE_EXECUTOR) {
        this.httpClientService
          .post<TableRequestModel>('/accident-victim/print-report-verificator', this.table.state.tableRequest, {
            responseType: 'arrayBuffer' as 'json'
          })
          .subscribe(response => {
            const file = new File([response], 'JRCARE - FORM KESEHATAN REPORT.xlsx', {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = url;
            a.download = file.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.loadingBlockService.showCompleteAndClose(
              this.translateService.instant('app.info.successDownloaded')
            );
          });
      } else {
        this.httpClientService
          .post<TableRequestModel>('/accident-victim/print-report-user', this.table.state.tableRequest, {
            responseType: 'arrayBuffer' as 'json'
          })
          .subscribe(response => {
            const file = new File([response], 'JRCARE - FORM KESEHATAN REPORT.xlsx', {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = url;
            a.download = file.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.loadingBlockService.showCompleteAndClose(
              this.translateService.instant('app.info.successDownloaded')
            );
          });
      }
    } else {
      this.appPopupService.open(AppModalConfirmDialogComponent, {
        prompt: this.translateService.instant('app.infromation.print.report.prompt.one') + this.global.config.parameterModel.maxDataPrintReport + this.translateService.instant('app.infromation.print.report.prompt.two'),
        title: 'app.infromation.print.report.title',
        icon: 'pvc pv-exclamation-triangle-alert',
        isSingleButton: true,
        buttonRight: 'app.button.ok'
      },
        { size: 'md' });

    }
  }

  public doClick(claimViewPaginationBean: ClaimViewPaginationBean): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('claimId', claimViewPaginationBean.id);
    this.global.routerParams.set(
      'addressHospital',
      claimViewPaginationBean.addressHospital
    );
    this.global.routerParams.set('urlBackOutside', '/pages/accident-victim/');
    const isUser =
      this.global.userSession.activeUserRole.role.code ===
        this.global.appConstant.ROLE_CODE_USER
        ? true
        : false;
    if (
      (claimViewPaginationBean.statusAccidentVictimCode ===
        this.global.appConstant.pm.ACCIDENT_VICTIM_STATUS_NEW ||
        claimViewPaginationBean.statusAccidentVictimCode ===
        this.global.appConstant.pm.ACCIDENT_VICTIM_STATUS_DRAFT) &&
      isUser && claimViewPaginationBean.glStatusCode === this.global.appConstant.pm.GL_STATUS_ACTIVE
      && (claimViewPaginationBean.claimStatus.code === this.global.appConstant.pm.CLAIM_STATUS_SUBMIT_CLAIM || claimViewPaginationBean.claimStatus.code === this.global.appConstant.pm.CLAIM_STATUS_REVISION)
      && claimViewPaginationBean.claimStatus.code !== this.global.appConstant.pm.CLAIM_STATUS_AUTHORITY_LETTER
    ) {
      this.global.routerParams.set('todo', 'detail');
    } else {
      this.global.routerParams.set('todo', 'view');
    }
    this.router.navigate(['/pages/accident-victim/detail']);
  }
}
