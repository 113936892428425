import { Authority } from "src/app/core/bean/authority";
import { Claim } from "../../core/bean/claim";
import { Gender } from "../../core/bean/gender";
import { AuthorityLetter } from "src/app/core/bean/authority-letter";
import { Verificator } from "src/app/core/bean/verificator";
import { Currency } from "src/app/core/bean/currency";

export class AuthorityLetterResponse {
    authorityLetter: AuthorityLetter;
    claim: Claim;
    genderList: Gender[];
    authorityList: Authority[];
    verificator: Verificator;
    currencyList: Currency[];
}