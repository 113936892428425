<div class="portal-faq">
  <div
    class="promise-container"
    style="max-width: 1024px; padding: 20px 0px; margin: 0 auto"
  >
    <app-card header="portal.menu.faq" [isLoading]="formLoading">
      <ng-template #customLoading>
        <div class="promise-loading">
          <div class="loading"></div>
        </div>
      </ng-template>

      <div class="faq-content">
        <div class="custom-search mb-4 d-flex justify-content-between">
          <div class="col-sm-8 p-0">
            <em class="pir pi-search"></em>
            <input
              type="text"
              class="form-control"
              placeholder="Search Question"
              [formControl]="keyword"
              (input)="doInputKeyword()"
            />
            <em class="pir pi-times" (click)="doResetSearch()"></em>
          </div>

          <div class="app-table-filter-item col-sm-3 p-0">
            <app-combo-box
              size="XL"
              [formControl]="groupText"
              [optionList]="groupTextList"
              (onChange)="setFaqState()"
            ></app-combo-box>
          </div>
        </div>

        <app-accordion [isShowMultiple]="true">
          <app-accordion-item
            *ngFor="let qa of faqList"
            [header]="qa.question"
            customClassHeader="bg-white"
            customClassBody="bg-light"
            [groupText]="qa.groupText"
          >
            <div
              [innerHTML]="qa.answer"
              [ngStyle]="{
                'white-space': 'normal',
                'max-width': '1000px',
                'word-break': 'break-word'
              }"
            ></div>
          </app-accordion-item>
        </app-accordion>

        <div
          class="d-flex py-5 align-items-center flex-column"
          *ngIf="!formLoading && faqList.length === 0"
        >
          <div class="pvc pv-search pv-1x mb-3"></div>
          <p>{{ 'app.text.noResultsFor' | translate }} "{{ keyword.value }}"</p>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12" *ngIf="pagination.totalRecords > 0">
          <app-pagination
            [model]="pagination"
            (onChange)="goToPage($event)"
          ></app-pagination>
        </div>
      </div>
    </app-card>
  </div>

  <!-- <div class="promise-container-right sticky">
    <app-card>
      <h4 class="text-center" style="color: var(--primary-dark)">
        Can't find the right answer? Ask us!
      </h4>
      <form class="mt-4" [formGroup]="formGroup">
        <div class="form-group">
          <label for="name">Name</label>
          <app-text-field formControlName="name" size="XL"></app-text-field>
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <app-text-field formControlName="email" size="XL"></app-text-field>
        </div>
        <div class="form-group">
          <label for="question">Question</label>
          <app-text-field formControlName="question" size="XL"></app-text-field>
          <small class="d-block mt-2">
            We will reply the answer to your email
          </small>
        </div>
        <div class="button-group full-button">
          <app-button (onClick)="doSendQuestion()">Send Question</app-button>
        </div>
      </form>
    </app-card>
  </div> -->
</div>
