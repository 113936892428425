import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { CatalogAttributeGroup } from '../bean/catalog-attribute-group';
import { CatalogStatus } from '../bean/catalog-status';
import { CatalogType } from '../bean/catalog-type';
import { Currency } from '../bean/currency';
import { File } from '../bean/file';
import { Item } from '../bean/item';
import { Uom } from '../bean/uom';
import { Vendor } from '../bean/vendor';

export class CatalogEntity extends BaseWorkflowObjectEntity {
    categoryNameList: string[] = [];
    fileImageList: File[] = [];
    regionName: string;
    priceCatalog: string;

    currency: Currency;
    catalogUom: Uom;
    weightUom: Uom;
    lengthUom: Uom;
    widthUom: Uom;
    heightUom: Uom;
    catalogAttributeGroup: CatalogAttributeGroup = new CatalogAttributeGroup();
    catalogStatus: CatalogStatus;
    vendor: Vendor;
    item: Item;
    catalogType: CatalogType;
    name: string;
    description: string;
    price: number;
    priceInIdr: number;
    weight: number;
    length: number;
    width: number;
    height: number;
    submissionDate: Date;
}
