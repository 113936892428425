import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppApprovalPrcsXModule } from "src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module";
import { SharedModule } from "src/app/core/shared/shared.module";
import { ConfirmGuard } from "../../core/guard/confirm.guard";
import { AuthorityLetterEditAddComponent } from "./authority-letter-edit-add.component";
import { AuthorityLetterComponent } from "./authority-letter.component";

const routes: Routes = [
  { path: '', component: AuthorityLetterComponent, data: { breadcrumb: '' } },
  {
    path: 'detail',
    component: AuthorityLetterEditAddComponent,
    data: { breadcrumb: 'authority-letter.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes), AppApprovalPrcsXModule
  ],
  declarations: [
    AuthorityLetterEditAddComponent,
    AuthorityLetterComponent
  ],
  providers: []
})
export class AuthorityLetterModule { }