import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { PatientConditionEditAddComponent } from './patient-condition-edit-add.component';
import { PatientConditionComponent } from './patient-condition.component';
const routes: Routes = [
  { path: '', component: PatientConditionComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: PatientConditionEditAddComponent,
    data: { breadcrumb: 'patient-condition.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: PatientConditionEditAddComponent,
    data: { breadcrumb: 'patient-condition.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [PatientConditionComponent, PatientConditionEditAddComponent]
})
export class PatientConditionModule { }
