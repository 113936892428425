import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AddressBook } from 'src/app/core/bean/address-book';
import { ItemType } from 'src/app/core/bean/item-type';
import { PrItem } from 'src/app/core/bean/pr-item';
import { PrShipping } from 'src/app/core/bean/pr-shipping';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';
import { AppTableComponent } from 'src/app/core/components/table/components/app-table/app-table.component';
import { AppTableModule } from 'src/app/core/components/table/components/app-table/app-table.module';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AddressPic } from '../../core/bean/address-pic';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { OrderStatus } from 'src/app/core/bean/order-status';
import { Order } from 'src/app/core/bean/order';
import { PrPoVerificationResponse } from './pr-po-verification.response';
import { PrPoVerification } from 'src/app/core/bean/pr-po-verification';
import { PrPoVerificationRequest } from './pr-po-verification.request';
import { AppPopupPrItemInfoService } from 'src/app/core/components/app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { OrderShipping } from 'src/app/core/bean/order-shipping';
import { Sow } from 'src/app/core/bean/sow';
import { OrderShippingItem } from 'src/app/core/bean/order-shipping-item';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
@Component({
  templateUrl: './pr-po-verification-edit.component.html'
})
export class PrPoVerificationEditComponent extends BaseModuleComponent {
  public orderId: number;
  public prPoVerificationResponse: PrPoVerificationResponse =
    new PrPoVerificationResponse();
  public order: Order = new Order();
  public orderStatusOptionList: OptionListModel<OrderStatus> = new OptionListModel();
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel();
  @ViewChild(AppTableModule) public table: AppTableComponent;
  @ViewChild('selectorItem') tableItem: AppTableComponent;
  @ViewChild('selectorVendorReference')
  tableVendorReference: AppTableComponent;
  // public tableResponseVendorReference: TableResponseModel<VendorView>;
  public tableResponseItem: TableResponseModel<PrItem>;
  @ViewChild('selectorShipping') tableShipping: AppTableComponent;
  public tableResponseShipping: TableResponseModel<PrShipping>;
  public addressBookOptionList: OptionListModel<any> = new OptionListModel(
    true,
    'addressDetail'
  );
  public orderStatus: OrderStatus = new OrderStatus();
  public token: string;
  public urlBackOutside: string;
  public workloadPrcsId: number;
  public objectId: number;
  public itemType: FormControl = new FormControl('');
  public appOfficialReportModel: AppOfficialReportModel;
  public isUpdated: boolean;
  public isOfficialReportNull: boolean;
  public approvalModelPrcsId: number;


  //===================
  public orderShippingOptionList: OptionListModel<OrderShipping> =
    new OptionListModel(true, 'address.address');
  public amount: number;
  public goodsAmount: number;
  public serviceAmount: number;
  public sowList: Sow[] = [];
  public orderShippingItemList: OrderShippingItem[] = [];
  public addressBookList: AddressBook[] = [];
  public orderShippingList: OrderShipping[] = [];
  public isSourceReady = false;
  public orderShipping: OrderShipping;
  public prItemList: PrItem[];


  constructor(
    translateService: TranslateService,
    public appPopupPrItemInfoService: AppPopupPrItemInfoService,
    public activatedRoute: ActivatedRoute,
    public appPopupService: AppPopupService,
  ) {
    super('pr-verification', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Order>('/order-verification/notification/' + this.token)
      .subscribe((order: Order) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('orderId', order.id);
        this.global.routerParams.set('orderStatus', order.orderStatus);
        this.global.routerParams.set(
          'urlBackOutside',
          '/pages/order-verification'
        );
        if (
          order.orderStatus.code === this.global.appConstant.pm.ORDER_STATUS_REVISION_VERIFICATION ||
          order.orderStatus.code === this.global.appConstant.pm.ORDER_STATUS_VERIFIED_VERIFICATION
        ) {
          this.global.routerParams.set('todo', 'view');
          this.router.navigate(['/pages/order-verification/detail']);
        } else {
          this.global.routerParams.set('todo', 'edit');
          this.router.navigate(['/pages/order-verification/edit']);
        }
      });
  }

  public setInitializationState(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.orderId = this.global.routerParams.get('orderId');
    this.orderStatus = this.global.routerParams.get('orderStatus');
    this.workloadPrcsId = this.global.routerParams.get('workloadPrcsId');
    this.objectId = this.global.routerParams.get('objectId');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
    if (this.objectId) {
      this.orderId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
  }
  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }
  public setOptionModel(): void {
    this.orderStatusOptionList.setRequestValues(
      this.prPoVerificationResponse.orderStatusList
    );
  }
  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      orderStatus: [null, Validators.compose([Validators.required()])],
      note: [
        null,
        Validators.compose([Validators.required(), , Validators.maxLength(512)])
      ],
      documentList: [null],
      orderShipping: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<PrPoVerificationResponse>(
        '/pr-po-verification/edit',
        new RouteRequestModel(this.todo, this.orderId)
      )
      .subscribe((prPoVerificationResponse: PrPoVerificationResponse) => {
        this.prPoVerificationResponse = prPoVerificationResponse;
        this.order = prPoVerificationResponse.order;
        this.sowList = prPoVerificationResponse.sowList;
        this.orderStatus = this.order.orderStatus;
        this.prItemList = prPoVerificationResponse.prItemList;
        if (
          this.prPoVerificationResponse.prPoVerification &&
          this.todo === 'view'
        ) {
          this.formGroup.patchValue({
            id: this.prPoVerificationResponse.prPoVerification.id,
            orderStatus: this.prPoVerificationResponse.prPoVerification.orderStatus,
            note: this.prPoVerificationResponse.prPoVerification.note
          });
        }
        if (this.prPoVerificationResponse.prPoVerification) {
          this.formGroup.patchValue({
            id: this.prPoVerificationResponse.prPoVerification.id
          });
        }
        this.appOfficialReportModel =
          prPoVerificationResponse.appOfficialReportModel;
        const addressList: AddressBook[] = [];
        this.prPoVerificationResponse.orderShippingList.forEach(orderShipping => {
          addressList.push(orderShipping.address);
        });
        // const shipping = this.prPoVerificationResponse.orderShippingList;
        this.addressBookOptionList.setRequestValues(addressList);
        this.formGroup.get('orderShipping').patchValue({
          addressList
        });
        this.formGroup
          .get('documentList')
          .patchValue(
            this.prPoVerificationResponse.appUploadDocumentTableModelList
          );
        this.formGroup.get('documentList').setIsView(true);
        this.setStateReady();
        this.formGroup.get('orderShipping').setIsView(true);
        this.buildTableResponse();
        this.setOptionModel();
      });
  }

  public buildTableResponse(): void {
    this.tableResponseItem = new TableResponseModel('pr', [
      {
        field: 'prItemImageList',
        header: 'table.column.thumbnail',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePluginData: TablePluginData): File => {
              if (tablePluginData.value && tablePluginData.value.length > 0) {
                return tablePluginData.value[0].file;
              }
              return null;
            }
          },
          {
            name: 'img',
            classNameMap: {
              MATERIAL: 'pvr pv-box',
              JASA: 'pvr pv-tools'
            },
            fieldClassName: 'item.itemType.code',
            onClick: this.doViewItem.bind(this)
          }
        ],
        isSortable: false,
        isSearchTable: false
      },
      {
        field: 'merk',
        header: 'table.column.itemName'
      },
      {
        field: 'code',
        header: 'table.column.prLine'
      },
      {
        field: 'catalog.catalogType.name',
        header: 'table.column.catalogType',
        plugins: {
          name: 'badge',
          className: 'badge-catalog',
          pill: false,
          colorMap: {
            ITEM: 'WARNING',
            VENDOR: 'INFO',
            CONTRACT: 'SUCCESS'
          },
          callbacks: {
            text: (tablePluginData: TablePluginData): string => {
              if (tablePluginData.row.record.catalog) {
                return this.translateService.instant(
                  'dynamic-master-attribute.' +
                    tablePluginData.row.record.catalog.catalogType
                      .translationKey.key
                );
              } else {
                return 'Item';
              }
            },
            color: (tablePluginData: TablePluginData): string => {
              if (tablePluginData.row.record.catalog) {
                if (
                  tablePluginData.row.record.catalog.catalogType.code ===
                  this.global.appConstant.vm.CATALOG_TYPE_VENDOR
                ) {
                  return 'VENDOR';
                } else if (
                  tablePluginData.row.record.catalog.catalogType.code ===
                  this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
                ) {
                  return 'CONTRACT';
                }
              } else {
                return 'ITEM';
              }
            }
          }
        }
      },
      {
        field: 'item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'item.itemType.code',
          callbacks: {
            text: (tablePluginData: TablePluginData): string => {
              if (
                tablePluginData.row.record.item.itemType.code ===
                this.global.appConstant.core.ITEM_TYPE_CODE_OBAT
              ) {
                return this.translateService.instant(
                  'dynamic-master-attribute.itemType.good'
                );
              } else if(tablePluginData.row.record.item.itemType.code ===
                this.global.appConstant.core.ITEM_TYPE_CODE_ALKES) {
                return this.translateService.instant(
                  'dynamic-master-attribute.itemType.service'
                );
              } else if (
                tablePluginData.row.record.item.itemType.parentCode ===
                this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
              ) {
                return this.translateService.instant(
                  'dynamic-master-attribute.itemType.good'
                );
              } else {
                return this.translateService.instant(
                  'dynamic-master-attribute.itemType.service'
                );
              }
            }
          }
        }
      },
      {
        field: 'workflowModelPrcs.name',
        header: 'table.column.stage'
      },
      {
        field: 'quantity',
        header: 'table.column.quantity',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        },
        className: 'text-right'
      },
      {
        field: 'price',
        header: 'table.column.price',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      },
      {
        field: 'totalPrice',
        header: 'table.column.totalPrice',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      }
    ]);


    this.tableResponseItem.setRecordList(
      this.prPoVerificationResponse.prItemList
    );
  }

  public doViewItem(prItem: PrItem): void {
    this.appPopupPrItemInfoService.open(prItem.id, true);
  }

  public doSetAmount(): void {
    let goodsAmountTotal = 0;
    let serviceAmountTotal = 0;
    this.prPoVerificationResponse.prItemList.forEach(prItem => {
      if (
        prItem.item.itemType.parentCode ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      ) {
        goodsAmountTotal = +goodsAmountTotal + +prItem.totalPrice;
      }
      if (
        prItem.item.itemType.parentCode ===
        this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
      ) {
        serviceAmountTotal = +serviceAmountTotal + +prItem.totalPrice;
      }
    });
    this.formGroup.patchValue({
      goodsAmount: goodsAmountTotal,
      serviceAmount: serviceAmountTotal,
      amount: +goodsAmountTotal + +serviceAmountTotal
    });
  }

  public onChangeItem(): void {
    const itemType = this.itemType.value;
    if (itemType) {
      const prItemList = [];
      this.prPoVerificationResponse.prItemList.forEach((prItem: PrItem) => {
        if (prItem.item.itemType.id === itemType.id) {
          prItemList.push(prItem);
        }
      });
      this.tableResponseItem.setRecordList(prItemList);
    } else {
      this.tableResponseItem.setRecordList(
        this.prPoVerificationResponse.prItemList
      );
    }
    this.tableResponseItem.reload();
    this.doSetAmount();
  }

  public doSubmit(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(results => {
          if (results) {
            this.setStateProcessing();
            const prPoVerificationRequest: PrPoVerificationRequest =
              new PrPoVerificationRequest();
            const prPoVerification: PrPoVerification =
              this.global.copyFormAttributeToModel(
                new PrPoVerification(),
                this.formGroup
              );
            prPoVerificationRequest.prPoVerification = prPoVerification;
            prPoVerificationRequest.order = this.prPoVerificationResponse.order;
            prPoVerificationRequest.isSubmit = true;
            prPoVerificationRequest.catalogList = this.prPoVerificationResponse.catalogList;
            this.httpClientService
              .post<Response<PrPoVerification>>(
                '/pr-po-verification/update',
                prPoVerificationRequest
              )
              .subscribe(response => {
                this.setStateReady();
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccess()
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router.navigate(['/pages/pr-po-verification/']);
                      } else {
                        this.router
                          .navigateByUrl('/', { skipLocationChange: true })
                          .then(() => {
                            this.global.routerParams.clear();
                            this.global.routerParams.set('orderId', this.orderId);
                            this.global.routerParams.set(
                              'orderStatus',
                              this.formGroup.get('orderStatus').value
                            );
                            this.global.routerParams.set('todo', 'view');
                              this.router.navigate([
                                '/pages/pr-po-verification/detail'
                              ]);
                          });
                      }
                    });
                } else {
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public getUserNameList(addressPicList: AddressPic[]): string {
    return addressPicList.map(a => a.user.name).join(', ');
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
          '.' +
          data.translationKey.key || '-'
      );
    }
  }
}
