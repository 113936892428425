<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="patient-condition.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'patient-condition.form.code' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              formControlName="code"
              type="code"
              maxLength="32"
              tooltip="{{ 'patient-condition.tooltip.code' | translate }}"
            >
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'patient-condition.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="name" maxLength="255">
            </app-text-field>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
            >{{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">{{
            'app.button.save' | translate
          }}</app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
