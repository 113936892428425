import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppDropdownSelectModule } from 'src/app/core/components/app-dropdown-select/app-dropdown-select.module';
import { SharedModule } from '../../core/shared/shared.module';
import { CategoriesComponent } from './categories/categories.component';
import { GeneralComponent } from './general/general.component';
import { ImageComponent } from './image/image.component';
import { InventoryComponent } from './inventory/inventory.component';
import { PopupRegionBulkPriceItemComponent } from './item-contract-detail-edit/popup/app-popup-region-bulk-price-item.component';
import { ManageCatalogTabComponent } from './manage-catalog-tab.component';
import { ShippingCostComponent } from './shipping-cost/shipping-cost.component';
import { SpecificationComponent } from './specification/specification.component';
import { VerificationEditComponent } from './verification/verification-edit.component';
import { ItemContractDetailXtComponent } from './item-contract-detail-edit/item-contract-detail-xt.component';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
const routes = [
  {
    path: '',
    component: ManageCatalogTabComponent,
    data: { breadcrumb: 'Manage Catalog' },
    children: [
      {
        path: 'general',
        component: GeneralComponent,
        data: { breadcrumb: 'manage-catalog.breadcrumb.general' }
      },
      {
        path: 'item-contract-detail',
        component: ItemContractDetailXtComponent,
        data: { breadcrumb: 'manage-catalog.breadcrumb.itemContractDetail' }
      },
      {
        path: 'shipping-cost',
        component: ShippingCostComponent,
        data: { breadcrumb: 'manage-catalog.breadcrumb.shippingCost' }
      },
      {
        path: 'inventory',
        component: InventoryComponent,
        data: { breadcrumb: 'manage-catalog.breadcrumb.inventory' }
      },
      {
        path: 'image',
        component: ImageComponent,
        data: { breadcrumb: 'manage-catalog.breadcrumb.image' }
      },
      {
        path: 'specification',
        component: SpecificationComponent,
        data: { breadcrumb: 'manage-catalog.breadcrumb.specification' }
      },
      {
        path: 'verification',
        component: VerificationEditComponent,
        data: { breadcrumb: 'manage-catalog.breadcrumb.verification' }
      }
    ]
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppDropdownSelectModule,
    AppApprovalPrcsXModule
  ],
  declarations: [
    GeneralComponent,
    CategoriesComponent,
    ShippingCostComponent,
    ItemContractDetailXtComponent,
    PopupRegionBulkPriceItemComponent,
    InventoryComponent,
    ImageComponent,
    SpecificationComponent,
    ManageCatalogTabComponent,
    VerificationEditComponent
  ]
})
export class ManageCatalogTabModule { }
