import { CatalogShippingCost } from './../../core/bean/catalog-shipping-cost';
import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Catalog } from '../../core/bean/catalog';
import { CatalogAttributeGroup } from '../../core/bean/catalog-attribute-group';
import { CatalogCategory } from '../../core/bean/catalog-category';
import { CatalogGroupTemplate } from '../../core/bean/catalog-group-template';
import { CatalogImage } from '../../core/bean/catalog-image';
import { CatalogInventory } from '../../core/bean/catalog-inventory';
import { ItemCategory } from '../../core/bean/item-category';
import { ManageCatalogTab } from '../../core/bean/manage-catalog-tab';
import { Module } from '../../core/bean/module';
import { Region } from '../../core/bean/region';
import { TranslationKey } from '../../core/bean/translation-key';
import { FormObjectModel } from '../../core/components/app-form-object/model/form-object-model';
import { FileObject } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { TabModel } from '../../core/model/tab/tab-model';
import { TabResponseModel } from '../../core/model/tab/tab-response-model';
import { Validators } from '../../core/validators';
import { ManageCatalogDTO } from './dto/manage-catalog.dto';
import { ActivatedRoute } from '@angular/router';
import { CatalogRegion } from 'src/app/core/bean/catalog-region';
import { AppPopupManageCatalogTOCService } from './app-popup-manage-catalog-toc/app-popup-manage-catalog-toc.service';
import { CatalogAttributeService } from './catalog-attribute-service';
import { Item } from 'src/app/core/bean/item';
@Component({
  selector: 'app-manage-catalog',
  templateUrl: './manage-catalog-tab.component.html',
  styleUrls: ['./manage-catalog-tab.component.scss']
})
export class ManageCatalogTabComponent extends BaseModuleComponent {
  public tabResponse: TabResponseModel;
  public manageCatalogDTO: ManageCatalogDTO = new ManageCatalogDTO();
  public vendorId: number;
  public catalogId: number;
  public isTodoView: boolean;
  public catalog: Catalog = new Catalog();
  public catalogInventory: CatalogInventory = new CatalogInventory();
  public catalogInventoryList: CatalogInventory[] = [];
  public catalogShippingCost: CatalogShippingCost = new CatalogShippingCost();
  public catalogShippingCostList: CatalogShippingCost[] = [];
  public catalagCategoryList: CatalogCategory[] = [];
  public catalogCategory: CatalogCategory = new CatalogCategory();
  public itemCategoryList: ItemCategory[] = [];
  public regionLIst: Region[] = [];
  public inventoryList: FormGroup;
  public shippingCostList: FormGroup;
  public catalogImageList: CatalogImage[] = [];
  public isGuest = false;
  public optionList: OptionListModel<CatalogAttributeGroup> = new OptionListModel();
  public isViewFormObject = false;
  public formObjectModel: FormObjectModel<CatalogGroupTemplate>;
  public generalTab: TabModel;
  public header: string = null;
  public token: string;
  public itemContractDetailTab: TabModel;
  public isActiveItem: boolean;
  public isVendor: boolean;

  public readonly urlInsertManageCatalog = '/manage-catalog/insert';
  public readonly urlUpdateManageCatalog = '/manage-catalog/update';

  constructor(translate: TranslateService,
    public activatedRoute: ActivatedRoute,
    public appPopUpManageCatalogTocService: AppPopupManageCatalogTOCService,
    private catalogAttributeService: CatalogAttributeService) {
    super('manage-catalog', translate);
  }

  onInit(): void {
    this.isVendor = this.global.userSession.vendor? true: false;
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setDataFromRouterParamsFromToken();
    } else {
      this.setDataFromRouterParams();
    }
  }

  public setDataFromRouterParamsFromToken(): void {
    this.httpClientService
      .get<ManageCatalogDTO>('/manage-catalog/notification/' + this.token)
      .subscribe((manageCatalogDTO: ManageCatalogDTO) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('catalog', manageCatalogDTO.catalog);
        this.global.routerParams.set('catalogId', manageCatalogDTO.catalog.id);
        if(manageCatalogDTO.viewIsOther){
          this.global.routerParams.set('urlBackOutside', '/pages/catalog-verification');
          if(manageCatalogDTO.catalog.catalogStatus.code === this.global.appConstant.vm.CATALOG_STATUS_VERIFICATION){
            this.global.routerParams.set('todo', 'view');
            this.global.routerParams.set('isGuest', true);
            this.global.routerParams.set('header', 'catalog-verification.title');
            this.router.navigate(['/pages/catalog-verification/edit']);
          }else{
            this.router.navigate(['/pages/catalog-verification']);
          }
        }else{
          this.global.routerParams.set('urlBackOutside', '/pages/manage-catalog');
          if (
            manageCatalogDTO.catalog.catalogStatus.code ===
            this.global.appConstant.vm.CATALOG_STATUS_ACTIVE ||
            manageCatalogDTO.catalog.catalogStatus.code ===
            this.global.appConstant.vm.CATALOG_STATUS_NON_ACTIVE ||
            manageCatalogDTO.catalog.catalogStatus.code ===
            this.global.appConstant.vm.CATALOG_STATUS_REVISION
          ) {
            this.router.navigate(['/pages/manage-catalog/edit']);
            this.global.routerParams.set('todo', 'edit');
          } else {
            this.router.navigate(['/pages/manage-catalog/detail']);
            this.global.routerParams.set('todo', 'view');
          }
        }
      });
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.catalogId = this.global.routerParams.get('catalogId');
    if (this.global.routerParams.get('isGuest')) {
      this.isGuest = this.global.routerParams.get('isGuest');
    }
    this.catalog = this.global.routerParams.get('catalog');
    this.header = this.global.routerParams.get('header')
      ? this.global.routerParams.get('header')
      : 'manage-catalog.title';
    this.global.routerParams.clear();

    this.buildFormGroup();
    this.buildTabResponse();
  }

  public setOptionListModel(): void {
    this.optionList.setRequestValues(
      this.manageCatalogDTO.catalogAttributeGroupList
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      attributeGroup: [null, Validators.required()],
      isViewFormObject: null,
      formObjectModel: null,
      termCondition: false
    });
    
    let isVendor = this.global.userSession.vendor? true: false;
    let isOther = isVendor? false : this.global.userSession.activeUserRole.role.type 
    === this.global.appConstant.core.ROLE_TYPE_OTHER? true: false;
    isOther? this.formGroup.get('attributeGroup').setIsView(true): '';
    this.manageCatalogDTO.viewIsOther = isOther;
    
  }

  public buildTabResponse(): void {
    this.isTodoView = this.todo === 'view';
    this.httpClientService
      .post<ManageCatalogDTO>(
        '/manage-catalog/add-edit',
        new RouteRequestModel(this.todo, this.catalogId)
      )
      .subscribe(manageCatalogDTO => {
        this.manageCatalogDTO = manageCatalogDTO;

        if (this.isGuest) {
          const manageCatalogTab: ManageCatalogTab = new ManageCatalogTab();
          manageCatalogTab.code = 'VERIFICATION';
          manageCatalogTab.description = 'Verification';
          manageCatalogTab.routeUrl = 'verification';
          const translationKey: TranslationKey = new TranslationKey();
          const module: Module = new Module();
          module.code = 'DYNAMIC-ATTRIBUTE';
          translationKey.id = null;
          translationKey.key = 'manageCatalogTab.verification';
          translationKey.module = module;
          manageCatalogTab.translationKey = translationKey;
          this.manageCatalogDTO.manageCatalogTabList.push(manageCatalogTab);
        }

        this.global.routerParams.set('manageCatalogDTO', this.manageCatalogDTO);
        this.tabResponse = TabResponseModel.create(
          this.manageCatalogDTO.manageCatalogTabList,
          this.router.url,
          'translationKey',
          this.isTodoView
        );
        this.global.routerParams.set('tabResponse', this.tabResponse);
        this.global.routerParams.set('isGuest', this.isGuest);
        this.global.routerParams.set('todo', this.todo);
        this.router.navigate([this.router.url + '/general']);

        this.global.routerParams.set(
          'currencyList',
          this.manageCatalogDTO.currencyList
        );

        this.setOptionListModel();
        this.setFormGroup();

        this.global.routerParams.set(
          'tabModelBeforeVerification',
          this.tabResponse.tabList[3]
        );
        this.catalogAttributeService.setCatalogAtributeParam({ catalogAtributId: this.manageCatalogDTO.catalog?.catalogAttributeGroup?.id });
        this.setStateReady();
      });
  }

  public setFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
      this.todo = this.isGuest ? 'edit' : 'view';
    }
    this.formGroup.patchValue({
      attributeGroup: this.manageCatalogDTO.catalog.catalogAttributeGroup
    });
    this.isViewFormObject = true;
    this.setFormObjectModel(this.manageCatalogDTO.catalogSpesificationList);

    // tslint:disable-next-line: no-string-literal
    this.formObjectModel.formGroup.setIsView(this.isTodoView);
  }

  public doNext(tab: TabModel): void {
    this.setManageCatalogDTORouterParam();
    if (!this.isGuest) {
      this.tabResponse.setIsSubmitted();
    }
    this.tabResponse.setCurrentTab(tab);
    this.router.navigate([tab.routeUrl]);
  }

  public doPrevious(tab: TabModel): void {
    this.tabResponse.setCurrentTab(tab);
    this.router.navigate([tab.routeUrl]);
  }

  public doBack(): void {
    if (!this.isGuest) {
      this.global.routerParams.set('isFromManageCatalog', true);
      this.router.navigate(['/pages/manage-catalog']);
    } else {
      this.router.navigate(['/pages/catalog-verification']);
    }
  }

  public setCatalogCategory(): void {
    this.catalogCategory = new CatalogCategory();
    this.catalogCategory.catalog = this.manageCatalogDTO.catalog;
    this.catalogCategory.itemCategory = this.manageCatalogDTO.catalog.item?.itemCategory;
    this.manageCatalogDTO.catalogCategoryList.push(this.catalogCategory);
  }

  // public setCatalogShippingCost(): void {
  //   const tabShippingCost = this.tabResponse.tabs.get('SHIPPING_COST');
  //   let shippingCostList = null;
  //   if (tabShippingCost.formGroup.get('shippingCostList')) {
  //     shippingCostList = tabShippingCost.formGroup.get('shippingCostList').value;
  //   } else {
  //     shippingCostList = this.manageCatalogDTO.catalogShippingCostList;
  //   }
  //   if(shippingCostList){
  //     shippingCostList.forEach(shippingCost => {
  //       if(shippingCost.amount !== null){
  //         const amountTemp = shippingCost.amount.price;
  //         shippingCost.amount = amountTemp;
  //       }
  //     })
  //   }
  //   this.manageCatalogDTO.catalogShippingCostList = shippingCostList ? shippingCostList : [];
  // }

  public setCatalogInventory(): void {
    const tabInventory = this.tabResponse.tabs.get('INVENTORY');

    let inventoryList = null;
    if (tabInventory.formGroup.get('inventoryList')) {
      inventoryList = tabInventory.formGroup.get('inventoryList').value;
      inventoryList.forEach(inventory => {
        if(inventory.vendorAddressAutoComplete){
          inventory.vendorAddress = inventory.vendorAddressAutoComplete;
        }
      });
    } else {
      inventoryList = this.manageCatalogDTO.catalogInventoryList;
    }
    this.manageCatalogDTO.catalogInventoryList = inventoryList ? inventoryList : [];
  }

  public doSave(): void {
    this.getItem();
    this.setCatalogCategory();
    this.setCatalogInventory();

    let termCondition = true;
    if (this.isVendor && this.todo !== 'view') {
      termCondition = this.formGroup.get('termCondition').value;
      if (!termCondition) {
        const termConditionForm = this.formGroup.controls.termCondition;
        termConditionForm.setErrors({
          message: this.translateService.instant('registration.validation.termsAndCondition')
        });
        termConditionForm.markAsDirty();
        termConditionForm.markAsTouched();
      }
    }

    if (!this.manageCatalogDTO.fileUploadImage) {
      this.manageCatalogDTO.fileUploadImage = [];
      this.manageCatalogDTO.catalogImageList.forEach(catalogImage => {
        const fileObject = new FileObject(catalogImage.file, false);
        this.manageCatalogDTO.fileUploadImage.push(fileObject);
      });
    }

    this.itemContractDetailTab = this.global.routerParams.get('tabResponse').tabs.get('ITEM_CONTRACT_DETAIL');
    this.manageCatalogDTO.catalog.catalogAttributeGroup = this.formGroup.value.attributeGroup;
    this.validate();
    this.tabResponse.setIsSubmitted();
    this.generalTab = this.tabResponse.tabs.get('GENERAL');
    const isValidTabSpecification = this.validateTabSpecification();

    this.isActiveItem = this.manageCatalogDTO.catalog.item?.isActive;
    const catalogRegionValid = this.doValidasiItemContractDetailRegion();
    // tslint:disable-next-line: max-line-length
    if (
      this.formGroup.valid &&
      isValidTabSpecification &&
      this.generalTab.formGroup.valid &&
      catalogRegionValid &&
      this.manageCatalogDTO.fileUploadImage.length !== 0 &&
      this.manageCatalogDTO.catalogInventoryList.length !== 0 &&
      this.tabResponse.tabs.get('INVENTORY').formGroup.valid &&
      this.isActiveItem &&
      termCondition
    ) {
      for (const catalogRegion of this.manageCatalogDTO.catalogRegionList) {
        if (catalogRegion.price !== null) {
          this.manageCatalogDTO.catalog.price = catalogRegion.price;
        }
      }
      // this.setCatalogShippingCost();
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const catalogRegionList = this.manageCatalogDTO.catalogRegionList;
            let catalogRegionNewList: CatalogRegion[] = [];
            catalogRegionList.forEach(cr =>{
              if (cr && cr.provinceRegion) {
                catalogRegionNewList.push(cr);
              }
            })
            this.manageCatalogDTO.catalogRegionList = catalogRegionNewList;
            this.setStateProcessing();
            this.httpClientService
              .post<Response<ManageCatalogDTO>>(
                this.urlSaveManageCatalog,
                this.manageCatalogDTO
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/manage-catalog/']);
                } else {
                  this.global.alertService.showError(
                    response.statusText,
                    '.main-row-manage-catalog'
                  );
                  this.setStateReady();
                }
              });
          }
        });
    } else {
      if (!isValidTabSpecification) {
        this.tabResponse.setCurrentTab(this.tabResponse.currentTab);
      } else if (!this.generalTab.formGroup.valid || !this.isActiveItem) {
        this.tabResponse.setCurrentTab(this.generalTab);
        this.router.navigate([this.generalTab.routeUrl]);
      } else if (!catalogRegionValid){
        this.global.routerParams.set('isValidItem', false);
        this.tabResponse.setCurrentTab(this.tabResponse.tabs.get('ITEM_CONTRACT_DETAIL'));
        this.router.navigate([this.tabResponse.tabs.get('ITEM_CONTRACT_DETAIL').routeUrl]);
      } else if (
        this.manageCatalogDTO.catalogInventoryList.length === 0 ||
        !this.tabResponse.tabs.get('INVENTORY').formGroup.valid
      ) {
        this.tabResponse.setCurrentTab(this.tabResponse.tabs.get('INVENTORY'));
        this.router.navigate([this.tabResponse.tabs.get('INVENTORY').routeUrl]);
      } else if (this.manageCatalogDTO.fileUploadImage.length === 0) {
        this.tabResponse.setCurrentTab(this.tabResponse.tabs.get('IMAGE'));
        this.router.navigate([this.tabResponse.tabs.get('IMAGE').routeUrl]);
      }
    }
  }

  public validateTabSpecification(): boolean {
    const currentTab = this.tabResponse.currentTab;
    if (currentTab.code === 'SPECIFICATION') {
      const formObjectModel: FormObjectModel<CatalogGroupTemplate> = this
        .formGroup.controls.formObjectModel.value;
      this.formGroupService.validateAllFormFields(formObjectModel.formGroup);
      if (formObjectModel.formGroup.valid) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public get urlSaveManageCatalog(): string {
    return this.todo === 'edit'
      ? this.urlUpdateManageCatalog
      : this.urlInsertManageCatalog;
  }

  public setManageCatalogDTORouterParam(): void {
    if (this.tabResponse.currentTab.code === 'GENERAL') {
      this.manageCatalogDTO.catalog = this.tabResponse.currentTab.formGroup.value;
      this.manageCatalogDTO.catalog.currency = this.manageCatalogDTO.currencyList[0];
      this.manageCatalogDTO.catalog.price = this.manageCatalogDTO.catalogContractList[0].price;
      /*this.manageCatalogDTO.catalog.currency = this.tabResponse.currentTab
        .formGroup.value.priceCatalogs
        ? this.tabResponse.currentTab.formGroup.value.priceCatalogs.currency
        : null;
      this.manageCatalogDTO.catalog.price = this.tabResponse.currentTab
        .formGroup.value.priceCatalogs
        ? this.tabResponse.currentTab.formGroup.value.priceCatalogs.price
        : null;*/
    }
    if (
      this.tabResponse.currentTab.code === 'IMAGE' &&
      this.tabResponse.currentTab.formGroup.value.file !== null &&
      this.tabResponse.currentTab.formGroup.value.file !== undefined
    ) {
      this.manageCatalogDTO.fileUploadImage = this.tabResponse.currentTab.formGroup.value.file;
    }
    this.global.routerParams.set('manageCatalogDTO', this.manageCatalogDTO);
    this.global.routerParams.set('catalog', this.catalog);
    this.global.routerParams.set('formGroup', this.formGroup);
    this.global.routerParams.set('todo', this.todo);
  }

  public setFormObjectModel(model: any): void {
    this.formObjectModel = FormObjectModel.create(model).setIsView(
      this.isTodoView
    );
    this.formGroup.patchValue({
      formObjectModel: this.formObjectModel
    });
    this.manageCatalogDTO.catalog.catalogAttributeGroup = this.formGroup.value.attributeGroup;
  }

  public doChooseCatalogGroup(
    catalogAttributeGroup: CatalogAttributeGroup
  ): void {

    this.formGroup.patchValue({
      isViewFormObject: false
    });
    this.isViewFormObject = this.formGroup.get('isViewFormObject').value;

    if (catalogAttributeGroup) {
      this.loadingBlockService.showInfo(`app.msg.processing`);
      this.httpClientService
        .get<CatalogGroupTemplate[]>('/manage-catalog/get-catalog-group-template/' + catalogAttributeGroup.id)
        .subscribe(catalogGroupTemplateList => {
          this.formGroup.patchValue({
            isViewFormObject: true
          });
          this.isViewFormObject = this.formGroup.get('isViewFormObject').value;

          this.setFormObjectModel(catalogGroupTemplateList);
        });

      this.httpClientService
        .get<Item[]>('/manage-catalog/get-item-list-by-catalog-atribut-group-id/' + catalogAttributeGroup.id)
        .subscribe(itemList => {
          this.loadingBlockService.close();
          this.manageCatalogDTO.itemList = [];
          this.manageCatalogDTO.itemList = itemList;

          this.catalogAttributeService.setCatalogAtributeParam({ catalogAtributId: catalogAttributeGroup?.id });
        });
    } else {
      this.catalogAttributeService.setCatalogAtributeParam({ catalogAtributId: null });
    }

    this.manageCatalogDTO.catalogSpesificationList = [];
    this.generalTab = this.tabResponse.tabs.get('GENERAL');
    this.tabResponse.setCurrentTab(this.generalTab);
    this.generalTab.formGroup.get('item').setValue(null);
    this.generalTab.formGroup.get('description').setValue(null);
    this.manageCatalogDTO.catalog.item = null;
    this.router.navigate([this.generalTab.routeUrl]);

  }

  public doDisplayTermCondition(): void {
    this.appPopUpManageCatalogTocService.open(this.manageCatalogDTO.content);
  }

  public doValidasiItemContractDetailRegion(): boolean {
    const catalogRegionList: CatalogRegion[] = this.manageCatalogDTO.catalogRegionList.filter(
      (catalogRegion: CatalogRegion) =>
        catalogRegion.price ||
        catalogRegion.startDate ||
        catalogRegion.quantityInPackage
    );

    let valid = true;
    if (catalogRegionList.length !== 0){
      catalogRegionList.forEach(catalogRegion => {
        if (catalogRegion.price.toString() !== "0" || (catalogRegion.startDate !== null && catalogRegion.startDate !== undefined)
         || catalogRegion.quantityInPackage.toString() !== "0") {
          if (catalogRegion.price) {
            const minPrice = this.manageCatalogDTO.catalog.item?.minPrice;
            const maxPrice = this.manageCatalogDTO.catalog.item?.maxPrice;
            if (catalogRegion.startDate === null || catalogRegion.startDate === undefined ||
              catalogRegion.quantityInPackage === null || catalogRegion.quantityInPackage === undefined || catalogRegion.price.toString() === "0"){
              valid = false;
            }else if (minPrice && minPrice > catalogRegion.price){
              valid = false;
            }else if (maxPrice && maxPrice < catalogRegion.price){
              valid = false;
            }else if (catalogRegion.quantityInPackage < 1){
              valid = false;
            }
          }else{
            valid = false;
          }
        }
      });
    }else{
      valid = false;
    }
    return valid;
  }

  public getItem(): void {
    if (this.manageCatalogDTO.catalog.item) {
      const item = this.manageCatalogDTO.catalog.item;
      this.httpClientService
        .get<Item>('/manage-catalog/get-item-by-id/' + item.id)
        .subscribe(dataItem => {
          this.manageCatalogDTO.catalog.item = dataItem;
        });
    }
  }
}
