import { Component, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Claim } from 'src/app/core/bean/claim';
import { ClaimRequestAmount } from 'src/app/core/bean/claim-request-amount';
import { ClaimStatus } from 'src/app/core/bean/claim-status';
import { ClaimVerification } from 'src/app/core/bean/claim-verification';
import { Currency } from 'src/app/core/bean/currency';
import { File } from "src/app/core/bean/file";
import { WorkflowPrcs } from 'src/app/core/bean/workflow-prcs';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';
import { FileUploader } from 'src/app/core/components/upload';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { Validators } from 'src/app/core/validators';
import { ClaimDocMaster } from '../../core/bean/claim-doc-master';
import { OptionListModel } from '../../core/model/option-list-model';
import { ClaimRequest } from './claim.request';
import { ClaimResponse } from './claim.response';

@Component({
  templateUrl: './claim-edit-add.component.html'
})
export class ClaimEditAddComponent extends BaseModuleComponent implements OnInit {
  public claimId: number;
  public objectId: number;
  public approvalModelPrcsId: number;
  public claimResponse: ClaimResponse = new ClaimResponse();
  public claimRequest: ClaimRequest = new ClaimRequest();
  public claimStatus: ClaimStatus = new ClaimStatus();
  public claim: Claim = new Claim();
  public workflowPrcs: WorkflowPrcs;
  public isSubmit = false;
  public urlBackOutside: string;
  public currentDate: Date = new Date();
  public token: string;
  public isUserAvailable = false;
  public isUserExecutor = false;
  public isUserSuperAdmin = false;
  public isUserHospital = false;
  public isShowOCRResult = false;
  public isPaymentSpecialCode = false;
  public isAllowSend = false;
  public isAllowResend = false;
  public isLoading = false;
  public paymentTotal: number;
  public paymentMsg: string;
  public glStatus: string;
  public paymentDate: Date = new Date();
  public isFillAccidentHealthForm = false;
  public isUploadReceipt = false;
  public tableResponse: TableResponseModel<ClaimVerification>;
  public fileUploaderList: FileUploader[] = new Array();
  public fileUploader: FileUploader = new FileUploader(
    '/claim/',
    'claim.form.headerImage',
    this.global.appConstant.fileType.DOC_KLAIM_OTHER,
    false,
    10
  );
  public fileUploaderOCR: FileUploader = new FileUploader(
    '/claim/',
    'claim.form.fileOCR',
    this.global.appConstant.fileType.DOC_OCR_RESULT,
    false,
  );
  public fileUploaderReceipt: FileUploader = new FileUploader(
    '/claim/',
    'claim.form.receiptDoc',
    this.global.appConstant.fileType.DOC_KLAIM_RECEIPT
  );
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true
  );
  public roomTypeList: OptionListModel<any> = new OptionListModel();
  public claimRequestAmount: ClaimRequestAmount = new ClaimRequestAmount();
  public totalRequest = null
  public nilaiSisaP3k: number;
  public nilaiSisaAmbl: number;
  public totalCompensationRequest = null
  public isDraft = true;
  public maxAdministrationAmount: number;

  constructor(
    translateService: TranslateService,
    public snackbarService: SnackbarService,
    public toastService: ToastService,
    public activatedRoute: ActivatedRoute,
    public appPopupService: AppPopupService
  ) {
    super('claim', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
    this.isUserExecutor = this.global.userSession.activeUserRole.role.code
      === this.global.appConstant.ROLE_CODE_EXECUTOR ? true : false;
    this.isUserSuperAdmin = this.global.userSession.activeUserRole.role.code
      === this.global.appConstant.ROLE_CODE_SUPER_ADMIN ? true : false;
    this.isUserHospital = this.global.userSession.activeUserRole.role.code
      === this.global.appConstant.ROLE_CODE_USER ? true : false;
    this.buildTableResponse();
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Claim>('/claim/notification/' + this.token)
      .subscribe((claim: Claim) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('claimId', claim.id);
        this.global.routerParams.set('claimStatus', claim.claimStatus);
        this.global.routerParams.set('urlBackOutside', '/pages/claim');
        if (
          ((claim.claimStatus.code === this.global.appConstant.pm.CLAIM_STATUS_NEW ||
            claim.claimStatus.code === this.global.appConstant.pm.CLAIM_STATUS_REVISION ) &&
            this.isUserHospital &&
            claim.glStatus === this.global.appConstant.pm.GL_STATUS_ACTIVE)
        ) {
          this.global.routerParams.set('todo', 'detail');
          this.router.navigate(['/pages/claim/detail']);
        } else if (claim.claimStatus.code === this.global.appConstant.pm.CLAIM_STATUS_UPLOAD_RECEIPT) {
          this.global.routerParams.set('todo', 'uploadReceipt');
          this.router.navigate(['/pages/claim/detail']);
        } else {
          this.global.routerParams.set('todo', 'view');
          this.router.navigate(['/pages/claim/detail']);
        }
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'processedByUser.name', header: 'table.column.admin' },
      {
        field: 'note',
        header: 'table.column.note',
        customClass: 'white-space-normal'
      },
      {
        field: 'processedDate',
        header: 'table.column.date',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'claimStatus.name',
        header: 'table.column.status',
        customClass: 'text-center'
      }
    ]);
  }


  public setInitializationState(): void {
    this.doSetDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.claimId = this.global.routerParams.get('claimId');
    this.claimStatus = this.global.routerParams.get('claimStatus');
    this.claimResponse = this.global.routerParams.get('claimResponse');
    this.objectId = this.global.routerParams.get('objectId');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
    if (this.objectId) {
      this.claimId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );

  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    } else if (this.todo === 'uploadReceipt'){
      this.setViewOnly();
      // this.formGroup.get('')
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      hospitalCode: [null, Validators.required()],
      hospitalName: [null, Validators.required()],
      glNumber: [null, Validators.required()],
      idJaminan: [null],
      accidentVictim: [null, Validators.required()],
      accidentDate: [null],
      link: [null],
      linkHistory: [null],
      description: [null],
      documentList: [null],
      fileObjectList: [null],
      verificator: [null],
      counter: [null],
      ocrVerifAmount: [null],
      ocrVerifAmountApproved: [null],
      fileOcrResult: [null],
      docClaimList: this.formBuilder.array([]),
      doctor: [null, Validators.required()],
      medicine: [null, Validators.required()],
      medicalTools: [null, Validators.required()],
      administration: [null, Validators.compose([Validators.required(), Validators.max(this.maxAdministrationAmount)])],
      room: [null, Validators.required()],
      totalP3k: [null, Validators.max(this.nilaiSisaP3k)],
      totalAmbulance: [null, Validators.max(this.nilaiSisaAmbl)],
      roomType: [null, Validators.required()],
      note: [null],
      receiptDoc: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ClaimResponse>(
        '/claim/edit',
        new RouteRequestModel(this.todo, this.claimId)
      )
      .subscribe((claimResponse: ClaimResponse) => {
        if (!this.claimResponse) {
          this.claimResponse = claimResponse;
        }

        this.maxAdministrationAmount = this.global.config.parameterModel.claimAdministrationAmountMaxInputValue;
        if (this.claimResponse.executorAssignment?.id) {
          this.isUserAvailable = true;
        }

        this.roomTypeList.setRequestValues(this.claimResponse.roomTypeList);

        if (this.claimResponse.claim) {
          this.fileUploader.setFileList(claimResponse.fileOtherList);
          this.fileUploaderOCR.setFile(claimResponse.claim.ocrFile);
          this.claim = this.claimResponse.claim;
          this.workflowPrcs = this.claimResponse?.claim?.workflowPrcs;
          this.paymentTotal = this.claimResponse?.claim?.paymentTotal;
          this.paymentDate = this.claimResponse?.claim?.paymentDate;
          this.glStatus = this.claimResponse?.glStatus?.name;
          this.fileUploaderReceipt.setFile(claimResponse.receiptDoc);

          if ((this.claim.claimStatus?.code === this.global.appConstant.pm.CLAIM_STATUS_DONE ||
            this.claim.claimStatus?.code === this.global.appConstant.pm.CLAIM_STATUS_RESEND_FILE ||
            this.claim.claimStatus?.code === this.global.appConstant.pm.CLAIM_STATUS_RESEND_INFORMATION) &&
            this.claim.verificatorCode === this.global.appConstant.core.VERIFICATOR_CODE_OCR.toString()) {
            this.isShowOCRResult = true;
          }

          if (this.claim.claimStatus?.code === this.global.appConstant.pm.CLAIM_STATUS_UPLOAD_RECEIPT || (this.claim.claimStatusBefore === this.global.appConstant.pm.CLAIM_STATUS_UPLOAD_RECEIPT && this.claim.claimStatus?.code)) {

            this.isUploadReceipt = true;
            this.formGroup.get('description').setIsView(true);
            this.formGroup.get('note').setIsView(true);
            this.formGroup.get('fileObjectList').setIsView(true);
            this.formGroup.get('receiptDoc').setIsView(false);

          } 

            this.formGroup.patchValue({
              hospitalCode: this.claimResponse.claim.hospitalCode,
              hospitalName: this.claimResponse.claim.hospitalName,
              glNumber: this.claimResponse.claim.glNumber,
              idJaminan: this.claim.idJaminan,
              accidentVictim: this.claimResponse.claim.accidentVictim,
              accidentDate: this.claim.accidentDate,
              link: this.claim.tautanGL,
              linkHistory: this.claim.tautanHistory,
              description: this.claimResponse.claim.description,
              fileObjectList: this.fileUploader.fileObjectList,
              verificator: this.claimResponse.verificator.name,
              counter: this.claimResponse.counter.name,
              ocrVerifAmount: { price: this.claimResponse.claim.ocrVerifAmount },
              ocrVerifAmountApproved: { price: this.claimResponse.claim.ocrVerifAmountApproved },
              fileOcrResult: this.fileUploaderOCR.fileObjectList,
              note: this.claimResponse.lastNoteFromUserRequester,
              receiptDoc: this.fileUploaderReceipt.fileObjectList
            });
  

          if (this.isUserHospital &&
            this.claimResponse.glStatus?.code === this.global.appConstant.pm.GL_STATUS_ACTIVE &&
            (this.claimResponse.accidentVictim === null ||
              this.claimResponse.accidentVictim?.accidentVictimStatus.code === this.global.appConstant.pm.ACCIDENT_VICTIM_STATUS_NEW ) &&
            (this.claim.claimStatus?.code === this.global.appConstant.pm.CLAIM_STATUS_NEW ||
              this.claim.claimStatus?.code === this.global.appConstant.pm.CLAIM_STATUS_REVISION)) {
            this.isFillAccidentHealthForm = true;
            this.todo = 'view';
            this.setIsViewOnlyFormGroup();
          }

          const isUserCanSubmit = this.isUserAvailable && !this.isUserExecutor && !this.isUserSuperAdmin;

          if ((this.claim.claimStatus?.code !== this.global.appConstant.pm.CLAIM_STATUS_WAITING_VERIFICATION &&
            this.claim.claimStatus?.code !== this.global.appConstant.pm.CLAIM_STATUS_RESEND_FILE &&
            this.claim.claimStatus?.code !== this.global.appConstant.pm.CLAIM_STATUS_RESEND_INFORMATION &&
            this.claim.claimStatus?.code !== this.global.appConstant.pm.CLAIM_STATUS_DONE
          ) &&
            this.claimResponse.glStatus?.code === this.global.appConstant.pm.GL_STATUS_ACTIVE &&
            isUserCanSubmit &&
            this.todo !== 'view') {
            this.isAllowSend = true;
          }

          const isUserCanResend = this.isUserAvailable && (this.isUserExecutor || this.isUserSuperAdmin);

          if ((this.claim.claimStatus?.code === this.global.appConstant.pm.CLAIM_STATUS_RESEND_FILE ||
            this.claim.claimStatus?.code === this.global.appConstant.pm.CLAIM_STATUS_RESEND_INFORMATION) &&
            isUserCanResend) {
            this.isAllowResend = true;
          }

          if (this.claim.nilaiJaminanP3K != null) {
            this.nilaiSisaP3k = this.claim.nilaiJaminanP3K - this.claim.sudahDibayarP3k;
          } else {
            this.nilaiSisaP3k = 0;
          }
          if (this.claim.nilaiJaminanAmbl != null) {
            this.nilaiSisaAmbl = this.claim.nilaiJaminanAmbl - this.claim.sudahDibayarAmbl;
          } else {
            this.nilaiSisaAmbl = 0;
          }

        }

        if (this.claimResponse.claimVerificationList.length > 0 && this.todo == 'view') {
          this.formGroup.patchValue({ note: this.claimResponse.lastNoteFromUserRequester });
        }

        if (this.claimResponse.claimRequestAmount) {
          this.claimRequestAmount = this.claimResponse.claimRequestAmount;
          this.formGroup.patchValue({
            doctor: { price: this.claimResponse.claimRequestAmount.doctor },
            medicine: { price: this.claimResponse.claimRequestAmount.medicine },
            medicalTools: { price: this.claimResponse.claimRequestAmount.medicalTools },
            administration: { price: this.claimResponse.claimRequestAmount.administration },
            room: { price: this.claimResponse.claimRequestAmount.room },
            totalP3k: { price: this.claimResponse.claimRequestAmount.totalP3k },
            totalAmbulance: { price: this.claimResponse.claimRequestAmount.totalAmbulance },
            roomType: this.claimResponse.claimRequestAmount.roomType ? this.roomTypeList.getRequestValues().filter(valueOption => valueOption.id === this.claimResponse.claimRequestAmount.roomType.id)[0] : null
          });
          this.totalRequest = this.claimResponse.claimRequestAmount.totalRequest
          this.totalCompensationRequest = this.claimResponse.claimRequestAmount.totalCompensationRequest
        }

        this.tableResponse.setRecords(
          this.claimResponse.claimVerificationList
        );
        this.tableResponse.setTotalRecords(
          this.claimResponse.claimVerificationList
            ? this.claimResponse.claimVerificationList.length
            : 0
        );
        if (this.isUserSuperAdmin) {
          this.formGroup.get('fileOcrResult').setIsView(false);
          this.formGroup.get('ocrVerifAmount').setIsView(false);
          this.formGroup.get('ocrVerifAmountApproved').setIsView(false);
        } else {
          this.formGroup.get('fileOcrResult').setIsView(true);
          this.formGroup.get('ocrVerifAmount').setIsView(true);
          this.formGroup.get('ocrVerifAmountApproved').setIsView(true);
        }
        this.currencyOptionList.setRequestValues(
          this.claimResponse.currencyList
        );

        this.buildFileUploader(claimResponse.claimDocMasterList);
        this.setFileUploader(claimResponse.fileList, claimResponse.claimDocMasterList);
        this.tableResponse.reloadClient();
        this.setStateReady();
      });
  }

  public doSubmit(isDraft: boolean): void {
    this.doUpdateValidator(isDraft);
    this.validate();
    this.validateAdministration();
    this.validateP3k();
    this.validateAmbl();
    if (isDraft && this.formGroup.valid && this.isUserAvailable) {
      const value = this.formGroup.value;
      this.claimRequest.claim = this.formGroup.value;
      this.claimRequest.claim.ocrVerifAmount = +this.formGroup.value.ocrVerifAmount.price;
      this.claimRequest.claim.ocrVerifAmountApproved = +this.formGroup.value.ocrVerifAmountApproved.price;
      this.claimRequest.claim.id = this.claimId;
      this.claimRequest.receiptDoc = this.formGroup.get('receiptDoc').value;
      this.claimRequest.fileObjectList = this.formGroup.get('fileObjectList').value;
      const fileDocList = this.formGroup.get('docClaimList').value;
      fileDocList.forEach(element => {
        if (element.file.length > 0) {
          this.claimRequest.fileObjectList.push(element.file[0]);
        }
      });

      const fields = [
        'doctor',
        'medicine',
        'medicalTools',
        'administration',
        'room',
        'totalP3k',
        'totalAmbulance'
      ];

      fields.forEach(field => {
        if (value[field]?.price === "0") {
          this.formGroup.patchValue({ [field]: null });
        }
      });

      fields.forEach(field => {
        this.claimRequestAmount[field] = value[field]?.price;
      });

      this.claimRequestAmount.totalRequest = this.totalRequest;
      this.claimRequestAmount.totalCompensationRequest = this.totalCompensationRequest;
      this.claimRequestAmount.roomType = value.roomType;
      this.claimRequest.claimRequestAmount = this.claimRequestAmount;
      this.claimRequest.note = value.note;
      this.claimRequest.isDraft = isDraft;

      this.setStateProcessing();
      this.httpClientService
        .post<Response<Claim>>('/claim/update', this.claimRequest)
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.global.modalService
              .submitSuccess()
              .pipe(take(1))
              .subscribe(result => {
                if (result) {
                  this.router.navigate(['/pages/claim/']);
                } else {
                  this.router
                    .navigateByUrl('/', { skipLocationChange: true })
                    .then(() => {
                      this.global.routerParams.clear();
                      this.global.routerParams.set('claimId', this.claimId);
                      this.global.routerParams.set('claimStatus', this.claimStatus);
                      this.router.navigate(['/pages/claim/detail']);
                    });
                }
              });
          } else {
            if (response.statusText !== null) {
              this.global.modalService.message(
                this.translateService.instant(response.statusText)
              );
            } else {
              this.global.alertService.showError(
                response.statusText
              );
            }

            this.setStateReady();
          }
        });
    } else {
      if (this.claimResponse.claim?.claimStatus?.code === this.global.appConstant.pm.CLAIM_STATUS_RESEND_FILE ||
        this.claimResponse.claim?.claimStatus?.code === this.global.appConstant.pm.CLAIM_STATUS_RESEND_INFORMATION
      ) {
        this.global.modalService.saveConfirmation({
          prompt: 'app.confirmation.resend.msg',
          title: 'app.confirmation.resend.title'
        }).subscribe(result => {
          if (result) {
            this.claimRequest.claim = this.formGroup.value;
            this.claimRequest.claim.ocrVerifAmount = +this.formGroup.value.ocrVerifAmount.price;
            this.claimRequest.claim.ocrVerifAmountApproved = +this.formGroup.value.ocrVerifAmountApproved.price;
            this.claimRequest.claim.id = this.claimId;
            this.claimRequest.receiptDoc = this.formGroup.get('receiptDoc').value;
            this.claimRequest.fileObjectList = this.formGroup.get('fileObjectList').value;
            const fileDocList = this.formGroup.get('docClaimList').value;
            fileDocList.forEach(element => {
              if (element.file.length > 0) {
                this.claimRequest.fileObjectList.push(element.file[0]);
              }
            });
            this.claimRequest.isDraft = isDraft;

            this.setStateProcessing();
            this.httpClientService
              .post<Response<Claim>>('/claim/update', this.claimRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccess({
                      prompt: 'app.confirmation.resendSuccess.msg',
                      title: 'app.confirmation.resendSuccess.title'
                    })
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router.navigate(['/pages/claim/']);
                      } else {
                        this.router
                          .navigateByUrl('/', { skipLocationChange: true })
                          .then(() => {
                            this.global.routerParams.clear();
                            this.global.routerParams.set('claimId', this.claimId);
                            this.global.routerParams.set('claimStatus', this.claimStatus);
                            this.global.routerParams.set('todo', 'view');
                            this.router.navigate(['/pages/claim/detail']);
                          });
                      }
                    });
                } else {
                  if (response.statusText !== null) {
                    this.global.modalService.message(
                      this.translateService.instant(response.statusText)
                    );
                  } else {
                    this.global.alertService.showError(
                      response.statusText
                    );
                  }

                  this.setStateReady();
                }
              });
          }
        });
      } else if (this.claimResponse.claim?.claimStatus?.code === this.global.appConstant.pm.CLAIM_STATUS_REVISION) {
        if (
          this.formGroup.valid && this.isUserAvailable
        ) {
          this.global.modalService.saveConfirmation({
            prompt: 'app.confirmation.revise.msg',
            title: 'app.confirmation.revise.title'
          }).subscribe(result => {
            if (result) {
              const value = this.formGroup.value;
              this.claimRequest.claim = this.formGroup.value;
              this.claimRequest.claim.ocrVerifAmount = +this.formGroup.value.ocrVerifAmount.price;
              this.claimRequest.claim.ocrVerifAmountApproved = +this.formGroup.value.ocrVerifAmountApproved.price;
              this.claimRequest.claim.id = this.claimId;
              this.claimRequest.receiptDoc = this.formGroup.get('receiptDoc').value;
              this.claimRequest.fileObjectList = this.formGroup.get('fileObjectList').value;
              const fileDocList = this.formGroup.get('docClaimList').value;
              fileDocList.forEach(element => {
                if (element.file.length > 0) {
                  this.claimRequest.fileObjectList.push(element.file[0]);
                }
              });

              const fields = [
                'doctor',
                'medicine',
                'medicalTools',
                'administration',
                'room',
                'totalP3k',
                'totalAmbulance'
              ];

              fields.forEach(field => {
                if (value[field]?.price === "0") {
                  this.formGroup.patchValue({ [field]: null });
                }
              });

              fields.forEach(field => {
                this.claimRequestAmount[field] = value[field]?.price;
              });

              this.claimRequestAmount.totalRequest = this.totalRequest;
              this.claimRequestAmount.totalCompensationRequest = this.totalCompensationRequest;
              this.claimRequestAmount.roomType = value.roomType;
              this.claimRequest.claimRequestAmount = this.claimRequestAmount;
              this.claimRequest.note = value.note;
              this.claimRequest.isDraft = isDraft;

              this.setStateProcessing();
              this.httpClientService
                .post<Response<Claim>>('/claim/update', this.claimRequest)
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.global.modalService
                      .submitSuccess()
                      .pipe(take(1))
                      .subscribe(result => {
                        if (result) {
                          this.router.navigate(['/pages/claim/']);
                        } else {
                          this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(() => {
                              this.global.routerParams.clear();
                              this.global.routerParams.set('claimId', this.claimId);
                              this.global.routerParams.set('claimStatus', this.claimStatus);
                              this.global.routerParams.set('todo', 'view');
                              this.router.navigate(['/pages/claim/detail']);
                            });
                        }
                      });
                  } else {
                    if (response.statusText !== null) {
                      this.global.modalService.message(
                        this.translateService.instant(response.statusText)
                      );
                    } else {
                      this.global.alertService.showError(
                        response.statusText
                      );
                    }

                    this.setStateReady();
                  }
                });
            }
          });
        }
      } else {
        if (
          this.formGroup.valid && this.isUserAvailable
        ) {
          this.global.modalService
            .submitConfirmation()
            .pipe(take(1))
            .subscribe(result => {
              if (result) {
                const value = this.formGroup.value;
                this.claimRequest.claim = this.formGroup.value;
                this.claimRequest.claim.ocrVerifAmount = +this.formGroup.value.ocrVerifAmount.price;
                this.claimRequest.claim.ocrVerifAmountApproved = +this.formGroup.value.ocrVerifAmountApproved.price;
                this.claimRequest.claim.id = this.claimId;
                this.claimRequest.receiptDoc = this.formGroup.get('receiptDoc').value;
                this.claimRequest.fileObjectList = this.formGroup.get('fileObjectList').value;
                const fileDocList = this.formGroup.get('docClaimList').value;
                fileDocList.forEach(element => {
                  if (element.file.length > 0) {
                    this.claimRequest.fileObjectList.push(element.file[0]);
                  }
                });

                const fields = [
                  'doctor',
                  'medicine',
                  'medicalTools',
                  'administration',
                  'room',
                  'totalP3k',
                  'totalAmbulance'
                ];

                fields.forEach(field => {
                  if (value[field]?.price === "0") {
                    this.formGroup.patchValue({ [field]: null });
                  }
                });

                fields.forEach(field => {
                  this.claimRequestAmount[field] = value[field]?.price;
                });

                this.claimRequestAmount.totalRequest = this.totalRequest;
                this.claimRequestAmount.totalCompensationRequest = this.totalCompensationRequest;
                this.claimRequestAmount.roomType = value.roomType;
                this.claimRequest.claimRequestAmount = this.claimRequestAmount;
                this.claimRequest.note = value.note;
                this.claimRequest.isDraft = isDraft;

                this.setStateProcessing();
                this.httpClientService
                  .post<Response<Claim>>('/claim/update', this.claimRequest)
                  .subscribe(response => {
                    if (response.status === ResponseStatusModel.OK) {
                      this.global.modalService
                        .submitSuccess()
                        .pipe(take(1))
                        .subscribe(result => {
                          if (result) {
                            this.router.navigate(['/pages/claim/']);
                          } else {
                            this.router
                              .navigateByUrl('/', { skipLocationChange: true })
                              .then(() => {
                                this.global.routerParams.clear();
                                this.global.routerParams.set('claimId', this.claimId);
                                this.global.routerParams.set('claimStatus', this.claimStatus);
                                this.global.routerParams.set('todo', 'view');
                                this.router.navigate(['/pages/claim/detail']);
                              });
                          }
                        });
                    } else {
                      if (response.statusText !== null) {
                        this.global.modalService.message(
                          this.translateService.instant(response.statusText)
                        );
                      } else {
                        this.global.alertService.showError(
                          response.statusText
                        );
                      }

                      this.setStateReady();
                    }
                  });
              }
            });
        }
      }
    }

  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key || '-'
      );
    }
  }

  public doBack(): void {
    this.router.navigate(['/pages/claim']);
  }

  public get formArrayDocClaimList(): FormArray {
    return this.formGroup.get('docClaimList') as FormArray;
  }

  public buildFileUploader(claimDocMasterList: ClaimDocMaster[]): void {
    claimDocMasterList.forEach(claimDocMaster => {
      const fileUploader: FileUploader = new FileUploader(
        '/claim/',
        claimDocMaster.fileType.translationKey.key,
        claimDocMaster.fileType.code,
        false,
        1
      );
      this.fileUploaderList.push(fileUploader);
    });
  }

  public setFileUploader(docFileClaimList: File[], claimDocMasterList: ClaimDocMaster[]): void {
    const control = this.formGroup.controls.docClaimList as FormArray;
    claimDocMasterList.forEach((claimDocMaster, index) => {
      if (
        claimDocMaster.fileType.code !==
        this.global.appConstant.fileType.DOC_KLAIM_OTHER
      ) {
        docFileClaimList.forEach(docFileClaim => {
          if (claimDocMaster.fileType.code === docFileClaim.fileType.code) {
            this.fileUploaderList[index].setFile(docFileClaim);
          }
        });
        const requiredValidator = claimDocMaster.isMandatory
          ? Validators.required('')
          : null;
        control.push(
          this.formBuilder.group({
            translationKey: claimDocMaster.fileType.translationKey,
            file: [
              this.fileUploaderList[index].fileObjectList,
              requiredValidator
            ]
          })
        );
      }
    });
    this.setIsViewArray();
  }
  public setIsViewArray(): void {
    if (this.todo === 'view' || this.isUserSuperAdmin || this.isUploadReceipt) {
      this.formArrayDocClaimList.controls.forEach(formGroup => {
        formGroup.setIsView(true);
      });
    }
  }

  public clickFormKesehatan(claimResponse: ClaimResponse): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('claimId', claimResponse.claim.id);
    this.global.routerParams.set(
      'addressHospital',
      claimResponse.organization.address
    );
    this.global.routerParams.set('urlBackOutside', '/pages/accident-victim/');
    this.global.routerParams.set('todo', 'detail');
    this.router.navigate(['/pages/accident-victim/detail']);
  }

  public doUpdate(): void {
    this.validate();
    if (
      this.formGroup.valid && this.isUserAvailable
    ) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.claimRequest.claim = this.formGroup.value;
            this.claimRequest.claim.ocrVerifAmount = +this.formGroup.value.ocrVerifAmount?.price;
            this.claimRequest.claim.ocrVerifAmountApproved = +this.formGroup.value.ocrVerifAmountApproved?.price;
            this.claimRequest.claim.id = this.claimId;
            this.claimRequest.fileOcrResult = this.formGroup.get('fileOcrResult').value;

            this.setStateProcessing();
            this.httpClientService
              .post<Response<Claim>>('/claim/update-by-admin', this.claimRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccess()
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router.navigate(['/pages/claim/']);
                      } else {
                        this.router
                          .navigateByUrl('/', { skipLocationChange: true })
                          .then(() => {
                            this.global.routerParams.clear();
                            this.global.routerParams.set('claimId', this.claimId);
                            this.global.routerParams.set('claimStatus', this.claimStatus);
                            this.global.routerParams.set('todo', 'view');
                            this.router.navigate(['/pages/claim/detail']);
                          });
                      }
                    });
                } else {
                  if (response.statusText !== null) {
                    this.global.modalService.message(
                      this.translateService.instant(response.statusText)
                    );
                  } else {
                    this.global.alertService.showError(
                      response.statusText
                    );
                  }

                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doGetPaymentInfo(): void {
    this.claimRequest.claim = this.formGroup.value;
    this.claimRequest.claim.id = this.claimId;
    this.isLoading = true;
    this.httpClientService
      .post<Response<Claim>>('/claim/get-payment-info', this.claimRequest.claim.id)
      .subscribe({
        next: (response) => {
          if (response.status === ResponseStatusModel.OK) {

            this.claim = response.body;

            if (this.claim.glStatus == this.global.appConstant.pm.GL_STATUS_ACTIVE) {
              this.glStatus = this.global.appConstant.pm.GL_STATUS_NAME_ACTIVE;
            } else {
              this.glStatus = this.global.appConstant.pm.GL_STATUS_NAME_CANCEL;
            }

            if (this.claim.paymentTotal != null) {
              this.paymentTotal = this.claim?.paymentTotal;
              this.paymentDate = this.claim?.paymentDate;
            }

            if (this.claim.sysPaymentCheckCode != "200") {
              this.isPaymentSpecialCode = true;
            }

          } else {
            if (response.statusText !== null) {
              this.global.modalService.message(
                this.translateService.instant(response.statusText)
              );
            } else {
              this.global.alertService.showError(
                response.statusText
              );
            }

          }
          this.isLoading = false;
        }
      });
  }

  public countTotalRequest(): void {
    const values = this.formGroup.value;
    const total =
      (parseFloat(values.doctor?.price) || 0) +
      (parseFloat(values.medicine?.price) || 0) +
      (parseFloat(values.medicalTools?.price) || 0) +
      (parseFloat(values.administration?.price) || 0) +
      (parseFloat(values.room?.price) || 0);

    this.totalRequest = +total;
    this.countTotalCompensationRequest();
  }

  public countTotalCompensationRequest(): void {
    const values = this.formGroup.value;
    const total =
      (this.totalRequest || 0) +
      (parseFloat(values.totalP3k?.price) || 0) +
      (parseFloat(values.totalAmbulance?.price) || 0);

    this.totalCompensationRequest = +total;
  }

  public validateP3k(): void {
    const totalP3kControl = this.formGroup.get('totalP3k');
    const totalP3k = parseFloat(totalP3kControl.value?.price);
    if (totalP3k > this.nilaiSisaP3k) {
      totalP3kControl.setErrors({
        max: true,
        message: this.translateService.instant('claim.validation.max.totalP3k') + this.nilaiSisaP3k
      });
      totalP3kControl.markAsDirty();
      totalP3kControl.markAsTouched();
    } else {
      this.countTotalCompensationRequest()
    }
  }

  public validateAmbl(): void {
    const totalAmbulanceControl = this.formGroup.get('totalAmbulance');
    const totalAmbulance = parseFloat(totalAmbulanceControl.value?.price);
    if (totalAmbulance > this.nilaiSisaAmbl) {
      totalAmbulanceControl.setErrors({
        max: true,
        message: this.translateService.instant('claim.validation.max.totalAmbulance') + this.nilaiSisaAmbl
      });
      totalAmbulanceControl.markAsDirty();
      totalAmbulanceControl.markAsTouched();
    } else {
      this.countTotalCompensationRequest()
    }
  }

  public validateAdministration(): void {
    const administrationControl = this.formGroup.get('administration');
    const administration = parseFloat(administrationControl.value?.price);
    if (administration > this.maxAdministrationAmount) {
      administrationControl.setErrors({
        max: true,
        message: this.translateService.instant('claim.validation.max.administration') + this.maxAdministrationAmount
      });
      administrationControl.markAsDirty();
      administrationControl.markAsTouched();
    } else {
      this.countTotalRequest();
    }
  }

  public doUpdateValidator(isDraft: boolean) {
    if (isDraft) {
      const docClaimList = this.formGroup.controls.docClaimList as FormArray;
      docClaimList.controls.forEach(group => {
        const fileControl = group.get('file');
        fileControl.clearValidators();
        fileControl.updateValueAndValidity();
      }
      );
      this.formGroup.get('doctor').clearValidators();
      this.formGroup.get('medicine').clearValidators();
      this.formGroup.get('medicalTools').clearValidators();
      this.formGroup.get('administration').clearValidators();
      this.formGroup.get('room').clearValidators();
      this.formGroup.get('totalP3k').clearValidators();
      this.formGroup.get('totalAmbulance').clearValidators();
      this.formGroup.get('roomType').clearValidators();
      this.formGroup.get('totalP3k').setValidators(Validators.max(this.nilaiSisaP3k));
      this.formGroup.get('totalAmbulance').setValidators(Validators.max(this.nilaiSisaAmbl));
    } else {
      const docClaimList = this.formGroup.controls.docClaimList as FormArray;
      docClaimList.clearValidators();
      docClaimList.controls.forEach(group => {
        const fileControl = group.get('file');
        fileControl.setValidators(Validators.required());
        fileControl.updateValueAndValidity();
      }
      );
      this.formGroup.get('doctor').setValidators(Validators.required());
      this.formGroup.get('medicine').setValidators(Validators.required());
      this.formGroup.get('medicalTools').setValidators(Validators.required());
      this.formGroup.get('administration').setValidators(Validators.required());
      this.formGroup.get('room').setValidators(Validators.required());
      this.formGroup.get('totalP3k').setValidators(Validators.required());
      this.formGroup.get('totalAmbulance').setValidators(Validators.required());
      this.formGroup.get('roomType').setValidators(Validators.required());
    }
    if(this.isUploadReceipt){
      this.formGroup.get('receiptDoc').setValidators(Validators.required());
    }
    this.formGroup.get('doctor').updateValueAndValidity();
    this.formGroup.get('medicine').updateValueAndValidity();
    this.formGroup.get('medicalTools').updateValueAndValidity();
    this.formGroup.get('administration').updateValueAndValidity();
    this.formGroup.get('room').updateValueAndValidity();
    this.formGroup.get('totalP3k').updateValueAndValidity();
    this.formGroup.get('totalAmbulance').updateValueAndValidity();
    this.formGroup.get('roomType').updateValueAndValidity();
  }
}
