import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RecapPrintService } from 'src/app/core/services/recap-print.service';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Order } from '../../core/bean/order';
import { OrderStatus } from '../../core/bean/order-status';
//import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { DatePipe } from '@angular/common';
import { FieldFormatEnum } from 'src/app/core/components/app-table-x/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table-x/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { OrderResponseRequest } from './order-response-request';

@Component({
  templateUrl: './order-response.component.html'
})
export class OrderResponseComponent
  extends BaseModuleComponent
  implements OnInit
{
  public orderIdList: number[] = [];
  public tableResponse: TableResponseModel<Order>;
  public orderStatusOptionList: OptionListModel<OrderStatus> =
    new OptionListModel(true);
  public isNotEmpty: boolean = true;
  constructor(translate: TranslateService, public recapPrintService: RecapPrintService , private datePipe: DatePipe) {
    super('order-response', translate);
  }
  public onInit(): void {
    this.setOptionList();
    this.buildFormGroup();
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      postDate: [null],
      requiredDate: [null],
      postFromDate: [null],
      postToDate: [null],
      requiredFromDate: [null],
      requiredToDate: [null],
      statusList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<OrderStatus[]>('/order-response/get-status-list')
      .subscribe((statusList: OrderStatus[]) => {
        this.orderStatusOptionList.setRequestValues(statusList);
      });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.orderNumber',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClick.bind(this)
        }
      },
      { field: 'orderType.name', header: 'table.column.orderType' },
      {
        field: 'postDate',
        header: 'table.column.postDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'requiredDate',
        header: 'table.column.requiredDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      { field: 'title', header: 'table.column.title' },
      { field: 'organization.name', header: 'table.column.requestorDepartment' },
      {
        field: 'amount',
        header: 'table.column.totalCost',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'orderStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          field: 'orderStatus.code',
          colorMap: {
            WAITING_APPROVAL: 'WARNING',
            WAITING_CONFIRMATION: 'WARNING',
            ON_PROGRESS: 'PROGRESS',
            VENDOR_REVISION: 'FEEDBACK',
            REJECTED: 'DANGER',
            CANCELED: 'DANGER',
            CLOSED: 'DANGER',
            DONE: 'SUCCESS'
          }
        }
      },
      {
        field: 'exportStatus.name',
        header: 'table.column.statusExport',
        plugins: {
          name: 'badge',
          field: 'exportStatus.code',
          colorMap: {
            NEW: 'INFO',
            NEED_COMPLETION: 'WARNING',
            DECLINE: 'DANGER',
            DOWNLOADED: 'SUCCESS'
          }
        }
      }
    ],
    { checkBoxFn: this.isShowCheckBox.bind(this) }
    );
  }

  public isShowCheckBox(record: Order): boolean {
    let isStatusDone = false;
    this.isNotEmpty = false;
    if (record.orderStatus.code === undefined) {
      isStatusDone = false;
    } else {
      isStatusDone = true;
    }
    return isStatusDone;
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.postDate) {
      const fromDate = this.datePipe.transform(this.formGroup.value.postDate.from, 'yyyy-MM-dd');
      const untilDate = this.datePipe.transform(this.formGroup.value.postDate.to, 'yyyy-MM-dd');
      this.formGroup.patchValue({
        postFromDate: fromDate,
        postToDate: untilDate
      });
    }
    if (this.formGroup.value.requiredDate) {
      const fromDate = this.datePipe.transform(this.formGroup.value.requiredDate.from, 'yyyy-MM-dd');
      const untilDate = this.datePipe.transform(this.formGroup.value.requiredDate.to, 'yyyy-MM-dd');
      this.formGroup.patchValue({
        requiredFromDate: fromDate,
        requiredToDate: untilDate
      });
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
    this.formGroup.get('postFromDate').reset();
    this.formGroup.get('postToDate').reset();
    this.formGroup.get('requiredFromDate').reset();
    this.formGroup.get('requiredToDate').reset();
  }

  public doClick(order: Order): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('orderId', order.id);
    this.global.routerParams.set('orderStatus', order.orderStatus);
    this.global.routerParams.set('urlBackOutside', '/pages/order-response');
    if (
      order.orderStatus.code !==
      this.global.appConstant.pm.ORDER_STATUS_WAITING_CONFIRMATION
    ) {
      this.global.routerParams.set('todo', 'view');
      this.router.navigate(['/pages/order-response/detail']);
    } else {
      this.global.routerParams.set('todo', 'edit');
      this.router.navigate(['/pages/order-response/detail']);
    }
  }

  public doExport(event): void {
    event.checkedRecordList.map(order => this.orderIdList.push(order.id))
    const request = new OrderResponseRequest();
    request.orderIdList = this.orderIdList;
    request.isCheckedAll = event.isCheckedAll;
    this.loadingBlockService.showInfo(
      this.translateService.instant('app.info.preparingDownload')
    );
    this.recapPrintService
      .print('/order-response/export', request)
      .subscribe(() => {
        this.loadingBlockService.showCompleteAndClose(
        this.translateService.instant(
          'app.info.successDownloaded'
        )
      );
        this.tableResponse.reload();
      });
    this.orderIdList = [];
  }
}
