import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AddressBook } from 'src/app/core/bean/address-book';
import { File } from 'src/app/core/bean/file';
import { ItemType } from 'src/app/core/bean/item-type';
import { Pr } from 'src/app/core/bean/pr';
import { PrCancellation } from 'src/app/core/bean/pr-cancellation';
import { PrItem } from 'src/app/core/bean/pr-item';
import { PrItemBudget } from 'src/app/core/bean/pr-item-budget';
import { PrShipping } from 'src/app/core/bean/pr-shipping';
import { PrType } from 'src/app/core/bean/pr-type';
import { PrVendorReference } from 'src/app/core/bean/pr-vendor-reference';
import { ProcurementType } from 'src/app/core/bean/procurement-type';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';
import { AppPopupAddressService } from 'src/app/core/components/app-popup/app-popup-address/app-popup-address.service';
import { AppPopupChooseVendorService } from 'src/app/core/components/app-popup/app-popup-choose-vendor/app-popup-choose-vendor.service';
import { AppPopupPrItemInfoService } from 'src/app/core/components/app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { Validators } from 'src/app/core/validators';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';
import { VendorView } from 'src/app/core/view/entity/bean/vendor-view';
import { AddressPic } from '../../core/bean/address-pic';
import { PrStatus } from '../../core/bean/pr-status';
import { AppPopupPrCancellationComponent } from './app-popup-pr-cancellation.component';
import { AppPopupPrItemDistributorComponent } from './app-popup-pr-item-distributor.component';
import { AppPopupPrItemEditComponent } from './app-popup-pr-item-edit.component';
import { PrCancellationRequest } from './pr-cancellation.request';
import { PrRequest } from './pr.request';
import { PrResponse } from './pr.response';

@Component({
  templateUrl: './pr-edit-add.component.html'
})
export class PrEditAddComponent extends BaseModuleComponent implements OnInit {
  public prId: number;
  public objectId: number;
  public approvalModelPrcsId: number;
  public prResponse: PrResponse = new PrResponse();
  public prRequest: PrRequest = new PrRequest();
  public prStatus: PrStatus = new PrStatus();
  public prShipping: PrShipping = new PrShipping();
  public prItemList: PrItem[];
  public delPrItemList: PrItem[] = [];
  public vendorList: PopupVendorView[];
  public cartIdList: number[];
  public addressBookList: AddressBook[] = [];
  public prShippingList: PrShipping[] = [];
  public isSubmit = false;
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  @ViewChild('selectorItem') tableItem: AppTableXComponent;
  public tableResponseVendorReference: TableResponseModel<PopupVendorView>;
  public tableResponseItem: TableResponseModel<any>;
  public addressBookOptionList: OptionListModel<any> = new OptionListModel(
    true,
    'addressDetail'
  );
  public prShippingOptionList: OptionListModel<PrShipping> =
  new OptionListModel(true, 'address.address'); // to keep data from popup
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    true
  );
  public urlBackOutside: string;
  public currentDate: Date = new Date();
  public prCancellation: PrCancellation = new PrCancellation();
  public token: string;
  public prItemBudgetEditList: Map<number, number> = new Map<number, number>(); // budget allocation id , prItemBudget Amount
  public itemType: FormControl = new FormControl('');
  public prTypeOptionList: OptionListModel<PrType> = new OptionListModel(true);
  public appOfficialReportModel: AppOfficialReportModel;
  public isUpdated: boolean;
  public isOfficialReportNull: boolean;
  public isItemValid: boolean = true;
  public isItemTypeValid: boolean = true;
  public isCategoryNull: boolean = false;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupAddressService: AppPopupAddressService,
    public appPopupPrItemInfoService: AppPopupPrItemInfoService,
    public appPopupChooseVendor: AppPopupChooseVendorService,
    public snackbarService: SnackbarService,
    public toastService: ToastService,
    public activatedRoute: ActivatedRoute
  ) {
    super('pr', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Pr>('/pr/notification/' + this.token)
      .subscribe((pr: Pr) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('prId', pr.id);
        this.global.routerParams.set('prStatus', pr.prStatus);
        this.global.routerParams.set('urlBackOutside', '/pages/pr');
        if (
          pr.prStatus.code !== this.global.appConstant.pm.PR_STATUS_DRAFT &&
          pr.prStatus.code !== this.global.appConstant.pm.PR_STATUS_REVISION
        ) {
          this.global.routerParams.set('todo', 'view');
          this.router.navigate(['/pages/pr/detail']);
        } else {
          this.global.routerParams.set('todo', 'edit');
          this.router.navigate(['/pages/pr/edit']);
        }
      });
  }

  public setInitializationState(): void {
    this.doSetDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.prId = this.global.routerParams.get('prId');
    this.prStatus = this.global.routerParams.get('prStatus');
    this.cartIdList = this.global.routerParams.get('cartIdList');
    this.prItemList = this.global.routerParams.get('prItemList');
    this.prResponse = this.global.routerParams.get('prResponse');
    this.delPrItemList = this.global.routerParams.get('delPrItemList') || [];
    this.objectId = this.global.routerParams.get('objectId');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
    if (this.objectId) {
      this.prId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    this.global.routerParams.delete('tabResponse');
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
      // this.formGroup.get('itemType').setIsView(false);
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      organization: [null, Validators.required()],
      requestor: [null, Validators.required()],
      requiredDate: [null, Validators.required()],
      category: [null, Validators.required()],
      description: [null, Validators.required()],
      postDate: [null],
      // prType: [null, Validators.required()],
      procurementType: [null],
      documentList: [null],
      amount: [null],
      goodsAmount: [null],
      serviceAmount: [null],
      addressBook: [null],
      // itemType: [null],
      addressBookList: [null],
      isItemContract: [false],
      prShipping: [null]
    });
    this.formGroup.patchValue({
      organization: this.global.userSession.user.organization,
      requestor: this.global.userSession.user
    });
    this.formGroup.get('organization').setIsView(true);
    this.formGroup.get('requestor').setIsView(true);
    // this.formGroup.get('itemType').setIsView(false);
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<PrResponse>(
        '/pr/add-edit',
        new RouteRequestModel(this.todo, this.prId)
      )
      .subscribe((prResponse: PrResponse) => {
        if (!this.prResponse) {
          this.prResponse = prResponse;
          this.prResponse.prItemList = this.cartIdList
            ? this.prItemList
            : this.prResponse.prItemList;
        }

        if (this.cartIdList && this.prItemList) {
          const vendorIdList = this.prItemList.map(
            prItem =>
              prItem.catalog &&
              prItem.catalog.vendor &&
              prItem.catalog.vendor.id
          );
          const vendorIdExixtList = this.prResponse.vendorViewList.map(
            vendor => vendor.id
          );
          const newVendorIdList = vendorIdList.filter(
            vId => vId && !vendorIdExixtList.includes(vId)
          );
          if (newVendorIdList && newVendorIdList.length > 0) {
            this.httpClientService
              .get<PopupVendorView[]>(
                '/pr/get-vendor-view-list/' + newVendorIdList
              )
              .subscribe((vendorViewsList: PopupVendorView[]) => {
                this.prResponse.vendorViewList.push(...vendorViewsList);
                this.setStateReady();
                this.tableResponseVendorReference.setRecordList(
                  this.prResponse.vendorViewList
                );
                this.tableResponseVendorReference.reload();
              });
          }
        }
        this.itemTypeOptionList.setRequestValues(prResponse.itemTypeList);
        this.prTypeOptionList.setRequestValues(prResponse.prTypeList);
        this.appOfficialReportModel = prResponse.appOfficialReportModel;
        this.appOfficialReportModel.isGenerate = this.isUpdated;
        this.isOfficialReportNull =
          !this.appOfficialReportModel?.fileObjectList?.find(
            fileObject => !fileObject?.isDeleted
          );

        if (this.prResponse.pr) {
          const requiredDate: Date = this.prResponse.pr.requiredDate
            ? new Date(this.prResponse.pr.requiredDate)
            : null;
          const postDate: Date = this.prResponse.pr.postDate
            ? new Date(this.prResponse.pr.postDate)
            : null;
          this.formGroup.patchValue({
            id: this.prResponse.pr.id,
            organization: this.prResponse.pr.organization
              ? this.prResponse.pr.organization
              : this.global.userSession.user.organization,
            requestor: this.prResponse.requestor
              ? this.prResponse.requestor
              : this.global.userSession.user,
            requiredDate,
            category: this.prResponse.procurementTypeList,
            // prType: this.prResponse.pr.prType,
            description: this.prResponse.pr.description,
            postDate,
            procurementType: this.prResponse.pr.procurementType,
            amount: this.prResponse.pr.amount,
            goodsAmount: this.prResponse.pr.goodsAmount,
            serviceAmount: this.prResponse.pr.serviceAmount,
            documentList: this.prResponse.appUploadDocumentTableModelList
          });
          const documentList = this.global.routerParams.get('documentList');
          if (documentList) {
            this.formGroup.patchValue({
              documentList
            });
          }
          if (this.prResponse.prShippingList) {
            this.prResponse.prShippingList.forEach(prShipping => {
              this.addressBookList.push(prShipping.address);
            });
            this.addressBookOptionList.setRequestValues(this.addressBookList);
            this.prShippingOptionList.setRequestValues(this.prResponse?.prShippingList);
            this.prShipping = this.prResponse?.prShippingList?.[0];
          }
          this.prCancellation = this.prResponse.prCancellation;
          // this.setPrItemBudgetEditList();

          this.formGroup
            .get('isItemContract')
            .patchValue(this.prResponse.pr.isItemContract);
        }
        this.prStatus = this.prStatus
          ? this.prStatus
          : this.prResponse.pr?.prStatus;
        this.prItemList = this.prResponse.prItemList;
        this.doSetAmount();
        this.setStateReady();
        this.buildTableResponseItem();
        if (this.cartIdList) {
          this.doSetPrLine();
        }
        if (
          this.prItemList[0]?.catalog?.catalogType.code ===
          this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
        ) {
          this.formGroup.get('isItemContract').patchValue(true);
        }
      });
  }

  public buildTableResponseItem(): void {
    if (
      this.prResponse?.pr?.workflowModelPrcs &&
      this.prResponse?.pr?.prStatus?.code &&
      this.prResponse?.pr?.prStatus?.code !==
        this.global.appConstant.pm.PR_STATUS_DRAFT
    ) {
      this.tableResponseItem = new TableResponseModel(this.moduleCode, [
        {
          field: 'prItemImageList',
          header: 'table.column.thumbnail',
          plugins: [
            {
              name: 'custom-plugin',
              before: (tablePluginData: TablePluginData): File => {
                if (tablePluginData.value && tablePluginData.value.length > 0) {
                  return tablePluginData.value[0].file;
                }
                return null;
              }
            },
            {
              name: 'img',
              classNameMap: {
                MATERIAL: 'pvr pv-box',
                JASA: 'pvr pv-tools'
              },
              fieldClassName: 'item.itemType.code',
              onClick: !this.formGroup.isView
                ? this.doEditItem.bind(this)
                : this.doViewItem.bind(this)
            }
          ],
          isSortable: false,
          isSearchTable: false
        },
        {
          field: 'merk',
          header: 'table.column.itemName'
        },
        {
          field: 'code',
          header: 'table.column.prLine'
        },
        {
          field: 'catalog.catalogType.name',
          header: 'table.column.catalogType',
          plugins: {
            name: 'badge',
            className: 'badge-catalog',
            pill: false,
            colorMap: {
              ITEM: 'WARNING',
              VENDOR: 'INFO',
              CONTRACT: 'SUCCESS'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.row.record.catalog) {
                  return this.translateService.instant(
                    'dynamic-master-attribute.' +
                      tablePluginData.row.record.catalog.catalogType
                        .translationKey.key
                  );
                } else {
                  return 'Item';
                }
              },
              color: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.row.record.catalog) {
                  if (
                    tablePluginData.row.record.catalog.catalogType.code ===
                    this.global.appConstant.vm.CATALOG_TYPE_VENDOR
                  ) {
                    return 'VENDOR';
                  } else if (
                    tablePluginData.row.record.catalog.catalogType.code ===
                    this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
                  ) {
                    return 'CONTRACT';
                  }
                } else {
                  return 'ITEM';
                }
              }
            }
          }
        },
        {
          field: 'item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'item.itemType.code',
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (
                  tablePluginData.row.record.item.itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                ) {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.good'
                  );
                } else {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.service'
                  );
                }
              }
            }
          },
          isSortable: false
        },
        {
          field: 'workflowModelPrcs.name',
          header: 'table.column.stage'
        },
        {
          field: 'quantity',
          header: 'table.column.quantity',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              return this.global.converterService.convertDecimal(
                tablePlugin.value
              );
            }
          },
          className: 'text-right'
        },
        {
          field: 'price',
          header: 'table.column.price',
          plugins: {
            name: 'default',
            type: 'currency'
          },
          className: 'text-right'
        },
        {
          field: 'totalPrice',
          header: 'table.column.totalPrice',
          plugins: {
            name: 'default',
            type: 'currency'
          },
          className: 'text-right'
        }
      ]);
    } else {
      this.tableResponseItem = new TableResponseModel(this.moduleCode, [
        {
          field: 'prItemImageList',
          header: 'table.column.thumbnail',
          plugins: [
            {
              name: 'custom-plugin',
              before: (tablePluginData: TablePluginData): File => {
                if (tablePluginData.value && tablePluginData.value.length > 0) {
                  return tablePluginData.value[0].file;
                }
                return null;
              }
            },
            {
              name: 'img',
              classNameMap: {
                MATERIAL: 'pvr pv-box',
                JASA: 'pvr pv-tools'
              },
              fieldClassName: 'item.itemType.code',
              onClick: !this.formGroup.isView
                ? this.doEditItem.bind(this)
                : this.doViewItem.bind(this)
            }
          ],
          isSortable: false,
          isSearchTable: false
        },
        {
          field: 'merk',
          header: 'table.column.itemName'
        },
        {
          field: 'code',
          header: 'table.column.prLine'
        },
        {
          field: 'catalog',
          header: 'table.column.catalogType',
          plugins: {
            name: 'badge',
            className: 'badge-catalog',
            pill: false,
            colorMap: {
              ITEM: 'WARNING',
              VENDOR: 'INFO',
              CONTRACT: 'SUCCESS'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.value !== null) {
                  return this.translateService.instant(
                    'dynamic-master-attribute.' +
                      tablePluginData.value.catalogType.translationKey.key
                  );
                } else {
                  return 'Item';
                }
              },
              color: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.value !== null) {
                  if (
                    tablePluginData.value.catalogType.code ===
                    this.global.appConstant.vm.CATALOG_TYPE_VENDOR
                  ) {
                    return 'VENDOR';
                  } else if (
                    tablePluginData.value.catalogType.code ===
                    this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
                  ) {
                    return 'CONTRACT';
                  }
                } else {
                  return 'ITEM';
                }
              }
            }
          }
        },
        {
          field: 'item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            field: 'item.itemType.code',
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (
                  tablePluginData.row.record.item.itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                ) {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.good'
                  );
                } else {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.service'
                  );
                }
              }
            }
          },
          isSortable: false
        },
        {
          field: 'quantity',
          header: 'table.column.quantity',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              return this.global.converterService.convertDecimal(
                tablePlugin.value
              );
            }
          },
          className: 'text-right'
        },
        {
          field: 'price',
          header: 'table.column.price',
          plugins: {
            name: 'default',
            type: 'currency'
          },
          className: 'text-right'
        },
        {
          field: 'totalPrice',
          header: 'table.column.totalPrice',
          plugins: {
            name: 'default',
            type: 'currency'
          },
          className: 'text-right'
        }
      ]);
    }

    this.tableResponseVendorReference = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'name',
          header: 'table.column.vendorName'
        },
        {
          field: 'address',
          header: 'table.column.address'
        },
        {
          field: 'phone',
          header: 'table.column.phone'
        },
        {
          field: 'averageRatingOverall',
          header: 'table.column.rating',
          plugins: {
            name: 'rating',
            isView: true
          }
        }
      ]
    );

    this.tableResponseItem.setRecordList(this.prItemList);
    this.tableResponseItem.reload();
    this.prResponse.prItemList = this.prItemList;
    this.tableResponseVendorReference.setRecordList(
      this.prResponse.vendorViewList
    );
  }

  public doAddItem(): void {
    const category = this.formGroup.get('category').value;
    if (category !== null){
      this.isCategoryNull = false;
      this.prResponse.prItemList = this.prItemList;
      this.prResponse.pr = this.formGroup.value;
      this.prResponse.pr.prStatus = this.prStatus;
      this.prResponse.prShippingList = this.prResponse.prShippingList;
      this.prResponse.procurementTypeList = this.formGroup.get('category').value;
      this.global.routerParams.set(
        'documentList',
        this.formGroup.value.documentList
      );
      this.global.routerParams.set('todo', this.todo);
      this.global.routerParams.set('prResponse', this.prResponse);
      this.global.routerParams.set('backToUrl', this.router.url);
      this.global.routerParams.set('urlBackOutside', '/pages/pr');
      this.global.routerParams.set('delPrItemList', this.delPrItemList);
      this.global.routerParams.set(
        'prItemBudgetEditList',
        this.prItemBudgetEditList
      );
      this.formGroup.reset();
      this.router.navigate(['/pages/pr/add-item']);
    }else{
      this.isCategoryNull = true;
    }
  }

  public doEditItem(prItemEdit): void {
    const prResponse = this.prResponse;
    const prItemBudgetEditList = this.prItemBudgetEditList;
    this.appPopupService
      .open(
        AppPopupPrItemEditComponent,
        { prItemEdit, prResponse, prItemBudgetEditList },
        { size: 'xl' }
      )
      .subscribe(prItemTemp => {
        prItemTemp.crudOperation =
          this.global.appConstant.core.CRUD_OPERATION_UPDATE;
        this.prItemList.forEach((prItem, index) => {
          if (JSON.stringify(prItem) === JSON.stringify(prItemEdit)) {
            this.prItemList[index] = prItemTemp;
          }
        });
        this.prResponse.prItemList = this.prItemList;
        this.tableResponseItem.setRecordList(this.prItemList);
        this.tableResponseItem.reload();
        this.doSetAmount();
        this.doSetPrLine();
      });
  }

  public doDeleteItem(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.selectedRecordList.forEach((record: PrItem) => {
            const indexOfRecord = this.tableResponseItem
              .getRecordList()
              .findIndex(r => r.code === record.code);
            if (indexOfRecord !== -1 && record.id) {
              const prItem = this.prResponse.prItemList.find(
                prItems => prItems.code === record.code
              );
              prItem.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_DELETE;
              this.delPrItemList.push(prItem);
            }
            this.tableResponseItem.getRecordList().splice(indexOfRecord, 1);
          });
          this.tableResponseItem.reload();
          this.tableResponseVendorReference.reload();
          this.doSetAmount();
          this.doSetPrLine();
          this.formGroup.markAsDirty();
        }
      });
  }

  public doSetPrLine(): void {
    this.tableResponseItem.getRecordList().forEach((prItem, index) => {
      prItem.code = '00' + (+index + +1);
    });
  }

  public doViewItem(prItem: PrItem): void {
    this.appPopupPrItemInfoService.open(prItem.id, true);
  }

  public onChangeItem(): void {
    const itemType = this.itemType.value;
    if (itemType) {
      const prItemList = [];
      this.prItemList.forEach((prItem: PrItem) => {
        if (prItem.item.itemType.id === itemType.id) {
          prItemList.push(prItem);
        }
      });
      this.tableResponseItem.setRecordList(prItemList);
    } else {
      this.tableResponseItem.setRecordList(this.prItemList);
    }
    this.tableResponseItem.reload();
    this.doSetAmount();
  }

  public doSetAmount(): void {
    let goodsAmountTotal = 0;
    let serviceAmountTotal = 0;
    this.prItemList.forEach(prItem => {
      if (
        prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      ) {
        goodsAmountTotal = +goodsAmountTotal + +prItem.totalPrice;
      }
      if (
        prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
      ) {
        serviceAmountTotal = +serviceAmountTotal + +prItem.totalPrice;
      }
    });
    this.formGroup.patchValue({
      goodsAmount: goodsAmountTotal,
      serviceAmount: serviceAmountTotal,
      amount: +goodsAmountTotal + +serviceAmountTotal
    });
  }

  public doAddVendorReference(): void {
    const vendorList = this.prResponse.vendorViewList;
    this.appPopupChooseVendor
      .openPr(vendorList)
      .subscribe(vendorListTempList => {
        this.vendorList = [];
        vendorListTempList.forEach(vendorTemp => [
          this.vendorList.push(vendorTemp)
        ]);
        this.prResponse.vendorViewList = this.vendorList;
        this.tableResponseVendorReference.setRecordList(
          this.prResponse.vendorViewList
        );
        this.tableResponseVendorReference.reload();
        this.formGroup.markAsDirty();
      });
  }

  public doDeleteVendorReference(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.selectedRecordList.forEach((record: VendorView) => {
            const indexOfRecord = this.tableResponseVendorReference
              .getRecordList()
              .findIndex(r => r.id === record.id);
            this.tableResponseVendorReference
              .getRecordList()
              .splice(indexOfRecord, 1);
          });
          this.tableResponseVendorReference.reload();
          this.formGroup.markAsDirty();
        }
      });
  }

  public doAddShipping(): void {
    const addressBookList = this.addressBookList;
    this.appPopupAddressService
      .open(addressBookList, true)
      .subscribe(addressBookListTemp => {
        const prShippingList: PrShipping[] = [];
        this.addressBookList = [];
        const prShipping: PrShipping = new PrShipping();
        prShipping.address = addressBookListTemp;
        this.addressBookList.push(addressBookListTemp);
        prShippingList.push(prShipping);
        this.prShipping = prShipping;
        this.formGroup.patchValue({
          addressBookList: this.addressBookList
        });
        this.prShippingOptionList.setRequestValues(prShippingList);
        this.formGroup.markAsDirty();
      });
  }

  public doDeleteShipping(): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.prShippingOptionList.setRequestValues([]);
          this.formGroup.patchValue({
            prShipping: null
          });
          this.formGroup.markAsDirty();
        }
      });
  }

  public doSave(): void {
    this.formGroup.get('documentList').clearValidators();
    this.formGroup.get('documentList').updateValueAndValidity();
    this.validate();
    this.setValidasiItem();

    if (this.formGroup.valid && this.isItemValid && this.isItemTypeValid) {
      this.formGroup
        .get('organization')
        .patchValue(this.global.userSession.user.organization);
      const procurementTypeList: ProcurementType[] =
        this.formGroup.get('category').value;
      this.formGroup.value.procurementType =
        procurementTypeList[procurementTypeList.length - 1];
      this.prRequest.pr = this.formGroup.value;
      this.prRequest.pr.organizationTenant =
        this.global.userSession.organizationTenant;
      this.prRequest.pr.organizationDelegated =
        this.global.userSession.organizationTenant;
      this.prRequest.appUploadDocumentTableModelList =
        this.formGroup.value.documentList;
      this.prRequest.appOfficialReportModel = this.appOfficialReportModel;
      this.prResponse.prItemList.forEach((prItem, index) => {
        prItem.code = '00' + (+index + +1);
      });
      const prItemLists = this.prResponse.prItemList.concat(this.delPrItemList);
      prItemLists.forEach(prItem => {
        if (prItem.crudOperation === null) {
          prItem.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_UPDATE;
        }
      });
      this.prRequest.prItemList = prItemLists;

      // pr vendor
      if (this.todo === 'add') {
        this.tableResponseVendorReference.getRecordList().forEach(vendor => {
          const prVendorReference = new PrVendorReference();
          prVendorReference.vendor.id = vendor.id;
          this.prRequest.prVendorReferenceList.push(prVendorReference);
        });
      } else {
        const existVendorIdList = this.prResponse.prVendorReferenceList.map(
          prVendorReference => prVendorReference.vendor.id
        );
        const newVendorList = this.tableResponseVendorReference
          .getRecordList()
          .filter(v => !existVendorIdList.includes(v.id));

        if (newVendorList.length > 0) {
          newVendorList.forEach(vendor => {
            const prVendorReference = new PrVendorReference();
            prVendorReference.vendor.id = vendor.id;
            prVendorReference.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
            this.prRequest.prVendorReferenceList.push(prVendorReference);
          });
        }
        const vendorIdList = this.tableResponseVendorReference
          .getRecordList()
          .map(vendor => vendor.id);
        const deleteProcVList = this.prResponse.prVendorReferenceList.filter(
          prVendorReference =>
            !vendorIdList.includes(prVendorReference.vendor.id)
        );
        deleteProcVList.forEach(procV => {
          procV.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
        });
        this.prRequest.prVendorReferenceList.push(...deleteProcVList);
        this.prRequest.prVendorReferenceList.forEach(prvendorRef => {
          if (prvendorRef.crudOperation === null) {
            prvendorRef.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_UPDATE;
          }
        });
      }
      // pr shipping
      this.prRequest.prShippingList = [];
      if (this.todo === 'add') {
        this.addressBookList.forEach(address => {
          const prShipping: PrShipping = new PrShipping();
          prShipping.address = address;
          this.prRequest.prShippingList.push(prShipping);
        });
      } else {
        const existAddressIdList = this.prResponse.prShippingList.map(
          existPrShipping => existPrShipping.address.id
        );
        const newAddressList = this.addressBookList.filter(
          address => !existAddressIdList.includes(address.id)
        );

        if (newAddressList.length > 0) {
          newAddressList.forEach(adress => {
            const prShipping = new PrShipping();
            prShipping.address = adress;
            prShipping.address.id = adress.id;
            prShipping.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
            this.prRequest.prShippingList.push(prShipping);
          });
        }
        const addressIdList = this.addressBookList.map(address => address.id);
        const deletePrShippingList = this.prResponse.prShippingList.filter(
          prShipping => !addressIdList.includes(prShipping.address.id)
        );
        deletePrShippingList.forEach(prShip => {
          prShip.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
        });
        this.prRequest.prShippingList.push(...deletePrShippingList);
        this.prRequest.prShippingList.forEach(prShip => {
          if (prShip.crudOperation === null) {
            prShip.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_UPDATE;
          }
        });
      }
      this.prRequest.isSubmit = false;
      this.prRequest.cartIdList = this.cartIdList;
      this.setStateProcessing();
      const url = this.todo === 'edit' ? '/pr/update' : '/pr/insert';
      this.httpClientService
        .post<Response<Pr>>(url, this.prRequest)
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.snackbarService.showWarning('app.msg.info.sucsessSave');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set('prId', response.body.id);
                this.global.routerParams.set('urlBackOutside', '/pages/pr');
                this.global.routerParams.set('todo', 'edit');
                this.router.navigate(['/pages/pr/edit']);
              });
          } else {
            this.global.alertService.showError(
              response.statusText
            );
            this.setStateReady();
          }
        });
    }
  }

  public setValidasiItem(): void {
    this.isItemValid = true;
    this.isItemTypeValid = true;
    const prStatus = this.prStatus;
    let category;
    if (this.formGroup.get('category').value[0].code ===
      this.global.appConstant.pm.PROCUREMENT_TYPE_NON_CONSTRUCTION){
        category = this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL;
    }else if (this.formGroup.get('category').value[0].code ===
      this.global.appConstant.pm.PROCUREMENT_TYPE_CONSTRUCTION){
        category = this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE;
    }
    this.prItemList.forEach(prItem => {
      if (prStatus?.code === this.global.appConstant.pm.PR_STATUS_REVISION){
        if (prItem.catalog.catalogStatus.code === this.global.appConstant.vm.CATALOG_CONTRACT_STATUS_NON_ACTIVE) {
          this.isItemValid = false;
        } else if (prItem.catalog.item.itemType.code !== category){
          this.isItemTypeValid = false;
        }
      } else {
        if (prItem.catalog.item.itemType.code !== category){
          this.isItemTypeValid = false;
        }
      }
    });
  }

  public doSubmit(): void {
    this.appOfficialReportModel.fileObjectList.forEach(fileObject => {
      fileObject.isUploaded = true;
    });

    this.setValidasiItem();

    if (
      this.appOfficialReportModel.fileObjectList.length === 0 ||
      this.appOfficialReportModel.fileObjectList.filter(
        fileObject => fileObject.isUploaded
      ).length === 0 ||
      this.appOfficialReportModel.letterDate === null ||
      this.appOfficialReportModel.letterNumber === null
    ) {
       this.isOfficialReportNull = true;
     }else if (
      this.isItemValid && this.isItemTypeValid) {
      this.isSubmit = true;
      this.validate();
      if (
        this.formGroup.valid &&
        this.prResponse.prItemList.length > 0
         &&
         this.appOfficialReportModel.isGenerate
      ) {
        this.global.modalService
          .submitConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.formGroup
                .get('organization')
                .patchValue(this.global.userSession.user.organization);
              const procurementTypeList: ProcurementType[] =
                this.formGroup.get('category').value;
              this.formGroup.value.procurementType =
                procurementTypeList[procurementTypeList.length - 1];
              this.prRequest.pr = this.formGroup.value;
              this.prRequest.pr.organizationTenant =
                this.global.userSession.organizationTenant;
              this.prRequest.pr.organizationDelegated =
                this.global.userSession.organizationTenant;
              this.prRequest.appUploadDocumentTableModelList =
                this.formGroup.value.documentList;
              this.prRequest.appOfficialReportModel =
                this.appOfficialReportModel;
              this.prResponse.prItemList.forEach((prItem, index) => {
                prItem.code = '00' + (+index + +1);
              });
              const prItemLists = this.prResponse.prItemList.concat(
                this.delPrItemList
              );
              prItemLists.forEach(prItem => {
                if (prItem.crudOperation === null) {
                  prItem.crudOperation =
                    this.global.appConstant.core.CRUD_OPERATION_UPDATE;
                }
              });
              this.prRequest.prItemList = prItemLists;
              this.prRequest.isSubmit = true;
              this.prRequest.cartIdList = this.cartIdList;
              // pr vendor
              if (this.todo === 'add') {
                this.tableResponseVendorReference
                  .getRecordList()
                  .forEach(vendor => {
                    const prVendorReference = new PrVendorReference();
                    prVendorReference.vendor.id = vendor.id;
                    this.prRequest.prVendorReferenceList.push(
                      prVendorReference
                    );
                  });
              } else {
                const existVendorIdList =
                  this.prResponse.prVendorReferenceList.map(pv => pv.vendor.id);
                const newVendorList = this.tableResponseVendorReference
                  .getRecordList()
                  .filter(v => !existVendorIdList.includes(v.id));

                if (newVendorList.length > 0) {
                  newVendorList.forEach(vendor => {
                    const prVendorReference = new PrVendorReference();
                    prVendorReference.vendor.id = vendor.id;
                    prVendorReference.crudOperation =
                      this.global.appConstant.core.CRUD_OPERATION_INSERT;
                    this.prRequest.prVendorReferenceList.push(
                      prVendorReference
                    );
                  });
                }
                const vendorIdList = this.tableResponseVendorReference
                  .getRecordList()
                  .map(vendor => vendor.id);
                const deleteProcVList =
                  this.prResponse.prVendorReferenceList.filter(
                    pv => !vendorIdList.includes(pv.vendor.id)
                  );
                deleteProcVList.forEach(procV => {
                  procV.crudOperation =
                    this.global.appConstant.core.CRUD_OPERATION_DELETE;
                });
                this.prRequest.prVendorReferenceList.push(...deleteProcVList);
                this.prRequest.prVendorReferenceList.forEach(prvendorRef => {
                  if (prvendorRef.crudOperation === null) {
                    prvendorRef.crudOperation =
                      this.global.appConstant.core.CRUD_OPERATION_UPDATE;
                  }
                });
              }

              // pr shipping
              this.prRequest.prShippingList = [];
              if (this.todo === 'add') {
                this.addressBookList.forEach(address => {
                  const prShipping: PrShipping = new PrShipping();
                  prShipping.address = address;
                  this.prRequest.prShippingList.push(prShipping);
                });
              } else {
                const existAddressIdList = this.prResponse.prShippingList.map(
                  existPrShipping => existPrShipping.address.id
                );
                const newAddressList = this.addressBookList.filter(
                  address => !existAddressIdList.includes(address.id)
                );

                if (newAddressList.length > 0) {
                  newAddressList.forEach(address => {
                    const prShipping = new PrShipping();
                    prShipping.address.id = address.id;
                    prShipping.address = address;
                    prShipping.crudOperation =
                      this.global.appConstant.core.CRUD_OPERATION_INSERT;
                    this.prRequest.prShippingList.push(prShipping);
                  });
                }
                const addressIdList = this.addressBookList.map(
                  address => address.id
                );
                const deletePrShippingList =
                  this.prResponse.prShippingList.filter(
                    prShipping => !addressIdList.includes(prShipping.address.id)
                  );
                deletePrShippingList.forEach(prShip => {
                  prShip.crudOperation =
                    this.global.appConstant.core.CRUD_OPERATION_DELETE;
                });
                this.prRequest.prShippingList.push(...deletePrShippingList);
                this.prRequest.prShippingList.forEach(prShip => {
                  if (prShip.crudOperation === null) {
                    prShip.crudOperation =
                      this.global.appConstant.core.CRUD_OPERATION_UPDATE;
                  }
                });
              }

              this.setStateProcessing();
              const url = this.todo === 'edit' ? '/pr/update' : '/pr/insert';
              this.httpClientService
                .post<Response<Pr>>(url, this.prRequest)
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.global.modalService
                      .submitSuccessCreateNew()
                      .pipe(take(1))
                      .subscribe(result => {
                        if (result) {
                          this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(() => {
                              this.global.routerParams.clear();
                              this.global.routerParams.set('todo', 'add');
                              this.router.navigate(['/pages/pr/add']);
                              this.global.routerParams.set(
                                'urlBackOutside',
                                '/pages/pr'
                              );
                            });
                        } else {
                          this.router.navigate(['/pages/pr/']);
                        }
                      });
                  } else {
                    this.global.alertService.showError(
                      response.statusText
                    );
                    this.setStateReady();
                  }
                });
            }
          });
      }
    }
  }

  public doCancel(): void {
    const pr = this.prResponse.pr;
    this.appPopupService
      .open(AppPopupPrCancellationComponent, { pr })
      .subscribe(prCancellationTemp => {
        if (prCancellationTemp) {
          const prCancellation = new PrCancellation();
          prCancellation.note = prCancellationTemp.note;
          const prCancellationRequest = new PrCancellationRequest();
          prCancellationRequest.prCancellation = prCancellation;
          prCancellationRequest.pr = pr;
          this.setStateProcessing();
          this.httpClientService
            .post<Response<PrCancellation>>('/pr/cancel', prCancellationRequest)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.prCancellation = response.body;
                this.prResponse.prCancellation = this.prCancellation;
                this.prStatus = this.prCancellation.pr.prStatus;
                this.setStateReady();
                this.setViewOnly();
              } else {
                this.toastService.showError(response.statusText);
                this.setStateReady();
              }
            });
        }
      });
  }

  public getUserNameList(addressPicList: AddressPic[]): string {
    return addressPicList.map(a => a.user.name).join(', ');
  }

  public setPrItemBudgetEditList(): void {
    if (this.todo !== 'add') {
      this.prResponse.prItemList.forEach((prItem: PrItem) => {
        prItem.prItemBudgetList.forEach((prItemBudget: PrItemBudget) => {
          if (prItemBudget.id) {
            this.prItemBudgetEditList.set(
              prItemBudget.budgetAllocation.id,
              prItemBudget.bookedAmount
            );
          }
        });
      });
    }
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
          '.' +
          data.translationKey.key || '-'
      );
    }
  }

  public onChangeOfficialReport(event: AppOfficialReportModel): void {
    this.appOfficialReportModel = event;
    this.isOfficialReportNull =
      !this.appOfficialReportModel?.fileObjectList?.find(
        fileObject => !fileObject?.isDeleted
      );
  }

  public doRequestItemToDistributor(): void {
    if (this.prResponse.organization.isDistributor && this.global.userSession.user.organization.parentId !== null) {
      this.appPopupService
      .open(AppPopupPrItemDistributorComponent)
      .subscribe(result => {
        if (result) {
          this.prRequest.user = this.global.userSession.user;
          this.prRequest.messageRequestItemToDistributor = result.messageRequestItemToDistributor;
          this.prRequest.organization = this.prResponse.organization;
          this.setStateProcessing();
          this.httpClientService
            .post<Response<PrRequest>>('/pr/notification-pr-item-to-distributor', this.prRequest)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.modalService
                .submitSuccessCreateNew()
                .pipe(take(1))
                .subscribe(result => {
                  if (result) {
                    this.router
                      .navigateByUrl('/', { skipLocationChange: true })
                      .then(() => {
                        this.global.routerParams.clear();
                        this.global.routerParams.set('todo', 'add');
                        this.router.navigate(['/pages/pr/add']);
                        this.global.routerParams.set(
                          'urlBackOutside',
                          '/pages/pr'
                        );
                      });
                  } else {
                    this.router.navigate(['/pages/pr']);
                  }
                  this.setStateReady();
                });
              } else {
                this.toastService.showError(response.statusText);
                this.setStateReady();
              }
            });
        }
      });
    } else {
      this.global.alertService.showError(this.translateService.instant(
        'pr.errorRequestItemToDistributor'
      ));
    }
  }
}
