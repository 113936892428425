import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Catalog } from '../../core/bean/catalog';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { CatalogViewManage } from 'src/app/core/bean/catalog-view-manage';
@Component({
  templateUrl: './catalog-verification.component.html',
  styles: ['.width-company-name { min-width : 250px; white-space: normal ! important }'],
  encapsulation: ViewEncapsulation.None
})
export class CatalogVerficationComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<Catalog>;

  constructor(translateService: TranslateService) {
    super('catalog-verification', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [


      { field: 'merk', header: 'table.column.merk', customClass: 'width-company-name' },
      // { field: 'catalogType.name', header: 'table.column.catalogTypeName' },
      { field: 'vendor.name', header: 'table.column.vendorName' },
      { field: 'catalogAttributeGroup.name', header: 'table.column.catalogAttributeGroup', customClass: 'text-center' },
      // { field: 'price', header: 'table.column.price', customClass: 'text-right', format: FieldFormatEnum.Currency, currencyCodeRef: 'currency.code' },
      { field: 'item.uom.name', header: 'table.column.catalogUomName', customClass: 'text-center' },
      { field: 'submissionDate', header: 'table.column.submissionDate', customClass: 'text-center', format: FieldFormatEnum.ShortDate },
      { field: 'catalogStatus.name', header: 'table.column.catalogStatusName', customClass: 'text-center' }
    ]);
    this.setStateReady();
  }

  public doEdit(catalog: CatalogViewManage): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('catalog', catalog);
    this.global.routerParams.set('catalogId', catalog.id);
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('isGuest', true);
    this.global.routerParams.set('header', 'catalog-verification.title');
    this.router.navigate(['/pages/catalog-verification/edit']);
  }
}
